import React, { useState } from 'react';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
    Input,
    Label,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
} from 'reactstrap';

import authapi from '~/services/authapi';
import FieldValidation from '~/components/FieldValidation';
import { useNotifyContext } from '~/hooks/useNotifyContext';
import history from '~/services/history';

export default function AlterarSenha() {
    // consts
    const { identificacaoUsuario } = useParams();

    // states
    const [loading, setLoading] = useState(false);

    // hooks
    const notificacao = useNotifyContext();

    const { register, handleSubmit, errors, formState, reset } = useForm({
        validationSchema: yup.object().shape({
            code: yup.string().required('Campo obrigatório'),
            password: yup.string().required('Campo obrigatório'),
            passwordConfirm: yup
                .string()
                .oneOf([yup.ref('password'), null], 'Senha não confere'),
        }),
    });

    const onSubmit = async (data) => {
        try {
            await authapi.post('autenticacao/alterar-senha', {
                userUUID: identificacaoUsuario,
                recoverCode: data.code,
                NewPassword: data.password,
                NewPasswordConfirm: data.passwordConfirm,
            });
            notificacao.sucesso('Sua senha foi alterada com sucesso.');
            reset();
            history.push("/");
        } catch (error) {
            notificacao.respostaHttp(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container">
            <div className="col-lg-4 col-md-6 ml-auto mr-auto">
                <form className="form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="card card-login">
                        <div className="card-header ">
                            <div className="card-header ">
                                <p
                                    className="text-center mt-1 mb-4"
                                    style={{
                                        fontSize: '20px',
                                        fontFamily: 'Inter, sans-serif',
                                        color: '#57607A',
                                    }}
                                >
                                    Alterar{' '}
                                    <strong style={{ color: '#41898C' }}>
                                        Senha
                                    </strong>
                                </p>

                                <p
                                    className="card-description text-center"
                                    style={{
                                        fontFamily: 'Inter, sans-serif',
                                        color: '#57607A',
                                    }}
                                >
                                    Altere sua senha utilizando os campos abaixo
                                </p>
                            </div>
                        </div>
                        <div className="card-body ">
                            <InputGroup className="boxButton">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="nc-icon nc-key-25" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    className="boxButtun"
                                    name="code"
                                    type="text"
                                    placeholder="Código de Recuperação..."
                                    innerRef={register}
                                    maxLength={20}
                                />
                                <FieldValidation error={errors?.code} />
                            </InputGroup>
                            <InputGroup className="boxButton">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="nc-icon nc-key-25" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    name="password"
                                    type="password"
                                    placeholder="Nova Senha..."
                                    innerRef={register}
                                    maxLength={20}
                                />
                                <FieldValidation error={errors?.password} />
                            </InputGroup>
                            <InputGroup className="boxButton">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="nc-icon nc-key-25" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    name="passwordConfirm"
                                    type="password"
                                    placeholder="Confirmar Nova Senha..."
                                    innerRef={register}
                                    maxLength={20}
                                />
                                <FieldValidation
                                    error={errors?.passwordConfirm}
                                />
                            </InputGroup>
                            <Label>
                                Sua senha deve ter no mínimo 08 caracteres entre
                                letras números e caracteres especiais (@, $,
                                %,...).
                            </Label>
                        </div>
                        <div className="card-footer">
                            <button
                                type="submit"
                                className="boxSenha btn btn-primary btn-round btn-block mb-3"
                            >
                                <i
                                    className="icon-icon-troca ml-4"
                                    style={{ fontSize: '24px' }}
                                />
                                <p className="ml-3">Alterar Senha</p>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
