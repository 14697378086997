import React, { useState, useEffect } from 'react';
import {
    useTable,
    useSortBy,
    usePagination,
    useGlobalFilter,
    useAsyncDebounce,
    useRowSelect,
    useFilters,
} from 'react-table';

import { Row, Col, Input } from 'reactstrap';
import { useMediaQuery } from 'react-responsive';

import Pagination from './components/Pagination';
import GlobalFilter from './components/GlobalFilter';
import SelectExtraFilter from './components/SelectExtraFilter';
import TableDataList from './components/DataList/TableDataList';
import CardDataList from './components/DataList/CardDataList';

function DataTable({
    columns,
    data,
    fetchData,
    loading = true,
    pageCount: controlledPageCount,
    total,
    reload = false,
    includeFilter = false,
    includePageSize = true,
    includePagination = true,
    includeTotalRecords = true,
    extraFilterOneTitle = '',
    extraFilterOneValues = [],
    extraFilterOneInitialValue = '',
    setSelectedRows,
    defaultPageSize = 10,
    isAtivo = false,
    tableRowUpdate = null,
}) {
    const isXs = useMediaQuery({
        query: '(min-width: 0px) and (max-width: 575px)',
    });

    const [extraFilterOne, setExtraFilterOne] = useState(extraFilterOneInitialValue);

    const {
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        pageCount,
        gotoPage,
        nextPage,
        setPageSize,
        previousPage,
        state: { sortBy, pageIndex, pageSize, globalFilter },
        setGlobalFilter,
        selectedFlatRows,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 1,
                sortBy: [],
                pageSize: defaultPageSize,
            },
            manualPagination: true,
            manualGlobalFilter: true,
            manualSortBy: true,
            pageCount: controlledPageCount + 1,
            tableRowUpdate,
            isAtivo,
        },
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect
    );

    const onFetchDataDebounced = useAsyncDebounce(fetchData, 300);

    useEffect(() => {
        let sort = '';
        let desc = false;

        if (sortBy.length > 0) {
            sort = sortBy[0].id;
            desc = sortBy[0].desc;
        }
        onFetchDataDebounced({
            pageIndex,
            pageSize,
            sort,
            desc,
            globalFilter,
            extraFilterOne,
        });

    }, [pageIndex, pageSize, sortBy, globalFilter, extraFilterOne, reload]);

    useEffect(() => {
        if (setSelectedRows) {
            setSelectedRows(selectedFlatRows?.map((row) => row.original));
        }
    }, [setSelectedRows, selectedFlatRows]);

    return (
        <>
            {includeTotalRecords && (
                <Row className="mr-0 mr-sm-4">
                    <Col sm="12" md="12" lg="12">
                        <div
                            id="totalregistros"
                            className="float-right"
                            >Total de Registros: {total}</div>
                    </Col>
                </Row>
            )}

            <Row className="mb-2">
                <Col
                    xs={12}
                    sm={extraFilterOneValues.length > 0 ? 6 : 9}
                    md={extraFilterOneValues.length > 0 ? 6 : 9}
                    lg={extraFilterOneValues.length > 0 ? 8 : 10}
                    mt={1}
                >
                </Col>
                {includeFilter && (
                    <Col
                        xs="12"
                        sm="3"
                        md="3"
                        lg="2"
                        className="pull-right mt-1"
                    >
                        <GlobalFilter
                            globalFilter={globalFilter}
                            setGlobalFilter={setGlobalFilter}
                        />
                    </Col>
                )}
                {extraFilterOneValues.length > 0 && (
                    <Col
                        xs="12"
                        sm="3"
                        md="3"
                        lg="2"
                        className="pull-right mt-1"
                    >
                        <SelectExtraFilter
                            title={extraFilterOneTitle}
                            values={extraFilterOneValues}
                            setExtraFilterOne={setExtraFilterOne}
                        />
                    </Col>
                )}
            </Row>

            {!isXs ? (
                <TableDataList
                    headerGroups={headerGroups}
                    getTableBodyProps={getTableBodyProps}
                    includePagination={includePagination}
                    page={page}
                    rows={rows}
                    prepareRow={prepareRow}
                    loading={loading}
                />
            ) : (
                <>
                    <CardDataList
                        headerGroups={headerGroups}
                        includePagination={includePagination}
                        page={page}
                        rows={rows}
                        prepareRow={prepareRow}
                        loading={loading}
                    />
                </>
            )}
            <div className='mx-sm-3 m-0' style={{alignItems: 'center',}}>
            <Pagination
                includePagination={includePagination}
                pageCount={pageCount}
                pageIndex={pageIndex}
                gotoPage={gotoPage}
                nextPage={nextPage}
                previousPage={previousPage}
            />

            {includePageSize ? (
                <div className='float-left' style={{ display: 'flex' }}><p style={{ marginTop: '20px' }}>Visualizar</p>
                    <Input
                        type="select"
                        className="filter-input mx-2"
                        value={pageSize}
                        style={{ border: "1px solid #dee2e6", width: '70px', backgroundColor: 'transparent' }}
                        onChange={(e) => {
                            setPageSize(Number(e.target.value));
                        }}
                    >
                        {[10, 30, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </Input> <p style={{ marginTop: '20px' }}>por página</p></div>
            ) : null}
            </div>
        </>
    );
}

// DataTable No Server Side Pagination
function DataTableNSSP({
    columns,
    data,
    fetchData,
    loading = false,
    total,
    includeFilter = true,
    includePageSize = true,
    includePagination = true,
    includeTotalRecords = true,
    extraFilterOneField = '',
    extraFilterOneTitle = '',
    extraFilterOneValues = [],
    extraFilterOneInitialValue = '',
    setSelectedRows,
    isAtivo = false,
    tableRowUpdate = null,
    defaultPageSize = 10,
    reload = false,
}) {
    const isXs = useMediaQuery({
        query: '(min-width: 0px) and (max-width: 575px)',
    });

    const [extraFilterOne, setExtraFilterOne] = useState(
        extraFilterOneInitialValue
    );

    const {
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        pageCount,
        gotoPage,
        setPageSize,
        state: { sortBy, pageIndex, pageSize, globalFilter, selectedRowIds },
        setGlobalFilter,
        setFilter,
        setHiddenColumns,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: [],
                pageSize: defaultPageSize,
                hiddenColumns: columns.map((column) => column.id),
            },
            tableRowUpdate,
            isAtivo,
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect
    );

    const onFetchDataDebounced = useAsyncDebounce(fetchData, 300);

    useEffect(() => {
        let sort = '';
        let desc = false;

        if (sortBy.length > 0) {
            sort = sortBy[0].id;
            desc = sortBy[0].desc;
        }

        if (fetchData) {
            onFetchDataDebounced({
                pageIndex,
                pageSize,
                sort,
                desc,
                globalFilter,
                extraFilterOne,
            });
        }
    }, [onFetchDataDebounced, reload]);

    useEffect(() => {
        if (setSelectedRows) {
            const selectedIds = Object.keys(selectedRowIds);
            var selectedRowsData = selectedIds
                .map((x) => data[x])
                .filter(function (x) {
                    return x != null;
                });
            setSelectedRows(selectedRowsData);
        }
    }, [selectedRowIds]);

    useEffect(() => {
        if (extraFilterOneField) {
            setFilter(extraFilterOneField, extraFilterOne || undefined);
        } else if (extraFilterOneField && !extraFilterOneField)
            setFilter(extraFilterOneField, undefined);
    }, [extraFilterOne]);

    useEffect(() => {
        setHiddenColumns(
            columns
                .filter((column) => !column.show && column.show != undefined)
                .map((column) => column.id)
        );
    }, [columns]);

    return (
        <>
            {includeTotalRecords && (
                <Row className="mb-0">
                    <Col sm="12" md="12" lg="12">
                        Total de Registros: {total}
                    </Col>
                </Row>
            )}
            <Row className="mb-2">
                <Col sm="12" md="6" lg="6">
                    {includePageSize ? (
                        <Input
                            type="select"
                            className="filter-input mt-1"
                            value={pageSize}
                            onChange={(e) => {
                                setPageSize(Number(e.target.value));
                            }}
                        >
                            {[ 10, 30, 50].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                </option>
                            ))}
                        </Input>
                    ) : null}
                </Col>

                <Col sm="12" md="3" lg="3" className="mt-1">
                    {includeFilter ? (
                        <GlobalFilter
                            globalFilter={globalFilter}
                            setGlobalFilter={setGlobalFilter}
                        />
                    ) : null}
                </Col>
                <Col sm="12" md="3" lg="3" className="mt-1">
                    {extraFilterOneValues.length > 0 && (
                        <SelectExtraFilter
                            title={extraFilterOneTitle}
                            values={extraFilterOneValues}
                            setExtraFilterOne={setExtraFilterOne}
                        />
                    )}
                </Col>
            </Row>
            {!isXs ? (
                <TableDataList
                    headerGroups={headerGroups}
                    getTableBodyProps={getTableBodyProps}
                    includePagination={includePagination}
                    page={page}
                    rows={rows}
                    prepareRow={prepareRow}
                    loading={loading}
                />
            ) : (
                <>
                    <CardDataList
                        headerGroups={headerGroups}
                        includePagination={includePagination}
                        page={page}
                        rows={rows}
                        prepareRow={prepareRow}
                        loading={loading}
                    />
                </>
            )}
            <Pagination
                includePagination={includePagination}
                pageCount={pageCount}
                pageIndex={pageIndex}
                gotoPage={gotoPage}
            />
        </>
    );
}

export { DataTable, DataTableNSSP };
