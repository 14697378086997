import React from 'react';
import { Input, Label } from 'reactstrap';
import { useOrcamentoContext } from '../../../../../hooks/useOrcamentoContext.tsx';

export default function DataColor() {
    const { data, isAtivoTecnico, dataSolicitacao } = useOrcamentoContext();
    return (
        <div>
            <div className="row">
                <div className="col-sm-12 mt-3 ml-0">
                    <Label>Pintura / Cor dos Perfis:</Label>
                    <Input
                        type="text"
                        placeholder="Cor dos Componentes..."
                        className="form-control form-control-sm"
                        value={isAtivoTecnico ? data?.corDescPerfil : dataSolicitacao?.profileColorName }
                        disabled
                    />
                </div>
                <div className="col-sm-6 mt-3">
                    <label>Cor dos Componentes:</label>
                    <Input
                        type="text"
                        placeholder="Cor dos Componentes..."
                        className="form-control form-control-sm"
                        value={isAtivoTecnico ? data?.corDescPerfil : dataSolicitacao?.componentColorName }
                        disabled
                    />
                </div>
            </div>
        </div>
    );
}
