import React, { useEffect, useState, useRef } from 'react';
import {
    Button,
    Label,
    Input,
    FormFeedback,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
} from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import NotificationAlert from 'react-notification-alert';

import CpfCnpj from '@react-br-forms/cpf-cnpj-mask';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import PhoneInput from 'react-phone-number-input/input';

import NumberFormat from 'react-number-format';
import ButtonSpin from '~/components/ButtonSpin';
import webApi from '~/services/webapi';

export default function ClienteFormulario({
    selectedRecord,
    isOpen,
    handleCloseModal,
    handleTableReaload,
}) {
    // refs
    const notify = useRef();

    // functions
    const onSubmit = async () => {
        try {
            const Industrydata = {
                codIndustriaCliente: selectedRecord
                    ? Number(selectedRecord)
                    : 0,
                razaoSocial: getValues('razaoSocial'),
                RazaoSocial: getValues('razaoSocial'),
                cnpj: getValues('cnpj'),
                nomeFantasia: getValues('nomeFantasia'),
                endereco: {
                    logradouro: getValues('endereco.logradouro'),
                    numero: getValues('endereco.numero'),
                    cep: getValues('endereco.cep'),
                    bairro: getValues('endereco.bairro'),
                    cidade: getValues('endereco.cidade'),
                    codigoCidade: Number(getValues('endereco.codigoCidade')),
                    estado: getValues('endereco.estado'),
                    codigoEstado: Number(getValues('endereco.codigoEstado')),
                },
                contato: {
                    nome: getValues('contato.nome'),
                    telefone: getValues('contato.telefone'),
                    email: getValues('contato.email'),
                },
            };
            if(selectedRecord){
                await webApi.put(
                    `industriaCliente/${selectedRecord}/atualizar`,
                    Industrydata);
            } else{
                const result = await webApi.post(
                    `industriaCliente`, Industrydata
                );
                // history.push(`/industriaCliente/${selectedRecord}/editar`);
            }
            const options = {
                place: 'tr',
                message: (
                    <div>
                        {' '}
                        Dados salvos com <b>sucesso!</b>
                    </div>
                ),
                type: 'success',
                icon: 'icon-alerta',
                autoDismiss: 7,
            };
            notify.current.notificationAlert(options);

            handleCloseModal();
            handleTableReaload();
        } catch (e) {}
    };

    // hooks
    const { register, handleSubmit, setValue, getValues, errors, control } =
        useForm({
            defaultValues: {
                industryClientId: 0,
                address: {
                    stateCode: 0,
                    cityCode: 0,
                },
            },
            validationSchema: yup.object({
                idInsutriaCliente: yup.number().nullable(),
                razaoSocial: yup.string().required('Campo obrigatório'),
                // cnpj: yup
                //     .string()
                //     .required('Campo obrigatório')
                //     .test('bR_CNPJ', 'Número de documento inválido', (value) => {
                //         if (value) {
                //             const numbersOnly = value.replace(/\D/g, '');
                //             if (numbersOnly.length === 11) {
                //                 return cpf.isValid(numbersOnly);
                //             } else if (numbersOnly.length === 14) {
                //                 return cnpj.isValid(numbersOnly);
                //             } else return false;
                //         }
                //     }),
            }),
        });

    useEffect(() => {
        const fetchClient = async () => {
            const requestIndustry = await webApi.get(
                `IndustriaCliente/${selectedRecord}`
            );

            const {
                codIndustriaCliente,
                cnpj,
                nomeFantasia,
                razaoSocial,
                contato,
                endereco,
            } = requestIndustry;

            setValue('codIndustriaCliente', codIndustriaCliente);
            setValue('razaoSocial', razaoSocial);
            setValue('nomeFantasia', nomeFantasia);
            setValue('cnpj', cnpj);
            setValue('contato.nome', contato.nome);
            setValue('contato.telefone', contato.telefone);
            setValue('contato.email', contato.email);
            setValue('endereco.logradouro', endereco.logradouro);
            setValue('endereco.cep', endereco.cep);
            setValue('endereco.bairro', endereco.bairro);
            setValue('endereco.numero', endereco.numero);
            setValue('endereco.cidade', endereco.cidade);
            setValue('endereco.estado', endereco.estado);
            setValue('endereco.CodigoCidade', endereco.CodigoCidade);
            setValue('endereco.CodigoEstado', endereco.CodigoEstado);
        };

        if (isOpen && selectedRecord) fetchClient();
    }, [isOpen]);

    return (
        <Modal isOpen={isOpen} size="xl">
            <ModalHeader>
                <button
                    type="button"
                    className="close"
                    onClick={() => handleCloseModal()}
                >
                    <i className="nc-icon nc-simple-remove" />
                </button>
                <span className="title title-up">Dados do Cliente</span>
            </ModalHeader>
            <ModalBody><form>
                        <Input
                            innerRef={register}
                            type="hidden"
                            name="codIndustriaCliente"
                        />
                        <Row>
                            <div className="col-sm-12 ml-n1 mt-3">
                                <Label>Razão Social</Label>
                                <Input
                                    innerRef={register}
                                    maxLength={120}
                                    type="text"
                                    name="razaoSocial"
                                    placeholder="Razão Social..."
                                    invalid={!!errors?.bR_RazaoSocial?.message}
                                    className="boxButton form-control-sm"
                                    autoComplete="off"
                                />
                                <FormFeedback>
                                    {errors?.bR_RazaoSocial?.message}
                                </FormFeedback>
                            </div>
                        </Row>
                        <div className="row mb-3">
                            <div className="col-sm-6 ml-n1 mt-3">
                                <Label>Nome Fantasia</Label>
                                <Input
                                    innerRef={register}
                                    maxLength={120}
                                    type="text"
                                    name="nomeFantasia"
                                    placeholder="Nome Fantasia..."
                                    className="boxButton form-control-sm"
                                    autoComplete="off"
                                />
                            </div>
                            <div className="col-sm-6 ml-n1 mt-3">
                                <Label>CNPJ / CPF</Label>
                                <Controller
                                    control={control}
                                    maxLength={18}
                                    name="cnpj"
                                    placeholder="CNPJ / CPF..."
                                    as={CpfCnpj}
                                    className="form-control form-control-sm"
                                />
                                <FormFeedback>
                                    {errors?.bR_CNPJ?.message}
                                </FormFeedback>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-sm-3 ml-n1 mt-3">
                                <Label>CEP</Label>
                                <Controller
                                    maxLength={8}
                                    name="endereco.cep"
                                    placeholder="CEP..."
                                    className="form-control form-control-sm"
                                    as={NumberFormat}
                                    control={control}
                                    decimalScale={0}
                                    allowLeadingZeros={true}
                                    isNumericString={true}
                                    autoComplete="off"
                                />
                                <FormFeedback valid={false}>
                                    {errors?.address?.postalCode?.message}
                                </FormFeedback>
                            </div>
                            <div className="col-sm-7 ml-n1 mt-3">
                                <Label>Endereço</Label>
                                <Input
                                    innerRef={register}
                                    maxLength={120}
                                    type="text"
                                    name="endereco.logradouro"
                                    placeholder="Endereço..."
                                    className="boxButton form-control-sm"
                                    autoComplete="off"
                                />
                            </div>
                            <div className="col-sm-2 ml-n1 mt-3">
                                <Label>Número</Label>
                                <Input
                                    innerRef={register}
                                    maxLength={50}
                                    type="text"
                                    name="endereco.numero"
                                    placeholder="Número..."
                                    className="boxButton form-control-sm"
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-sm-4 ml-n1 mt-3">
                                <Label>Bairro</Label>
                                <Input
                                    innerRef={register}
                                    maxLength={120}
                                    type="text"
                                    name="endereco.bairro"
                                    placeholder="Bairro..."
                                    className="boxButton form-control-sm"
                                    autoComplete="off"
                                />
                            </div>
                            <div className="col-sm-4 ml-n1 mt-3">
                                <Label>Estado</Label>
                                <Input
                                    innerRef={register}
                                    type="text"
                                    name="endereco.estado"
                                    placeholder="Estado..."
                                    className="boxButton form-control-sm"
                                />

                                <Input
                                    type="hidden"
                                    innerRef={register}
                                    name="endereco.CodigoEstado"
                                />
                            </div>
                            <div className="col-sm-4 ml-n1 mt-3">
                                <Label>Cidade</Label>
                                <Input
                                    innerRef={register}
                                    type="text"
                                    name="endereco.cidade"
                                    placeholder="Cidade..."
                                    className="boxButton form-control-sm"
                                />

                                <Input
                                    type="hidden"
                                    innerRef={register}
                                    name="endereco.CodigoCidade"
                                />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-sm-4 ml-n1 mt-3">
                                <Label>Contato</Label>
                                <Input
                                    innerRef={register}
                                    maxLength={80}
                                    type="text"
                                    name="contato.nome"
                                    placeholder="Contato..."
                                    className="boxButton form-control-sm"
                                />
                            </div>
                            <div className="col-sm-4 ml-n1 mt-3">
                                <Label>Telefone</Label>
                                <Controller
                                    maxLength={15}
                                    type="text"
                                    name="contato.telefone"
                                    placeholder="Telefone..."
                                    className="form-control form-control-sm"
                                    as={PhoneInput}
                                    control={control}
                                    country="BR"
                                />
                            </div>
                            <div className="col-sm-4 ml-n1 mt-3">
                                <Label>E-mail</Label>
                                <Input
                                    innerRef={register}
                                    maxLength={120}
                                    type="text"
                                    name="contato.email"
                                    placeholder="E-mail..."
                                    className="boxButton form-control-sm"
                                />
                                <FormFeedback>
                                    {errors?.contact?.email?.message}
                                </FormFeedback>
                            </div>
                        </div>
                    </form>
            </ModalBody>
            <ModalFooter>
                <div className="left-side">
                    <button
                        type="button"
                        className="btn btn-link"
                        onClick={() => handleCloseModal()}
                    >
                        Cancelar
                    </button>
                </div>
                <div className="divider" />
                <div className="right-side">
                    {/* <Button
                        className="btn btn-success btn-link"
                        onClick={handleSubmit((e) => onSubmit(e))}
                    >
                        Salvar
                    </Button> */}

                    <ButtonSpin
                        title="Salvar"
                        className="btn btn-success btn-link"
                        onClick={handleSubmit((e) => onSubmit(e))}
                    />
                </div>
            </ModalFooter>
            <NotificationAlert ref={notify} />
        </Modal>
    );
}
