import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import NumberFormat from 'react-number-format';
import ImagemNaoDefinida from '~/assets/img/imagem-nao-definida.jpg';
import { useNotifyContext } from '~/hooks/useNotifyContext';
import webApi from '~/services/webapi';
import history from '~/services/history';
import './itemProduto.css';
import SelectComponent from '../../../components/Select/index';

function TipoProdutoComponent({ item }) {
    if (item.seguimento === 1) {
        return (
            <>
                <span>Perfil - {item.barra} mm </span>
            </>
        );
    }
    if (item.seguimento === 2) {
        return (
            <>
                {item.tipoComponente != 1 && (
                    <span>
                        Componente - {item.unidade} c/ {item.quantidade}un{' '}
                    </span>
                )}
                {item.tipoComponente == 1 && (
                    <span>Componente - {item.unidade} </span>
                )}
            </>
        );
    }
    return (
        <>
            <span />
        </>
    );
}

export default function ItemProduto({ item, location }) {
    const [cor, setCor] = useState();
    const [quantidadeItem, setQuantidadeItem] = useState(0);
    const [listaCor, setListaCor] = useState([]);

    const notificacao = useNotifyContext();

    function incrementarQuantidade() {
        setQuantidadeItem(quantidadeItem + 1);
    }

    function decrementarQuantidade() {
        if (quantidadeItem > 0) {
            setQuantidadeItem(quantidadeItem - 1);
        }
    }

    function filtrarCor(parametro) {
        setCor(parametro);
    }

    async function adicionarItem() {
        try {
            const result = await webApi.post('cotacao/novo', {
                codCotacao: location.state.codCotacao,
                dataCotacao: new Date(),
                prazoEntrega: new Date(),
                itens: [
                    {
                        codProduto: item.codProduto,
                        codCorProduto: cor,
                        quantidade: quantidadeItem,
                        valor: 0,
                    },
                ],
            });

            const { codCotacao } = result;

            history.replace({ state: { codCotacao, status: 1 } });

            setQuantidadeItem(0);
            setCor(0);

            notificacao.sucesso('Item adicionado com sucesso!');
        } catch (error) {
            notificacao.notificationHttp(error);
        }
    }

    useEffect(() => {
        const listaCor = item.cores.map((item) => ({
            label: item.codCorProduto,
            name: item.descricao,
        }));
        setListaCor(listaCor);
    }, []);

    return (
        <div className="col-12 col-sm-5 col-md-5 col-lg-5 col-xl-3 mt-2 mr-5 mb-3">
            <div className="row materiaisAvulsos">
                <div className="card-header headerAvulso">
                    <img
                        className="card-img-top"
                        src={
                            item.imagemUrl ? item.imagemUrl : ImagemNaoDefinida
                        }
                        style={{
                            borderRadius: '10px 10px 0px 0px',
                        }}
                    />
                </div>
                <div
                    className="card-body"
                    style={{
                        width: '100%',
                        border: '1px solid #ABAFBC',
                        borderRadius: '0px 0px 10px 10px',
                    }}
                >
                    <p
                        style={{
                            fontSize: '20px',
                            fontWeight: '400',
                            color: '#57607A',
                            marginLeft: '1rem',
                        }}
                    >
                        {item.codigo}
                    </p>

                    <p className="card-text">
                        <TipoProdutoComponent item={item} />
                    </p>
                    <p
                        className="card-text"
                        style={{ fontSize: '14px', marginLeft: '1rem' }}
                    >
                        {item.descricao}
                    </p>
                    
                    <div style={{ display: 'flex' }}>
                        <p
                            className="card-text"
                            style={{ fontSize: '14px', marginLeft: '1rem' }}
                        >
                            {item.tipoProduto === 2
                                ? `Embalagem: ${item.quantidade} ${item.unidade}(s) `
                                : ``}
                            {/* : `Peso: ${item.peso}kg/m`} */}
                        </p>
                        <p
                            className="card-text"
                            style={{ fontSize: '14px', marginLeft: '1rem' }}
                        >
                            {item.tipoProduto == 1
                                ? `Barra: ${item.barra} mm`
                                : ''}
                        </p>
                    </div>

                    <hr
                        className=""
                        style={{
                            border: '1px solid #DFE3E9',
                            width: '84%',
                            marginLeft: '1rem',
                        }}
                    />

                    <label
                        className=""
                        style={{
                            fontSize: '12px',
                            color: '#57607A',
                            marginLeft: '1rem',
                        }}
                    >
                        {' '}
                        Quantidade
                    </label>
                    <div
                        className="form-group d-flex"
                        style={{
                            marginLeft: '1rem',
                        }}
                    >
                        <button
                            className="icon-icon-menos iconeMenos"
                            style={{
                                fontSize: '18px',
                                background: 'transparent',
                                color: '#57607A',
                            }}
                            onClick={() => decrementarQuantidade()}
                        />

                        <NumberFormat
                            placeholder="0"
                            value={quantidadeItem}
                            decimalScale={0}
                            allowNegative={false}
                            max={999}
                            decimalSeparator=","
                            thousandSeparator="."
                            className="inputNumber"
                            onValueChange={(values) => {
                                const { value } = values;
                                setQuantidadeItem(parseInt(value));
                            }}
                            style={{ width: '72%' }}
                        />
                        <button
                            className="icon-icon-h iconeMais"
                            style={{
                                fontSize: '12px',
                                background: 'transparent',
                                color: '#57607A',
                            }}
                            onClick={() => incrementarQuantidade()}
                        />
                    </div>
                    <div className="mx-3">
                        <SelectComponent
                            label="Cor"
                            data={listaCor}
                            value={cor}
                            onChange={filtrarCor}
                        />
                    </div>

                    <hr
                        style={{
                            border: '1px solid #DFE3E9',
                            width: '84%',
                            marginLeft: '1rem',
                        }}
                    />
                    <Button
                        style={{
                            fontSize: '16px',
                            width: '85%',
                            backgroundColor: '#3073CB',
                            marginLeft: '1rem',
                        }}
                        disabled={quantidadeItem == 0 || cor == undefined}
                        onClick={() => adicionarItem()}
                        className="boxButton"
                    >
                        <i className="icon-icon-adc-carrinho ml-n2 mr-2" />
                        Adicionar Item
                    </Button>
                </div>
            </div>
        </div>
    );
}
