import shortid from 'shortid';

function setRwidInArray(items) {
    return items.map((m) => {
        return {
            rwid: shortid.generate(),
            ...m,
        };
    });
}

function removeRwidInArray(items) {
    return items.map((m) => {
        delete m.rwid;
        return {
            ...m,
        };
    });
}

function getSingleErrorMessage(httpResponse) {
    const {statusCode,errors} = JSON.parse(httpResponse.request.response);
    if (statusCode === 400) {
        if (errors) {
            return errors[0];
        }
    }
    return null;
}

function getListErrorMessage(httpResponse) {
    if (httpResponse.request.status === 400) {
        if (httpResponse.request.response) {
            const requestData = JSON.parse(httpResponse.request.response);
            const { error } = requestData;
            if (error.length > 0) {
                return error.map((m) => {
                    return {
                        fieldName: m.fieldName?.toLowerCase(),
                        message: m.message,
                    };
                });
            }
        }
    }
    return null;
}

function convertObjectInArrayKeyValue(object) {
    const values = [];
    for (let [key, value] of Object.entries(object)) {
        values.push({ [key]: value });
    }
    return values;
}

export {
    setRwidInArray,
    removeRwidInArray,
    getSingleErrorMessage,
    getListErrorMessage,
    convertObjectInArrayKeyValue,
};
