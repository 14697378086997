import React from 'react';
import {Card, CardBody, CardHeader, Col} from 'reactstrap';
import { Chart } from 'react-google-charts';

interface ICardTop5 {
    title: string;
    data: {} | any[] | undefined;
    formatters: any;
}

export function CardTop5({title, data, formatters}: ICardTop5){
    return(
        <>
            <Card className='m-0 w-100 h-100 boxButton'>
                <CardHeader className='text-center mt-4'><strong>{title}</strong></CardHeader>
                    <CardBody>
                        <Chart
                            chartType="Table"
                            width="100%"
                            height="300px"
                            data={data}
                            formatters={formatters}
                        />
                    </CardBody>
                    <hr className="borde-1" />
            </Card>
        </>
    )
};
