import React from 'react';
import { Table } from 'reactstrap';

import TableHeader from './components/TableHeader';
import TableBody from './components/TableBody';

const TableDataList = ({ headerGroups, ...rest }) => {
    return (
        <div className="col-12" onScrollY={Table}>
            <Table
                striped
                bordered
                cellSpacing="0"
                className="col-12"
                responsive="xl"
            >
                <TableHeader headerGroups={headerGroups} />
                <TableBody {...rest} />
            </Table>
        </div>
    );
};

export default TableDataList;
