export const reactSelectCustomStyles = {
    control: (provided, state) => ({
        ...provided,
        boxShadow: 'rgb(198 203 212) 0px 6px 15px -10px',
        borderRadius: '10px',
        border: '1px solid #dddddd',
        minHeight: '48px',
        maxheight: '48px',
    }),

    valueContainer: (provided, state) => ({
        ...provided,
        height: '48px',
        padding: '0 6px',
    }),

    input: (provided, state) => ({
        ...provided,
        margin: '0px',
    }),
    indicatorSeparator: (state) => ({
        display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        maxHeight: '48px',
    }),
};
