import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Label, Input } from 'reactstrap';
import { useParams } from 'react-router-dom';

import PageContent from '~/components/PageContent';
import OrcamentoNavegacaoSuperior from '~/components/OrcamentoNavegacaoSuperior';
import history from '~/services/history';
import NavigationSubArea from '../../components/OrcamentoTecnicoNavegacao';
import { useOrcamentoContext } from '~/hooks/useOrcamentoContext';
import webApi from '~/services/webapi';

export default function OrcamentoTecnicoCores() {
    const { codOrcamentoTecnico, versao } = useParams();
    const { orcamentoStateContext } = useOrcamentoContext();

    // hooks
    const { register, setValue, getValues } = useForm({});

    // functions
    const handleChange = async () => {
        return true;
    };

    const handleClickNext = async () => {
        history.push(
            `/orcamentos-tecnicos/${codOrcamentoTecnico}/versao/${versao}/itens`
        );
    };

    useEffect(() => {
        const fetchData = async () => {
            const result = await webApi.get(
                `/orcamentoTecnico/${codOrcamentoTecnico}/versao/${versao}/cor`
            );

            const {
                corCodigoPerfil,
                corDescPerfil,
                profileExternalColor,
                profileExternalColorName,
                profileInternalColor,
                profileInternalColorName,
                corCodigoComponente,
                glassColor,
            } = result;

            setValue('profileColor', `${corCodigoPerfil} - ${corDescPerfil}`);

            if (profileExternalColor) {
                setValue('profileExternalColor', profileExternalColor);
                setValue(
                    'profileExternalColor',
                    `${profileExternalColor} - ${profileExternalColorName}`
                );
            }

            if (profileInternalColor) {
                setValue(
                    'profileInternalColor',
                    `${profileInternalColor} - ${profileInternalColorName}`
                );
            }

            setValue('componentColor', corCodigoComponente);

            setValue('glassColor', glassColor);
        };
        if (codOrcamentoTecnico) {
            fetchData();
        }
    }, []);

    return (
        <>
            <PageContent className="no-border">
                <OrcamentoNavegacaoSuperior />
            </PageContent>

            <PageContent titlePage="Orçamento - Cores">
                <NavigationSubArea
                    handleChange={() => handleChange()}
                    active="Color"
                >
                    <form>
                        <div className="row">
                            <div className="col-sm-10 mt-3">
                                <Label>Pintura / Cor dos Perfis: *</Label>
                                <Input
                                    innerRef={register}
                                    name="profileColor"
                                    className="boxInput form-control form-control-sm"
                                    placeholder="Pintura / Cor dos Perfis:..."
                                    disabled
                                />
                            </div>
                        </div>

                        {getValues('profileExternalColor') &&
                            getValues('profileInternalColor') && (
                                <div className="row">
                                    <div className="col-sm-5 mt-3">
                                        <Label>Cor Externa:</Label>
                                        <Input
                                            innerRef={register}
                                            name="profileExternalColor"
                                            className="boxInput form-control form-control-sm"
                                            disabled
                                        />
                                    </div>
                                    <div className="col-sm-5 mt-3">
                                        <Label>Cor Interna:</Label>
                                        <Input
                                            innerRef={register}
                                            name="profileInternalColor"
                                            className="boxInput form-control form-control-sm"
                                            disabled
                                        />
                                    </div>
                                </div>
                            )}
                        <div className="row">
                            <div className="col-sm-5 mt-3">
                                <Label>Cor Componentes:</Label>
                                <Input
                                    innerRef={register}
                                    name="componentColor"
                                    className="boxInput form-control form-control-sm"
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="m-3">
                            <div className="pull-left">
                                <button
                                    type="button"
                                    className="boxButtonAvancar btn"
                                    role="tablist"
                                    onClick={() => {
                                        history.push(
                                            `/orcamentos/${orcamentoStateContext.codOrcamento}/anexos`
                                        );
                                    }}
                                >
                                    <i className="far fa-arrow-alt-circle-left" />
                                    {' Voltar'}
                                </button>
                            </div>
                            <div className="pull-right">
                                <button
                                    type="button"
                                    className="boxButtonAvancar btn"
                                    role="tablist"
                                    onClick={() => handleClickNext()}
                                >
                                    Avançar{' '}
                                    <i className="far fa-arrow-alt-circle-right" />
                                </button>
                            </div>
                        </div>
                    </form>
                </NavigationSubArea>
            </PageContent>
        </>
    );
}
