import React, { useMemo, useState, useEffect, useRef } from 'react';
import PageContent from '~/components/PageContent';
import { DataTable } from '~/components/DataTable';
import { useOrcamentoContext } from 'hooks/useOrcamentoContext';
import { useParams } from 'react-router-dom';
import {
    Button,
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Row,
    Label,
} from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import { getSingleErrorMessage } from '~/utils';
import classnames from 'classnames';
import NumberFormat from 'react-number-format';
import webApi from '~/services/webapi';
import history from '~/services/history';
import ModalProduct from '../../../../../components/Modal/index.tsx';
import DataGeral from '../ModalDetail/dataGeral';
import DataColor from '../ModalDetail/dataColor';
import DataGlass from '../ModalDetail/dataVidro';
import DataCroqui from '../ModalDetail/dataCroqui';
import { getItensOrcamentoTecnico } from '../../../../../services/modules/Orcamento/orcamentoTecnico';
import {
    formatMoneyMask,
    removeMoneyMask,
} from '../../../../../shared/function/index.ts';

const dataItemDetail = [
    {
        activeTab: 1,
        title: 'Geral',
        element: <DataGeral />,
        icon: <i className="far fa-list-alt mr-2" />,
    },
    {
        activeTab: 2,
        title: 'Cores',
        element: <DataColor />,
        icon: <i className="fas fa-fill-drip mr-2" />,
    },
    {
        activeTab: 3,
        title: 'Vidro',
        element: <DataGlass />,
        icon: <i className="fas egf egf-vidro mr-2" />,
    },
    {
        activeTab: 4,
        title: 'Croqui',
        element: <DataCroqui />,
        icon: <i className="far fa-image mr-2" />,
    },
];

const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    tableRowUpdate,
}) => {
    const [value, setValue] = useState(initialValue);

    const onChange = ({ floatValue }) => {
        setValue(floatValue);
    };
    const onBlur = () => {
        if (initialValue !== parseFloat(value)) {
            tableRowUpdate(index, id, parseFloat(value));
        }
    };

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    return (
        <NumberFormat
            allowNegative={false}
            className="form-control"
            value={value || 0}
            onValueChange={onChange}
            onBlur={onBlur}
            style={{ width: 120 }}
            decimalScale={2}
            fixedDecimalScale
            prefix="R$ "
            decimalSeparator=","
            thousandSeparator="."
        />
    );
};

export default function MyBudgetTechnical() {
    const { codOrcamento, versao } = useParams();
    const [isActive, setIsActive] = useState(1);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRecords, setTotalRecords] = useState(0);
    const [alertSend, setAlertSend] = useState(null);
    const [alertMessage, setAlertMessage] = useState(null);
    const [isOpenFormProduct, setIsOpenFormProduct] = useState(false);
    const {
        orcamentoStateContext,
        setCodOrcamentoTecnicoItem,
        setIsAtivoTecnico,
    } = useOrcamentoContext();

    const inputRef = useRef(0);

    const handleOpenFormProduct = (codOrcamentoTecnicoItem) => {
        setIsAtivoTecnico(true);
        setCodOrcamentoTecnicoItem(codOrcamentoTecnicoItem);
        setIsOpenFormProduct(true);
    };

    const handleSave = async () => {
        const items = data.map((m) => {
            return {
                codOrcamentoTecnicoItem: m.codOrcamentoTecnicoItem,
                valorTotal: m.valorTabela,
            };
        });
        await webApi.post(
            `/OrcamentoTecnico/${orcamentoStateContext.codOrcamentoTecnico}/versao/${versao}/alterar-total-orcamento-tecnico-precificacao-direta`,
            {
                itens: items,
            }
        );
    };

    const tableRowUpdate = (rowIndex, columnId, value) => {
        setData((old) =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value,
                    };
                }

                return row;
            })
        );
    };

    const handleCloseModal = () => {
        setIsOpenFormProduct(false);
        setIsActive(1);
    };

    const handleCalcularValorItem = () => {
        const valorTotal = parseFloat(removeMoneyMask(inputRef.current.value));
        if (valorTotal) {
            const quantidadeItens = data.length;
            const valorPorItem = parseFloat(valorTotal / quantidadeItens);
            setData((old) => {
                return old.map((item) => {
                    return { ...item, valorTabela: valorPorItem };
                });
            });
        }
    };
    const handleShowMessage = (type, title, message, handleClickConfirm) => {
        setAlertMessage(
            <SweetAlert
                show
                type={type}
                title={title}
                style={{ display: 'block' }}
                confirmBtnBsStyle="success"
                onConfirm={() => {
                    if (handleClickConfirm) handleClickConfirm();
                    setAlertMessage(null);
                }}
            >
                {message}
            </SweetAlert>
        );
    };

    const handleEnviarSerralheria = () => {
        setAlertSend(
            <SweetAlert
                show
                info
                style={{ display: 'block' }}
                title="Deseja enviar o orçamento para Serralheria?"
                confirmBtnBsStyle="success"
                cancelBtnText="Não"
                confirmBtnText="Sim"
                showCancel
                closeOnClickOutside={false}
                reverseButtons
                onConfirm={async () => {
                    try {
                        await handleSave();
                        await webApi.put(
                            `/Orcamentos/${codOrcamento}/retornado-vendedor`
                        );
                        setAlertSend(null);
                        handleShowMessage('success', 'Sucesso', () => {
                            history.push(`/orcamentoVendedor`);
                        });
                    } catch (error) {
                        setAlertSend(null);
                        handleShowMessage(
                            'warning',
                            'Não foi possível realizar esta ação',
                            getSingleErrorMessage(error)
                        );
                    }
                }}
                onCancel={() => {
                    setAlertSend(null);
                }}
            />
        );
    };

    const handleRejeitarSolicitacao = () => {
        setAlertSend(
            <SweetAlert
                show
                danger
                style={{ display: 'block' }}
                title="Deseja rejeitar este orçamento?"
                confirmBtnBsStyle="success"
                cancelBtnText="Não"
                confirmBtnText="Sim"
                showCancel
                closeOnClickOutside={false}
                reverseButtons
                onConfirm={async () => {
                    try {
                        await webApi.put(
                            `/Orcamentos/${codOrcamento}/proposta-rejeitada`
                        );
                        setAlertSend(null);
                        handleShowMessage(
                            'success',
                            'Sucesso',
                            'Orçamento rejeitado com sucesso',
                            () => {
                                history.push(`/orcamentoVendedor`);
                            }
                        );
                    } catch (error) {
                        setAlertSend(null);
                        handleShowMessage(
                            'warning',
                            'Não foi possível realizar esta ação',
                            getSingleErrorMessage(error)
                        );
                    }
                }}
                onCancel={() => {
                    setAlertSend(null);
                }}
            >
                Tem certeza que deseja rejeitar esta solicitação? Você não
                poderá reverter essa ação!
            </SweetAlert>
        );
    };

    const fetchData = React.useCallback(() => {
        setLoading(true);
        (async () => {
            const result = await getItensOrcamentoTecnico(
                orcamentoStateContext.codOrcamentoTecnico,
                orcamentoStateContext.versaoOrcamentoTecnico
            );
            const listaTecnico = result.map((item) => ({
                altura: item.altura,
                codOrcamentoTecnico: item.codOrcamentoTecnico,
                codOrcamentoTecnicoItem: item.codOrcamentoTecnicoItem,
                codigo: item.codigo,
                custo: item.custo,
                descricao: item.descricao,
                imagem: item.imagem,
                largura: item.largura,
                linha: item.linha,
                peso: item.peso,
                quantidade: item.quantidade,
                rateio: item.rateio,
                totalCustoVendedor: item.totalCustoVendedor,
                totalOrcamentoTecnico: item.totalOrcamentoTecnico,
                urlImagem: item.urlImagem,
                valorComponente: item.valorComponente,
                valorMaterial: item.valorMaterial,
                valorServico: item.valorServico,
                valorTotal: item.valorTotal,
                valorTabela:
                    orcamentoStateContext.situacaoOrcamentoIndustria === 15
                        ? item.valorTotal
                        : item.totalCustoVendedor,
            }));
            setData(listaTecnico);
            setTotalRecords(result.length);
            setLoading(false);
        })();
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (data.length > 0) {
            const soma = data.reduce((valorTabela, item) => {
                return valorTabela + item.valorTabela;
            }, 0);

            inputRef.current.value = formatMoneyMask(soma);
        }
    }, [data]);

    const columnsDevolucaoCEM = useMemo(
        () => [
            {
                Header: 'Croqui',
                accessor: 'imagem',
                disableSortBy: true,
                Cell: ({ row }) => {
                    const { urlImagem } = row.original;
                    return (
                        <div className="text-center">
                            <img
                                alt=""
                                src={urlImagem}
                                style={{
                                    maxHeight: '80px',
                                }}
                            />
                        </div>
                    );
                },
            },
            {
                Header: 'Codigo',
                accessor: 'codigo',
            },
            {
                Header: 'Descrição',
                accessor: 'descricao',
            },
            {
                Header: 'Linha',
                accessor: 'linha',
            },
            {
                Header: 'Quantidade',
                accessor: 'quantidade',
            },
            {
                Header: 'Largura',
                accessor: 'largura',
            },
            {
                Header: 'Altura',
                accessor: 'altura',
            },
            {
                Header: 'Visualizar',
                accessor: 'actions',
                disableSortBy: true,
                Cell: ({ row }) => (
                    <div className="text-center">
                        <button
                            type="button"
                            rel="tooltip"
                            title="Visualizar"
                            className="btn btn-icon btn-icon-mini btn-neutral"
                            onClick={() =>
                                handleOpenFormProduct(
                                    row.original.codOrcamentoTecnicoItem
                                )
                            }
                        >
                            <i className=" icon-icon-ver" />
                        </button>
                    </div>
                ),
            },
            {
                Header: 'Valores',
                accessor: 'valorTabela',
                disableSortBy: true,
                Cell: EditableCell,
            },
        ],
        []
    );

    return (
        <div>
            <PageContent titlePage="Orçamento detalhado">
                <span className="titulo-pagina">Orçamento Técnico</span>
                <hr />
                <DataTable
                    columns={columnsDevolucaoCEM}
                    data={data}
                    fetchData={fetchData}
                    loading={loading}
                    pageCount={0}
                    reload={false}
                    extraFilterOneInitialValue="true"
                    extraFilterOneTitle="Filtrar por Status"
                    includePageSize={false}
                    includePagination={false}
                    title="Lista de Itens"
                    total={totalRecords}
                    tableRowUpdate={tableRowUpdate}
                />

                <Row style={{ justifyContent: 'end' }}>
                    <Col md={12} className="text-right">
                        <Label>
                            Valor Total
                            <div className="input-group valores ml-auto">
                                <div className="input-group-prepend">
                                    <input
                                        ref={inputRef}
                                        className="form-control valores"
                                        onChange={(e) =>
                                            handleCalcularValorItem(e)
                                        }
                                        style={{ textAlign: 'end' }}
                                    />
                                </div>
                            </div>
                        </Label>
                    </Col>
                </Row>
            </PageContent>
            <Col>
                {orcamentoStateContext.situacaoOrcamentoIndustria === 15 && (
                    <Col md="12">
                        <Button
                            className="btn btn-red mb-4"
                            onClick={() => handleRejeitarSolicitacao()}
                        >
                            <i className="far fa-trash-alt" /> Rejeitar
                            solicitação
                        </Button>
                        <Button
                            className="btn btn-success mb-4 pull-right mr-4"
                            onClick={() => handleEnviarSerralheria()}
                        >
                            <i className="far fa-share-square" /> Enviar para
                            Serralheira
                        </Button>
                    </Col>
                )}
            </Col>
            <ModalProduct
                open={isOpenFormProduct}
                onClose={handleCloseModal}
                title="Detalhes do Item"
                onAdvance={() => ''}
                onBack={() => ''}
                size="xl"
                isActive={isActive}
                count={dataItemDetail.length}
            >
                <div className="wizard">
                    <Nav
                        tabs
                        style={{
                            paddingRight: '25px',
                            paddingLeft: '25px',
                            paddingTop: '25PX',
                        }}
                        className="nav  nav-pills-primary nav-justified"
                    >
                        {dataItemDetail.map(({ title, activeTab, icon }) => (
                            <NavItem key={activeTab}>
                                <NavLink
                                    className={classnames({
                                        active: isActive === activeTab,
                                    })}
                                    onClick={() => setIsActive(activeTab)}
                                >
                                    {icon}
                                    {title}
                                </NavLink>
                            </NavItem>
                        ))}
                    </Nav>
                </div>
                <TabContent
                    activeTab={isActive}
                    style={{
                        paddingRight: '25px',
                        paddingLeft: '25px',
                        paddingBottom: '25px',
                    }}
                >
                    {dataItemDetail.map(({ element, activeTab }) => (
                        <TabPane key={activeTab} tabId={activeTab}>
                            {element}
                        </TabPane>
                    ))}
                </TabContent>
            </ModalProduct>
            {alertSend}
            {alertMessage}
        </div>
    );
}
