import React from 'react';
import { Row, Col, Label } from 'reactstrap';
import './styles.css'

export interface IDadosProps {
    active: boolean,
    authenticate: boolean,
    codPerfilUsuario:number,
    codUsuario:number,
    email:string,
    name: string,
    roleAdmin: string,
 }

 interface IItemDadosProps {
     dados:IDadosProps;
 };

 export default function ListaUsuario({dados}:IItemDadosProps) {
    return(
        <Row className="listCard">
            <Col xl={3} lg={3}>
                <Label>Nome</Label>
                <div><strong>{dados.name}</strong></div>
            </Col>
            <Col xl={5} lg={5} xs={12}>
                <Label>E-mail</Label>
                <div><strong>{dados.email}</strong></div>
            </Col>
            <Col xl={2} lg={2}>
                <Label>Administrador</Label>
                <div><strong>{dados.roleAdmin}</strong></div>
                <div>
                    {dados.roleAdmin ?
                    <div className='statusAtivo'>
                        Ativo
                    </div>
                    :
                    <div className='statusInativo'>
                        Inativo
                    </div>
                    }
                </div>
            </Col>
            <Col xl={2} lg={2} xs={12}>
                <Label>Status</Label>
                <div>
                    {dados.active ?
                    <div className='statusAtivo'>
                        Ativo
                    </div>
                    :
                    <div className='statusInativo'>
                        Inativo
                    </div>
                    }
                </div>
            </Col>
        </Row>
    )
}

