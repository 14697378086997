import React from 'react';
import {Card, CardBody, CardHeader, Col} from 'reactstrap';
import { Chart } from 'react-google-charts';

interface ICardPeriodo {
    title: string;
    data: {} | any[] | undefined;
}

export function CardPeriodo({title, data}: ICardPeriodo){
    return(
        <>
            <Card className='m-0 w-100 h-100 boxButton'>
                <CardHeader className='text-center mt-4'><strong>{title}</strong></CardHeader>
                    <CardBody>
                        <Chart
                            chartType="ColumnChart"
                            height="430px"
                            data={data}
                            options={{
                                colors: ['#4EAA63'],
                                prefix: 'R$',
                                backgroundColor: 'transparent',}}
                        />
                    </CardBody>
                    <hr className="borde-1" />
            </Card>
        </>
    )
};
