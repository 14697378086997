import React from 'react';
import { Row, Col, Label } from 'reactstrap';
import './styles.css'

interface IDadosProps {
    nome: string,
    cidade: string,
    ativo: boolean,
    uf: string,
    bairro:string,
    cep:string,
    codUsuario:number,
    codVendedor:number,
    cpf:string,
    email:string,
    endereco:string,
    numero:string,
    telefone:string,
 }

 interface IItemDadosProps {
     dados:IDadosProps;
 };

 export default function ItemDados({dados}:IItemDadosProps) {
    return(
        <Row className="listCard px-0">
            <Col xl={2} lg={2}>
                <Label>Nome</Label>
                <div><strong>{dados.nome}</strong></div>
            </Col>
            <Col xl={2} lg={2}>
                <Label>CPF</Label>
                <div><strong>{dados.cpf}</strong></div>
            </Col>
            <Col xl={4} lg={3} xs={12}>
                <Label>E-mail</Label>
                <div><strong>{dados.email}</strong></div>
            </Col>
            <Col xl={2} lg={2} xs={6}>
                <Label>Cidade</Label>
                <div><strong>{dados.cidade}</strong></div>
            </Col>
            <Col xl={1} lg={1} xs={6}>
                <Label>Estado</Label>
                <div><strong>{dados.uf}</strong></div>
            </Col>
            <Col xl={1} lg={2} xs={12}>
                <Label>Status</Label>
                <div>
                    {dados.ativo ?
                    <label className='statusAtivo'>
                        Ativo
                    </label>
                    :
                    <label className='statusInativo'>
                        Inativo
                    </label>
                    }
                </div>
            </Col>
        </Row>
    )
}

