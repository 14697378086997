/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-no-undef */
import React, { useState, useMemo, useRef, useCallback } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

import { DataTable } from '~/components/DataTable';
import PageContent from '~/components/PageContent';
import ClientForm from '../Formulario';
import webApi from '~/services/webapi';
import './styles.css';
import Cabecalho from '../../../components/Cabecalho/index';

export default function ClienteLista() {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [alertDelete, setAlertDelete] = useState(null);
    const [loading, setLoading] = useState(true);
    const [reload, setReload] = useState(0);
    const [data, setData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [filtro, setFiltro] = useState('');

    const fetchIdRef = useRef(0);

    function filtrar(parametroFiltro) {
        setFiltro(parametroFiltro);
        handleReloadTable();
    }

    const handleClickNew = () => {
        setIsOpen(true);
    };

    const handleReloadTable = () => {
        const fetchId = ++fetchIdRef.current;
        setReload(fetchId);
    };

    const handleClickEdit = (clienteId) => {
        setSelectedRecord(clienteId);
        setIsOpen(true);
    };

    const handleCloseModal = () => {
        setIsOpen(false);
        setSelectedRecord(null);
    };

    const handleClickDelete = (clienteId) => {
        setAlertDelete(
            <SweetAlert
                show
                warning
                style={{ display: 'block' }}
                title="Você tem certeza?"
                confirmBtnBsStyle="success"
                showCancel
                reverseButtons
                closeOnClickOutside={false}
                onConfirm={async () => {
                    try {
                        await webApi.delete(
                            `industriaCliente/${clienteId}/excluir`
                        );
                        setAlertDelete(null);
                        handleReloadTable();
                    } catch (e) {}
                }}
                onCancel={() => {
                    setAlertDelete(null);
                }}
            >
                Tem certeza que deseja excluir? Você não poderá reverter essa
                ação!
            </SweetAlert>
        );
    };

    const columns = useMemo(
        () => [
            //
            {
                Header: 'Nome Fantasia',
                accessor: 'nomeFantasia',
            },
            {
                Header: 'Razão Social',
                accessor: 'razaoSocial',
                display: 'd-none  d-md-table-cell',
            },
            {
                Header: 'Contato',
                accessor: 'contato.nome',
                display: 'd-none  d-lg-none d-lg-table-cell',
            },
            {
                Header: 'Celular',
                accessor: 'contato.telefone',
                display: 'd-none  d-lg-table-cell',
            },

            {
                Header: 'Cidade',
                accessor: 'endereco.cidade',
                display: 'd-none  d-md-table-cell',
            },
            {
                Header: 'Estado',
                accessor: 'endereco.estado',
                display: 'd-none d-md-table-cell',
            },

            {
                Header: 'Ações',
                accessor: 'actions',
                disableSortBy: true,
                Cell: ({ row }) => (
                    <div className="text-left">
                        <button
                            type="button"
                            rel="tooltip"
                            title="Editar"
                            className="btn btn-icon btn-icon-mini btn-neutral"
                            onClick={() => {
                                handleClickEdit(
                                    row.original.codIndustriaCliente
                                );
                            }}
                        >
                            <i className="icon-icon-edita" />
                        </button>
                        <button
                            type="button"
                            rel="tooltip"
                            title="Excluir"
                            className="btn btn-icon btn-icon-mini btn-red-over"
                            onClick={() =>
                                handleClickDelete(
                                    row.original.codIndustriaCliente
                                )
                            }
                        >
                            <i className="icon-lixeira" />
                        </button>
                    </div>
                ),
            },
        ],
        []
    );

    const getClients = useCallback(
        ({ pageSize, pageIndex, sort, desc }) => {
            setLoading(true);
            (async () => {
                const result = await webApi.post(
                    `industriacliente/obter-lista`,
                    {
                        page: pageIndex,
                        take: pageSize,
                        filter: filtro,
                        sort,
                        desc,
                    }
                );
                const { records, page, total } = result;
                setData(records);
                setTotalRecords(total);
                setPageCount(page);
                setLoading(false);
            })();
        },
        [reload]
    );

    return (
        <>
            <form>
                <Cabecalho
                    isButton
                    iconeBtn={<i className="icon-icon-add-cliente" />}
                    title="Novo Cliente"
                    styles="btnPrimary"
                    onClick={() => handleClickNew()}
                    onChangeSelect={() => {}}
                    isSearch
                    value={filtro}
                    onChange={(e) => filtrar(e.target.value)}
                    iconeColor="#09456b"
                />
            </form>

            <PageContent>
                <p
                    className="ml-1 mt-1 mb-3 float-left"
                    style={{
                        fontSize: '20px',
                        fontFamily: 'Inter, sans-serif',
                        color: '#57607A',
                        marginBottom: '-45px',
                    }}
                >
                    Lista de{' '}
                    <strong style={{ color: '#09456b' }}>clientes</strong>
                </p>

                <DataTable
                    includeFilter={false}
                    columns={columns}
                    data={data}
                    fetchData={getClients}
                    pageCount={pageCount}
                    total={totalRecords}
                    reload={reload}
                    loading={loading}
                    className=""
                    includePageSize={false}
                    includePagination={false}
                />
                {alertDelete}
            </PageContent>
            <ClientForm
                isOpen={isOpen}
                selectedRecord={selectedRecord}
                handleCloseModal={handleCloseModal}
                handleTableReaload={handleReloadTable}
            />
        </>
    );
}
