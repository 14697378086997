import React, { createContext, useContext, useState, useEffect } from 'react';
import tipoPrecificacaoOrcamentoTecnicoVendaConsts from '../consts/tipoPrecificacaoOrcamentoTecnicoVendaConsts';

const OrcamentoTecnicoVendaContext = createContext({});

const OrcamentoTecnicoVendaContextProvider = ({ children }) => {
    // states
    const [totalValorOrcamentoTecnico, setTotalValorOrcamentoTecnico] =
        useState();
    const [totalValorInsumos, setTotalValorInsumos] = useState();
    const [totalValorCustosAdicionais, setTotalValorCustosAdicionais] =
        useState();

    const [tipoPrecificacao, setTipoPrecificacao] = useState();
    const [orcamentoTecnicoValores, setOrcamentoTecnicoValores] = useState({});
    const [orcamentoTecnicoItens, setOrcamentoTecnicoItens] = useState([]);
    const [
        orcamentoTecnicoCustoAdicionais,
        setOrcamentoTecnicoCustosAdicionais,
    ] = useState([]);
    const [
        orcamentoTecnicoItensSelecionados,
        setOrcamentoTecnicoItensSelecionados,
    ] = useState([]);

    const [salvarAlteracoesOrcamento, setSalvarAlteracoesOrcamento] =
        useState(false);

    // functions
    function reiniciarValores() {
        setTotalValorOrcamentoTecnico(0);
        setTotalValorInsumos(0);
        setTotalValorCustosAdicionais(0);
        setOrcamentoTecnicoValores([]);
        setOrcamentoTecnicoItens([]);
        setOrcamentoTecnicoCustosAdicionais([]);
        setOrcamentoTecnicoItensSelecionados([]);
    }
    function atualizarTotalValorInsumos(valorInsumos) {
        setTotalValorInsumos(valorInsumos);
    }

    function atualizarOrcamentoTecnicoCustosAdicionais(custosAdicionais) {
        setOrcamentoTecnicoCustosAdicionais(custosAdicionais);
    }

    function atualizarListaItensSelecionados(itens) {
        setOrcamentoTecnicoItensSelecionados(itens);
    }

    async function atualizarTipoPrecificacao(tipo) {
        setTipoPrecificacao(tipo);
    }

    function atualizarTotalOrcamentoTecnicoComSomaValorItens() {
        const valorItens = orcamentoTecnicoItens.reduce(
            (a, b) => a + b.valorTotal,
            0
        );
        setTotalValorOrcamentoTecnico(valorItens);
    }

    function atualizarTotalOrcamentoTecnico(valor) {
        setTotalValorOrcamentoTecnico(valor);
    }

    function atualizarOrcamentoTecnicoItens(itens) {
        setOrcamentoTecnicoItens(itens);
    }

    async function atualizarOrcamentoTecnicoValores(valores) {
        setOrcamentoTecnicoValores(valores);
    }

    function atualizarValorItensBaseadoValorVenda(valorOrcamentoTecnico) {
        let itens = [];
        if (orcamentoTecnicoValores.aplicarRateio) {
            itens = orcamentoTecnicoItens.map((item) => {
                if (item.rateio) {
                    const valorItem =
                        (valorOrcamentoTecnico * item.rateio) / 100;
                    return { ...item, valorTotal: valorItem };
                }
                return { ...item };
            });
        } else {
            itens = orcamentoTecnicoItens.map((item) => {
                const valorItem =
                    valorOrcamentoTecnico / orcamentoTecnicoItens.length;
                return { ...item, valorTotal: valorItem };
            });
        }

        atualizarOrcamentoTecnicoItens(itens);
    }

    useEffect(() => {
        if (totalValorInsumos) {
            if (
                tipoPrecificacao ==
                tipoPrecificacaoOrcamentoTecnicoVendaConsts.painelOrcamento
            ) {
                const totalOrcamentoTecnico =
                    totalValorInsumos + totalValorCustosAdicionais;
                atualizarTotalOrcamentoTecnico(totalOrcamentoTecnico);
            }
        }
    }, [totalValorInsumos]);

    useEffect(() => {
        if (
            tipoPrecificacao ==
            tipoPrecificacaoOrcamentoTecnicoVendaConsts.painelOrcamento
        ) {
            const totalOrcamentoTecnico =
                totalValorInsumos + totalValorCustosAdicionais;
            atualizarTotalOrcamentoTecnico(totalOrcamentoTecnico);
        }
    }, [totalValorCustosAdicionais]);

    useEffect(() => {
        if (orcamentoTecnicoCustoAdicionais) {
            if (
                tipoPrecificacao ==
                tipoPrecificacaoOrcamentoTecnicoVendaConsts.painelOrcamento
            ) {
                const custosAdicionais = orcamentoTecnicoCustoAdicionais.reduce(
                    (a, b) => a + b.custo,
                    0
                );
                setTotalValorCustosAdicionais(custosAdicionais);
            }
        }

        if (orcamentoTecnicoItens) {
            if (
                tipoPrecificacao ==
                tipoPrecificacaoOrcamentoTecnicoVendaConsts.direta
            ) {
                atualizarTotalOrcamentoTecnicoComSomaValorItens();
            }
        }
    }, [
        tipoPrecificacao,
        orcamentoTecnicoCustoAdicionais,
        orcamentoTecnicoItens,
    ]);

    useEffect(() => {
        if (totalValorOrcamentoTecnico) {
            if (
                tipoPrecificacao ==
                tipoPrecificacaoOrcamentoTecnicoVendaConsts.painelOrcamento
            ) {
                atualizarValorItensBaseadoValorVenda(
                    totalValorOrcamentoTecnico
                );
            }
        }
    }, [totalValorOrcamentoTecnico]);

    return (
        <OrcamentoTecnicoVendaContext.Provider
            value={{
                orcamentoTecnicoCustoAdicionais,
                orcamentoTecnicoItensSelecionados,
                orcamentoTecnicoItens,
                orcamentoTecnicoValores,
                totalValorOrcamentoTecnico,
                tipoPrecificacao,
                salvarAlteracoesOrcamento,
                atualizarTotalValorInsumos,
                atualizarTotalOrcamentoTecnico,
                atualizarValorItensBaseadoValorVenda,
                atualizarOrcamentoTecnicoValores,
                atualizarOrcamentoTecnicoItens,
                atualizarListaItensSelecionados,
                atualizarTipoPrecificacao,
                atualizarOrcamentoTecnicoCustosAdicionais,
                reiniciarValores,
                setSalvarAlteracoesOrcamento,
            }}
        >
            {children}
        </OrcamentoTecnicoVendaContext.Provider>
    );
};

function useOrcamentoTecnicoVendaContext() {
    const context = useContext(OrcamentoTecnicoVendaContext);

    if (!context) {
        throw new Error('hook must be used with an Provider');
    }

    return context;
}

export {
    OrcamentoTecnicoVendaContextProvider,
    useOrcamentoTecnicoVendaContext,
};
