import React from 'react';
import { Row, Col } from 'reactstrap';
import Switch from 'react-bootstrap-switch';
import { IDadosProps, ISelect } from '../../../../shared/interface/itensAvulsos';

interface IItemAvulsoProps {
    dados:IDadosProps;
    tipo:ISelect,
    onActive: (dados:IDadosProps, state:boolean) => void,
 };

 export default function ListaItemAvulso({dados, tipo, onActive}:IItemAvulsoProps) {
    return(
        <Row className="listCard mr-5">
            <Col lg={1} className='p-0 m-0'>
                 <img
                    src={dados.imagemUrl}
                    height={"100%"}
                    width={"100%"}
                    />
             </Col>
             <Col lg={2}>
                 <label>Codigo</label>
                 <p>{dados.codigo}</p>
             </Col>
             <Col lg={4}>
                <label>Descrição</label>
                <p className='desc'>{dados.descricao}</p>
             </Col>
             <Col lg={2}>
                 <label>Tipo</label>
                 <p>
                     {
                        tipo.find(
                            (i:any) => i.label === dados.tipoProduto
                        )?.name
                    }
                </p>
            </Col>
            <Col lg={1} className='acao'>
                <label>
                    {dados.ativo ? 'Ativo' : 'Inativo'}
                </label>
                <Switch
                    offColor=""
                    offText={<i />}
                    onColor="success"
                    onText={<i />}
                    value={dados.ativo}
                    onChange={(e) => onActive(dados, e)}
                />
            </Col>
        </Row>
    )
}
