import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PageContent from '~/components/PageContent';
import OrcamentoNavegacaoSuperior from '~/components/OrcamentoNavegacaoSuperior';
import NavigationSubArea from '~/components/OrcamentoTecnicoNavegacao';
import { useOrcamentoTecnicoVendaContext } from 'hooks/useOrcamentoTecnicoVendaContext';
import tipoPrecificacaoOrcamentoTecnicoVendaConsts from '~/consts/tipoPrecificacaoOrcamentoTecnicoVendaConsts';
import { Button, Col } from 'reactstrap';
import OrcamentoTecnicoVendaCalculoPreco from './components/OrcamentoTecnicoVendaCalculoPreco';
import OrcamentoTecnicoVendaPainel from './components/OrcamentoTecnicoVendaPainel';
import './style.css';
import './icons.css';
import history from '~/services/history';
import webApi from '~/services/webapi';
import OrcamentoDadosCliente from '../../components/OrcamentoDadosCliente';
import { useOrcamentoContext } from '~/hooks/useOrcamentoContext';
import { situacaoOrcamentoIndustriaConsts } from '../../consts/situacaoOrcamentoIndustriaConsts';

export default function OrcamentoTecnicoVenda() {
    // params
    const { codOrcamentoTecnico, versao } = useParams();

    // hooks
    const {
        atualizarOrcamentoTecnicoValores,
        tipoPrecificacao,
        atualizarTipoPrecificacao,
        atualizarOrcamentoTecnicoCustosAdicionais,
        atualizarOrcamentoTecnicoItens,
        reiniciarValores,
    } = useOrcamentoTecnicoVendaContext();

    const { orcamentoStateContext } = useOrcamentoContext();

    useEffect(() => {
        (async () => {
            reiniciarValores();

            const itens = await webApi.get(
                `orcamentoTecnico/${codOrcamentoTecnico}/versao/${versao}/itens`
            );
            atualizarOrcamentoTecnicoItens(itens);

            const custosAdicionais = await webApi.get(
                `orcamentoTecnico/${codOrcamentoTecnico}/versao/${versao}/custos-adicionais`
            );
            atualizarOrcamentoTecnicoCustosAdicionais(custosAdicionais);

            const valoresOrcamentoTecnico = await webApi.get(
                `orcamentoTecnico/${codOrcamentoTecnico}/versao/${versao}/valores`
            );
            atualizarOrcamentoTecnicoValores(valoresOrcamentoTecnico);
            atualizarTipoPrecificacao(
                valoresOrcamentoTecnico.tipoPrecificacaoOrcamento
            );
        })();
    }, []);

    // functions
    async function handleChange() {
        return true;
    }

    function handleClickPrevious() {
        history.push(
            `/orcamentos-tecnicos/${codOrcamentoTecnico}/versao/${versao}/observacoes`
        );
    }

    return (
        <div titlePage="Valores">
            <div className="mb-1">
                <PageContent className="no-border">
                    <OrcamentoNavegacaoSuperior />
                    <OrcamentoDadosCliente />
                </PageContent>
            </div>

            <PageContent>
                <NavigationSubArea
                    active="vendas"
                    handleChange={() => handleChange()}
                />
                <div
                    className={
                        tipoPrecificacao ===
                        tipoPrecificacaoOrcamentoTecnicoVendaConsts.direta
                            ? 'cards-painel-block'
                            : 'cards-painel-flex'
                    }
                >
                    {tipoPrecificacao ===
                        tipoPrecificacaoOrcamentoTecnicoVendaConsts.painelOrcamento && (
                        <OrcamentoTecnicoVendaPainel />
                    )}
                </div>
                <OrcamentoTecnicoVendaCalculoPreco />
                <form>
                    <div className="m-3" style={{ fontSize: '18px' }}>
                        <div className="pull-left">
                            <Button
                                type="button"
                                className="boxButtonAvancar"
                                role="tablist"
                                style={{ backgroundColor: '#ABAFBC' }}
                                onClick={() => handleClickPrevious()}
                            >
                                <i
                                    className="icon-icon-voltar mr-1"
                                    style={{ fontSize: '18px' }}
                                />
                                Voltar
                            </Button>
                        </div>
                        {(orcamentoStateContext.situacaoOrcamentoIndustria ===
                            situacaoOrcamentoIndustriaConsts.pedidoAberto ||
                            orcamentoStateContext.situacaoOrcamentoIndustria ===
                                situacaoOrcamentoIndustriaConsts.pedidoSolicitado ||
                            orcamentoStateContext.situacaoOrcamentoIndustria ===
                                situacaoOrcamentoIndustriaConsts.pedidoFinalizado) && (
                            <div
                                className="pull-right mr-3 mb-1"
                                style={{ fontSize: '18px' }}
                            >
                                <Button
                                    type="button"
                                    role="tablist"
                                    className="boxButton"
                                    style={{ backgroundColor: '#3073CB' }}
                                    onClick={() => {
                                        history.push(
                                            `/orcamentos-tecnicos/${orcamentoStateContext.codOrcamento}/versao/${versao}/pedido`
                                        );
                                    }}
                                >
                                    Avançar{' '}
                                    <i
                                        className="icon-icon-avancar"
                                        style={{ fontSize: '18px' }}
                                    />
                                </Button>
                            </div>
                        )}
                    </div>
                </form>
            </PageContent>
        </div>
    );
}
