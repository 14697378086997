import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
// import { useSelector, useDispatch } from 'react-redux';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
} from 'reactstrap';
import history from '~/services/history';

import { useOrcamentoContext } from '~/hooks/useOrcamentoContext';

export default function NavigationSubArea(props) {
    const { orcamentoStateContext, permiteEdicaoOrcamento } =
        useOrcamentoContext();

    const { codOrcamento } = useParams();

    const [collapsed, setCollapsed] = useState(false);

    const modules = [
        {
            number: 1,
            title: 'Cliente',
            name: 'Client',
            icon: 'far fa-handshake',
            link: `/orcamentos${
                codOrcamento ? `/${codOrcamento}` : ''
            }/cliente`,
        },
        {
            number: 2,
            title: 'Obra',
            name: 'Project',
            icon: 'far fa-building',
            link: `/orcamentos/${codOrcamento}/obra`,
        },
        {
            number: 3,
            title: 'Cores',
            name: 'Color',
            icon: 'fas fa-fill-drip',
            link: `/orcamentos/${codOrcamento}/cores`,
        },
        {
            number: 4,
            title: 'Itens',
            name: 'items',
            icon: 'fas fa-sitemap',
            link: `/orcamentos/${codOrcamento}/itens`,
        },
        {
            number: 5,
            title: 'Dados Adicionais',
            name: 'additional',
            icon: 'fas fa-list-alt',
            link: `/orcamentos/${codOrcamento}/adicionais`,
        },
        {
            number: 6,
            title: 'Observações',
            name: 'observation',
            icon: 'fas fa-clipboard',
            link: `/orcamentos/${codOrcamento}/observacoes`,
        },
        {
            number: 7,
            title: 'Anexos',
            name: 'attachment',
            icon: 'fas fa-paperclip',
            link: `/orcamentos/${codOrcamento}/anexos`,
        },
    ];

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    };

    const onClickChange = async (e, m, props) => {
        e.preventDefault();
        if (permiteEdicaoOrcamento()) {
            if (m.name !== props.active) {
                const valid = await props.handleValid();
                if (valid) {
                    if (props.handleSave) await props.handleSave();
                    history.push(m.link);
                }
            }
        } else if (m.name !== props.active) {
            history.push(m.link);
        }
    };

    return (
        <>
            <div
                className="tab-content text-center d-sm-none d-md-block d-none d-sm-block"
                id="default"
            >
                <div
                    className="tab-pane active wizard"
                    role="tabpanel"
                    aria-expanded="true"
                    id="levantamento"
                >
                    <ul
                        className="nav nav-pills nav-pills-primary nav-pills-icons justify-content-center"
                        role="tablist"
                    >
                        {modules.map((m) => (
                            <li className="nav-item" key={m.name}>
                                <a
                                    href=""
                                    className={
                                        m.name === props.active
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }
                                    role="tablist"
                                    onClick={async (e) =>
                                        onClickChange(e, m, props)
                                    }
                                >
                                    <div className="mb-2">
                                        <i className={m.icon} />
                                    </div>
                                    <div
                                        style={{
                                            fontFamily: ' Inter, sans-serif',
                                        }}
                                    >
                                        {m.title}
                                    </div>
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="d-block d-sm-none">
                <Navbar color="faded" light>
                    <NavbarBrand href="/" className="mr-auto">
                        <i
                            className={module.icon}
                            style={{ marginBottom: '10px' }}
                        />
                        Cliente
                    </NavbarBrand>
                    <NavbarToggler onClick={toggleNavbar} className="mr-2" />
                    <Collapse isOpen={collapsed} navbar>
                        <Nav navbar>
                            {modules.map((module) => (
                                <NavItem key={module.name}>
                                    <NavLink href="/components/">
                                        <i className={`${module.icon} pr-2`} />
                                        {module.title}
                                    </NavLink>
                                </NavItem>
                            ))}
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
            <div className="row">
                <div className="col-sm-12 pl-4 pr-4">
                    <div className="tab-pane active text-left">
                        {props.children}
                    </div>
                </div>
            </div>
        </>
    );
}
