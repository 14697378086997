import React, { useEffect, useState } from 'react';
import {
    Button,
    Input,
    ListGroup,
    ListGroupItem,
    Modal,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import webApi from '~/services/webapi';

export default function RelatorioIndustria({
    isOpen,
    industryId,
    handleCloseModal,
}) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(false);

    const handleClickSelect = async (tipoLiberacaoRelatorio, item) => {
        try {
            setLoading(true);
            const { codRelatorioTecnico } = item;
            await webApi.put(
                `revenda/relatorio-tecnico/${industryId}/liberar-relatorios-tecnico-industria`,
                {
                    codRelatorioTecnico,
                    tipoLiberacaoRelatorio,
                }
            );
            setReload(!reload);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            (async () => {
                const result = await webApi.get(
                    `revenda/relatorios-tecnico/${industryId}`
                );
                const { records, total } = result;
                setData(records);
            })();
        }
    }, [isOpen, reload]);

    const handleClickSave = async () => {
        handleCloseModal();
    };

    return (
        <Modal isOpen={isOpen} toggle={() => handleCloseModal()} size="lg">
            <div className="modal-header">
                <button
                    type="button"
                    className="close"
                    onClick={() => handleCloseModal()}
                >
                    <i className="mc-icon nc0simple-remove" />
                </button>
                <h4 className="title title-up">Liberação de Relatórios</h4>
            </div>
            <ModalBody>
                <form>
                    <ListGroup>
                        {data.map((item) => (
                            <ListGroupItem>
                                {item.nome}
                                <div className="form-check">
                                    <Input
                                        type="select"
                                        className="form-control-sm"
                                        value={item.tipoLiberacaoRelatorio}
                                        onChange={async (e) => {
                                            await handleClickSelect(
                                                Number(e.target.value),
                                                item
                                            );
                                        }}
                                    >
                                        <option key={1} value={1}>
                                            {' '}
                                            Não Liberar{' '}
                                        </option>
                                        {item.nome != 'Relatório de Cortes' &&
                                            item.nome !=
                                                'Relatório de Montagens' && (
                                                <option key={2} value={2}>
                                                    {' '}
                                                    Liberar Antes de Fechar
                                                    Proposta{' '}
                                                </option>
                                            )}

                                        <option key={3} value={3}>
                                            {' '}
                                            Liberar Após de Fechar Proposta{' '}
                                        </option>
                                    </Input>
                                </div>
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </form>
            </ModalBody>

            <ModalFooter>
                <div className="left-side">
                    <Button
                        type="button"
                        className="btn btn-link"
                        onClick={() => handleCloseModal()}
                    >
                        Fechar
                    </Button>
                </div>
                <div className="right-side">
                    <Button
                        type="button"
                        className="btn btn-success btn-link"
                        onClick={() => handleClickSave()}
                        title="Salvar"
                    >
                        Salvar
                    </Button>
                </div>
                <div className="divider" />
            </ModalFooter>
        </Modal>
    );
}
