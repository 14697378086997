import React from 'react';
import PageContent from '~/components/PageContent';

export default function Tutorial() {
    return (
        <PageContent titlePage="Tutoriais">
            <div className="row mb-5">
                <div className="col-sm-12">
                    <div className="col-lg-12 col-xl-8 ml-auto mr-auto">
                        <div className="card-body text-center">
                            <h4 className="pb-3" style={{ color: '#57607A' }}>
                                Acesse o modelo de tutorial que deseja
                            </h4>
                            <div className="row">
                                <div className="col-12 col-xl-6 ">
                                    <div
                                        className="boxButton card card-pricing"
                                        style={{ borderBottom: 'none' }}
                                    >
                                        <div className="card-header">
                                            <h6
                                                className="card-category"
                                                style={{
                                                    color: '#57607A',
                                                    fontSize: '16px',
                                                }}
                                            >
                                                Tutorial em PDF
                                            </h6>
                                        </div>
                                        <div className="card-body ">
                                            <div className="card-icon">
                                                <i
                                                    className="icon-icon-pdf"
                                                    style={{
                                                        padding: '15px',
                                                        fontSize: '36px',
                                                        color: '#57607A',
                                                    }}
                                                />
                                            </div>
                                            <p
                                                className="mt-4 description"
                                                style={{
                                                    color: '#57607A',
                                                    fontSize: '16px',
                                                }}
                                            >
                                                Este manual tem por objetivo
                                                apresentar as funcionalidades da
                                                Plataforma Sync Revenda
                                            </p>
                                        </div>
                                        <div className="card-footer">
                                            <a
                                                href="#"
                                                className="boxButton btn "
                                                style={{
                                                    backgroundColor: '#4EAA63',
                                                }}
                                            >
                                                <span className="btn-label" />
                                                Acessar PDF
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-xl-6">
                                    <div
                                        className="boxButton card card-pricing"
                                        style={{ borderBottom: 'none' }}
                                    >
                                        <div className="card-header">
                                            <h6
                                                className="card-category"
                                                style={{
                                                    color: '#57607A',
                                                    fontSize: '16px',
                                                }}
                                            >
                                                Tutorial em Vídeo
                                            </h6>
                                        </div>
                                        <div className="card-body">
                                            <div className="card-icon">
                                                <i
                                                    className="icon-icon-ver-janela"
                                                    style={{
                                                        padding: '15px',
                                                        fontSize: '36px',
                                                        color: '#57607A',
                                                    }}
                                                />
                                            </div>
                                            <p
                                                className="mt-4 description"
                                                style={{
                                                    color: '#57607A',
                                                    fontSize: '16px',
                                                }}
                                            >
                                                Vídeo de treinamento das
                                                funcionalidades da Plataforma
                                                Sync Revenda
                                            </p>
                                        </div>
                                        <div className="card-footer">
                                            <a
                                                href="http://canaldocliente.esquadgroup.com.br"
                                                target="blanck"
                                                className="boxButton btn"
                                                style={{
                                                    backgroundColor: '#3073CB',
                                                }}
                                            >
                                                <span className="btn-label" />
                                                Acessar Vídeo
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageContent>
    );
}
