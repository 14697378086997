import React, { createContext, useContext, useRef } from "react";
import NotificationAlert from "react-notification-alert";

const NotifyContext = createContext({
	alerta: (message) => {},
	erro: (message) => {},
	sucesso: (message) => {},
	respostaHttp: (httpResponse) => {},
});

const NotifyContextProvider = ({ children }) => {
	const notify = useRef();

	function notification(message, type) {
		const options = {
			place: "tr",
			message: <div>{message}</div>,
			type: type,
			icon: "icon-alerta",
			autoDismiss: 2,
		};

		notify.current.notificationAlert(options);
	}

	function alerta(message) {
		notification(message, "warning");
	}

	function erro(error, customMessage = "Ocorreu um erro inesperado.") {
		let errorMessage = customMessage;

		if (typeof error === "string") {
			errorMessage = error;
		} else if (error && error.response && error.response.data) {
			const backendMessage = error.response.data.message;
			if (backendMessage) {
				errorMessage = backendMessage;
			} else if (error.response.data.errors) {
				const errorMessages = Object.values(error.response.data.errors);
				errorMessage = errorMessages.join(", ");
			}
		} else if (error && error.message) {
			errorMessage = error.message;
		}

		notification(errorMessage, "danger");
	}

	function sucesso(message) {
		notification(message, "success");
	}

	function notificationHttp(errors) {
		if (errors) {
			const mensagens = (
				<div>
					{errors.map((e) => (
						<div key={e}>{e}</div>
					))}
				</div>
			);

			const options = {
				place: "tr",
				message: mensagens,
				type: "warning",
				icon: "icon-alerta",
				autoDismiss: 3,
			};

			if (notify.current) {
				notify.current.notificationAlert(options);
			}
		}
	}

	function respostaHttp(httpResponse) {
		if (httpResponse?.request) {
			const { status, responseType } = httpResponse?.request;
			if (status === 400) {
				if (responseType !== "blob") {
					const { errors } = JSON.parse(httpResponse.request.response);
					notificationHttp(errors);
				} else {
					httpResponse.request.response.text().then((text) => {
						const { errors } = JSON.parse(text);
						notificationHttp(errors);
					});
				}
			} else erro("Ocorreu um erro ao realiza essa operação");
		}
	}

	return (
		<NotifyContext.Provider value={{ alerta, erro, sucesso, respostaHttp }}>
			{children}
			<NotificationAlert ref={notify} />
		</NotifyContext.Provider>
	);
};

function useNotifyContext() {
	const context = useContext(NotifyContext);

	if (!context) {
		throw new Error("hook must be used with an Provider");
	}

	return context;
}

export { NotifyContextProvider, useNotifyContext };
