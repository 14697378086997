import React, { useEffect, useState } from 'react';
import {
    Input,
    Label,
    FormFeedback,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import AsyncSelect from 'react-select/async';
import { useForm, Controller } from 'react-hook-form';

import { reactSelectCustomStyles } from '~/styles/react-select';
import * as yup from 'yup';
import webapi from '~/services/webapi';
import Desenhador from './components/Desenhador';
import BuscaPorVidro from '../BuscaPorVidro';
import { getListErrorMessage } from '~/utils';
import { useOrcamentoContext } from '~/hooks/useOrcamentoContext';
import { useParams } from 'react-router-dom';

const schema = yup.object({
    type: yup.string().required('Campo obrigatório'),
    description: yup.string().required('Campo obrigatório'),
    amount: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : parseInt(value)))
        .positive('')
        .min(1, 'Valor deve ser maior que 0')
        .required('Campo obrigatório'),
    height: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .positive('')
        .min(100, 'Valor deve ser maior que 100')
        .required('Campo obrigatório'),
    width: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .positive('')
        .min(100, 'Valor deve ser maior que 100')
        .required('Campo obrigatório'),
    glassthickness: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .positive('')
        .min(0),
});

const defaultValues = {
    code: '',
    description: '',
    designer: '',
    line: '',
    type: '',
    profileColor: {},
    componentColor: '',
    glassCode: '',
    glassDescription: '',
    glassColor: '',
    glassThickness: 0,
};

export default function FormularioItem({
    selectedRecord,
    isOpen,
    handleCloseModal,
    handleReloadTable,
}) {
    // hooks
    const { permiteEdicaoOrcamento } = useOrcamentoContext();

    // consts
    const { codOrcamento } = useParams();

    const [selectedGlass, setSelectedGlass] = useState({});
    const [searchGlassOpen, setSearchGlassOpen] = useState(false);
    const [useExternalInternalColor, setUseExternalInternalColor] =
        useState(false);
    const [image, setImage] = useState();
    const [imageChanged, setImageChanged] = useState(false);
    const [activeTab, setActiveTab] = useState('general');
    const {
        register,
        triggerValidation,
        setError,
        reset,
        resetField,
        errors,
        getValues,
        setValue,
        formState,
        control,
    } = useForm({
        defaultValues,
        validationSchema: schema,
        mode: 'onChange',
    });

    const handleClickSave = async () => {
        setError('type', 'notEqual', 'Passwords are different');
        const valid = await triggerValidation();
        if (valid) {
            let imageRaster = document
                .getElementById('croqui')
                .toDataURL('image/png')
                .replace('data:image/png;base64,', '');

            if (!imageChanged) imageRaster = null;

            setImageChanged(false);

            let data = {
                ...getValues(),
                amount: parseInt(getValues('amount')),
                height: parseInt(getValues('height')),
                width: parseInt(getValues('width')),
                image: imageRaster,
                glassThickness: parseInt(getValues('glassThickness')),
            };

            if (getValues().profileColor) {
                const { value, label } = getValues().profileColor;
                Object.assign(data, {
                    profileColor: value,
                    profileColorName: label,
                });
            }

            if (getValues().profileExternalColor) {
                const { value, label } = getValues().profileExternalColor;
                Object.assign(data, {
                    profileExternalColor: value,
                    profileExternalColorName: label,
                });
            }

            if (getValues().profileInternalColor) {
                const { value, label } = getValues().profileInternalColor;
                Object.assign(data, {
                    profileInternalColor: value,
                    profileInternalColorName: label,
                });
            }

            if (getValues().componentColor) {
                const { value, label } = getValues().componentColor;
                Object.assign(data, {
                    componentColor: value,
                    componentColorName: label,
                });
            }

            if (getValues().glassColor) {
                const { value, label } = getValues().glassColor;
                Object.assign(data, {
                    glassColor: value,
                    glassColorName: label,
                });
            }

            if (selectedRecord) {
                data = { ...data, codOrcamentoItem: selectedRecord };
            }

            try {
                await webapi.post(`/orcamentos/${codOrcamento}/item`, data);
                handleCloseModal();
                handleReloadTable();
            } catch (error) {
                let errors = getListErrorMessage(error);
                if (errors) {
                    let validation = errors[0];
                    if (validation && validation.fieldName) {
                        setError(
                            validation.fieldName,
                            'required',
                            validation.message
                        );
                    }
                }
            }
        }
    };

    const handleClickChange = async (e, target) => {
        e.preventDefault();
        if (target === undefined) {
            if (activeTab === 'general') target = 'color';
            if (activeTab === 'color') target = 'glass';
            else if (activeTab === 'glass') target = 'draw';
            else if (activeTab === 'draw') target = 'observation';
        }

        if (activeTab === 'general') {
            const valid = await triggerValidation();
            if (valid) {
                setActiveTab(target);
            }
        } else setActiveTab(target);
    };

    const handleClickBack = async () => {
        let target = 'general';
        if (activeTab === 'color') target = 'general';
        else if (activeTab === 'glass') target = 'color';
        else if (activeTab === 'draw') target = 'glass';
        else if (activeTab === 'observation') target = 'draw';

        setActiveTab(target);
    };

    const obterCoresPerfis = async (filter) => {
        const result = await webapi.post(`/cores/perfis`, { filter: filter });
        const colors = result.records.map((m) => {
            return {
                label: m.description,
                value: m.code,
            };
        });

        return colors;
    };

    const obterCoresComponentes = async (filter) => {
        const result = await webapi.post(`/cores/componentes`, {
            filter: filter,
        });
        const colors = result.records.map((m) => {
            return {
                label: m.description,
                value: m.code,
            };
        });

        return colors;
    };

    const obterCoresVidros = async (filter) => {
        const result = await webapi.post(`/cores/vidros`, { filter: filter });
        const colors = result.records.map((m) => {
            return {
                label: m.description,
                value: m.code,
            };
        });
        return colors;
    };

    const handleClickOpenSearchGlass = () => {
        setSearchGlassOpen(true);
    };

    const handleClickCloseSearchGlass = () => {
        setSearchGlassOpen(false);
    };

    const setColor = (result) => {
        const {
            profileColor,
            profileColorName,
            externalInternalColor,
            profileExternalColor,
            profileExternalColorName,
            profileInternalColor,
            profileInternalColorName,
            componentColor,
            componentColorName,
            glassColor,
            glassColorName,
        } = result;

        reset({
            ...getValues(),
            profileColor: { value: profileColor, label: profileColorName },
            componentColor: {
                value: componentColor,
                label: componentColorName,
            },
            glassColor: { value: glassColor, label: glassColorName },
            externalInternalColor: externalInternalColor,
            profileExternalColor: {
                value: profileExternalColor,
                label: profileExternalColorName,
            },
            profileInternalColor: {
                value: profileInternalColor,
                label: profileInternalColorName,
            },
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            if (selectedRecord) {
                const result = await webapi.get(
                    `/orcamentos/${codOrcamento}/itens/${selectedRecord}`
                );

                reset(result);

                const { imagem } = result;
                if (imagem) setImage(imagem);

                const { profileColor } = result;
                setUseExternalInternalColor(false);

                if (profileColor) {
                    setColor(result);
                } else {
                    const result = await webapi.get(
                        `/orcamentos/${codOrcamento}/cor`
                    );
                    setColor(result);
                }
            } else {
                const result = await webapi.get(
                    `/orcamentos/${codOrcamento}/cor`
                );
                setColor(result);
            }
        };

        if (isOpen) setImage(null);

        reset({});

        setActiveTab('general');

        fetchData();
    }, [selectedRecord, isOpen]);

    useEffect(() => {
        if (selectedGlass) {
            setValue('glassCode', selectedGlass.code);
            setValue('glassDescription', selectedGlass.description);
            setValue('glassReference', selectedGlass.reference);
            setValue('glassType', selectedGlass.type);
            setValue('glassThickness', selectedGlass.thickness);
        }
    }, [selectedGlass]);

    return (
        <Modal isOpen={isOpen} size="xl">
            <ModalHeader>
                <button
                    type="button"
                    className="close"
                    onClick={() => handleCloseModal()}
                >
                    <i className="nc-icon nc-simple-remove" />
                </button>
                <span className="title title-up">
                    {getValues('code') ? (
                        <span>Adicionar Item</span>
                    ) : (
                        <span> Adicionar Item Especial</span>
                    )}
                    <br />
                </span>
            </ModalHeader>
            <ModalBody>
                <div className="wizard">
                    <ul
                        className="nav nav-pills nav-pills-primary nav-pills-icons nav-justified"
                        role="tablist"
                    >
                        <li className="nav-item mt-2">
                            <a
                                href="#"
                                onClick={(e) => handleClickChange(e, 'general')}
                                className={
                                    activeTab == 'general'
                                        ? 'nav-link active'
                                        : 'nav-link'
                                }
                                role="tablist"
                            >
                                <i className="icon-icon-pedidos" />
                                {' Geral'}
                            </a>
                        </li>
                        <li className="nav-item mt-2">
                            <a
                                href="#"
                                onClick={(e) => handleClickChange(e, 'color')}
                                className={
                                    activeTab == 'color'
                                        ? 'nav-link active'
                                        : 'nav-link'
                                }
                                role="tablist"
                            >
                                <i className="icon-icon-cor" />
                                {' Cores'}
                            </a>
                        </li>
                        <li className="nav-item mt-n1">
                            <a
                                href="#"
                                onClick={(e) => handleClickChange(e, 'glass')}
                                className={
                                    activeTab == 'glass'
                                        ? 'nav-link active'
                                        : 'nav-link'
                                }
                                role="tablist"
                            >
                                <i className="fas egf egf-vidro" />
                                {' Vidro'}
                            </a>
                        </li>
                        <li className="nav-item mt-n1">
                            <a
                                href="#"
                                onClick={(e) => handleClickChange(e, 'draw')}
                                className={
                                    activeTab === 'draw'
                                        ? 'nav-link active'
                                        : 'nav-link'
                                }
                                role="tablist"
                            >
                                <i className="far fa-image" />
                                {' Croqui'}
                            </a>
                        </li>
                        <li className="nav-item mt-n1">
                            <a
                                href="#"
                                onClick={(e) =>
                                    handleClickChange(e, 'observation')
                                }
                                className={
                                    activeTab === 'observation'
                                        ? 'nav-link active'
                                        : 'nav-link'
                                }
                                role="tablist"
                            >
                                <i className="far fa-clipboard" />
                                {' Observações'}
                            </a>
                        </li>
                    </ul>
                    <div className="tab-content tab-space tab-subcategories">
                        <div
                            className={
                                activeTab === 'general'
                                    ? 'tab-pane active'
                                    : 'tab-pane'
                            }
                            id="item-especial-geral"
                        >
                            <form autoComplete="off">
                                <div className="row">
                                    <div className="col-sm-4 mt-3">
                                        <Label>Código:</Label>

                                        {getValues('code') ? (
                                            <Input
                                                innerRef={register}
                                                maxLength={20}
                                                type="text"
                                                name="code"
                                                placeholder={
                                                    getValues('code')
                                                        ? ''
                                                        : '#Item Especial'
                                                }
                                                className="boxInput form-control form-control-sm"
                                                invalid={
                                                    !!errors?.code?.message
                                                }
                                                disabled={
                                                    !permiteEdicaoOrcamento()
                                                }
                                            />
                                        ) : (
                                            <div>
                                                <div className="badge badge-primary mt-2">
                                                    Item Especial não possui
                                                    código
                                                </div>
                                            </div>
                                        )}

                                        <FormFeedback>
                                            {errors?.code?.message}
                                        </FormFeedback>
                                    </div>
                                    <div className="col-sm-8 mt-3">
                                        <Label>Descrição:</Label>
                                        <Input
                                            innerRef={register}
                                            maxLength={500}
                                            type="text"
                                            name="description"
                                            placeholder="Descrição..."
                                            className="boxInput form-control form-control-sm"
                                            invalid={
                                                !!errors?.description?.message
                                            }
                                            disabled={
                                                getValues('code') ||
                                                !permiteEdicaoOrcamento()
                                            }
                                        />
                                        <FormFeedback>
                                            {errors?.description?.message}
                                        </FormFeedback>
                                    </div>
                                </div>
                                <div className="row">
                                    {getValues('code') && (
                                        <div className="col-sm-3 mt-3">
                                            <Label>Projetista:</Label>
                                            <Input
                                                innerRef={register}
                                                maxLength={20}
                                                type="text"
                                                name="designer"
                                                placeholder="Projetista..."
                                                className="boxInput form-control form-control-sm"
                                                invalid={
                                                    !!errors?.designer?.message
                                                }
                                                disabled={
                                                    getValues('code') ||
                                                    !permiteEdicaoOrcamento()
                                                }
                                            />
                                            <FormFeedback>
                                                {errors?.designer?.message}
                                            </FormFeedback>
                                        </div>
                                    )}
                                    <div
                                        className={`col-sm-${
                                            getValues('code') ? '3' : '4'
                                        } mt-3`}
                                    >
                                        <Label>Linha:</Label>
                                        <Input
                                            innerRef={register}
                                            maxLength={20}
                                            type="text"
                                            name="line"
                                            placeholder="Linha..."
                                            className=" boxInput form-control form-control-sm"
                                            invalid={!!errors?.line?.message}
                                            disabled={
                                                getValues('code') ||
                                                !permiteEdicaoOrcamento()
                                            }
                                        />
                                        <FormFeedback>
                                            {errors?.line?.message}
                                        </FormFeedback>
                                    </div>
                                    <div
                                        className={`col-sm-${
                                            getValues('code') ? '2' : '3'
                                        } mt-3`}
                                    >
                                        <Label>Tipo:</Label>
                                        <Input
                                            innerRef={register}
                                            maxLength={20}
                                            type="text"
                                            name="type"
                                            placeholder="Tipo..."
                                            className="boxInput form-control form-control-sm"
                                            invalid={!!errors?.type?.message}
                                            disabled={!permiteEdicaoOrcamento()}
                                        />
                                        <FormFeedback>
                                            {errors?.type?.message}
                                        </FormFeedback>
                                    </div>
                                    <div
                                        className={`col-sm-${
                                            getValues('code') ? '4' : '5'
                                        } mt-3`}
                                    >
                                        <Label>Localização:</Label>
                                        <Input
                                            innerRef={register}
                                            maxLength={20}
                                            type="text"
                                            name="localization"
                                            placeholder="Localização..."
                                            className="boxInput form-control form-control-sm"
                                            invalid={
                                                !!errors?.localization?.message
                                            }
                                            disabled={!permiteEdicaoOrcamento()}
                                        />
                                        <FormFeedback>
                                            {errors?.localization?.message}
                                        </FormFeedback>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4 mt-3">
                                        <Label>Quantidade:</Label>
                                        <Input
                                            innerRef={register}
                                            maxLength={3}
                                            type="number"
                                            name="amount"
                                            placeholder="Quantidade..."
                                            className="boxInput form-control form-control-sm"
                                            invalid={!!errors?.amount?.message}
                                            onChange={(e) => {
                                                const value = parseInt(
                                                    e.target.value
                                                )
                                                    ? parseInt(e.target.value)
                                                    : '';
                                                setValue('amount', value);
                                            }}
                                            disabled={!permiteEdicaoOrcamento()}
                                        />
                                        <FormFeedback>
                                            {errors?.amount?.message}
                                        </FormFeedback>
                                    </div>
                                    <div className="col-sm-4 mt-3">
                                        <Label>Largura (mm):</Label>
                                        <Input
                                            innerRef={register}
                                            maxLength={5}
                                            type="text"
                                            name="width"
                                            placeholder="Largura (mm)..."
                                            className="boxInput form-control form-control-sm"
                                            invalid={!!errors?.width?.message}
                                            onChange={(e) => {
                                                const value = parseInt(
                                                    e.target.value
                                                )
                                                    ? parseInt(e.target.value)
                                                    : '';
                                                setValue('width', value);
                                            }}
                                            disabled={!permiteEdicaoOrcamento()}
                                        />
                                        <FormFeedback>
                                            {errors?.width?.message}
                                        </FormFeedback>
                                    </div>
                                    <div className="col-sm-4 mt-3">
                                        <Label>Altura (mm):</Label>
                                        <Input
                                            innerRef={register}
                                            maxLength={5}
                                            type="text"
                                            name="height"
                                            placeholder="Altura (mm)..."
                                            className="boxInput form-control form-control-sm"
                                            invalid={!!errors?.height?.message}
                                            onChange={(e) => {
                                                const value = parseInt(
                                                    e.target.value
                                                )
                                                    ? parseInt(e.target.value)
                                                    : '';
                                                setValue('height', value);
                                            }}
                                            disabled={!permiteEdicaoOrcamento()}
                                        />
                                        <FormFeedback>
                                            {errors?.height?.message}
                                        </FormFeedback>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div
                            className={
                                activeTab === 'color'
                                    ? 'tab-pane active'
                                    : 'tab-pane'
                            }
                            id="item-especial-cores"
                        >
                            <div className="row">
                                <div className="col-md-6">
                                    <Label>Pintura / Cor dos Perfis: *</Label>
                                    <Controller
                                        ref={register}
                                        name="profileColor"
                                        styles={reactSelectCustomStyles}
                                        classNamePrefix="react-select"
                                        placeholder="Pintura / Cor dos Perfis:..."
                                        defaultOptions
                                        loadOptions={(filter) =>
                                            obterCoresPerfis(filter)
                                        }
                                        onChange={([selected]) => {
                                            return selected;
                                        }}
                                        as={AsyncSelect}
                                        control={control}
                                        isClearable={true}
                                        className={
                                            !!errors?.profileColor?.message
                                                ? 'invalid is-invalid '
                                                : ''
                                        }
                                        noOptionsMessage={() => null}
                                        rules={{ required: true }}
                                        isDisabled={!permiteEdicaoOrcamento()}
                                    />
                                    <FormFeedback>
                                        {errors?.profileColor?.message}
                                    </FormFeedback>
                                    <input
                                        type="hidden"
                                        ref={register}
                                        name="profileColorName"
                                    />
                                </div>
                                <div className="col-md-6"></div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 mt-3">
                                    <div className="form-check pl-2">
                                        <label className="form-check-label">
                                            <input
                                                ref={register}
                                                name="externalInternalColor"
                                                className="form-check-input"
                                                type="checkbox"
                                                data-toggle="collapse"
                                                onChange={() =>
                                                    setUseExternalInternalColor(
                                                        !useExternalInternalColor
                                                    )
                                                }
                                                disabled={
                                                    !permiteEdicaoOrcamento()
                                                }
                                            />
                                            <span className="form-check-sign"></span>
                                            COR INTERNA/EXTERNA
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {useExternalInternalColor && (
                                <div className="row">
                                    <div className="col-sm-6 mt-3">
                                        <Label>Cor Externa:</Label>
                                        <Controller
                                            name="profileExternalColor"
                                            styles={reactSelectCustomStyles}
                                            classNamePrefix="react-select"
                                            placeholder="Cor Externa..."
                                            defaultOptions
                                            loadOptions={(filter) =>
                                                obterCoresPerfis(filter)
                                            }
                                            onChange={([selected]) => {
                                                return selected;
                                            }}
                                            as={AsyncSelect}
                                            control={control}
                                            isClearable={true}
                                            className={
                                                !!errors?.name?.message
                                                    ? 'invalid is-invalid '
                                                    : ''
                                            }
                                            noOptionsMessage={() => null}
                                            isDisabled={
                                                !permiteEdicaoOrcamento()
                                            }
                                        />
                                        <input
                                            type="hidden"
                                            ref={register}
                                            name="profileExternalColorName"
                                        />
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <Label>Cor Interna:</Label>
                                        <Controller
                                            name="profileInternalColor"
                                            styles={reactSelectCustomStyles}
                                            classNamePrefix="react-select"
                                            placeholder="Cor Interna..."
                                            defaultOptions
                                            loadOptions={(filter) =>
                                                obterCoresPerfis(filter)
                                            }
                                            onChange={([selected]) => {
                                                return selected;
                                            }}
                                            as={AsyncSelect}
                                            control={control}
                                            isClearable={true}
                                            className={
                                                !!errors?.name?.message
                                                    ? 'invalid is-invalid '
                                                    : ''
                                            }
                                            noOptionsMessage={() => null}
                                            isDisabled={
                                                !permiteEdicaoOrcamento()
                                            }
                                        />
                                        <input
                                            type="hidden"
                                            ref={register}
                                            name="profileInternalColorName"
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="row">
                                <div className="col-sm-6 mt-3">
                                    <Label>Cor Componentes:</Label>
                                    <Controller

                                style={{border:'1px solid red'}}
                                        ref={register}
                                        name="componentColor"
                                        styles={reactSelectCustomStyles}
                                        classNamePrefix="react-select"
                                        placeholder="Cor Componentes..."
                                        defaultOptions
                                        loadOptions={(filter) =>
                                            obterCoresComponentes(filter)
                                        }
                                        onChange={([selected]) => {
                                            return selected;
                                        }}
                                        as={AsyncSelect}
                                        control={control}
                                        isClearable={true}
                                        className={
                                            !!errors?.componentColor?.message
                                                ? 'invalid is-invalid '
                                                : ''
                                        }
                                        noOptionsMessage={() => null}
                                        rules={{ required: true }}
                                        isDisabled={!permiteEdicaoOrcamento()}
                                    />
                                    <FormFeedback>
                                        {errors?.componentColor?.message}
                                    </FormFeedback>
                                    <input
                                        type="hidden"
                                        ref={register}
                                        name="componentColorName"
                                    />
                                </div>
                                <div className="col-sm-6 mt-3">
                                    <Label>Cor Vidros:</Label>
                                    <Controller
                                        name="glassColor"
                                        styles={reactSelectCustomStyles}
                                        classNamePrefix="react-select"
                                        placeholder="Cor Vidros..."
                                        defaultOptions
                                        loadOptions={(filter) =>
                                            obterCoresVidros(filter)
                                        }
                                        onChange={([selected]) => {
                                            return selected;
                                        }}
                                        as={AsyncSelect}
                                        control={control}
                                        isClearable={true}
                                        className={
                                            !!errors?.glassColor?.message
                                                ? 'invalid is-invalid '
                                                : ''
                                        }
                                        noOptionsMessage={() => null}
                                        rules={{ required: true }}
                                        isDisabled={!permiteEdicaoOrcamento()}
                                    />
                                    <FormFeedback>
                                        {errors?.glassColor?.message}
                                    </FormFeedback>
                                    <input
                                        type="hidden"
                                        ref={register}
                                        name="glassColorName"
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            className={
                                activeTab === 'glass'
                                    ? 'tab-pane active'
                                    : 'tab-pane'
                            }
                            id="item-especial-vidro"
                        >
                            {permiteEdicaoOrcamento() && (
                                <a
                                    className="btn btn-primary"
                                    data-toggle="modal"
                                    data-target="#modal-escolher-vidros"
                                    onClick={() => handleClickOpenSearchGlass()}
                                >
                                    <i className="fas fa-search" /> Escolher
                                    Vidro
                                </a>
                            )}
                            <Input
                                type="hidden"
                                innerRef={register}
                                name="glassDescription"
                            />
                            <div className="row text-left">
                                <div className="col-sm-6 mt-3">
                                    <label>Código:</label>
                                    <Input
                                        innerRef={register}
                                        name="glassCode"
                                        type="text"
                                        placeholder="Código:..."
                                        className="form-control form-control-sm"
                                        disabled={!permiteEdicaoOrcamento()}
                                    />
                                </div>
                                <div className="col-sm-6 mt-3">
                                    <label>Referência:</label>
                                    <Input
                                        innerRef={register}
                                        name="glassReference"
                                        type="text"
                                        placeholder="Referência..."
                                        className="form-control form-control-sm"
                                        disabled={!permiteEdicaoOrcamento()}
                                    />
                                </div>
                                <div className="col-sm-6 mt-3">
                                    <label>Tipo de Vidro:</label>
                                    <Input
                                        innerRef={register}
                                        name="glassType"
                                        type="text"
                                        placeholder="Tipo de Vidro..."
                                        className="form-control form-control-sm"
                                        disabled={!permiteEdicaoOrcamento()}
                                    />
                                </div>
                                <div className="col-sm-6 mt-3">
                                    <label>Espessura do Vidro:</label>
                                    <Input
                                        innerRef={register}
                                        name="glassThickness"
                                        type="text"
                                        placeholder="Espessura do Vidro..."
                                        className="form-control form-control-sm"
                                        defaultValue={0}
                                        disabled={!permiteEdicaoOrcamento()}
                                    />
                                </div>
                            </div>
                            <BuscaPorVidro
                                isOpen={searchGlassOpen}
                                handleCloseModal={handleClickCloseSearchGlass}
                                setSelectedGlass={setSelectedGlass}
                            />
                        </div>
                        <div
                            className={
                                activeTab === 'draw'
                                    ? 'tab-pane active'
                                    : 'tab-pane'
                            }
                            id="item-especial-croqui"
                        >
                            <Desenhador
                                image={image}
                                setImage={setImage}
                                setImageChanged={setImageChanged}
                                selectedRecord={selectedRecord}
                                readOnly={permiteEdicaoOrcamento()}
                            />
                        </div>
                        <div
                            className={
                                activeTab === 'observation'
                                    ? 'tab-pane active'
                                    : 'tab-pane'
                            }
                            id="item-especial-observacoes"
                        >
                            <div className="row">
                                <div className="col-md-12">
                                    <Label>Observações do Item:</Label>
                                    <Input
                                        name="observation"
                                        type="textarea"
                                        innerRef={register}
                                        maxLength={120}
                                        className="form-control form-control-sm"
                                        disabled={!permiteEdicaoOrcamento()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="left-side ">
                    {activeTab !== 'general' && (
                        <button
                            className="btn pull-left boxButtonAvancar voltarBtn"
                            role="tablist"
                            onClick={() => handleClickBack()}
                        >
                            <i className="far fa-arrow-alt-circle-left" style={{
                                fontSize:'18px',
                            }}></i>{' '}
                            Voltar
                        </button>
                    )}
                </div>
                <div className="right-side">
                    <button
                        disabled={!permiteEdicaoOrcamento()}
                        className="btn btn-primary pull-right boxButton salvarOrça"
                        role="tablist"
                        onClick={() => handleClickSave()}
                    >
                        Salvar no Orçamento <i className="fas fa-share" style={{
                                fontSize:'18px',
                            }}></i>
                    </button>
                    {activeTab !== 'observation' && (
                        <button
                            className="btn boxButtonAvancar pull-right avancarBtn"
                            role="tablist"
                            onClick={(e) => handleClickChange(e)}
                        >
                            Avançar{' '}
                            <i className="icon-icon-avancar ml-1" style={{
                                fontSize:'18px'
                            }}></i>
                        </button>
                    )}
                </div>
            </ModalFooter>
        </Modal>
    );
}
