import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import './styles.css';

interface ICabecalhoEgShop {
    title: string;
    iconBtn?: any;
    descricaoBtn?: string;
    onRegisterNew?: () => void;
}
export default function CabecalhoEgShop({
    title,
    iconBtn,
    descricaoBtn,
    onRegisterNew,
}: ICabecalhoEgShop) {
    return (
        <Row className="rowHeader">
            <Col xl={8} lg={7} md={12} sm={12} xs={12} className="m-0 p-0">
                <h3 className="titulo">{title}</h3>
            </Col>
            <Col xl={3} lg={5} md={12} sm={12} xs={12} className="btnColHeader">
                <Button className="primaryEgShp" onClick={onRegisterNew}>
                    <div className="btnDescricao">
                        <p>{iconBtn}</p>
                        <p>{descricaoBtn}</p>
                    </div>
                </Button>
            </Col>
        </Row>
    );
}
