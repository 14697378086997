import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Button, Input, InputGroup, Label, Row } from 'reactstrap';

import loginImg from './img/login.png';
import SweetAlert from 'react-bootstrap-sweetalert';
import FieldValidation from '../../components/FieldValidation';
import { useAuthContext } from 'hooks/useAuthContext';
import { useNotifyContext } from 'hooks/useNotifyContext';
import history from '../../services/history';
import { getSingleErrorMessage } from '../../helpers/getSingleErrorMessage';

type UserProps = {
    email: string;
    password: string;
    inativarSessaoAnterior: boolean;
};

interface AuthUserProps extends UserProps {
    inativarSessaoAnterior: boolean;
}

export default function Acesso() {
    // states
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);

    // hooks
    const auth = useAuthContext();
    const notificacao = useNotifyContext();

    const { register, handleSubmit, getValues, errors, formState, setError } =
        useForm<UserProps>({
            mode: 'onChange',
            validationSchema: yup.object().shape({
                email: yup
                    .string()
                    .email('Formato de e-mail inválido')
                    .required('Campo obrigatório'),
                password: yup
                    .string()
                    .max(20, 'Senha não pode ter mais de 20 caracteres')
                    .required('Campo obrigatório'),
            }),
        });

    //functions
    const onSubmit = async ({ email, password }: UserProps) => {
        try {
            setLoading(true);
            await auth.autenticar({ email, password });
        } catch (error: any) {
            const message = getSingleErrorMessage(error);
            if (message === 'Usuário já está logado no sistema') {
                setShowModal(true);
            } else {
                setError('password', '', 'E-mail ou Senha inválido');
            }
        } finally {
            setLoading(false);
        }
    };

    const onSubmitInativarSessaoAnterior = async () => {
        const { email, password } = getValues();
        try {
            const authUser: AuthUserProps = {
                email,
                password,
                inativarSessaoAnterior: true,
            };
            await auth.autenticar(authUser);
        } catch (error) {
            notificacao.alerta('Erro ao autenticar');
        } finally {
            setShowModal(false);
        }
    };

    return (
        <>
            <Row>
                <div className="containerLogin d-flex ">
                    <div className="containerSub float-left ml-sm-2 mr-sm-2">
                        <h2 className="tituloLogin">Entrar</h2>
                        <form className="form">
                            <InputGroup>
                                <Label>
                                    E-mail
                                    <Input
                                        name="email"
                                        className="boxButton inputLogin"
                                        placeholder="Digite o seu e-mail de acesso"
                                        innerRef={register}
                                    />
                                    <FieldValidation error={errors?.email} />
                                </Label>
                            </InputGroup>

                            <InputGroup>
                                <Label>
                                    Senha
                                    <Input
                                        name="password"
                                        type="password"
                                        className="boxButton inputLogin"
                                        placeholder="Digite a sua senha"
                                        innerRef={register}
                                    />
                                </Label>
                                <FieldValidation error={errors?.password} />
                            </InputGroup>

                            <Button
                                type="submit"
                                className="boxButton buttonLogin mb-3"
                                onClick={handleSubmit(onSubmit)}
                            >
                                <p className="mt-1 mb-n1">Entrar </p>
                                <i className="icon-icon-avancar ml-3" />
                            </Button>

                            <hr className="bordaLogin" />
                            <div className="row buttonsLogin">
                                <Button
                                    className="buttonPrimeiro ml-n4 mr-2 mt-4"
                                    onClick={() =>
                                        history.push('primeiro-acesso')
                                    }
                                >
                                    <i className="icon-icon-ajuda mr-1" />
                                    <p>Primeiro Acesso</p>
                                </Button>

                                <Button
                                    className="buttonEsqueceu"
                                    onClick={() =>
                                        history.push('recuperar-senha')
                                    }
                                >
                                    <i className="icon-icon-ajuda mr-1" />
                                    <p>Esqueceu a Senha?</p>
                                </Button>
                            </div>
                        </form>
                    </div>

                    <div className="imgLogin rounded float-right">
                        <img
                            className="img-fluid img-responsive"
                            src={loginImg}
                            width="1080"
                            height="960"
                        />
                    </div>
                </div>
            </Row>

            <SweetAlert
                show={showModal}
                type="warning"
                title="Usuário já logado no sitema, deseja encerrar a sessão anterior e iniciar uma nova?"
                confirmBtnText="Sim"
                cancelBtnText="Não"
                confirmBtnBsStyle="primary"
                cancelBtnBsStyle="secondary"
                onConfirm={onSubmitInativarSessaoAnterior}
                onCancel={() => setShowModal(false)}
                showCancel={true}
            />
        </>
    );
}
