import React from 'react';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Col, Row } from 'reactstrap';
import history from 'services/history';
import StatusOrcamento from '../../../components/OrcamentoStatus/StatusOrcamento';
import styles from './OrcamentoItem.module.css';
import { useOrcamentoContext } from 'hooks/useOrcamentoContext';
import { IOrcamentoVendedor } from 'shared/interface/orcamento';
import CotacaoStatus from '../../../components/CotacaoStatus/index';
import PedidoStatus from '../../../components/CotacaoStatus/pedido';

interface OrcamentoProps {
    orcamento: IOrcamentoVendedor;
}

export default function OrcamentoVendedorItem(item: OrcamentoProps) {
    const { definirOrcamentoSelecionado } = useOrcamentoContext();

    const handleEditarOrcamento = () => {
            definirOrcamentoSelecionado(
                item.orcamento.observacao,
                item.orcamento.codPedido,
                item.orcamento.codOrcamento,
                item.orcamento.situacaoOrcamentoIndustria,
                item.orcamento.situacaoOrcamentoRevenda,
                item.orcamento.codOrcamentoTecnico,
                item.orcamento.versao,
                item.orcamento.clienteContatoEmail,
                item.orcamento.codIndustria,
                item.orcamento.nomeObra,
                item.orcamento.tipo,
            )
        history.push(`/orcamentoVendedor/${item.orcamento.codOrcamento}/versao/${item.orcamento.versao}/detalhes`);

    };

    return (
        <>
            <Row className={item.orcamento.tipo === 1 ? `${styles.item}` : `${styles.itemCotacao}`}>
                <Col xs={12} sm={2} md={2} lg={2} xl={2}>
                    <div className="d-flex flex-column">
                        <span className={item.orcamento.tipo === 1 ? `${styles.data}` : `${styles.dataCotacao}`}>
                            {item.orcamento?.nomeIndustria
                                ? item.orcamento?.nomeIndustria
                                : 'Orçamento sem Nome'}
                        </span>
                        <span
                            className={`${styles.subtitle}`}
                        >{`Cód. ${item.orcamento.codigoSequencial}`}</span>
                    </div>
                </Col>
                <Col className="d-flex flex-column">
                    <label>Criação</label>
                    <span className={`${styles.subtitle} font-weight-bold`}>
                        {format(parseISO(item.orcamento.dataCriacao), 'dd/MM/yyyy', {
                            locale: ptBR,
                        })}
                    </span>
                </Col>
                <Col className="d-flex flex-column">
                    <label>Módulo</label>
                    <span className={`${styles.subtitle} font-weight-bold`}>
                        {item.orcamento.tipo === 1 ?
                        <span className={`${styles.modEsquad}`}>Esquadria</span> :
                        <span className={`${styles.modAvulso}`}>Avulso</span>}
                    </span>
                </Col>
                <Col className="d-flex flex-column">
                    <label>Usuário</label>
                    <span className={`${styles.subtitle} font-weight-bold`}>
                        {item.orcamento.nomeUsuario}
                    </span>
                </Col>
                <Col className="d-flex flex-column" xs={6} sm={2} md={2} lg={2} xl={2}>
                    <label>Preço de Venda</label>
                    <span className={`${styles.custo}`}>
                        {item.orcamento.totalCustoVendedor > item.orcamento.totalCusto ?
                        <>
                        {String(
                            item.orcamento.totalCustoVendedor
                                ? `R$ ${item.orcamento.totalCustoVendedor
                                    .toLocaleString(
                                    'pt-br',
                                    { minimumFractionDigits: 2 }
                                )}`
                                : 'Aguardando'
                        )}
                        </> :
                        <>
                        {String(
                            item.orcamento.totalCusto
                                ? `R$ ${item.orcamento.totalCusto
                                    .toLocaleString(
                                    'pt-br',
                                    { minimumFractionDigits: 2 }
                                )}`
                                : 'Aguardando'
                        )}
                        </>
                        }
                    </span>
                </Col>
                <Col className="d-flex align-items-center justify-items-end p-0" xs={6} sm={2} md={2} lg={2} xl={2}>
                    {item.orcamento.tipo === 1 ?
                        <StatusOrcamento
                            situacaoOrcamentoIndustria={
                                item.orcamento.situacaoOrcamentoIndustria
                            }
                        />
                    :
                    <Col className='d-flex align-items-center justify-items-end p-0 '>
                        {item.orcamento.codPedido === null ?
                            <CotacaoStatus status={item.orcamento.situacaoOrcamentoIndustria} />
                            :
                            <PedidoStatus status={item.orcamento.situacaoOrcamentoIndustria} />
                        }
                    </Col>
                }
                </Col>
                <Col className='col-1'>
                    <button
                        type="button"
                        className="btn btn-icon btn-icon-mini btn-neutral"
                        onClick={() => handleEditarOrcamento()}
                    >
                        <i className="icon-icon-edita" />
                    </button>
                </Col>
            </Row>
        </>
    );
}
