import React from 'react';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Col, Row } from "reactstrap";
import styles from '../pedidoLista.module.css';
import history from 'services/history';
import CotacaoStatus from 'components/CotacaoStatus';
import { IOrcamentoVendedor } from 'shared/interface/orcamento';
import PedidoStatus from 'components/CotacaoStatus/pedido';
import { situacaoOrcamentoIndustriaConsts } from '../../../consts/situacaoOrcamentoIndustriaConsts';
//#region INTERFACES
interface CotacaoItemProps {
    cotacao: IOrcamentoVendedor;

}

interface EditarCotacaoProps {
    codCotacao: Number;
    status: Number;
    codPedido: number | null;
    cotacao: IOrcamentoVendedor;
}

//#endregion

export default function CotacaoItem( cotacao : CotacaoItemProps) {

    function editarPedido({ codCotacao, status, codPedido, cotacao }: EditarCotacaoProps) {
        history.push({
            pathname: `/pedido/resumo`,
            state: { codCotacao: codCotacao, status: status, codPedido: codPedido, cotacao: cotacao },
        });
    }

    return (
        <>
            <Row className={`${styles.item}`}>
                <Col className={`${styles.data}`}>
                    <span className={`${styles.date}`}>
                        {format(
                            parseISO(cotacao.cotacao.dataCriacao),
                            "dd MMMM 'de' yyyy",
                            { locale: ptBR }
                        )}
                    </span>
                    <span className={`${styles.subtitle}`}>{`Cód. ${String(cotacao.cotacao.codOrcamento).padStart(6, '0')}`}</span>
                </Col>
                <Col className='d-flex flex-column'>

                    <label>Total de Produtos</label>
                    <span className={`${styles.subtitle} font-weight-bold`}>
                        {String(cotacao.cotacao.itens.length  || 0).padStart(6, '0')}</span>
                </Col>
                <Col className='d-flex flex-column'>
                    <label>Usuário</label>
                    <span className={`${styles.subtitle} font-weight-bold`}>{cotacao.cotacao.nomeUsuario}</span>
                </Col>
                <Col className='d-flex flex-column'>
                    <label>Valor Cotação</label>
                    <span className={`${styles.custo}`}>
                        {String(cotacao.cotacao.totalCusto ?
                        `R$ ${cotacao.cotacao.totalCusto.toLocaleString('pt-br',{minimumFractionDigits:2})}`
                        : 'Aguardando')}</span>

                </Col>
                <Col className='d-flex align-items-center justify-items-center mx-0 px-0'>
                    {cotacao.cotacao.codPedido !== null ?
                        <PedidoStatus status={cotacao.cotacao.situacaoOrcamentoIndustria} />
                        :
                        <CotacaoStatus status={cotacao.cotacao.situacaoOrcamentoIndustria} />
                        }
                </Col>
                <Col className='d-flex align-content-between justify-content-end'>
                    <button
                        type="button"
                        className="btn btn-icon btn-icon-mini btn-neutral"
                        onClick={() => editarPedido({ codCotacao: cotacao.cotacao.codOrcamento,
                                                        status: cotacao.cotacao.situacaoOrcamentoIndustria,
                                                        codPedido: cotacao.cotacao.codPedido,
                                                        cotacao: cotacao.cotacao })}

                    >
                        <i className={` ${cotacao.cotacao.situacaoOrcamentoIndustria === situacaoOrcamentoIndustriaConsts.orcamentoRascunho && cotacao.cotacao.codPedido === null ? 'icon-icon-edita' : 'icon-icon-ver'}`} />
                    </button>

                </Col>
            </Row >
        </>
    );
}
