import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import tipoPrecificacaoOrcamentoTecnicoVendaConsts from '~/consts/tipoPrecificacaoOrcamentoTecnicoVendaConsts';
import { useOrcamentoTecnicoVendaContext } from '~/hooks/useOrcamentoTecnicoVendaContext';

import imagemNaoDefinida from '~/assets/img/imagem-nao-definida.jpg';
import { useOrcamentoContext } from '~/hooks/useOrcamentoContext';

import '../style.css';
import { Col } from 'reactstrap';
import { getObterConfigs } from 'services/modules/Orcamento/orcamentoOcultar';

export default function OrcamentoTecnicoVendaItem(props) {
    // hooks
    const {
        tipoPrecificacao,
        orcamentoTecnicoItens,
        atualizarOrcamentoTecnicoItens,
    } = useOrcamentoTecnicoVendaContext();

    const { permiteEdicaoOrcamentoTecnicoVendas } = useOrcamentoContext();

    // states
    const [valorItem, setValorItem] = useState(0);
    const [percentualAjuste, setPercentualAjuste] = useState(0);
    const [ocultarPeso, setOcultarPeso] = useState(false);

    useEffect(() => {
        setValorItem(props.item.valorTotal);
    }, [props.item.valorTotal]);

    useEffect(() => {
        obterPercentualSobreCustoEVenda();
    }, [valorItem]);

    useEffect(() => {
        (async () => {
            const orcamentosConfiguracoes = await getObterConfigs();
            const orcamentoConfPeso = orcamentosConfiguracoes.filter(
                (oc) => oc.codOrcamentoConfiguracao === 1
            )[0];
            setOcultarPeso(orcamentoConfPeso.oculto);
        })();
    }, []);

    function atualizarItemLista() {
        if (permiteEdicaoOrcamentoTecnicoVendas()) {
            const itens = orcamentoTecnicoItens.map((item) => {
                if (
                    item.codOrcamentoTecnicoItem ===
                    props.item.codOrcamentoTecnicoItem
                ) {
                    return { ...item, valorTotal: valorItem };
                }
                return { ...item };
            });

            atualizarOrcamentoTecnicoItens(itens);
        }
    }

    function obterPercentualSobreCustoEVenda() {
        const custo = Number(props.item.custo);
        const valorVenda = Number(valorItem);
        const percentual = ((valorVenda - custo) * 100) / custo;
        setPercentualAjuste(percentual);
    }

    return (
        <Col
            className="card-itens-proposta  m-2"
            xl={4}
            lg={5}
            md={4}
            sm={4}
            xs={4}
        >
            <div className="img-item-proposta m-0">
                {props.item.urlImagem ? (
                    <img src={props.item.urlImagem} height={170} />
                ) : (
                    <img src={imagemNaoDefinida} height={170} />
                )}
            </div>

            <span className="nome-item-proposta col-12">
                {props.item.codigo}
            </span>
            <br />
            <label className="col-12 descrItem">{props.item.descricao}</label>
            <div className="infos-produto col-12">
                {!ocultarPeso ? (
                    <span className="peso-item-produto">
                        {props.item.peso} kg
                    </span>
                ) : (
                    <span className="d-none" />
                )}
                <span className="quant-item-produto">
                    {props.item.quantidade} un
                </span>
            </div>
            <hr />
            <div className="d-block col-12">
                <span>
                    Item <strong>Total de venda</strong>
                </span>
                <NumberFormat
                    id="valuePreco"
                    name="inpt-total-item-proposta"
                    placeholder="R$ 0,00"
                    value={valorItem}
                    decimalScale={2}
                    fixedDecimalScale
                    className={
                        tipoPrecificacao ===
                        tipoPrecificacaoOrcamentoTecnicoVendaConsts.painelOrcamento
                            ? 'input-bloqueado'
                            : 'porcentagem m-0'
                    }
                    disabled={
                        tipoPrecificacao ===
                            tipoPrecificacaoOrcamentoTecnicoVendaConsts.painelOrcamento ||
                        !permiteEdicaoOrcamentoTecnicoVendas()
                    }
                    prefix="R$ "
                    decimalSeparator=","
                    thousandSeparator="."
                    onValueChange={(values) =>
                        setValorItem(Number(values.value))
                    }
                    onBlur={() => atualizarItemLista()}
                />
            </div>
        </Col>
    );
}
