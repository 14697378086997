import React, { useState, useMemo } from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Col,
    Label,
    Input,
    Row,
} from 'reactstrap';
import _ from 'lodash';
import SweetAlert from 'react-bootstrap-sweetalert';

import ModalProduct from '../../../../components/Modal/index.tsx';
import webapi from '~/services/webapi';
import { DataTableNSSP } from '~/components/DataTable';
import TableColumnCheckBox from '~/components/DataTable/components/TableColumnCheckBox';
import ButtonSpin from '~/components/ButtonSpin';
import { useParams } from 'react-router-dom';
import Search from 'components/Search';

export default function BuscaPorTipologia({
    isOpen,
    handleCloseModal,
    handleReloadTable,
}) {
    // consts
    const { codOrcamento } = useParams();

    // states
    const [selectedRows, setSelectedRows] = useState([]);
    const [data, setData] = useState([]);
    const [lines, setLines] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [alertClose, setAlertClose] = useState(null);
    const [filtro, setFiltro] = useState('');
    const [reloadTable, setReloadTable] = useState(false);

    // functions
    function filtrar(parametroFiltro) {
        setFiltro(parametroFiltro);
        setReloadTable(!reloadTable);
    }

    const columns = useMemo(
        () => [
            {
                id: 'selection',
                disableSortBy: true,
                Header: ({ getToggleAllRowsSelectedProps }) => (
                    <TableColumnCheckBox
                        selectProps={getToggleAllRowsSelectedProps()}
                    />
                ),
                Cell: ({ row }) => (
                    <TableColumnCheckBox
                        selectProps={row.getToggleRowSelectedProps()}
                    />
                ),
            },
            {
                Header: 'Croqui',
                accessor: 'image',
                Cell: ({ row }) => {
                    const { urlImagem } = row.original;
                    return (
                        <div className="text-left">
                            <img
                                alt=""
                                src={urlImagem}
                                style={{
                                    maxHeight: '80px',
                                }}
                            />
                        </div>
                    );
                },
            },
            {
                Header: 'Código',
                accessor: 'code',
            },
            {
                Header: 'Descrição',
                accessor: 'description',
            },
            {
                Header: 'Linha',
                accessor: 'line',
            },
        ],
        []
    );

    const getItems = React.useCallback(

        ({ pageSize, pageIndex, sort, desc }) => {
            setLoading(true);
            (async () => {
                const result = await webapi.post(`/orcamentos/tipologias`, {
                    page: pageIndex + 1,
                    filter: filtro,
                    sort,
                    desc,
                    take: 50,
                });
                const { records, total } = result;

                setData(records);

                setTotalRecords(total);

                setPageCount(Math.ceil(total / pageSize));

                setLoading(false);

                setLines(
                    _(records)
                        .groupBy((m) => m.line)
                        .map((value, key) => {
                            return { value: key, label: key };
                        })
                        .orderBy((m) => m.value)
                        .value()
                );
            })();
        },
        [reloadTable]
    );

    const handleClickSave = async () => {
        if (selectedRows) {
            await webapi.post(
                `/orcamentos/${codOrcamento}/tipologias/importar`,
                {
                    tipologias: [...selectedRows],
                }
            );
            handleCloseModal();
            handleReloadTable();
        }
    };

    return (
        <>
        <Modal isOpen={isOpen} size="xl">
            <ModalHeader>
                <button
                    type="button"
                    className="close"
                    onClick={() => {
                        if (selectedRows?.length > 0) {
                            setAlertClose(
                                <SweetAlert
                                    show
                                    info
                                    style={{ display: 'block' }}
                                    title="Aviso"
                                    confirmBtnBsStyle="success"
                                    cancelBtnText="Não"
                                    confirmBtnText="Sim"
                                    showCancel
                                    reverseButtons
                                    onConfirm={async () => {
                                        await handleClickSave();
                                        setAlertClose(null);
                                    }}
                                    onCancel={() => {
                                        setAlertClose(null);
                                        handleCloseModal();
                                    }}
                                >
                                    Deseja incluir os itens já selecionados ao
                                    orçamento?
                                </SweetAlert>
                            );
                        } else handleCloseModal();
                    }}
                >
                    <i className="nc-icon nc-simple-remove" />
                </button>
                <span className="title title-up">Escolher Item</span>
            </ModalHeader>
            <ModalBody>
                <Search value={filtro} onChange={(e) => filtrar(e.target.value)}/>
                <Row style={{justifyContent:"end"}} className='mx-3 mt-2'>
                    {' '}
                    <p className='m-0'>Total de Registro: {totalRecords}</p>
                </Row>
                <div style={{overflowY:"scroll", overflowX:"hidden", height:"350PX"}}>
                    <DataTableNSSP
                        includeFilter={false}
                        columns={columns}
                        data={data}
                        fetchData={getItems}
                        loading={loading}
                        pageCount={pageCount}
                        total={totalRecords}
                        includePageSize={false}
                        setSelectedRows={setSelectedRows}
                        defaultPageSize={25}
                        extraFilterOneField="line"
                        extraFilterOneTitle="Linha"
                        extraFilterOneValues={false}
                        includePagination={false}
                        includeTotalRecords={false}
                        reload={reloadTable}
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="left-side">
                    <button
                        type="button"
                        className="btn btn-link"
                        onClick={() => handleCloseModal()}
                    >
                        Cancelar
                    </button>
                </div>
                <div className="divider" />
                <div className="right-side">
                    <ButtonSpin
                        className="btn btn-success btn-link"
                        onClick={() => handleClickSave()}
                        title="Salvar"
                    />
                </div>
            </ModalFooter>
            {alertClose}
        </Modal>
        </>
    );
}
