import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import PageContent from '../../components/PageContent';
import history from '../../services/history';
import CotacaoItem from './CotacaoItem';
import Cabecalho from '../../components/Cabecalho';
import styles from './pedidoLista.module.css';
import { IPedido } from 'shared/interface/pedido';
import { getCotacoes } from 'services/modules/Cotacao/cotacao';
import { getPedidos } from 'services/modules/Pedido';
import { formatDadosCotacaoMap, formatDadosPedidoMap } from 'shared/map/mapRequest';
import { IOrcamentoVendedor } from 'shared/interface/orcamento';
import { CotacaoProps } from 'shared/interface/cotacao';


export default function PedidoLista() {
    const [cotacoes, setCotacoes] = useState<IOrcamentoVendedor[]>([]);


    useEffect(() => {
        (async () => {
            const result: CotacaoProps[] = await getCotacoes() as unknown as CotacaoProps[];
            const data: IPedido[] = await getPedidos() as unknown as IPedido[];

            const requestCotacao = result.map((item) => {return formatDadosCotacaoMap(item);});
            const requestPedido = data.map((item) => {return formatDadosPedidoMap(item)});

            const gridIndustria = requestCotacao.concat(requestPedido);
            const ordenar = gridIndustria.sort((a, b) => Number(a.codigoSequencial) - Number(b.codigoSequencial)).reverse();
            setCotacoes(ordenar);
        })();
    }, []);

    function novoPedido() {
        history.push({
            pathname: `/pedido/edicao`,
            state: { codCotacao: 0, status: 1,  codPedido: null },
        });
    }

    return (
        <>
            <Cabecalho
                isButton
                iconeBtn={<i className={`icon-perfil ml-1 ${styles.iconeP}`} />}
                title="Nova Cotação"
                styles="buttonAvulso"
                onClick={() => novoPedido()}
                onChangeSelect={() => {}}
            />
             <PageContent>
                <Container fluid className="p-4">

                    <Row className="d-flex border-bottom">
                        <Col>
                            <span className={`${styles.title}`}>
                                Cotações de {' '}
                                <span className={`${styles.strong}`}>
                                    Materiais Avulsos
                                </span>
                            </span>
                        </Col>

                        <Col style={{textAlign:"end"}}>
                            Total de registros{' '}
                            {String(cotacoes.length).padStart(6, '0')}
                        </Col>
                    </Row>


                    {cotacoes.map((cotacao, index) => <CotacaoItem key={index} cotacao={cotacao} />)}
                </Container>
            </PageContent>
        </>
    );
}
