import React from 'react';
import { Label } from 'reactstrap';

export interface InputComponentProps{
    label?: string,
    valueLabel?: string,
    innerRef?: any,
    name?: string,
    value?: string,
    placeholder?: string,
    isShadow?: boolean;
    disabled:boolean,
    onInput: (value: React.FormEventHandler<HTMLInputElement> | undefined) => void;
    onchange?: (value:any) => void;
};


export default function InputComponent({label, valueLabel, innerRef, name, placeholder, isShadow, disabled, onInput, onchange}:InputComponentProps){

    return(
        <div style={{display:valueLabel}} className='my-2'>
            <div className="d-flex justify-content-between">
                <Label style={{ color: "#57607A"}}>{label}</Label>
            </div>
            <input
                disabled={disabled}
                ref={innerRef}
                type='text'
                name={name}
                placeholder={placeholder}
                className={isShadow ? "form-control form-control-sm" : "form-control form-control-sm boxButton"}
                onInput={() => onInput}
                onChange={onchange}
            />
        </div>
    );
};
