import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import {
    Button,
    Label,
    Modal,
    FormGroup,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';

import webapi from '~/services/webapi';
import FormFieldErrorValidation from '~/components/FormFieldErrorValidation';
import {
    setRwidInArray,
    getListErrorMessage,
} from '~/utils';

export default function ParameterForm({
    isEdit,
    isOpen,
    selectedRecord,
    handleCloseModal,
    handleReloadTable,
}) {
    const [parameter, setParameter] = useState({
        resellerBudgetRequestParameterOptions: [],
    });

    const formRefParameter = useRef();

    useEffect(() => {
        if (isOpen) {
            if (isEdit && selectedRecord !== null) {
                (async () => {
                    const result = await webapi.get(
                        `parametros/${selectedRecord}`
                    );
                    setParameter({ ...result });
                    if (formRefParameter.current) {
                        formRefParameter.current.setValues(result);
                    }
                })();
            } else {
                setParameter({ resellerBudgetRequestParameterOptions: [] });
            }
        }
    }, [isOpen]);

    const handleClickRemove = (item) => {
        setParameter({
            ...parameter,
            resellerBudgetRequestParameterOptions: [
                ...parameter.resellerBudgetRequestParameterOptions.filter(
                    (m) => m !== item
                ),
            ],
        });
    };

    return (
        <>
            <Modal isOpen={isOpen} toggle={() => handleCloseModal()} size="xl">
                <ModalHeader
                    toggle={() => handleCloseModal()}
                    style={{ color: '#57607A', fontSize: '16px' }}
                >
                    PARAMETRIZAÇÃO
                </ModalHeader>
                <ModalBody>
                    <Formik
                        innerRef={formRefParameter}
                        initialValues={{ description: '', parameterType: '1' }}
                        validationSchema={Yup.object({
                            description:
                                Yup.string().required('Campo obrigatório'),
                            parameterType:
                                Yup.string().required('Campo obrigatório'),
                        })}
                        onSubmit={async (values, actions) => {
                            try {
                                const {
                                    resellerBudgetRequestParameterId,
                                    resellerBudgetRequestParameterOptions,
                                } = parameter;
                                const { description, parameterType } = values;
                                await webapi.post('parametros', {
                                    resellerBudgetRequestParameterId,
                                    description,
                                    parameterType: parseInt(parameterType, 0),
                                    resellerBudgetRequestParameterOptions:
                                        resellerBudgetRequestParameterOptions,
                                });
                                actions.setSubmitting(false);
                                actions.resetForm();
                                handleReloadTable();
                                handleCloseModal();
                            } catch (e) {
                                getListErrorMessage(e).map((m) => {
                                    actions.setFieldError(
                                        m.fieldName,
                                        m.message
                                    );
                                });
                            }
                        }}
                    >
                        {(props) => (
                            <form onSubmit={props.handleSubmit}>
                                <div className="row mb-3">
                                    <div className="col-sm-6 mt-3">
                                        <Label
                                            style={{
                                                color: '#57607A',
                                                fontSize: '16px',
                                            }}
                                        >
                                            Nome
                                        </Label>
                                        <Field
                                            style={{
                                                boxShadow:
                                                    'rgb(198 203 212) 0px 6px 15px -10px',
                                                borderRadius: '10px',
                                            }}
                                            type="text"
                                            name="description"
                                            placeholder="Digite o nome da Parametrização..."
                                            className={
                                                props.errors.description
                                                    ? 'form-control form-control-sm invalid is-invalid '
                                                    : 'form-control form-control-sm'
                                            }
                                        />
                                        <FormFieldErrorValidation
                                            error={props.errors.description}
                                            touched={props.touched.description}
                                        />
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <Label
                                            style={{
                                                color: '#57607A',
                                                fontSize: '16px',
                                            }}
                                        >
                                            Tipo
                                        </Label>
                                        <Field
                                            style={{
                                                boxShadow:
                                                    'rgb(198 203 212) 0px 6px 15px -10px',
                                                borderRadius: '10px',
                                            }}
                                            as="select"
                                            className="form-control form-control-sm"
                                            type="select"
                                            name="parameterType"
                                        >
                                            <option value="1">Radial (escolha única)</option>
                                            <option value="2">Checklist (escolha de multipla opções)</option>
                                        </Field>
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
                    <Formik
                        initialValues={{ description: '' }}
                        validationSchema={Yup.object({
                            description:
                                Yup.string().required('Campo obrigatório'),
                        })}
                        onSubmit={(values, actions) => {
                            setParameter({
                                ...parameter,
                                resellerBudgetRequestParameterOptions:
                                    setRwidInArray([
                                        ...parameter.resellerBudgetRequestParameterOptions,
                                        values,
                                    ]),
                            });
                            actions.setSubmitting(true);
                            actions.resetForm();
                        }}
                    >
                        {(props) => (
                            <form onSubmit={props.handleSubmit}>
                                <div className="row mb-3">
                                    <div className="col-sm-7 mt-3">
                                        <FormGroup>
                                            <Label
                                                style={{
                                                    color: '#57607A',
                                                    fontSize: '16px',
                                                }}
                                            >
                                                Opção
                                            </Label>

                                            <Field
                                                className={
                                                    props.errors.description
                                                        ? 'boxButton form-control form-control-sm invalid is-invalid'
                                                        : 'boxButton form-control form-control-sm'
                                                }
                                                type="text"
                                                name="description"
                                                placeholder="Digite o nome da Opção..."
                                            />
                                            <FormFieldErrorValidation
                                                error={props.errors.description}
                                                touched={
                                                    props.touched.description
                                                }
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className="col-sm-3">
                                        <Button
                                            className="boxButton btn"
                                            style={{
                                                marginTop: 50,
                                                backgroundColor: '#4EAA79',
                                            }}
                                        >
                                            <span className="btn-label">
                                                <i
                                                    className="icon-icon-h mr-1"
                                                    style={{ color: '#fff' }}
                                                />
                                            </span>
                                            Adicionar
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
                    <table
                        className="table table-striped table-bordered"
                        cellSpacing="0"
                        width="100%"
                    >
                        <thead>
                            <tr>
                                <th>Opção</th>
                                <th className="text-center">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {parameter?.resellerBudgetRequestParameterOptions?.map(
                                (m, index) => (
                                    <tr key={index}>
                                        <td className="text-left">
                                            {m.description}
                                        </td>
                                        <td className="td-actions text-center">
                                            <button
                                                type="button"
                                                rel="tooltip"
                                                className="boxButton btn btn-icon btn-icon-mini btn-red-over"
                                                data-original-title="Excluir"
                                                onClick={() =>
                                                    handleClickRemove(m)
                                                }
                                            >
                                                <i className="icon-icon-lixeira" />
                                            </button>
                                        </td>
                                    </tr>
                                )
                            )}
                            {parameter?.resellerBudgetRequestParameterOptions
                                ?.length === 0 && (
                                <tr>
                                    <td
                                        colSpan={2}
                                        style={{
                                            boxShadow:
                                                'rgb(198 203 212) 0px 6px 15px -10px',
                                            borderRadius: '10px',
                                        }}
                                    >
                                        Nenhuma opção informada
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <div
                        className="mt-1"
                        style={{ color: '#57607A', fontSize: '16px' }}
                    >
                        * Para salvar o parâmetro é necessário ter pelo menos
                        uma opção.
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="left-side">
                        <button
                            type="button"
                            className="boxButton btn btn-link"
                            onClick={() => handleCloseModal()}
                        >
                            Cancelar
                        </button>
                    </div>
                    <div className="divider" />
                    <div className="right-side">
                        <Button
                            className="boxButton btn btn-success btn-link"
                            disabled={
                                parameter?.resellerBudgetRequestParameterOptions
                                    ?.length === 0
                            }
                            onClick={() =>
                                formRefParameter.current.handleSubmit()
                            }
                        >
                            Salvar
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
}

ParameterForm.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleCloseModal: PropTypes.func.isRequired,
};
