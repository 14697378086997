import React from 'react';
import { Label } from 'reactstrap';
import { useOrcamentoContext } from '../../../../../hooks/useOrcamentoContext.tsx';

export default function DataObservacao() {
    const { data, isAtivoTecnico, dataSolicitacao } = useOrcamentoContext();
    return (
        <div>
            <div className="mt-3">
                <Label>Observações do Item:</Label>
                <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="10"
                    placeholder="Observações do Item..."
                    value={isAtivoTecnico ? data?.observacao : dataSolicitacao?.observation }
                    disabled
                />
            </div>
        </div>
    );
}
