import React, { useMemo, useCallback, useState } from 'react';
import { Row } from 'reactstrap';
import { DataTable } from '~/components/DataTable';
import PageContent from '~/components/PageContent';
import Search from 'components/Search';
import webapi from '~/services/webapi';

function Vidros() {
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [pageCount, setPageCount] = React.useState(0);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [filtro, setFiltro] = useState('');
    const [reloadTable, setReloadTable] = useState(false);

    // functions
    function filtrar(parametroFiltro) {
        setFiltro(parametroFiltro);
        setReloadTable(!reloadTable);
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Código',
                accessor: 'code',
                isSorted: true,
                isSortedDesc: true,
            },
            {
                Header: 'Descrição',
                accessor: 'description',
            },
            {
                Header: 'Referência',
                accessor: 'reference',
            },
            {
                Header: 'Tipo',
                accessor: 'type',
            },
            {
                Header: 'Espessura',
                accessor: 'thickness',
            },
        ],
        []
    );

    const getGlass = useCallback(
        ({ pageSize, pageIndex, sort, desc }) => {
            setLoading(true);
            (async () => {
                const result = await webapi.post(`vidros`, {
                    page: pageIndex,
                    take: pageSize,
                    filter: filtro,
                    sort,
                    desc,
                });
                const { records, total } = result;
                setData(records);
                setTotalRecords(total);
                setPageCount(Math.ceil(total / pageSize));
                setLoading(false);
            })();
        },
        [reloadTable]
    );

    return (
        <>
            <Row className="mx-1">
                <h3>
                    Lista de{' '}
                    <strong style={{ color: '#6b2c5f' }}>vidros</strong>
                </h3>
            </Row>
            <PageContent>
                <Search
                    value={filtro}
                    onChange={(e) => filtrar(e.target.value)}
                />
                <DataTable
                    columns={columns}
                    data={data}
                    fetchData={getGlass}
                    loading={loading}
                    pageCount={pageCount}
                    total={totalRecords}
                    includePageSize
                    includePagination
                    includeFilter={false}
                    reload={reloadTable}
                />
            </PageContent>
        </>
    );
}

export default Vidros;
