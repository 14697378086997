import React from 'react';
import { Card, Col, Input, Label } from 'reactstrap';
import { useOrcamentoContext } from '~/hooks/useOrcamentoContext';

export default function Parametro({
    setParamaters,
    parameters,
    parameter,
    setHasChange,
    disabled,
}) {
    // hooks
    const { permiteEdicaoOrcamento } = useOrcamentoContext();

    function handleChange(parameter, option, event) {
        const { value } = event.target;
        const parametersNew = parameters.map((p) => {
            if (parameter.parameterName === p.parameterName) {
                if (parameter.parameterType === 1) {
                    p.options = p.options.map((opt) => {
                        return { ...opt, selected: opt.optionName === value };
                    });
                } else if (parameter.parameterType === 2) {
                    p.options = p.options.map((opt) => {
                        if (option.optionName === opt.optionName) {
                            opt = { ...opt, selected: !opt.selected };
                        }
                        return opt;
                    });
                }
            }
            return p;
        });

        setParamaters(parametersNew);
        setHasChange(true);
    }

    return (
        <Col md="3" className="mt-3">
            <Card className="col-sm-8 checkbox-radios ml-auto mr-auto card-parametro">
                <Label style={{ color: '#57607A', fontSize: '16px' }}>{parameter.parameterName}</Label>
                {parameter.options.map((option) => {
                    return parameter.parameterType === 1 ? (
                        <div className="form-check-radio">
                            <Label className="form-check-label">
                                <Input
                                    type="radio"
                                    value={option.optionName}
                                    onChange={(e) =>
                                        handleChange(parameter, option, e)
                                    }
                                    name={parameter.parameterName}
                                    checked={option.selected}
                                    disabled={disabled}
                                />
                                <span className="form-check-sign" />
                                {option.optionName}
                            </Label>
                        </div>
                    ) : (
                        parameter.parameterType === 2 && (
                            <div className="form-check">
                                <Label className="form-check-label">
                                    <Input
                                        value={option.optionName}
                                        type="checkbox"
                                        checked={option.selected}
                                        onChange={(e) =>
                                            handleChange(parameter, option, e)
                                        }
                                        disabled={disabled}
                                    />
                                    <span className="form-check-sign" />
                                    {option.optionName}
                                </Label>
                            </div>
                        )
                    );
                })}
            </Card>
        </Col>
    );
}