import React, { useEffect, useState } from 'react';
import './styles.css';

import PageContent from '~/components/PageContent';
import OrcamentoNavegacaoSuperior from '~/components/OrcamentoNavegacaoSuperior';
import OrcamentoDadosCliente from '~/components/OrcamentoDadosCliente';
import { situacaoOrcamentoIndustriaConsts } from '~/consts/situacaoOrcamentoIndustriaConsts';
import { useParams } from 'react-router-dom';
import { useOrcamentoContext } from '~/hooks/useOrcamentoContext';
import { useNotifyContext } from '~/hooks/useNotifyContext';
import { getValores } from "../../services/modules/Orcamento/orcamentoTecnico";
import { putPedidoSolicitado } from "../../services/modules/Orcamento/orcamento";
import { getObservacao } from '../../services/modules/Orcamento/orcamentoTecnico';
import { Button } from 'reactstrap';
import history from '~/services/history';

export default function OrcamentoPedido() {
    // params
    const { versao } = useParams();

    // states
    const [temCustoPerfil, setTemCustoPerfil] = useState(false);
    const [temCustoComponente, setTemCustoComponente] = useState(false);
    const [observacoes, setObservacoes] = useState();
    const [totalCustoVendedor, setTotalCustoVendedor] = useState(0);
    const [isAtivo, setIsAtivo] = useState(false);

    // hooks
    const {
        alterarSituacaoOrcamentoIndustria,
        orcamentoStateContext,
        permiteEdicaoOrcamentoTecnicoPedido,
    } = useOrcamentoContext();

    const notificacao = useNotifyContext();

    // functions
    async function enviarPedido() {
        if (permiteEdicaoOrcamentoTecnicoPedido()) {
            try {
                await putPedidoSolicitado(orcamentoStateContext.codOrcamento, observacoes)
                alterarSituacaoOrcamentoIndustria(
                    situacaoOrcamentoIndustriaConsts.pedidoSolicitado
                );
                notificacao.sucesso('Dados orçamento salvos com sucesso!');
            } catch (error) {
                notificacao.respostaHttp(error);
            }
        }
    }

    useEffect(() => {
        (async () => {
            const result = await getValores(orcamentoStateContext.codOrcamentoTecnico, versao);
            setTemCustoPerfil(result.temCustoPerfil);
            setTemCustoComponente(result.temCustoComponente);
            setTotalCustoVendedor(result.totalCustoVendedor);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const response = await getObservacao(
                orcamentoStateContext.codOrcamentoTecnico,
                versao
            );
            setObservacoes(response.orcamentoObservacaoPedido);
        })();
    }, []);

    return (
        <>
            <PageContent className="no-border mb-1" >
                <OrcamentoNavegacaoSuperior />
                <OrcamentoDadosCliente />
            </PageContent>
            <PageContent>
                <div className="row">
                    <div className="row">
                        <div className="card-body">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <p
                                    style={{
                                        fontSize: '28px',
                                        color: '#3073CB',
                                        fontWeight: '700',
                                    }}
                                >
                                    Meu pedido de materiais
                                </p>
                                <p>
                                    {' '}
                                    <strong style={{ color: '#57607A' }}>
                                        Tipo de pedido:
                                    </strong>
                                </p>

                                <div
                                    className="row"
                                    style={{ marginBottom: '-10px' }}
                                >
                                    {temCustoPerfil && (
                                        <div className="col-3 mr-4">
                                            <p className="divAlu">
                                                Perfis de alumínio
                                            </p>
                                        </div>
                                    )}
                                    {temCustoComponente && (
                                        <div className="col-3">
                                            <p className="divCom">
                                                Componentes
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xs-6 col-sm-12 col-md-12 col-lg-12 ml-5 mt-4 mb-5">
                            <p style={{ color: '#57607A' }}>
                                Observações do pedido
                            </p>
                            <input
                                type="text"
                                placeholder="Escreva aqui uma observação para ser enviada juntamente com o pedido de compra para a revenda."
                                style={{
                                    width:'644px',
                                    height: '158px',
                                    border: '1px solid #707070',
                                    fontSize: '12px',
                                    borderRadius: '10px',
                                    paddingLeft: '10px',
                                    paddingBottom: '100px',

                                }}
                                onChange={(e) => setObservacoes(e.target.value)}
                                value={observacoes}
                                disabled={
                                    orcamentoStateContext.situacaoOrcamentoIndustria ===
                                    situacaoOrcamentoIndustriaConsts.pedidoAberto
                                        ? false
                                        : true
                                }
                            />
                        </div>
                    </div>

                    {(orcamentoStateContext.situacaoOrcamentoIndustria === situacaoOrcamentoIndustriaConsts.pedidoAberto ||
                        orcamentoStateContext.situacaoOrcamentoIndustria === situacaoOrcamentoIndustriaConsts.pedidoSolicitado
                             ||
                        orcamentoStateContext.situacaoOrcamentoIndustria ===
                        situacaoOrcamentoIndustriaConsts.pedidoFinalizado) && (
                        <>
                            <div className="row mb-5">
                                <div className="col-sm-12 col-md-12 col-lg-12 ml-5 mt-4">
                                    <p
                                        style={{
                                            color: '#4EAA79',
                                            fontWeight: '700',
                                        }}
                                    >
                                        Custo Total
                                    </p>
                                    <p
                                        style={{
                                            color: '#57607A',
                                            fontSize: '48px',
                                            fontWeight: '700',
                                            marginTop: '-30px',
                                        }}
                                    >
                                        {totalCustoVendedor?.toLocaleString(
                                            'pt-br',
                                            {
                                                style: 'currency',
                                                currency: 'BRL',
                                            }
                                        )}
                                    </p>
                                    <button
                                        type="button"
                                        className="boxButton btn"
                                        style={{
                                            backgroundColor: '#4EAA79',
                                            marginTop: '-10px',
                                        }}
                                        disabled={
                                            orcamentoStateContext.situacaoOrcamentoIndustria ===
                                            11
                                                ? false
                                                : true
                                        }
                                        onClick={async () =>
                                            await enviarPedido()
                                        }
                                    >
                                        <i className="icon-icon-ckeck mr-2" />
                                        Enviar pedido para revenda*
                                    </button>
                                    <br />
                                    <button
                                        style={{
                                            backgroundColor: 'transparent',
                                            fontSize: '12px',
                                        }}
                                        className="no-border"
                                    >
                                        * Encerrar orçamento
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <div
                    className="mt-2 "
                    style={{ fontSize: '18px' }}
                >
                    <div className="pull-left">
                        <Button
                            type="button"
                            role="tablist"
                            className="boxButtonAvancar"
                            style={{ backgroundColor: '#ABAFBC' }}
                            onClick={() => {
                                history.push(
                                    `/orcamentos-tecnicos/${orcamentoStateContext.codOrcamentoTecnico}/versao/${orcamentoStateContext.versaoOrcamentoTecnico}/vendas`
                                );
                            }}
                        >
                            <i
                                className="icon-icon-voltar mr-1"
                                style={{ fontSize: '18px' }}
                            />
                            {' Voltar'}
                        </Button>
                    </div>
                    {isAtivo &&
                    <div
                        className="pull-right mr-3 mb-1"
                        style={{ fontSize: '18px' }}
                    >
                        <Button
                            type="button"
                            role="tablist"
                            className="boxButton"
                            style={{ backgroundColor: '#3073CB' }}
                            onClick={() => {}}
                            disabled
                        >
                            Avançar{' '}
                            <i
                                className="icon-icon-avancar"
                                style={{ fontSize: '18px' }}
                            />
                        </Button>
                    </div>
                    }
                </div>
            </PageContent>
        </>
    );
}
