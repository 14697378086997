import React, { useEffect, useState } from 'react';
import { Input, Row, Col, Label, Button } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import SweetAlert from 'react-bootstrap-sweetalert';
import PageContent from '~/components/PageContent';
import OrcamentoNavegacaoSuperior from '~/components/OrcamentoNavegacaoSuperior';
import NavigationSubArea from '~/components/OrcamentoNavegacao';
import { getSingleErrorMessage } from '~/utils';
import history from '~/services/history';

import { useOrcamentoContext } from '~/hooks/useOrcamentoContext';
import webApi from '~/services/webapi';

export default function OrcamentoObservacao() {
    // hooks
    const { permiteEdicaoOrcamento } = useOrcamentoContext();

    // consts
    const { codOrcamento } = useParams();

    // states
    const [alertSend, setAlertSend] = useState(null);
    const [alertMessage, setAlertMessage] = useState(null);

    const { register, getValues, reset } = useForm({
        mode: 'onChange',
    });

    const handleValid = async () => {
        return true;
    };

    const handleSave = async () => {
        if (permiteEdicaoOrcamento()) {
            await webApi.post(`/orcamentos/${codOrcamento}/observacao`, {
                observation: getValues().observation,
            });
        }
    };

    const handleClickNext = async () => {
        if (permiteEdicaoOrcamento()) {
            await webApi.post(`/orcamentos/${codOrcamento}/observacao`, {
                observation: getValues().observation,
            });
        }
        history.push(`/orcamentos/${codOrcamento}/anexos`);
    };

    const handleClickPrevious = async () => {
        await handleSave();
        history.push(`/orcamentos/${codOrcamento}/adicionais`);
    };

    const handleShowMessage = (type, title, message, handleClickConfirm) => {
        setAlertMessage(
            <SweetAlert
                show
                type={type}
                title={title}
                style={{ display: 'block' }}
                confirmBtnBsStyle="success"
                onConfirm={() => {
                    if (handleClickConfirm) handleClickConfirm();
                    setAlertMessage(null);
                }}
            >
                {message}
            </SweetAlert>
        );
    };

    const handleClickSend = () => {
        setAlertSend(
            <SweetAlert
                show
                info
                style={{ display: 'block' }}
                title="Você tem certeza?"
                confirmBtnBsStyle="success"
                cancelBtnText="Não"
                confirmBtnText="Sim"
                showCancel
                closeOnClickOutside={false}
                reverseButtons
                onConfirm={async () => {
                    try {
                        await handleSave();
                        await webApi.put(
                            `/orcamentos/${codOrcamento}/com-vendedor`
                        );
                        setAlertSend(null);
                        handleShowMessage(
                            'success',
                            'Sucesso',
                            'Orçamento solicitado com sucesso',
                            () => {
                                history.push(`/orcamentos`);
                            }
                        );
                    } catch (error) {
                        setAlertSend(null);
                        handleShowMessage(
                            'warning',
                            'Não foi possível realizar esta ação',
                            getSingleErrorMessage(error)
                        );
                    }
                }}
                onCancel={() => {
                    setAlertSend(null);
                }}
            >
                Tem certeza que deseja realizar o envio desta solicitação? Você
                não poderá reverter essa ação!
            </SweetAlert>
        );
    };

    useEffect(() => {
        const fetchData = async () => {
            const result = await webApi.get(
                `/orcamentos/${codOrcamento}/observacao`
            );
            reset(result);
        };

        if (codOrcamento) fetchData();
    }, []);

    return (
        <>
            <PageContent className="no-border">
                <OrcamentoNavegacaoSuperior />
            </PageContent>

            <PageContent titlePage="Observações">
                <NavigationSubArea
                    active="observation"
                    handleValid={() => handleValid()}
                    handleSave={() => handleSave()}
                >
                    <form>
                        <Row>
                            <Col
                                md={{ size: 10 }}
                                className="mt-4 ml-auto mr-auto"
                            >
                                <Label
                                    style={{
                                        color: '#57607A',
                                        fontSize: '16px',
                                    }}
                                >
                                    Observações da Obra
                                </Label>
                                <Input
                                    name="observation"
                                    type="textarea"
                                    innerRef={register}
                                    className="boxInput form-control form-control-sm"
                                    placeholder="Observações da Obra..."
                                    rows={10}
                                    disabled={!permiteEdicaoOrcamento()}
                                    style={{ maxHeight: 400 }}
                                />
                            </Col>
                        </Row>
                        <div
                            className="mt-2 "
                            style={{ marginLeft: '-25px', fontSize: '18px' }}
                        >
                            <div className="pull-left">
                                <Button
                                    type="button"
                                    role="tablist"
                                    className="boxButtonAvancar"
                                    style={{ backgroundColor: '#ABAFBC' }}
                                    onClick={() => {
                                        handleClickPrevious();
                                    }}
                                >
                                    <i
                                        className="icon-icon-voltar mr-1"
                                        style={{ fontSize: '18px' }}
                                    />
                                    {' Voltar'}
                                </Button>
                            </div>
                            <div
                                className="pull-right mr-3 mb-1"
                                style={{ fontSize: '18px' }}
                            >
                                <Button
                                    type="button"
                                    role="tablist"
                                    className="boxButtonAvancar"
                                    style={{ backgroundColor: '#3073CB' }}
                                    onClick={() => handleClickNext()}
                                >
                                    Avançar{' '}
                                    <i
                                        className="icon-icon-avancar"
                                        style={{ fontSize: '18px' }}
                                    />
                                </Button>
                                {permiteEdicaoOrcamento() && (
                                    <Button
                                        style={{
                                            backgroundColor: '#3073CB',
                                            height: '45px',
                                        }}
                                        color="primary"
                                        onClick={() => handleClickSend()}
                                        className="boxButton ml-3 mr-3"
                                    >
                                        Solicitar Orçamento{' '}
                                        <i className="icon-icon-ckeck ml-1" />
                                    </Button>
                                )}
                            </div>
                        </div>
                    </form>
                </NavigationSubArea>
                {alertSend}
                {alertMessage}
            </PageContent>
        </>
    );
}
