import React, { useState, useEffect, useMemo, useRef } from 'react';
import PageContent from '~/components/PageContent';
import { DataTable } from '~/components/DataTable';
import NumberFormat from 'react-number-format';
import { useOrcamentoContext } from 'hooks/useOrcamentoContext';
import { Col, Row, Label, Button } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useNotifyContext } from '~/hooks/useNotifyContext';
import history from 'services/history';
import { getCotacaoEnviadaCotada } from '../../../../../services/modules/Cotacao/cotacao';
import webApi from '~/services/webapi';
import InputComponent from '../../../../../components/Input';
import { errosMessage } from '../../../../../shared/messages/errorMessages';
import {
    removeMoneyMask,
    formatMoneyMask,
} from '../../../../../shared/function/index.ts';
import { postPedidoFecharVendedor } from '../../../../../services/modules/Pedido/index';
import {
    situacaoOrcamentoIndustriaConsts,
    situacaoCotacaoIndustriaConsts,
    situacaoPedidoIndustriaConsts,
} from '../../../../../consts/situacaoOrcamentoIndustriaConsts';
import SelectComponent from 'components/Select';

const { OBSERVACAO } = errosMessage;

const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    tableRowUpdate,
    isAtivo,
}) => {
    const [value, setValue] = useState(initialValue);

    const onChange = ({ floatValue }) => {
        setValue(floatValue);
    };
    const onBlur = () => {
        if (initialValue !== parseFloat(value)) {
            tableRowUpdate(index, id, parseFloat(value));
        }
    };

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    return (
        <NumberFormat
            allowNegative={false}
            className="form-control"
            value={value || 0}
            onValueChange={onChange}
            onBlur={onBlur}
            style={{ width: 120 }}
            decimalScale={2}
            fixedDecimalScale
            prefix="R$ "
            decimalSeparator=","
            thousandSeparator="."
            disabled={isAtivo}
        />
    );
};

export default function MyBudgetDetached() {
    const { codOrcamento } = useParams();
    const {
        orcamentoStateContext,
        alterarSituacaoOrcamentoIndustria,
        alterarSituacaoPedidoIndustria,
    } = useOrcamentoContext();
    const notificacao = useNotifyContext();
    const inputRef = useRef(0);

    const [numPedido, setNumPedido] = useState();
    const [status, setStatus] = useState();
    const [alertSend, setAlertSend] = useState(null);
    const [loading, setLoading] = useState(true);
    const [totalRecords, setTotalRecords] = useState(0);
    const [data, setData] = useState([]);
    const [dataResponse, setDataResponse] = useState([]);
    const [obsRejeitar, setObsRejeitar] = useState('');
    const [isRejeitar, setIsRejeitar] = useState(false);
    const [tabelasPreco, setTabelasPreco] = useState([]);
    const [renderTabelas, setRenderTabelas] = useState(true);

    const [selectedTabela, setSelectedTabela] = useState();

    async function handleBaixarXML() {
        try {
            const result = await webApi.post(
                `Pedido/${Number(
                    orcamentoStateContext.codPedido
                )}/expotar-xml-pedido`
            );
            const downloadUrl = window.URL.createObjectURL(new Blob([result]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute(
                'download',
                `pedido-${orcamentoStateContext.codPedido}.xml`
            );
            document.body.appendChild(link);
            link.click();
            history.push('/orcamentoVendedor');
            link.remove();
        } catch (error) {
            notificacao.alerta('Erro ao acessar o arquivo.');
        }
    }

    async function getTabelasPreco() {
        try {
            const data = await webApi.get('/ArquivosExcel/obterExcel');
            return data;
        } catch {
            notificacao.erro('Não foi possível obter as tabelas de preço');
        }
    }

    async function getTabelasPrecoId(id) {
        try {
            const data = await webApi.get(`/ArquivosExcel/obterExcel/${id}`);
            return data;
        } catch {
            notificacao.erro('Não foi possível obter a tabela de preço');
        }
    }

    async function handleOnSelectTabela(id) {
        const tabela = await getTabelasPrecoId(id);
        if (tabela) {
            setSelectedTabela(tabela);
        }
    }

    function handleClearTabela() {
        setRenderTabelas(false);
        setTimeout(() => {
            setRenderTabelas(true);
        }, 5);
        setSelectedTabela(undefined);
    }

    const handleFecharPedido = async (value) => {
        try {
            await postPedidoFecharVendedor(value);
            alterarSituacaoPedidoIndustria(
                situacaoPedidoIndustriaConsts.pedidoFechado,
                value
            );
            notificacao.sucesso('Pedido fechado com sucesso!');
        } catch (error) {
            notificacao.alerta('Erro ao fechar pedido!');
        }
    };

    const handleAbrirPedido = async () => {
        try {
            const result = await webApi.post(
                `pedido/cotacao/${dataResponse.codCotacao}/novo-pedido`
            );
            setNumPedido(result);
        } catch (error) {
            notificacao.alerta('Erro ao gerar pedido!');
        }
    };

    const columnsAvulso = useMemo(
        () => [
            {
                Header: 'Croqui',
                accessor: 'imagem',
                disableSortBy: true,
                Cell: ({ row }) => {
                    const { imagemUrl } = row.original;
                    return (
                        <div className="text-center">
                            <img
                                alt=""
                                src={imagemUrl}
                                style={{
                                    maxHeight: '80px',
                                }}
                            />
                        </div>
                    );
                },
            },
            {
                Header: 'Codigo',
                accessor: 'codigo',
            },
            {
                Header: 'Descrição',
                accessor: 'descricao',
            },
            {
                Header: 'Cor',
                accessor: 'desCor',
            },
            {
                Header: 'Total na Embalagem',
                accessor: 'embalagem',
            },
            {
                Header: 'Quantidade',
                accessor: 'quantidadeCotacao',
            },
            {
                Header: 'Valor Total',
                accessor: 'valor',
                disableSortBy: true,
                Cell: EditableCell,
            },
        ],
        []
    );

    async function handleSave() {
        const newProduct = {
            codCotacao: dataResponse.codCotacao,
            codIndustria: orcamentoStateContext.codIndustria,
            dataCotacao: new Date(),
            prazoEntrega: new Date(),
            nomeUsuario: '',
            itens: data.map((item) => ({
                codItemCotacao: item.codItemCotacao,
                quantidade: item.quantidadeCotar,
                produto: {
                    codProduto: item.codProduto,
                    codigo: item.codigo,
                    codigoErp: item.codigoErp,
                    descricao: item.descricao,
                    peso: item.peso,
                    barra: item.barra,
                    imagem: item.imagem,
                    imagemUrl: item.imagemUrl,
                    quantidade: item.quantidadeProduto,
                    unidade: item.unidade,
                    tipoProduto: item.tipoProduto,
                    tipoUnidade: item.tipounidade,
                },
                cor: {
                    codCorProduto: item.codCor,
                    descricao: item.desCor,
                    valor: item.valor,
                },
            })),
        };
        try {
            await webApi.post('cotacao/cotar', newProduct);
            alterarSituacaoOrcamentoIndustria(
                situacaoCotacaoIndustriaConsts.cotacaoRecebida
            );
            notificacao.sucesso('Cotação enviada com sucesso!');
            setAlertSend(null);
        } catch (error) {
            setAlertSend(null);
            notificacao.error(error);
        }
    }

    const handleRejeitarCotacao = async (value) => {
        if (value) {
            try {
                await webApi.patch(
                    `/Cotacao/${dataResponse.codCotacao}/rejeitar-cotacao`,
                    {
                        codCotacao: dataResponse.codCotacao,
                        notificacao: value,
                    }
                );
                notificacao.sucesso(
                    `Cotação ${dataResponse.codCotacao} rejeitada!`
                );
                setAlertSend(null);
                history.push('/orcamentoVendedor');
            } catch (error) {
                setAlertSend(null);
                notificacao.error(error);
            }
        } else {
            notificacao.alerta(OBSERVACAO);
            setAlertSend(null);
        }
    };
    const handleEnviarSerralheria = () => {
        const semValor = data.find((item) => item.valor === 0);
        if (semValor) {
            notificacao.alerta(
                'A itens com valor 0, preencha o valor antes de enviar a proposta.'
            );
        } else {
            setAlertSend(
                <SweetAlert
                    show
                    info
                    style={{ display: 'block' }}
                    title="Deseja enviar o cotação para Serralheria?"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Não"
                    confirmBtnText="Sim"
                    showCancel
                    closeOnClickOutside={false}
                    reverseButtons
                    onConfirm={() => handleSave()}
                    onCancel={() => setAlertSend(null)}
                />
            );
        }
    };

    const handleRejeitar = () => {
        setAlertSend(
            <SweetAlert
                show
                danger
                style={{ display: 'block' }}
                title="Deseja rejeitar esse orçamento?"
                confirmBtnBsStyle="success"
                cancelBtnText="Não"
                confirmBtnText="Sim"
                showCancel
                closeOnClickOutside={false}
                reverseButtons
                onConfirm={() => setIsRejeitar(true)}
                onCancel={() => setAlertSend(null)}
            >
                <Col>
                    <InputComponent
                        label="Observação"
                        name="observacao"
                        isShadow
                        onchange={(e) => setObsRejeitar(e.target.value)}
                        placeholder="Digite uma observação para..."
                    />
                </Col>
            </SweetAlert>
        );
    };

    const fetchData = React.useCallback(() => {
        setLoading(true);

        (async () => {
            const result = await getCotacaoEnviadaCotada(codOrcamento);
            setDataResponse(result);
            const listaAvulso = result.itens.map((item) => ({
                codItemCotacao: item.codItemCotacao,
                codProduto: item.produto.codProduto,
                codigo: item.produto.codigo,
                descricao: item.produto.descricao,
                imagem: item.produto.imagem,
                imagemUrl: item.produto.imagemUrl,
                valor: item.valor,
                embalagem:
                    item.produto.tipoProduto === 1 ||
                    item.produto.tipoProduto === '1'
                        ? `Barra: ${item.produto.barra}mm`
                        : `Embalagem: ${item.produto.quantidade}${item.produto.unidade}(s)`,
                quantidadeProduto: item.produto.quantidade,
                quantidadeCotar: item.quantidade,
                quantidadeCotacao: item.quantidade,
                tipounidade: item.produto.tipoUnidade,
                tipoProduto: item.produto.tipoProduto,
                peso: item.produto.peso,
                codigoErp: item.produto.codigoErp,
                barra: item.produto.barra,
                codCor: item.cor.codCorProduto,
                codigoCor: item.cor.codigo,
                desCor: item.cor.descricao,
                valorCor: item.cor.valor,
                unidade: item.produto.unidade,
            }));

            const tabelas = await getTabelasPreco();
            setTabelasPreco(
                tabelas.map((item) => ({ label: item.id, name: item.nome }))
            );
            setStatus(orcamentoStateContext.situacaoOrcamentoIndustria);

            if (selectedTabela && selectedTabela.length) {
                setData(
                    listaAvulso.map((item) => {
                        const matchingItem = selectedTabela.find(
                            (tabelaItem) =>
                            tabelaItem.codigo === item.codigo &&
                            item.codigoCor.includes(tabelaItem.codigoCor));
                        if (matchingItem) {
                            return {
                                ...item,
                                valor:
                                    matchingItem.preco * item.quantidadeCotacao,
                            };
                        } else {
                            return item;
                        }
                    })
                );
            } else {
                setData(listaAvulso);
            }

            setTotalRecords(listaAvulso.length);
            setLoading(false);
        })();
    }, [selectedTabela]);

    const tableRowUpdate = (rowIndex, columnId, value) => {
        setData((old) =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value,
                    };
                }

                return row;
            })
        );
    };

    const handleCalcularValorItem = () => {
        const valorTotal = parseFloat(removeMoneyMask(inputRef.current.value));
        if (valorTotal) {
            const quantidadeItens = data.length;
            const valorPorItem = parseFloat(valorTotal / quantidadeItens);
            setData((old) => {
                return old.map((item) => {
                    return { ...item, valor: valorPorItem };
                });
            });
        }
    };

    useEffect(() => {
        fetchData();
    }, [selectedTabela]);

    useEffect(() => {
        if (data.length > 0) {
            const soma = data.reduce((valor, item) => {
                return valor + item.valor;
            }, 0);
            inputRef.current.value = formatMoneyMask(soma);
        }
    }, [data]);

    useEffect(() => {
        if (isRejeitar === true) handleRejeitarCotacao(obsRejeitar);
    }, [isRejeitar]);

    useEffect(() => {
        if (numPedido !== undefined) {
            handleFecharPedido(numPedido.codPedido);
        }
    }, [numPedido]);

    return (
        <div>
            <PageContent titlePage="Orçamento detalhado">
                <span className="titulo-pagina">Orçamento</span>
                <hr />

                {orcamentoStateContext.situacaoOrcamentoIndustria ===
                    situacaoOrcamentoIndustriaConsts.orcamentoEnviado && (
                    <div className="container-subtitulo">
                        <span className="subtitulo-pagina">
                            Importar Tabela de Preço
                        </span>
                        <div className="container-select-tabela">
                            {renderTabelas && (
                                <SelectComponent
                                    label="Selecionar Tabela"
                                    data={tabelasPreco}
                                    onChange={handleOnSelectTabela}
                                />
                            )}
                            {selectedTabela && (
                                <button
                                    onClick={handleClearTabela}
                                    className="botao-limpar-tabela"
                                >
                                    Limpar
                                </button>
                            )}
                        </div>
                    </div>
                )}

                <DataTable
                    columns={columnsAvulso}
                    data={data}
                    fetchData={fetchData}
                    loading={loading}
                    pageCount={0}
                    reload={false}
                    extraFilterOneInitialValue="true"
                    extraFilterOneTitle="Filtrar por Status"
                    includePageSize={false}
                    includePagination={false}
                    title="Lista de Itens"
                    total={totalRecords}
                    tableRowUpdate={tableRowUpdate}
                    isAtivo={
                        orcamentoStateContext.situacaoOrcamentoIndustria !==
                        situacaoCotacaoIndustriaConsts.cotacaoEnviada
                    }
                />
                <Row style={{ justifyContent: 'end' }}>
                    <Col md={12} className="text-right">
                        <Label>
                            Valor Total
                            <div className="input-group valores ml-auto">
                                <div className="input-group-prepend">
                                    <input
                                        ref={inputRef}
                                        className="form-control valores"
                                        disabled={
                                            orcamentoStateContext.situacaoOrcamentoIndustria !==
                                            situacaoCotacaoIndustriaConsts.cotacaoEnviada
                                        }
                                        onChange={(e) =>
                                            handleCalcularValorItem(e)
                                        }
                                        style={{ textAlign: 'end' }}
                                    />
                                </div>
                            </div>
                        </Label>
                    </Col>
                </Row>
            </PageContent>
            <Row className="ml-2">
                {(orcamentoStateContext.situacaoOrcamentoIndustria ===
                    situacaoOrcamentoIndustriaConsts.orcamentoEnviado ||
                    orcamentoStateContext.situacaoOrcamentoIndustria ===
                        situacaoOrcamentoIndustriaConsts.orcamentoRetornado ||
                    (orcamentoStateContext.codPedido !== null &&
                        orcamentoStateContext.situacaoOrcamentoIndustria ===
                            situacaoOrcamentoIndustriaConsts.orcamentoRascunho)) && (
                    <Col md="6">
                        <Button
                            className="btn btn-red mb-4"
                            onClick={() => handleRejeitar()}
                        >
                            <i className="far fa-trash-alt" />
                            Rejeitar solicitação
                        </Button>
                    </Col>
                )}
                {orcamentoStateContext.situacaoOrcamentoIndustria ===
                    situacaoOrcamentoIndustriaConsts.orcamentoEnviado && (
                    <Col md="6">
                        <Button
                            className="btn btn-success mb-4 pull-right mr-4"
                            onClick={() => handleEnviarSerralheria()}
                            disabled={
                                status !==
                                situacaoOrcamentoIndustriaConsts.orcamentoEnviado
                            }
                        >
                            <i className="far fa-share-square" />
                            Enviar para Serralheira
                        </Button>
                    </Col>
                )}
                <Col>
                    {orcamentoStateContext.situacaoOrcamentoIndustria ===
                        situacaoCotacaoIndustriaConsts.cotacaoAprovada && (
                        <Col md="12" className="d-flex justify-content-end">
                            <Button
                                className="btn btn-success mb-4 pull-right mr-4"
                                onClick={handleAbrirPedido}
                            >
                                <i className="far fa-share-square mr-2" />
                                Confirmar Pedido
                            </Button>
                        </Col>
                    )}
                    {orcamentoStateContext.codPedido !== 0 &&
                        (orcamentoStateContext.situacaoOrcamentoIndustria ===
                            situacaoOrcamentoIndustriaConsts.orcamentoRascunho ||
                            orcamentoStateContext.situacaoOrcamentoIndustria ===
                                situacaoOrcamentoIndustriaConsts.orcamentoRejeitado) && (
                            <Col>
                                <Button
                                    className="btn btn-success mb-4 pull-right mr-4"
                                    disabled={
                                        orcamentoStateContext.situacaoOrcamentoIndustria ===
                                        situacaoPedidoIndustriaConsts.pedidoFechado
                                    }
                                >
                                    <i className="far fa-share-square mr-2 boxButton" />
                                    Pedido Confirmado
                                </Button>
                                <Link
                                    className="pull-right mr-4 m-4"
                                    style={{
                                        alignContent: 'center',
                                        height: '100%',
                                    }}
                                    onClick={() => handleBaixarXML()}
                                >
                                    <i className="fa fa-download mr-2 boxButton" />
                                    Baixar XML
                                </Link>
                            </Col>
                        )}
                </Col>
            </Row>
            {alertSend}
        </div>
    );
}
