import React, { useState, useEffect } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane, Row, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';
import PageContent from '~/components/PageContent';
import classnames from 'classnames';
import { useOrcamentoContext } from 'hooks/useOrcamentoContext';
import { useNotifyContext } from '~/hooks/useNotifyContext';
import MyBudgetRequest from './components/Orcamento/solicitacao';
import MyRequest from './components/Orcamento/pedido';
import MyBudgetTechnical from './components/Orcamento/orcamentoTecnico';
import MyBudgetDetached from './components/Orcamento/cotacaoAvulso';
import webApi from '~/services/webapi';
import { getIndustria } from '../../../services/modules/Industria/cliente';
import StatusOrcamento from '../../../components/OrcamentoStatus/StatusOrcamento';
import { getRelatorioTecnicoDownload } from '../../../services/modules/Orcamento/orcamentoTecnico';
import CotacaoStatus from "../../../components/CotacaoStatus";
import { situacaoOrcamentoIndustriaConsts, situacaoCotacaoIndustriaConsts } from '../../../consts/situacaoOrcamentoIndustriaConsts';
import PedidoStatus from '../../../components/CotacaoStatus/pedido';
const myBugdget = [
    {
        id: 1,
        title: 'Solicitação',
        element: <MyBudgetRequest />,
        status: [2, 3, 5, 11, 12, 13, 14, 15],
    },
    {
        id: 2,
        title: 'Orçamento Técnico',
        element: <MyBudgetTechnical />,
        status: [5, 11, 12, 13, 15],
    },
    {
        id: 3,
        title: 'Pedido',
        element: <MyRequest />,
        status: [12, 13],
    },
];

export default function OrcamentoVendedorDetalhesItem() {
    // states
    const { versao } = useParams();
    const { orcamentoStateContext } = useOrcamentoContext();
    const [isBudget, setIsBudget] = useState(1);
    const [cabecalho, setCabecalho] = useState({});
    const [data, setData] = useState([]);
    const [obra, setObra] = useState('');
    const notificacao = useNotifyContext();

    async function getRelatorios() {
        if(orcamentoStateContext.codOrcamentoTecnico !== 0){
            const result = await webApi.get(
                `/orcamentoTecnico/${orcamentoStateContext.codOrcamentoTecnico}/versao/${orcamentoStateContext.versaoOrcamentoTecnico}/relatorios-tecnicos`
            );
            setData(result);
        }
    }


    async function handleRelatorio(
        {
        nomeRelatorio,
        codOrcamentoTecnicoRelatorio,
    }) {
        try {
            const result = await getRelatorioTecnicoDownload(orcamentoStateContext.codOrcamentoTecnico, versao, codOrcamentoTecnicoRelatorio);
            const downloadUrl = window.URL.createObjectURL(new Blob([result]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `${nomeRelatorio}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.remove();

        } catch (error) {
            notificacao.respostaHttp(error);
        }
    }

    const cabecalhoArray = [
        { nome: 'Cliente:', valor: cabecalho?.razaoSocial },
        { nome: 'Obra:', valor: obra },
        { nome: 'Contato:', valor: cabecalho?.contato?.nome },
        { nome: 'Telefone:', valor: cabecalho?.contato?.celular },
        { nome: 'E-mail:', valor: cabecalho?.contato?.email },
        { nome: 'CEP:', valor: cabecalho?.endereco?.cep },
        {
            nome: 'Endereço:',
            valor:
                cabecalho.addressStreet && cabecalho.addressNumber
                    ? `${cabecalho?.enderecao?.logradouro}, ${cabecalho?.enderecao?.numero}`
                    : '',
        },
        { nome: 'Complemento:', valor: cabecalho?.enderecao?.complemento },
        { nome: 'Bairro:', valor: cabecalho?.endereco?.bairro },
        { nome: 'Cidade:', valor: cabecalho?.endereco?.cidade },
    ];

    useEffect(() => {
        (async () => {
            setObra(orcamentoStateContext?.nomeObra);
            const response = await getIndustria(orcamentoStateContext.codIndustria);
            setCabecalho(response);
        })();
    }, []);

    useEffect(() => {
        getRelatorios();
    }, []);

    return (
        <>
            <PageContent titlePage="Orçamento detalhado" className="no-border">
                {' '}
                <Row style={{alignItems:"center"}}>
                    <Col>
                        <span className="titulo-pagina" >Meu orçamento {orcamentoStateContext.codigoModulo === 1 ? <>Esquadria</> : <>Avulso</>}</span>
                    </Col>
                    <Col>
                        <span className='status-orcamento-detalhes'>
                        {orcamentoStateContext.codigoModulo === 1 ?
                                <StatusOrcamento
                                    situacaoOrcamentoIndustria={
                                        orcamentoStateContext.situacaoOrcamentoIndustria
                                    }
                                />
                        :
                            <Col className='statusAvulso'>
                                {orcamentoStateContext.codPedido === null ?
                                    <CotacaoStatus status={orcamentoStateContext.situacaoOrcamentoIndustria} />
                                    :
                                    <PedidoStatus status={orcamentoStateContext.situacaoOrcamentoIndustria} />
                                }
                            </Col>
                        }
                        </span>
                    </Col>
                </Row>
                <hr />
                <div className="dados-orcamento">
                    {cabecalhoArray.map(
                        (item, index) =>
                            item.valor && (
                                <div key={index} className="dado-orcamento d-flex align-items-center">
                                    <span className="nome-info">
                                        {item.nome}
                                    </span>
                                    <span className="conteudo-info">
                                        {item.valor}
                                    </span>
                                </div>
                            )
                    )}
                </div>
                {orcamentoStateContext.codigoModulo === situacaoOrcamentoIndustriaConsts.orcamentoRascunho &&
                (orcamentoStateContext.situacaoOrcamentoIndustria === situacaoOrcamentoIndustriaConsts.orcamentoRetornado ||
                    orcamentoStateContext.situacaoOrcamentoIndustria === situacaoOrcamentoIndustriaConsts.pedidoAberto ||
                    orcamentoStateContext.situacaoOrcamentoIndustria === situacaoOrcamentoIndustriaConsts.pedidoSolicitado ||
                    orcamentoStateContext.situacaoOrcamentoIndustria === situacaoOrcamentoIndustriaConsts.pedidoFinalizado ||
                    orcamentoStateContext.situacaoOrcamentoIndustria === situacaoOrcamentoIndustriaConsts.recebidoPeloVendedor
                        ) && (
                    <>
                        {data.filter(
                            (item) =>
                                !item.nomeRelatorio.includes('Relatório de Montagens') &&
                                !item.nomeRelatorio.includes('Relatório de Cortes')
                            ).length > 0 && (
                                <>
                                    <hr className="mb-1" />
                                    <nav className="navbar navbar-expand-lg navbar-light p-0 m-0">
                                        Visualizar relatórios:
                                        <ul className="navbar-nav mr-auto">
                                            {data.filter(
                                                    (item) =>
                                                        !item.nomeRelatorio.includes('Relatório de Montagens') &&
                                                        !item.nomeRelatorio.includes('Relatório de Cortes'))
                                                .map((item) => (
                                                    <li
                                                        className="nav-item"
                                                        key={item.codOrcamentoTecnicoRelatorio}
                                                        onClick={() => handleRelatorio(item)}
                                                    >
                                                        <a className="nav-link text-secondary pointer">
                                                            {item.nomeRelatorio}
                                                        </a>
                                                    </li>
                                                ))}
                                        </ul>
                                    </nav>
                                </>
                        )}
                    </>
                )}
                {(orcamentoStateContext.situacaoOrcamentoIndustria === situacaoCotacaoIndustriaConsts.cotacaoCancelada ||
                    orcamentoStateContext.situacaoOrcamentoIndustria === situacaoCotacaoIndustriaConsts.cotacaoRejeitada) &&
                    (orcamentoStateContext.codPedido == null) && (
                    <>
                        <hr className="my-1" />
                        <Col style={{backgroundColor:"#e92e0916", borderRadius:10}} className='p-2 boxButton'>
                            <p><strong>Observação: </strong>{orcamentoStateContext.observacao}</p>
                        </Col>
                    </>
                )}
            </PageContent>

            {orcamentoStateContext.codigoModulo === 1 ?

            <div className="wizard">
                <Row>
                    <Nav
                        tabs
                        className="nav ml-5 mb-3 m nav-pills-primary nav-navbar-right"
                        style={{ paddingLeft: '-10px' }}
                    >
                        {myBugdget.map(({ id, title, status }) =>
                            status.map(
                                (item) =>
                                    item === orcamentoStateContext.situacaoOrcamentoIndustria && (
                                        <NavItem key={id}>
                                            <NavLink
                                                className={classnames({active: isBudget === id, pointer: true,})}
                                                onClick={() => setIsBudget(id)}
                                            >
                                                {title}
                                            </NavLink>
                                        </NavItem>
                                    )
                            )
                        )}
                    </Nav>
                </Row>
                <TabContent activeTab={isBudget}>
                    {myBugdget.map(({ id, element, status }) =>
                        status.map(
                            (item) =>
                                item === orcamentoStateContext.situacaoOrcamentoIndustria && (
                                    <TabPane key={id} tabId={id}>
                                        {element}
                                    </TabPane>
                                )
                        )
                    )}
                </TabContent>
            </div>
            :
            <div>
                {orcamentoStateContext.codPedido === null ?
                    (orcamentoStateContext.situacaoOrcamentoIndustria === situacaoCotacaoIndustriaConsts.cotacaoEnviada ||
                        orcamentoStateContext.situacaoOrcamentoIndustria === situacaoCotacaoIndustriaConsts.cotacaoRecebida ||
                        orcamentoStateContext.situacaoOrcamentoIndustria === situacaoCotacaoIndustriaConsts.cotacaoAprovada) &&
                        <MyBudgetDetached />
                :
                    <MyBudgetDetached />
                }
            </div>}
        </>
    );
}
