import React, { useRef, useState, useEffect } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

import PageContent from '~/components/PageContent';
import { useNotifyContext } from '~/hooks/useNotifyContext';
import { Row } from 'reactstrap';
import GridData from 'components/GridData';
import ModalNovaParametrizacao from './ModalNovaParametrizacao';
import webapi from '~/services/webapi';
import CabecalhoEgShop from '../../components/Cabecalho/CabecalhoEgShop.tsx';
import ItemParametrizacao from './components/ListaParametrizacao';

export default function Parametrizacao() {
    // hooks
    const notificacao = useNotifyContext();

    // Alerts
    const [alertDelete, setAlertDelete] = React.useState(null);
    const [alertMessage, setAlertMessage] = React.useState(null);

    // Table
    const [data, setData] = React.useState([]);
    const [reload, setReload] = React.useState(0);
    const [loading, setLoading] = React.useState(true);
    const [pageCount, setPageCount] = React.useState(0);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState({});

    const fetchIdRef = useRef(0);

    const handleReloadTable = () => {
        const fetchId = ++fetchIdRef.current;
        setReload(fetchId);
    };

    const handleCloseModal = () => {
        setSelectedRecord(null);
        setIsOpen(false);
        setRefresh(true);
    };

    const handleClickNew = () => {
        setSelectedRecord(null);
        setIsEdit(false);
        setIsOpen(true);
    };

    const handleClickEdit = async (id) => {
        setSelectedRecord(id);
        setIsEdit(true);
        setIsOpen(true);
    };

    const handleShowAlertDelete = (id) => {
        setAlertDelete(
            <SweetAlert
                show
                warning
                style={{ display: 'block' }}
                title="Você tem certeza?"
                confirmBtnBsStyle="success"
                showCancel
                reverseButtons
                openAnim
                onConfirm={async () => {
                    try {
                        await webapi.delete(`parametros/${id}`);
                        setAlertDelete(null);
                        setRefresh(true);
                    } catch (error) {
                        notificacao.respostaHttp(error);
                    }
                }}
                onCancel={() => {
                    setAlertDelete(null);
                }}
            >
                Tem certeza que deseja excluir? Você não poderá reverter essa
                ação!
            </SweetAlert>
        );
    };

    useEffect(() => {
        setLoading(true);
        (async () => {
            const result = await webapi.get('parametros');
            const { records, total } = result;
            const value = records.map((item) => ({
                id: item.resellerBudgetRequestParameterId,
                descricao: item.description,
                tipo: item.parameterType,
            }));
            setData(value);
            setTotalRecords(total);
            setPageCount(Math.ceil(total / 1));
            setLoading(false);
            setRefresh(false);
        })();
    }, [refresh]);

    return (
        <>
            <CabecalhoEgShop
                title="Lista de Parametrização"
                onRegisterNew={() => handleClickNew()}
                iconBtn={<i className="fas fa-tasks" />}
                descricaoBtn="Cadastrar Parametrização"
            />
            <div className="m-0 col-12">
                <PageContent className="m-0 container">
                    {totalRecords === 0 ? (
                        <div className="cardList my-3">
                            <strong>Nenhum Registro</strong>
                        </div>
                    ) : (
                        <>
                            <Row className="rowRecord">
                                {' '}
                                <p>Total de Registro: {totalRecords}</p>
                            </Row>
                            <div>
                                {data.map((item, index) => (
                                    <div key={Number(index)}>
                                        <GridData
                                            dados={item}
                                            onEdit={handleClickEdit}
                                            onTrash={handleShowAlertDelete}
                                            registro={totalRecords}
                                            numBtn={2}
                                        >
                                            <ItemParametrizacao dados={item} />
                                        </GridData>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                    {alertDelete}
                    {alertMessage}
                </PageContent>
                <ModalNovaParametrizacao
                    isEdit={isEdit}
                    isOpen={isOpen}
                    selectedRecord={selectedRecord}
                    handleCloseModal={handleCloseModal}
                    handleReloadTable={handleReloadTable}
                />
            </div>
        </>
    );
}
