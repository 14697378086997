import React from 'react';
import { Input, Label } from 'reactstrap';
import { useOrcamentoContext } from '../../../../../hooks/useOrcamentoContext.tsx';

export default function DataGlass() {
    const { data, isAtivoTecnico, dataSolicitacao } = useOrcamentoContext();
    return (
        <div>
            <div className="tab-pane" id="item-especial-vidro">
                <div className="row text-left">
                    <div className="col-sm-6 mt-3">
                        <Label>Código:</Label>
                        <Input
                            type="text"
                            placeholder="Código:..."
                            className="form-control form-control-sm"
                            value={isAtivoTecnico ? data?.itemVidro?.codigo : dataSolicitacao?.glassCode }
                            disabled
                        />
                    </div>
                    <div className="col-sm-6 mt-3">
                        <label>Referência:</label>
                        <Input
                            type="text"
                            placeholder="Referência..."
                            className="form-control form-control-sm"
                            value={isAtivoTecnico ? data?.itemVidro?.referencia : dataSolicitacao?.glassReference }
                            disabled
                        />
                    </div>
                    <div className="col-sm-6 mt-3">
                        <label>Tipo de Vidro:</label>
                        <Input
                            type="text"
                            placeholder="Tipo de Vidro..."
                            className="form-control form-control-sm"
                            value={isAtivoTecnico ? data?.itemVidro?.descricao : dataSolicitacao?.glassType }
                            disabled
                        />
                    </div>
                    <div className="col-sm-6 mt-3">
                        <label>Espessura do Vidro:</label>
                        <Input
                            type="text"
                            placeholder="Espessura do Vidro..."
                            className="form-control form-control-sm"
                            value={isAtivoTecnico ? data?.itemVidro?.espessura : dataSolicitacao?.glassThickness }
                            disabled
                        />
                    </div>
                    <div className="col-sm-6 mt-3">
                    <label>Cor dos Vidros:</label>
                    <Input
                        type="text"
                        placeholder="Cor dos Vidros..."
                        className="form-control form-control-sm"
                        value={isAtivoTecnico ? data?.itemVidro?.cor : dataSolicitacao?.glassColorName }
                        disabled
                    />
                </div>
                </div>
            </div>
        </div>
    );
}
