import React, { useEffect, useState } from 'react';
import SelectComponent, { ISelect } from '../../components/Select/index';
import { Button, Col, Input, Row } from 'reactstrap';

interface ICabecalhoProps {
    isButton?: boolean;
    iconeBtn?: any;
    isAtivo?: boolean;
    title?: string;
    styles?: string;
    onClick?: (value: any) => void;

    isSelect?: boolean;
    label?: string;
    valueLabel?: string;
    data?: ISelect[];
    valueSelect?: string;
    onChangeSelect: (valueSelect: any) => void | null;

    isSearch?: boolean;
    iconeColor?: any;
    value?: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
}

export default function Cabecalho({
    isButton,
    iconeBtn,
    isAtivo,
    title,
    styles,
    onClick,
    isSelect,
    isSearch,
    label,
    valueLabel,
    data,
    valueSelect,
    onChangeSelect,
    iconeColor,
    value,
    onChange,
}: ICabecalhoProps) {
    const [justifyContent, setJustifyContent] = useState('space-between');

    useEffect(() => {
        if (isButton == true && isSelect == true && isSearch == true)
            setJustifyContent('space-between');
        else if (isButton == true && isSearch == true)
            setJustifyContent('space-between');
        else if (isSearch) setJustifyContent('end');
        else if (isSelect) setJustifyContent('end');
        else setJustifyContent('space-between');
    }, [justifyContent]);

    return (
        <Row style={{ justifyContent: justifyContent }} className="m-0 mr-xl-4">
            {isButton && (
                <Col xl={3} lg={5} md={12} sm={12} xs={12} className="p-xl-0">
                    <Button
                        className={styles}
                        onClick={onClick}
                        disabled={isAtivo}
                    >
                        <div
                            className="d-flex"
                            style={{ alignItems: 'center' }}
                        >
                            <p>{iconeBtn}</p>
                            <p>{title}</p>
                        </div>
                    </Button>
                </Col>
            )}
            {isSelect && data && (
                <Col xl={4} lg={5} md={12} sm={12} xs={12} className="mx-0">
                    <SelectComponent
                        label={label}
                        valueLabel={valueLabel}
                        data={data}
                        value={valueSelect}
                        onChange={onChangeSelect}
                    />
                </Col>
            )}
            {isSearch && (
                <Col
                    xl={4}
                    lg={5}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ display: 'flex' }}
                    className="pr-xl-0"
                >
                    <i
                        className="icon-icon-busca mt-3 mr-2"
                        style={{ fontSize: '22px', color: `${iconeColor}` }}
                    />
                    <Input
                        type="search"
                        placeholder="Pesquisa..."
                        className="boxButton d-inline-block"
                        value={value}
                        onChange={onChange}
                    />
                </Col>
            )}
        </Row>
    );
}
