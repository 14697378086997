import axios from 'axios';

const authApi = axios.create({
    baseURL: process.env.REACT_APP_API_AUTH,
});

authApi.interceptors.response.use(
    function (response) {
        return response.data.data ? response.data.data : response.data;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export default authApi;
