import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { Button, FormFeedback, Row, Col, Label, Input } from 'reactstrap';
import CpfCnpj from '@react-br-forms/cpf-cnpj-mask';

import * as Yup from 'yup';
import PageContent from '~/components/PageContent';
import webApi from '~/services/webapi';
import Switch from 'react-bootstrap-switch';
import { useNotifyContext } from '~/hooks/useNotifyContext';
import {errosMessage} from '../../../shared/messages/errorMessages';
import SweetAlert from 'react-bootstrap-sweetalert';
import webapi from '~/services/webapi';
import { handleZipCode } from '../../../shared/function/index.ts';

const { ALTERAR_EMAIL, IS_REQUIRED, FORMATO_EMAIL_INVALIDO } = errosMessage;

const schema = Yup.object({
    nome: Yup.string().required(IS_REQUIRED),
    cpf: Yup.string().required(IS_REQUIRED),
    endereco: Yup.string().required(IS_REQUIRED),
    bairro: Yup.string().required(IS_REQUIRED),
    numero: Yup.string().required(IS_REQUIRED),
    uf: Yup.string().required(IS_REQUIRED),
    cidade: Yup.string().required(IS_REQUIRED),
    email: Yup.string().email(FORMATO_EMAIL_INVALIDO).nullable().required(IS_REQUIRED),
});

const defaultValues = {
    codVendedor: 0,
    cpf: '',
    nome: '',
    endereco: '',
    numero: '',
    uf: '',
    cep: '',
    cidade: '',
    email: '',
    telefone: '',
    apportionmentByItem: false,
    ativo: true,
    TrocarEmail: false,
};

export default function VendedoresFormulario() {
    const { codVendedor } = useParams();

    const history = useHistory();

    const notificacao = useNotifyContext();

    const [ativo, setAtivo] = useState(false);
    const [ alterarEmail, setAlterarEmail ] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);

    const {
        register,
        triggerValidation,
        getValues,
        setValue,
        errors,
        control,
    } = useForm({
        mode: 'onChange',
        defaultValues,
        validationSchema: schema,
    });

    const handleModalAlterarEmail = () => {
        setAlertMessage(
            <SweetAlert
                show
                info
                title="Deseja alterar o email?"
                cancelBtnText="Não"
                confirmBtnText="Sim"
                confirmBtnBsStyle="danger"
                showCancel
                onConfirm={() => {
                    setAlterarEmail(true);
                    setAlertMessage(null);
                }}
                onCancel={() =>  setAlertMessage(null)}
            >Essa conta já está ativa, ao trocar o e-mail o login atual do usuário será descontinuado e um cadastro novo deverá ser feito. Deseja continuar?
            </SweetAlert>
        )
    };

    useEffect(() => {
        async function loadData() {
            const result = await webApi.get(`/Vendedor/${codVendedor}`);
            loadForm(result);
        }
        loadData();
    }, []);

    function loadForm(payload) {
        const {
            cpf,
            nome,
            endereco,
            numero,
            bairro,
            cep,
            cidade,
            uf,
            email,
            telefone,
            ativo,
        } = payload;

        setValue('cpf', cpf);
        setValue('nome', nome);
        setValue('endereco', endereco);
        setValue('numero', numero);
        setValue('bairro', bairro);
        setValue('cep', cep);
        setValue('cidade', cidade);
        setValue('uf', uf);
        setValue('email', email);
        setValue('telefone', telefone);
        setValue('ativo', ativo);
        setAtivo(ativo);
    }

    const handleChangePostalCode = async (e) => {
        const postalCode = e.replace(/\s/g, '');
        if (postalCode.length === 9) {
            const result = await webapi.get(
                `endereco/endereco-via-cep/${postalCode}`
            );
            const { logradouro, bairro, uf, cidade } = result;
            if (logradouro) {
                setValue('endereco', logradouro);
                setValue('bairro', bairro);
                setValue('uf', uf);
                setValue('cidade', cidade);
            }
        }

    };

    const handleClickSave = async () => {
        const valid = await triggerValidation();

        if (valid) {
            const vendedorData = {
                codVendedor: codVendedor,
                nome: getValues('nome'),
                cpf: getValues('cpf'),
                endereco: getValues('endereco'),
                numero: getValues('numero'),
                bairro: getValues('bairro'),
                cidade: getValues('cidade'),
                cep: getValues('cep'),
                uf: getValues('uf'),
                email: getValues('email'),
                telefone: getValues('telefone'),
                ativo: ativo,
                TrocarEmail: alterarEmail,
            };
            try {
                if (codVendedor) {
                    await webApi.post(`/Vendedor/alterar`, vendedorData);
                } else {
                    await webApi.post(`/Vendedor`, vendedorData);
                    history.push(`/vendedores/${codVendedor}/editar`);
                }
                notificacao.sucesso('Dados salvos com sucesso');
            } catch (error) {
                notificacao.respostaHttp(error);
                const message = error.response.data.errors[0];
                if(message == ALTERAR_EMAIL) {
                    handleModalAlterarEmail();

                }
            }
        }
    };

    useEffect(() => {
        if(alterarEmail === true) handleClickSave();
    },[alterarEmail]);

    return (
        <>
            <h3 style={{color:"#66615b", marginLeft:30}}>
                {codVendedor ? "Editar Vendedor" : "Cadastrar Vendedor"}
            </h3>
            <PageContent>

                <div className="toolbar">
                    <Button
                        className="btn btn-secondary"
                        data-origin-title="Voltar"
                        onClick={() => history.push('/vendedores')}
                    >
                        <i className="fas fa-arrow-circle-left" /> Voltar
                    </Button>

                    <Button
                        className="btn btn-success float-right"
                        data-original-title="Salvar"
                        onClick={() => handleClickSave()}
                    >
                        <i className="fas fa-save" /> Salvar
                    </Button>
                </div>
                <form>
                    <Row>
                        <Col md="4" className="mt-3">
                            <Label>Nome:</Label>
                            <Input
                                innerRef={register}
                                type="text"
                                className="form-control form-control-sm"
                                name="nome"
                                placeholder="Nome"
                                invalid={errors?.nome?.message}
                            />
                            <FormFeedback>{errors?.nome?.message}</FormFeedback>
                        </Col>

                        <Col md="4" className="mt-3">
                            <Label>CPF :</Label>
                            <Controller
                                innerRef={register}
                                control={control}
                                className={
                                    !!errors?.cpf?.message
                                        ? 'form-control form-control-sm invalid is-invalid '
                                        : 'form-control form-control-sm'
                                }
                                name="cpf"
                                placeholder="CPF"
                                as={CpfCnpj}
                                autoComplete="off"
                            />
                            <FormFeedback invalid>
                                {errors?.cpf?.message}
                            </FormFeedback>
                        </Col>

                        <Col md="2" className="mt-3">
                            <Label>E-mail:</Label>
                            <Input
                                innerRef={register}
                                type="text"
                                className="form-control form-control-sm"
                                invalid={errors?.email?.message}
                                name="email"
                                placeholder="E-mail"
                            />
                            <FormFeedback>{errors?.email?.message}</FormFeedback>
                        </Col>
                        <Col md="2" className="mt-3">
                            <Label>Telefone:</Label>
                            <Input
                                innerRef={register}
                                type="text"
                                className="form-control form-control-sm"
                                control={control}
                                name="telefone"
                                placeholder="Telefone"
                            />
                            <FormFeedback>{errors?.telefone?.message}</FormFeedback>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="2" className="mt-3">
                            <Label>CEP:</Label>
                            <Input
                                innerRef={register}
                                type="text"
                                className="form-control form-control-sm"
                                invalid={errors?.cep?.message}
                                name="cep"
                                placeholder="CEP"
                                onChange={(event) =>
                                    handleChangePostalCode(
                                        event.target.value
                                    )
                                }
                                maxLength={9}
                                onKeyUp={(e) => handleZipCode(e)}
                            />
                            <FormFeedback>{errors?.cep?.message}</FormFeedback>
                        </Col>
                        <Col md="4" className="mt-3">
                            <Label>Endereço :</Label>
                            <Input
                                innerRef={register}
                                type="text"
                                className="form-control form-control-sm"
                                invalid={errors?.endereco?.message}
                                name="endereco"
                                placeholder="Endereço"
                                onKeyUp="handle"
                            />
                            <FormFeedback>
                                {errors?.endereco?.message}
                            </FormFeedback>
                        </Col>
                        <Col md="1" className="mt-3">
                            <Label>Número :</Label>
                            <Input
                                innerRef={register}
                                type="text"
                                className="form-control form-control-sm"
                                invalid={errors?.numero?.message}
                                name="numero"
                                placeholder="Número"
                            />
                            <FormFeedback>{errors?.numero?.message}</FormFeedback>
                        </Col>
                        <Col md="2" className="mt-3">
                            <Label>Bairro:</Label>
                            <Input
                                innerRef={register}
                                type="text"
                                className="form-control form-control-sm"
                                invalid={errors?.bairro?.message}
                                name="bairro"
                                placeholder="Bairro"
                            />
                            <FormFeedback>{errors?.bairro?.message}</FormFeedback>
                        </Col>
                        <Col md="1" className="mt-3">
                            <Label>UF:</Label>
                            <Input
                                innerRef={register}
                                type="text"
                                className="form-control form-control-sm"
                                invalid={errors?.uf?.message}
                                name="uf"
                                placeholder="UF"
                                maxLength={2}
                            />
                            <FormFeedback>{errors?.uf?.message}</FormFeedback>
                        </Col>
                        <Col md="2" className="mt-3">
                            <Label>Cidade:</Label>
                            <Input
                                innerRef={register}
                                type="text"
                                className="form-control form-control-sm"
                                invalid={errors?.cidade?.message}
                                name="cidade"
                                placeholder="Cidade"
                            />
                            <FormFeedback>{errors?.cidade?.message}</FormFeedback>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="3" className="mt-3">
                            <Label className="mr-2">
                                {'Status do Vendedor '}
                                <Switch
                                    name="ativo"
                                    offColor="warning"
                                    offText={
                                        <i className="nc-icon nc-simple-remove" />
                                    }
                                    onColor="success"
                                    onText={<i className="nc-icon nc-check-2" />}
                                    onChange={(e) => {
                                        setAtivo(e.state.value);
                                    }}
                                    value={ativo}
                                />
                            </Label>
                        </Col>
                    </Row>
                </form>
            </PageContent>
            {alertMessage}
        </>
    );
}
