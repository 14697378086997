import React, { useEffect, useRef, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useOrcamentoTecnicoVendaContext } from '~/hooks/useOrcamentoTecnicoVendaContext';
import tipoPrecificacaoOrcamentoTecnicoVendaConsts from '~/consts/tipoPrecificacaoOrcamentoTecnicoVendaConsts';
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Col,
    Label,
    Button,
} from 'reactstrap';
import { RiExchangeDollarFill } from 'react-icons/ri';
import { situacaoOrcamentoIndustriaConsts } from '~/consts/situacaoOrcamentoIndustriaConsts';
import ButtonSpin from '~/components/ButtonSpin';
import { useParams, useHistory } from 'react-router-dom';
import { useNotifyContext } from '~/hooks/useNotifyContext';
import SweetAlert from 'react-bootstrap-sweetalert';
import { BsPrinter, BsMailbox, BsCartCheck } from 'react-icons/bs';
import webApi from '~/services/webapi';
import { useSweetAlertContext } from '~/hooks/useSweetAlertContext';
import { useOrcamentoContext } from '~/hooks/useOrcamentoContext';
import OrcamentoTecnicoVendaListaItem from './OrcamentoTecnicoVendaListaItem';

const tipoOperacao = {
    acrescimo: 'acrescimo',
    decrescimo: 'decrescimo',
};

export default function OrcamentoTecnicoVendaCalculoPreco() {
    // params
    const { codOrcamentoTecnico, versao } = useParams();
    const [alertMessage, setAlertMessage] = useState(false);
    const [titulo, setTitulo] = useState('');
    const [valorVendedor, setValorVendedor] = useState('');
    const history = useHistory();

    // hooks
    const {
        orcamentoTecnicoItens,
        totalValorOrcamentoTecnico,
        tipoPrecificacao,
        atualizarValorItensBaseadoValorVenda,
        atualizarOrcamentoTecnicoItens,
        setSalvarAlteracoesOrcamento,
    } = useOrcamentoTecnicoVendaContext();
    const { exibirDialogoSimNao } = useSweetAlertContext();

    const {
        orcamentoStateContext,
        permiteEdicaoOrcamentoTecnicoVendas,
        alterarSituacaoOrcamentoIndustria,
    } = useOrcamentoContext();

    const { atualizarOrcamentoTecnicoValores } =
        useOrcamentoTecnicoVendaContext();

    const notificacao = useNotifyContext();

    // variable
    const valorTotalAlterado = useRef(false);

    // states
    const [imprimir, setImprimir] = useState(false);
    const [propostaEmail, setPropostaEmail] = useState(false);
    const [percentual, setPercentual] = useState();
    const [operacao, setOperacao] = useState(tipoOperacao.acrescimo);
    const [totalVenda, setTotalVenda] = useState(0);
    const [data, setData] = useState([]);

    // hooks

    useEffect(() => {
        getRelatorios();
        setTotalVenda(totalValorOrcamentoTecnico);
    }, [totalValorOrcamentoTecnico]);

    useEffect(() => {
        (async () => {
            const result = await webApi.get(
                `orcamentoTecnico/${codOrcamentoTecnico}/versao/${versao}/valores`
            );

            setValorVendedor(result.totalCustoVendedor);
            atualizarOrcamentoTecnicoValores(result);
        })();
    }, []);

    async function getRelatorios() {
        const result = await webApi.get(
            `/orcamentoTecnico/${codOrcamentoTecnico}/versao/${versao}/relatorios-tecnicos`
        );
        setData(result);
    }

    function calcularNovoValorTotalItem(valorTotalItem) {
        if (operacao === tipoOperacao.acrescimo) {
            return valorTotalItem + (percentual / 100) * valorTotalItem;
        }
        if (operacao === tipoOperacao.decrescimo) {
            return valorTotalItem - (percentual / 100) * valorTotalItem;
        }
        return valorTotalItem;
    }
    function cancelarAtualizarValorItensBaseadoValorVenda() {
        setTotalVenda(totalValorOrcamentoTecnico);
    }

    // functions
    function confirmarAtualizarValorItensBaseadoValorVenda() {
        if (valorTotalAlterado.current) {
            valorTotalAlterado.current = false;
            exibirDialogoSimNao(
                'Confirmar alteração valor total venda',
                'Ao confirmar alteração do valor total de venda o valor dos itens será recalculado, deseja continuar?',
                () => {
                    atualizarValorItensBaseadoValorVenda(totalVenda);
                    setSalvarAlteracoesOrcamento(true);
                },
                cancelarAtualizarValorItensBaseadoValorVenda,
                ''
            );
        }
    }

    function aplicarOperacaoPercentual() {
        if (percentual > 0) {
            const itens = orcamentoTecnicoItens.map((item) => {
                return {
                    ...item,
                    valorTotal: calcularNovoValorTotalItem(item.valorTotal),
                };
            });
            atualizarOrcamentoTecnicoItens(itens);
            setPercentual(0);
        }
    }

    function aplicarOperacao() {
        if (percentual > 0) {
            exibirDialogoSimNao(
                'Confirmar alteração valor total venda',
                'Ao confirmar alteração os valores dos itens serão recalculados, deseja continuar?',
                () => {
                    aplicarOperacaoPercentual();
                },
                cancelarAtualizarValorItensBaseadoValorVenda,
                ''
            );
        }
    }
    async function handleClick(name) {
        const result = await webApi.get(
            `/orcamentoTecnico/${codOrcamentoTecnico}/versao/${versao}/download-proposta/${name}`,
            { responseType: 'blob' }
        );

        const downloadUrl = window.URL.createObjectURL(new Blob([result]));

        const link = document.createElement('a');

        link.href = downloadUrl;

        link.setAttribute('download', `${codOrcamentoTecnico}-${name}.pdf`);

        document.body.appendChild(link);

        link.click();

        link.remove();

        setImprimir(false);
    }

    async function enviarPropostaEmail(proposta) {
        try {
            await webApi.get(
                `/orcamentoTecnico/${codOrcamentoTecnico}/versao/${versao}/enviar-proposta-por-email/${proposta}`
            );
            notificacao.sucesso('E-mail enviado com sucesso');
        } catch (error) {
            notificacao.respostaHttp(error);
        } finally {
            setPropostaEmail(false);
        }
    }

    async function fecharCliente() {
        if (totalVenda <= valorVendedor) {
            notificacao.erro('Sem margem de lucro!');
        } else if (permiteEdicaoOrcamentoTecnicoVendas()) {
            exibirDialogoSimNao(
                'Tem certeza que deseja fechar a proposta com o cliente?',
                'Ao realizar esta operação não será possível desfazer essa alteração, deseja continuar?',
                async () => {
                    try {
                        await webApi.put(
                            `orcamentos/${orcamentoStateContext.codOrcamento}/pedido-aberto`
                        );
                        alterarSituacaoOrcamentoIndustria(
                            situacaoOrcamentoIndustriaConsts.pedidoAberto
                        );
                        history.push(
                            `/orcamentos-tecnicos/${orcamentoStateContext.codOrcamento}/versao/${versao}/pedido`
                        );
                        notificacao.sucesso(
                            'Dados orçamento salvos com sucesso!'
                        );
                    } catch (error) {
                        notificacao.respostaHttp(error);
                    }
                }
            );
        }
    }

    return (
        <>
            <Row className="rowRevenda mx-0">
                <Col lg={3} className="custoRevenda p-1 px-3">
                    <p className="m-0 py-0">
                        <strong>Orçamento da revenda </strong>
                    </p>
                    <NumberFormat
                        className="form-control pb-2 "
                        id="desc-acre"
                        name="desc-acre"
                        placeholder="R$ 0,00"
                        value={valorVendedor}
                        decimalScale={2}
                        fixedDecimalScale
                        prefix="R$ "
                        decimalSeparator=","
                        thousandSeparator="."
                        disabled
                    />
                </Col>
                <Col lg={3} md={6} xs={12} className="valuePorc">
                    <Label className="col-12 px-0">
                        Acréscimo ou Desconto:
                    </Label>

                    <form className="d-flex">
                        <div className="pull-left col-auto px-0 sumPorc">
                            <input
                                className="mr-1"
                                type="radio"
                                id="desc-acre"
                                name="desc-acre"
                                value="+"
                                defaultChecked
                                onChange={() =>
                                    setOperacao(tipoOperacao.acrescimo)
                                }
                                disabled={
                                    tipoPrecificacao ===
                                        tipoPrecificacaoOrcamentoTecnicoVendaConsts.painelOrcamento ||
                                    !permiteEdicaoOrcamentoTecnicoVendas()
                                }
                            />
                            <label htmlFor="desc-acre" className="mr-2">
                                +
                            </label>
                            <input
                                className="mr-1"
                                type="radio"
                                id="desc-acre"
                                name="desc-acre"
                                value="-"
                                onChange={(e) =>
                                    setOperacao(tipoOperacao.decrescimo)
                                }
                                disabled={
                                    tipoPrecificacao ===
                                        tipoPrecificacaoOrcamentoTecnicoVendaConsts.painelOrcamento ||
                                    !permiteEdicaoOrcamentoTecnicoVendas()
                                }
                            />
                            <label htmlFor="desc-acre" className="mr-3">
                                -
                            </label>
                        </div>
                        <div className="pull-right w-100 pb-2">
                            <NumberFormat
                                id="desc-acre"
                                name="desc-acre"
                                placeholder="0%"
                                className="porcentagem w-100"
                                value={percentual}
                                decimalScale={0}
                                decimalSeparator=","
                                thousandSeparator="."
                                suffix="%"
                                isAllowed={(values) => {
                                    const { formattedValue, floatValue } =
                                        values;
                                    return (
                                        formattedValue === '' ||
                                        floatValue <= 1000
                                    );
                                }}
                                onValueChange={(values) =>
                                    setPercentual(Number(values.value))
                                }
                                onBlur={() => aplicarOperacao()}
                                disabled={
                                    tipoPrecificacao ===
                                        tipoPrecificacaoOrcamentoTecnicoVendaConsts.painelOrcamento ||
                                    !permiteEdicaoOrcamentoTecnicoVendas()
                                }
                            />
                        </div>
                    </form>
                </Col>
                <Col lg={3} md={6} xs={12} className="totalVenda px-3">
                    <Label className="col-12 px-0">
                        Total da Venda <span>(proposta)</span>
                    </Label>
                    <div className="pb-2">
                        <NumberFormat
                            id="desc-acre"
                            name="desc-acre"
                            className="w-100 porcentagem pb-2"
                            placeholder="R$ 0,00"
                            defaultValue={totalValorOrcamentoTecnico}
                            value={totalVenda}
                            decimalScale={2}
                            fixedDecimalScale
                            prefix="R$ "
                            decimalSeparator=","
                            thousandSeparator="."
                            onChange={(event) => {
                                valorTotalAlterado.current = true;
                            }}
                            onValueChange={(values) => {
                                setTotalVenda(Number(values.value));
                            }}
                            onBlur={() =>
                                confirmarAtualizarValorItensBaseadoValorVenda()
                            }
                            disabled={
                                tipoPrecificacao ===
                                    tipoPrecificacaoOrcamentoTecnicoVendaConsts.painelOrcamento ||
                                !permiteEdicaoOrcamentoTecnicoVendas()
                            }
                        />
                    </div>
                </Col>
                <Col lg={3} className="btnAplicar p-1 px-3">
                    <Button
                        type="button"
                        className="form-control boxButton btn-outline-primary"
                        onClick={() => setSalvarAlteracoesOrcamento(true)}
                        disabled={
                            tipoPrecificacao ===
                                tipoPrecificacaoOrcamentoTecnicoVendaConsts.painelOrcamento ||
                            !permiteEdicaoOrcamentoTecnicoVendas()
                        }
                    >
                        <RiExchangeDollarFill
                            className="mr-2 mb-1"
                            style={{ fontSize: 18 }}
                        />
                        Aplicar
                    </Button>
                </Col>
            </Row>
            <OrcamentoTecnicoVendaListaItem />
            <Row className="actionBtns p-2 m-0 mt-2">
                <Col lg={4} xs={12}>
                    <Button
                        onClick={() => setImprimir(true)}
                        className="form-control boxButton btn-outline-primary"
                        style={{
                            backgroundColor: 'white',
                            alignSelf: 'center',
                        }}
                    >
                        <BsPrinter
                            className="mr-2 mb-1"
                            style={{ fontSize: 18 }}
                        />
                        Imprimir
                    </Button>
                </Col>
                <Col lg={4} xs={12} className="respButton">
                    {situacaoOrcamentoIndustriaConsts.orcamentoRecebido && (
                        <Button
                            type="button"
                            className="form-control boxButton btn-outline-primary align-center"
                            style={{
                                backgroundColor: 'white',
                                alignItems: 'center',
                            }}
                            onClick={() => setPropostaEmail(true)}
                        >
                            <BsMailbox
                                className="mr-2 mb-1"
                                style={{ fontSize: 18 }}
                            />
                            Enviar por e-mail
                        </Button>
                    )}
                </Col>
                <Col lg={4} xs={12}>
                    <Button
                        type="button"
                        className="form-control boxButton btn-success"
                        onClick={() => fecharCliente()}
                        disabled={
                            tipoPrecificacao ===
                                tipoPrecificacaoOrcamentoTecnicoVendaConsts.painelOrcamento ||
                            !permiteEdicaoOrcamentoTecnicoVendas()
                        }
                    >
                        <BsCartCheck
                            className="mr-2 mb-1"
                            style={{ fontSize: 18 }}
                        />
                        Fechar Orçamento
                    </Button>
                </Col>
            </Row>
            <Modal isOpen={imprimir} size="md">
                <ModalHeader>
                    <button
                        type="button"
                        className="close"
                        onClick={() => setImprimir(false)}
                    >
                        <i className="nc-icon nc-simple-remove" />
                    </button>
                    <div
                        className="modal-profile mx-auto"
                        style={{ lineHeight: 4 }}
                    >
                        <i className="nc-icon nc-bulb-63" />
                    </div>
                </ModalHeader>
                <ModalBody>
                    <p className="text-center mt-5 mb-5">
                        Qual modelo de proposta deseja visualizar?
                    </p>
                </ModalBody>
                <ModalFooter>
                    <div className="left-side">
                        <ButtonSpin
                            title="Valor por Item"
                            className="btn btn-info btn-link"
                            onClick={() => handleClick('Proposta01')}
                        />
                    </div>
                    <div className="divider" />
                    <div className="right-side">
                        <ButtonSpin
                            title="Valor Total"
                            className="btn btn-info btn-link"
                            onClick={() => handleClick('Proposta02')}
                        />
                    </div>
                </ModalFooter>
            </Modal>

            <Modal isOpen={propostaEmail} size="md">
                <ModalHeader>
                    <button
                        type="button"
                        className="close"
                        onClick={() => setPropostaEmail(false)}
                    >
                        <i className="nc-icon nc-simple-remove" />
                    </button>
                    <div
                        className="modal-profile mx-auto"
                        style={{ lineHeight: 4 }}
                    >
                        <i className="nc-icon nc-bulb-63" />
                    </div>
                </ModalHeader>
                <ModalBody>
                    <p className="text-center mt-5 mb-5">
                        Qual modelo de proposta deseja enviar por e-mail?
                    </p>
                </ModalBody>
                <ModalFooter>
                    <div className="left-side">
                        <ButtonSpin
                            title="Valor por Item"
                            className="btn btn-info btn-link"
                            onClick={() => enviarPropostaEmail('Proposta01')}
                        />
                    </div>
                    <div className="divider" />
                    <div className="right-side">
                        <ButtonSpin
                            title="Valor Total"
                            className="btn btn-info btn-link"
                            onClick={() => enviarPropostaEmail('Proposta02')}
                        />
                    </div>
                </ModalFooter>
            </Modal>

            <SweetAlert
                show={alertMessage}
                type="info"
                title={titulo}
                showCancel
                reverseButtons
                cancelBtnBsStyle="warning"
                cancelBtnText="Não"
                confirmBtnText="Sim"
                confirmBtnBsStyle="success"
                onConfirm={() => {
                    setAlertMessage(false);
                }}
                onCancel={() => {
                    setAlertMessage(false);
                }}
            />
        </>
    );
}
