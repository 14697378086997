const situacaoOrcamentoIndustriaConsts = {
    orcamentoRascunho: 1,
    orcamentoEnviado: 2,
    orcamentoRecebido: 3,
    orcamentoRejeitado: 4,
    orcamentoRetornado: 5,
    orcamentoRevisao: 6,
    propostaEnviada: 7,
    propostaRejeitada: 8,
    propostaFechada: 9,
    propostaRevisao: 10,
    pedidoAberto: 11,
    pedidoSolicitado: 12,
    pedidoFinalizado: 13,
    orcamentoComVendedor: 14,
    recebidoPeloVendedor: 15,
};

const situacaoCotacaoIndustriaConsts = {
    cotacaoAberta: 1,
    cotacaoEnviada: 2,
    cotacaoRecebida: 3,
    cotacaoCancelada: 4,
    cotacaoAprovada: 5,
    cotacaoRejeitada: 6,
};

const situacaoPedidoIndustriaConsts = {
    pedidoAberto: 1,
    pedidoEnviada: 2,
    pedidoCancelada: 3,
    pedidoFechado: 4,
};

export {
    situacaoOrcamentoIndustriaConsts,
    situacaoCotacaoIndustriaConsts,
    situacaoPedidoIndustriaConsts,
};
