import React from 'react';

const SelectExtraFilter = ({ title, values, setExtraFilterOne }) => {
    return (
        <select
            style={{ width: '100%' }}
            maxLength={30}
            className="form-control pull-right filter-input"
            onChange={(e) => {
                setExtraFilterOne(e.target.value);
            }}
            placeholder="Optional ..."
        >
            <option value="">{title}</option>
            {values.map((m) => (
                <option value={m.value} key={m.value} selected={m.selected}>
                    {m.label}
                </option>
            ))}
        </select>
    );
};

export default SelectExtraFilter;
