import React, { useState, useMemo, useRef } from 'react';
import { Formik, Field } from 'formik';
import {
    Label,
    Modal,
    FormGroup,
    ModalBody,
    ModalFooter,
    Row,
} from 'reactstrap';

import { DataTable } from '~/components/DataTable';
import PageContent from '~/components/PageContent';
import webApi from '~/services/webapi';
import Search from '../../components/Search/index.tsx';

export default function Tipologias() {
    const [image, setImage] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState('');

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageCount, setPageCount] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);

    const [filtro, setFiltro] = useState('');
    const [reloadTable, setReloadTable] = useState(false);

    const formRef = useRef();

    const handleClickEdit = async (codTipologia) => {
        setIsOpen(true);
        setSelectedRecord(codTipologia);
        const result = await webApi.get(`tipologias/${codTipologia}`);
        formRef.current.setValues(result);
        setImage(result.urlImagem);
    };

    const handleToogleModal = () => {
        setIsOpen(!isOpen);
    };

    // functions
    function handleFiltrar(parametroFiltro) {
        setFiltro(parametroFiltro);
        setReloadTable(!reloadTable);
    }

    const getProducts = React.useCallback(
        ({ pageSize, pageIndex, sort, desc }) => {
            setLoading(true);
            (async () => {
                const result = await webApi.post(`tipologias`, {
                    page: pageIndex === 0 ? 1 : pageIndex,
                    take: pageSize,
                    filter: filtro,
                    sort,
                    desc,
                });
                const { records, total } = result;
                setData(records);
                setTotalRecords(total);
                setPageCount(Math.ceil(total / pageSize));
                setLoading(false);
            })();
        },
        [reloadTable]
    );

    const columns = useMemo(
        () => [
            {
                Header: 'Imagem',
                accessor: 'image',
                disableSortBy: true,
                Cell: ({ row }) => {
                    const { urlImagem } = row.original;
                    return (
                        <div className="text-center">
                            <img
                                alt=""
                                src={urlImagem}
                                style={{
                                    maxHeight: '80px',
                                }}
                            />
                        </div>
                    );
                },
            },
            {
                Header: 'Código',
                accessor: 'code',
            },
            {
                Header: 'Descrição',
                accessor: 'description',
            },
            {
                Header: 'Projetista',
                accessor: 'designer',
            },
            {
                Header: 'Linha',
                accessor: 'line',
            },
            {
                Header: 'Ações',
                accessor: 'actions',
                disableSortBy: true,
                Cell: ({ row }) => (
                    <div className="text-center">
                        <button
                            type="button"
                            title="Ver Dados"
                            className="btn btn-icon btn-icon-mini btn-neutral"
                            onClick={() => {
                                handleClickEdit(row.original.codTipologia);
                            }}
                        >
                            <i className="icon-icon-pedidos" />
                        </button>
                    </div>
                ),
            },
        ],
        []
    );

    return (
        <>
            <Row className="mx-1">
                <h3>
                    Lista de{' '}
                    <strong style={{ color: '#6b2c5f' }}>tipologias</strong>
                </h3>
            </Row>
            <PageContent>
                <Search
                    value={filtro}
                    onChange={(e) => handleFiltrar(e.target.value)}
                />
                <DataTable
                    columns={columns}
                    data={data}
                    fetchData={getProducts}
                    loading={loading}
                    pageCount={pageCount}
                    total={totalRecords}
                    includePageSize
                    includePagination
                    includeFilter={false}
                    reload={reloadTable}
                />
            </PageContent>
            <Modal isOpen={isOpen} toggle={() => handleToogleModal()} size="md">
                <div className="modal-header">
                    <button
                        type="button"
                        className="close"
                        onClick={() => handleToogleModal()}
                    >
                        <i className="nc-icon nc-simple-remove" />
                    </button>
                    <h4 className="title title-up">DADOS DA TIPOLOGIA</h4>
                </div>
                <ModalBody>
                    <Formik innerRef={formRef}>
                        <form>
                            <div className="row mb-3">
                                <div className="col-12 mt-3">
                                    <FormGroup>
                                        <Label>Código</Label>
                                        <Field
                                            type="text"
                                            name="description"
                                            className="boxButton form-control form-control-sm"
                                            readonly="readonly"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Projetista</Label>
                                        <Field
                                            type="text"
                                            name="designer"
                                            className="boxButton form-control form-control-sm"
                                            readonly="readonly"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Linha</Label>
                                        <Field
                                            type="text"
                                            name="line"
                                            className="boxButton form-control form-control-sm"
                                            readonly="readonly"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Linha</Label>
                                        <Field
                                            component="textarea"
                                            name="description"
                                            className="boxButton form-control form-control-sm"
                                            readonly="readonly"
                                            rows="5"
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-md-7 mt-3 text-center">
                                    <img
                                        alt=""
                                        src={image}
                                        style={{
                                            height: '100%',
                                            maxHeight: '400px',
                                        }}
                                    />
                                </div>
                            </div>
                        </form>
                    </Formik>
                </ModalBody>
                <ModalFooter>
                    <button
                        type="button"
                        className="btn btn-link"
                        onClick={() => handleToogleModal()}
                    >
                        Fechar
                    </button>
                </ModalFooter>
            </Modal>
        </>
    );
}
