import React from 'react';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import { Chart } from 'react-google-charts';

interface ICardStatus {
    title: string;
    data: ((string | number)[] | (string | {})[])[];
    options: any;
}

export function CardStatus({title, data, options}: ICardStatus){
    return(
        <Col className="px-0 pl-2 pr-2 pr-sm-0">
            <Card className='m-0 w-100 h-100 boxButton'>
                <CardHeader className='text-center mt-4'><strong>{title}</strong></CardHeader>
                <CardBody>
                    <Chart
                        chartType="ColumnChart"
                        height={"400px"}
                        width={"100%"}
                        data={data}
                        options={options}
                    />
            </CardBody>
            <hr className="borde-1" />
        </Card>
    </Col>
    )
};
