import React, {
    useState,
    useEffect,
} from 'react';
import { Row, Col } from 'reactstrap';

import './style.css';
import PageContent from '../../components/PageContent';
import webApi from '../../services/webapi';

import OrcamentoItem from './OrcamentoItem';
import styles from './orcamento.module.css';
import { IOrcamentoVendedor, OrcamentoResultProps } from '../../shared/interface/orcamento';
import { CotacaoProps } from '../../shared/interface/cotacao';
import { IPedido } from '../../shared/interface/pedido';
import { formatDadosCotacaoMap, formatDadosOrcamentoMap, formatDadosPedidoMap } from '../../shared/map/mapRequest';
import { getCotacoes } from 'services/modules/Cotacao/cotacao';
import { getPedidos } from '../../services/modules/Pedido/index';

export default function OrcamentoVendedor() {

    // states
    const [table, setTable] = useState<IOrcamentoVendedor[]>();
    const [reload, setReload] = useState(false);
    const [filtroExtra, setFiltroExtra] = useState<string>();

    const handleFiltrar = (parametroFiltro:any) => {
        if(parametroFiltro === "0" || parametroFiltro === 0){setFiltroExtra("");
        } else{setFiltroExtra(parametroFiltro)};
    };

   useEffect(() => {
        (async () => {
            const result: OrcamentoResultProps = await webApi.post(
                `orcamentos`,
                {
                    page: 1,
                    take: 10,
                    sort: '',
                    filter: "",
                    desc: true,
                    extraFilterOne: '',
                }
            );
            const cotacao: CotacaoProps[] = await getCotacoes() as unknown as CotacaoProps[];
            const pedido: IPedido[] = await getPedidos() as unknown as IPedido[];

            const requestOrcamento = result.records.map((item) => {return formatDadosOrcamentoMap(item);});
            const requestCotacao = cotacao.map((item) => {return formatDadosCotacaoMap(item);});
            const requestPedido = pedido.map((item) => {return formatDadosPedidoMap(item)});

            const gridVendedor = requestCotacao.concat(requestOrcamento).concat(requestPedido);
            const ordenar = gridVendedor.sort((a,b) => {
                if(a.dataCriacao > b.dataCriacao) return 1;
                if(a.dataCriacao < b.dataCriacao) return -1;
                return 0;
            });
            setTable(ordenar);

        })();
    }, [reload]);

    return (
        <>
            <PageContent>
                <Row className={`${styles.linha}`}>
                    <Col className={`${styles.title}`}>
                        Lista de <strong>Orçamentos</strong>
                    </Col>

                    <Col className={`${styles.total}`}>
                        Total de registros{' '}
                        {String(table?.length).padStart(6, '0')}
                    </Col>
                </Row>

                <div className={`${styles.colunas}`}>
                    {table &&
                        table.map((item, index) => (
                            <OrcamentoItem
                                key={index}
                                orcamento={item}
                            />
                        ))}
                </div>
            </PageContent>
        </>
    );
}
