import React, { useState } from 'react';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Col, Row } from 'reactstrap';
import history from 'services/history';
import StatusOrcamento from '../../../components/OrcamentoStatus/StatusOrcamento';
import styles from './OrcamentoItem.module.css';
import { useOrcamentoContext } from 'hooks/useOrcamentoContext';
import SweetAlert from 'react-bootstrap-sweetalert';
import webApi from 'services/webapi';
import { situacaoOrcamentoIndustriaConsts } from '../../../consts/situacaoOrcamentoIndustriaConsts';

interface OrcamentoProps {
    orcamento: OrcamentoItemProps;
    reload: () => void;
}

interface OrcamentoItemProps {
    observacao?: string;
    codPedido?: number;
    codIndustria: Number;
    codOrcamento: Number;
    codOrcamentoTecnico: Number;
    codRevenda: Number;
    codUsuario: Number;
    codigoSequencial: string;
    dataCriacao: string;
    nomeCliente: string;
    nomeUsuario: string;
    projetoNome: string;
    situacaoOrcamentoIndustria: number;
    situacaoOrcamentoRevenda: Number;
    totalCusto: Number;
    totalOrcamentoTecnico: Number;
    versao: Number;
    totalCustoVendedor: Number;
}


export default function OrcamentoItem({
    orcamento,
    reload,
}: OrcamentoProps) {
    const { definirOrcamentoSelecionado } =
        useOrcamentoContext();

    const [showDelete, setShowDelete] = useState(false);

    const [showDuplicate, setShowDuplicate] = useState(false);


    const handleClickEdit = () => {
        definirOrcamentoSelecionado(
            "",
            null,
            orcamento.codOrcamento,
            orcamento.situacaoOrcamentoIndustria,
            orcamento.situacaoOrcamentoRevenda,
            orcamento.codOrcamentoTecnico,
            Number(orcamento.versao),
            "",
            orcamento.codIndustria,
            orcamento.projetoNome,
            0,
            orcamento.codigoSequencial,
        );

        if (
            orcamento.situacaoOrcamentoIndustria >= situacaoOrcamentoIndustriaConsts.orcamentoRetornado &&
            orcamento.situacaoOrcamentoIndustria <= situacaoOrcamentoIndustriaConsts.propostaRevisao
        ) {
            history.push(
                `/orcamentos-tecnicos/${orcamento.codOrcamentoTecnico}/versao/${orcamento.versao}/cores`
            );
        } else if (orcamento.situacaoOrcamentoIndustria == situacaoOrcamentoIndustriaConsts.orcamentoRetornado ||
            (orcamento.situacaoOrcamentoIndustria >= situacaoOrcamentoIndustriaConsts.pedidoAberto &&
                orcamento.situacaoOrcamentoIndustria <= situacaoOrcamentoIndustriaConsts.pedidoFinalizado)
            ) {
            history.push(
                `/orcamentos-tecnicos/${orcamento.codOrcamentoTecnico}/versao/${orcamento.versao}/pedido`
            );
        } else {
            history.push(`/orcamentos/${orcamento.codOrcamento}/cliente`);
        }
    };

    return (
        <>
            <Row className={`${styles.item}`}>
                <Col>
                    <div className="d-flex flex-column pl-0">
                        <span className={`${styles.data}`}>
                            {orcamento.projetoNome
                                ? orcamento.projetoNome
                                : 'Orçamento sem Nome'}
                        </span>
                        <span
                            className={`${styles.subtitle}`}
                        >{`Cód. ${orcamento.codigoSequencial}`}</span>
                    </div>
                </Col>
                <Col className="d-flex flex-column px-0">
                    <label>Cliente</label>
                    <span className={`${styles.subtitle} font-weight-bold`}>
                        {orcamento.nomeCliente}
                    </span>
                </Col>
                <Col className="d-flex flex-column px-0">
                    <label>Criação</label>
                    <span className={`${styles.subtitle} font-weight-bold`}>
                        {format(parseISO(orcamento.dataCriacao), 'dd/MM/yyyy', {
                            locale: ptBR,
                        })}
                    </span>
                </Col>
                <Col className="d-flex flex-column px-0">
                    <label>Preço Revenda</label>
                    <span className={`${styles.custo}`}>
                        {String(
                            orcamento.situacaoOrcamentoIndustria === situacaoOrcamentoIndustriaConsts.recebidoPeloVendedor
                            ? 'Aguardando'
                            : orcamento.totalCustoVendedor
                            ? `R$ ${orcamento.totalCustoVendedor.toLocaleString(
                                  'pt-br',
                                  { minimumFractionDigits: 2 }
                              )}`
                            : 'Aguardando'
                        )}
                    </span>
                </Col>

                <Col className="d-flex flex-column pl-0">
                    <label>Preço Orçamento</label>
                    <span className={`${styles.custo}`}>
                        {String(
                            orcamento.situacaoOrcamentoIndustria === situacaoOrcamentoIndustriaConsts.recebidoPeloVendedor
                                ? 'Aguardando'
                                : orcamento.totalOrcamentoTecnico
                                ? `R$ ${orcamento.totalOrcamentoTecnico.toLocaleString(
                                      'pt-br',
                                      { minimumFractionDigits: 2 }
                                  )}`
                                : 'Aguardando'
                        )}
                    </span>
                </Col>
                <Col className='d-flex align-items-center justify-items-end px-1  col-2'>
                    <StatusOrcamento
                        situacaoOrcamentoIndustria={
                            orcamento.situacaoOrcamentoIndustria
                        }
                    />
                </Col>
                <Col className="d-flex align-content-between my-2 mx-0 px-0">
                    <button
                        type="button"
                        className="btn btn-icon btn-icon-mini btn-neutral"
                        onClick={() => handleClickEdit()}
                    >
                        <i className="icon-icon-edita" />
                    </button>
                    <button
                        type="button"
                        className="btn btn-icon btn-icon-mini btn-neutral"
                        onClick={() => setShowDelete(true)}
                    >
                        <i className="icon-lixeira" />
                    </button>
                    <button
                        type="button"
                        className="btn btn-icon btn-icon-mini btn-neutral"
                        onClick={() => setShowDuplicate(true)}
                    >
                        <i className="icon-icon-copia" />
                    </button>
                </Col>
            </Row>

            <SweetAlert
                show={showDuplicate}
                warning
                style={{ display: 'block' }}
                title="Um novo orçamento será gerado com o status Rascunho. Deseja continuar?"
                confirmBtnBsStyle="danger"
                cancelBtnText="Não"
                confirmBtnText="Sim"
                showCancel
                reverseButtons
                closeOnClickOutside={false}
                onConfirm={async () => {
                    await webApi.post(
                        `orcamentos/${orcamento.codOrcamento}/duplicar`
                    );
                    reload();
                    setShowDuplicate(false);
                }}
                onCancel={() => {
                    setShowDuplicate(false);
                }}
            />

            <SweetAlert
                show={showDelete}
                warning
                style={{ display: 'block' }}
                title="Tem certeza que deseja excluir este orçamento?"
                confirmBtnBsStyle="danger"
                cancelBtnText="Não"
                confirmBtnText="Sim"
                showCancel
                reverseButtons
                closeOnClickOutside={false}
                onConfirm={async () => {
                    const result = await webApi.delete(
                        `orcamentos/${orcamento.codOrcamento}`
                    );
                    reload();
                    setShowDelete(false);
                }}
                onCancel={() => {
                    setShowDelete(false);
                }}
            />
        </>
    );
}
