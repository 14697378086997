import React, { useState, useRef, useEffect } from 'react';
import { Row } from 'reactstrap';
import NotificationAlert from 'react-notification-alert';
import PageContent from '~/components/PageContent';
import ModalProduct from '../../components/Modal/index';
import webapi from '~/services/webapi';
import CabecalhoEgShop from '../../components/Cabecalho/CabecalhoEgShop';
import GridData from '../../components/GridData/index';
import ItemDadosUsuario from './components/ListaUsuarios';
import FormularioUsuario from './components/Formulario';

export default function Usuarios() {
    // refs
    const notify = useRef();

    // states
    const [alertDelete, setAlertDelete] = useState();
    const [selectedRecord, setSelectedRecord] = useState('');
    const [refresh, setRefresh] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageCount, setPageCount] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);

    // functions
    const handleClickNew = () => {
        setSelectedRecord(0);
        setIsOpen(true);
        setIsModal(true);
    };

    const handleClickEdit = (codUsuario) => {
        setSelectedRecord(codUsuario);
        setIsModal(true);
        setIsOpen(true);
    };

    const handleCloseModal = () => {
        setIsModal(false);
        setIsOpen(false);
        setRefresh(true);
    };

    const handleReload = (value) => {
        setRefresh(!value);
        setLoading(true);
    };

    const handleDataUsuario = async () => {
        setLoading(true);
        const result = await webapi.get(`revenda/usuarios`);
        const { records, total } = result;
        const value = records.map((item) => ({
            active: item.active,
            authenticate: item.authenticate,
            codPerfilUsuario: item.codPerfilUsuario,
            id: item.codUsuario,
            email: item.email,
            name: item.name,
            roleAdmin: item.roleAdmin,
        }));
        setData(value);
        setTotalRecords(total);
        setPageCount(Math.ceil(total / 1));
        setLoading(false);
        setRefresh(false);
    };

    useEffect(() => {
        handleDataUsuario();
    }, [refresh]);

    return (
        <>
            <CabecalhoEgShop
                title="Lista de usuários"
                onRegisterNew={() => handleClickNew()}
                iconBtn={<i className="fas fa-users" />}
                descricaoBtn="Cadastrar Usuário"
            />
            <div className="m-0 col-12">
                <PageContent className="m-0 container">
                    {totalRecords === 0 ? (
                        <div className="cardList my-3">
                            <strong>Nenhum Registro</strong>
                        </div>
                    ) : (
                        <>
                            <Row className="rowRecord">
                                {' '}
                                <p>Total de Registro: {totalRecords}</p>
                            </Row>
                            <div>
                                {data.map((item, index) => (
                                    <div key={Number(index)}>
                                        <GridData
                                            dados={item}
                                            onEdit={handleClickEdit}
                                            registro={totalRecords}
                                            numBtn={1}
                                        >
                                            <ItemDadosUsuario dados={item} />
                                        </GridData>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                    {alertDelete}
                </PageContent>
            </div>
            <ModalProduct
                open={isModal}
                onClose={handleCloseModal}
                title="Usuário"
                size="md"
            >
                <FormularioUsuario
                    idUsuario={selectedRecord}
                    onClose={handleCloseModal}
                    onRefresh={handleReload}
                />
            </ModalProduct>
            <div className="rna-container">
                <NotificationAlert ref={notify} />
            </div>
        </>
    );
}
