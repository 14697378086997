import React from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';
import DashboardIndustria from './DashboardIndustria';
import DashboardRevenda from './DashboardRevenda';

export default function Dashboard() {
    const { ehUsuarioIndustria } = useAuthContext();

    return (
        <div className='col-12 col-md-12 col-sm-6 col-xs-6'>
            {ehUsuarioIndustria() ? <DashboardIndustria /> : <DashboardRevenda />}
        </div>
    );
}
