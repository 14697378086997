import React, { useEffect } from 'react';
import { Input } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import PageContent from '~/components/PageContent';
import OrcamentoNavegacaoSuperior from '~/components/OrcamentoNavegacaoSuperior';
import history from '~/services/history';
import NavigationSubArea from '../../components/OrcamentoTecnicoNavegacao';
import webApi from '~/services/webapi';

export default function OrcamentoTecnicoObservacao() {
    const { codOrcamentoTecnico, versao } = useParams();

    const { register, getValues, reset } = useForm({
        mode: 'onChange',
    });

    const handleChange = async () => {
        return true;
    };

    const handleClickNext = async () => {
        history.push(
            `/orcamentos-tecnicos/${codOrcamentoTecnico}/versao/${versao}/vendas`
        );
    };

    const handleClickPrevious = async () => {
        history.push(
            `/orcamentos-tecnicos/${codOrcamentoTecnico}/versao/${versao}/itens`
        );
    };

    useEffect(() => {
        const fetchData = async () => {
            const result = await webApi.get(
                `/orcamentoTecnico/${codOrcamentoTecnico}/versao/${versao}/observacoes`
            );
            reset(result);
        };
        if (codOrcamentoTecnico) fetchData();
    }, []);

    return (
        <>
            <PageContent className="no-border">
                <OrcamentoNavegacaoSuperior />
            </PageContent>

            <PageContent titlePage="Observações">
                <NavigationSubArea
                    active="observation"
                    hasObservation={getValues('technicalBudgetObservation')}
                    handleChange={() => handleChange()}
                >
                    <form>
                        <div className="row">
                            <div className="col-md-10 ml-auto mr-auto mt-4">
                                <label>Minhas Observações:</label>
                                <Input
                                    name="orcamentoObservacao"
                                    type="textarea"
                                    innerRef={register}
                                    className="boxInput form-control form-control-sm"
                                    placeholder="Observações da Obra..."
                                    rows={5}
                                    disabled
                                    style={{ maxHeight: 400 }}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-10 mt-4 ml-auto mr-auto">
                                <label>Observações da Revenda:</label>
                                <Input
                                    name="orcamentoTecnicoObservacao"
                                    type="textarea"
                                    innerRef={register}
                                    className="boxInput form-control form-control-sm"
                                    placeholder="Observações da Obra..."
                                    rows={5}
                                    disabled
                                    style={{ maxHeight: 400 }}
                                />
                            </div>
                        </div>
                        <div className="m-3">
                            <div className="pull-left">
                                <button
                                    type="button"
                                    className="boxButtonAvancar btn"
                                    role="tablist"
                                    onClick={() => handleClickPrevious()}
                                >
                                    <i className="far fa-arrow-alt-circle-left" />
                                    {' Voltar'}
                                </button>
                            </div>
                            <div className="pull-right">
                                <button
                                    type="button"
                                    className="boxButtonAvancar btn"
                                    role="tablist"
                                    onClick={() => handleClickNext()}
                                >
                                    Avançar{' '}
                                    <i className="far fa-arrow-alt-circle-right" />
                                </button>
                            </div>
                        </div>
                    </form>
                </NavigationSubArea>
            </PageContent>
        </>
    );
}
