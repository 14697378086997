import React from 'react';
import styles from './Indicador.module.css';
import { Col, Row, Card, CardBody, CardTitle, CardFooter } from 'reactstrap';


interface IndicadorProps {
    icone?: string;
    icon?: string;
    total: Number;
    texto: string;
    status: any;
    tipo: 'PROPOSTAPENDENTE' | 'PEDIDOPENDENTE' | 'PROPOSTAFECHADA' | 'PROPOSTAREJEITADA' | 'PEDIDOACEITE' | 'SOLICITADA';
}

export function Indicador({ total, texto, tipo, status }: IndicadorProps) {
    return (
        <Col className="px-0 pl-2 pr-2 pr-sm-0">
            <Card className='w-100 ml-lg-0 ml-sm-4 px-0 mx-0'>
                <CardBody className='px-0 my-0 py-0' style={{textAlign:"center"}}>
                    <Row className='col-12 col-sm-12 mx-0 px-1 justify-content-between'>

                        <Col >
                            <CardTitle className={`${styles.numeros}`}>{String(total)}</CardTitle>
                        </Col>
                    </Row>
                </CardBody>
                <CardFooter className='py-0'>
                    <hr className={`
                        ${tipo === 'PROPOSTAPENDENTE' ?
                            ` ${styles.borderPendentes}` : ''}
                        ${tipo === 'PEDIDOPENDENTE' ?
                            ` ${styles.borderEnviadas}` : ''}
                        ${tipo === 'PEDIDOACEITE' ?
                            ` ${styles.borderFechadas}` : ''}
                        ${tipo === 'PROPOSTAREJEITADA' ?
                            ` ${styles.borderRejeitadas}` : ''}
                        ${tipo === 'PROPOSTAFECHADA' ?
                            ` ${styles.borderRecebidas}` : ''}
                        ${tipo === 'SOLICITADA' ?
                            ` ${styles.borderSolicitadas}` : ''}
                    `} />
                    <Row>
                        <Col style={{textAlign:"center"}}>
                            <p>
                                <b className={`
                                ${tipo === 'PROPOSTAPENDENTE' ?
                                        ` ${styles.b1}` : ''}
                                ${tipo === 'PEDIDOPENDENTE' ?
                                        ` ${styles.b2}` : ''}
                                ${tipo === 'PEDIDOACEITE' ?
                                        ` ${styles.b3}` : ''}
                                ${tipo === 'PROPOSTAREJEITADA' ?
                                        ` ${styles.b4}` : ''}
                                ${tipo === 'PROPOSTAFECHADA' ?
                                        ` ${styles.b5}` : ''}
                                ${tipo === 'SOLICITADA' ?
                                        ` ${styles.b6}` : ''}`}>{texto}</b>
                            </p>
                        </Col>
                    </Row>
                </CardFooter>
                <hr className={`
                ${tipo === 'PROPOSTAPENDENTE' ?
                        ` ${styles.borde1}` : ''}
                ${tipo === 'PEDIDOPENDENTE' ?
                        ` ${styles.borde2}` : ''}
                ${tipo === 'PEDIDOACEITE' ?
                        ` ${styles.borde3}` : ''}
                ${tipo === 'PROPOSTAREJEITADA' ?
                        ` ${styles.borde4}` : ''}
                ${tipo === 'PROPOSTAFECHADA' ?
                        ` ${styles.borde5}` : ''}
                ${tipo === 'SOLICITADA' ?
                        ` ${styles.borde6}` : ''}`} />
            </Card>
        </Col>

    )
}
