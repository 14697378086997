import React, {
    useState,
    useEffect,
} from 'react';
import { Row, Col } from 'reactstrap';

import './style.css';
import history from '../../services/history';
import PageContent from '../../components/PageContent';
import { useOrcamentoContext } from '../../hooks/useOrcamentoContext';
import webApi from '../../services/webapi';
import { situacaoOrcamentoIndustriaConsts } from '../../consts/situacaoOrcamentoIndustriaConsts';
import OrcamentoItem from './OrcamentoItem';
import styles from './orcamento.module.css';
import Cabecalho from './../../components/Cabecalho/index';

//Recebo do BD
interface OrcamentoResultProps {
    total: Number;
    page: Number;
    records: OrcamentoItemProps[];
}
interface OrcamentoItemProps {
    codIndustria: Number;
    codOrcamento: Number;
    codOrcamentoTecnico: Number;
    codRevenda: Number;
    codUsuario: Number;
    codigoSequencial: string;
    dataCriacao: string;
    nomeCliente: string;
    nomeUsuario: string;
    projetoNome: string;
    situacaoOrcamentoIndustria: number;
    situacaoOrcamentoRevenda: Number;
    totalCusto:Number;
    totalOrcamentoTecnico: Number;
    versao: Number;
    totalCustoVendedor: Number;
}

const status = [
    {
        label:situacaoOrcamentoIndustriaConsts.orcamentoRascunho,
        name: "Cotação em rascunho",
    },
    {
        label:situacaoOrcamentoIndustriaConsts.orcamentoComVendedor,
        name: "Cotação enviada",
    },
    {
        label: situacaoOrcamentoIndustriaConsts.orcamentoRecebido  || situacaoOrcamentoIndustriaConsts.orcamentoRecebido,
        name: "Cotação em analise técnica",
    },
    {
        label:situacaoOrcamentoIndustriaConsts.recebidoPeloVendedor,
        name: "Analise técnica concluída",
    },
    {
        label:situacaoOrcamentoIndustriaConsts.orcamentoRetornado,
        name:"Cotação realizada",
    },
    {
        label: situacaoOrcamentoIndustriaConsts.orcamentoRejeitado,
        name: "Cotação rejeitada",

    },
    {
        label: situacaoOrcamentoIndustriaConsts.pedidoAberto,
        name: "Pedido em aberto",
    },
    {
        label: situacaoOrcamentoIndustriaConsts.pedidoSolicitado,
        name: "Pedido Solicitado",
    },
    {
        label: situacaoOrcamentoIndustriaConsts.pedidoFinalizado,
        name: "Pedido Confirmado",
    },
];


export default function OrcamentoLista() {
    // hooks
    const { limparOrcamentoSelecionado } =
        useOrcamentoContext();

    // states
    const [table, setTable] = useState<OrcamentoResultProps>();
    const [reload, setReload] = useState(false);
    const [loading, setLoading] = useState(false);
    const [filtro, setFiltro] = useState<string>();
    const [filtroExtra, setFiltroExtra] = useState<string>();


    // functions
    function filtrar(parametroFiltro: string) {
        setFiltro(parametroFiltro);
        setReload(!reload);
    }

    function filtrarExtra(parametroFiltro: string) {
        if(parametroFiltro === "0"){setFiltroExtra("");
        } else{setFiltroExtra(parametroFiltro)};
        setReload(!reload);

    }

    const handleClickNew = () => {
        limparOrcamentoSelecionado();
        history.push(`/orcamentos/cliente`);
    };

    useEffect(() => {
        (async () => {
            setLoading(true);
            const result: OrcamentoResultProps = await webApi.post(
                `orcamentos`,
                {
                    page: 1,
                    take: 10,
                    sort: "",
                    filter: filtro,
                    desc: true,
                    extraFilterOne: filtroExtra,
                  }
            );
            setTable(result);
            setLoading(false);
        })();
    }, [reload]);

    return (
        <div className='col-12'>
            <Cabecalho
                isButton
                iconeBtn={<i className="icon-icon-esquadria" />}
                title="+ Novo Orçamento"
                styles="btnPrimary"
                onClick={() => handleClickNew()}
                isSelect
                label='Filtrar'
                valueLabel="flex"
                data={status}
                valueSelect={filtroExtra}
                onChangeSelect={filtrarExtra}
                isSearch
                value={filtro}
                onChange={(e) => filtrar(e.target.value)}
                iconeColor="#09456b"
            />
            <PageContent>
                <Row className={`${styles.linha}`}>
                    <Col className={`${styles.title}`}>
                            Orçamentos de {' '}
                        <strong>Esquadrias</strong>
                    </Col>

                    <Col className={`${styles.total}`}>
                        Total de registros{' '}
                        {String(table?.total).padStart(6, '0')}
                    </Col>
                </Row>

                <div className={`${styles.colunas}`}>
                    {table &&
                        table.records?.map((item) => (
                            <OrcamentoItem
                                key={item.codigoSequencial}
                                orcamento={item}
                                reload={() => setReload(true)}
                            />
                        ))}
                </div>

            </PageContent>
        </div>
    );
}
