import React from 'react';
import { useParams } from 'react-router-dom';
import history from '~/services/history';
import OrcamentoDadosCliente from '../OrcamentoDadosCliente';

function IconRender({ module, icon, hasTechnicalBudgetObservation }) {
    if (module === 'observation') {
        if (hasTechnicalBudgetObservation) {
            return <i className={`${icon} icon-success`} />;
        }
        return <i className={icon} />;
    }
    return <i className={icon} />;
}

export default function NavigationSubArea(props) {
    const { codOrcamentoTecnico, versao } = useParams();

    const modules = [
        {
            title: 'Cores',
            name: 'Color',
            icon: 'fas fa-fill-drip',
            link: `/orcamentos-tecnicos/${codOrcamentoTecnico}/versao/${versao}/cores`,
        },
        {
            title: 'Itens',
            name: 'items',
            icon: 'fas fa-sitemap',
            link: `/orcamentos-tecnicos/${codOrcamentoTecnico}/versao/${versao}/itens`,
        },
        {
            title: 'Observações',
            name: 'observation',
            icon: 'fas fa-clipboard',
            link: `/orcamentos-tecnicos/${codOrcamentoTecnico}/versao/${versao}/observacoes`,
        },
        {
            title: 'Valores',
            name: 'vendas',
            icon: 'far fa-money-bill-alt',
            link: `/orcamentos-tecnicos/${codOrcamentoTecnico}/versao/${versao}/vendas`,
        },
    ];

    return (
        <>
            <div className="tab-content text-center" id="default">
                <div
                    className="tab-pane active wizard"
                    role="tabpanel"
                    aria-expanded="true"
                    id="levantamento"
                >
                    <ul
                        className="nav nav-pills nav-pills-primary nav-pills-icons nav-justified"
                        role="tablist"
                    >
                        {modules.map((m) => (
                            <li className="nav-item" key={m.name}>
                                <a
                                    href=""
                                    className={
                                        m.name === props.active
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }
                                    role="tablist"
                                    onClick={async (e) => {
                                        e.preventDefault();
                                        if (codOrcamentoTecnico) {
                                            if (m.name !== props.active) {
                                                const valid =
                                                    await props.handleChange();
                                                if (valid) {
                                                    history.push(m.link);
                                                }
                                            }
                                        }
                                    }}
                                >
                                    <div>
                                        <IconRender
                                            module={m.name}
                                            icon={m.icon}
                                        />
                                    </div>
                                    {m.title.toUpperCase()}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 p-1">
                    <div className="tab-pane active text-left">
                        {props.children}
                    </div>
                </div>
            </div>
        </>
    );
}
