import React from 'react';
import { Row, Col } from 'reactstrap';

interface IListaCorProps{
    codigo:string;
    descricao: string;
    onDelete: (value:any) => void;
};
export default function ListaCor({codigo, descricao, onDelete}:IListaCorProps) {
    return(
        <div className="col-12 col-m-12">
           <Row className="justify-content-between">
                <Col className="mt-2">
                    <p>{codigo}</p>
                </Col>
                <Col className="mt-2">
                    <p>{descricao}</p>
                </Col>
                <Col>
                    <button
                        type="button"
                        className="btnnLixeira"
                        onClick={onDelete}
                    >
                        <i className="icon-lixeira" />
                    </button>
                </Col>
            </Row>
        </div>
    )
};

