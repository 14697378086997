import React from 'react';
import { Router } from 'react-router-dom';

import AppRoutes from './routes';
import history from './services/history';
import GlobalStyle from './styles/global';

import { AuthContextProvider } from 'hooks/useAuthContext';
import { NotifyContextProvider } from 'hooks/useNotifyContext';
import { OrcamentoContextProvider } from 'hooks/useOrcamentoContext';
import { OrcamentoTecnicoVendaContextProvider } from './hooks/useOrcamentoTecnicoVendaContext';
import { SweetAlertContextProvider } from './hooks/useSweetAlertContext';

function App() {
    return (
        <NotifyContextProvider>
            <SweetAlertContextProvider>
                <AuthContextProvider>
                    <OrcamentoContextProvider>
                        <OrcamentoTecnicoVendaContextProvider>
                            <Router history={history}>
                                <AppRoutes />
                                <GlobalStyle />
                            </Router>
                        </OrcamentoTecnicoVendaContextProvider>
                    </OrcamentoContextProvider>
                </AuthContextProvider>
            </SweetAlertContextProvider>
        </NotifyContextProvider>
    );
}

export default App;
