import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import { Button, Fade, Label, Input, FormFeedback, Row, Col } from 'reactstrap';
import { useParams, Link } from 'react-router-dom';
import Placeholder from '../../../../assets/img/img-placeholder.png';

export default function Itens(props) {
    //Fields
    const baseUrlImagem = `${process.env.REACT_APP_API}figurasicones/`;

    // States
    const [imagem, setImagem] = useState(Placeholder);

    return (
        <>
            <div className="col-2 card card-sm p-0 item-produto">
                <div className="card-body p-0">
                    <Link
                        // to={`/produtos/item/${props.produto.id}/${props.produto.codigo}/${props.produto.tipoProduto}`}
                        className="d-block"
                    >
                        <img src={imagem} className="card-img" />
                    </Link>

                    <div className="d-flex flex-column mt-3">
                        <div className="col subheader">Código:</div>
                        <div className="font-weight-bold">
                            {props.produto.codigo}
                        </div>

                        <div className="col subheader mt-2">Descrição:</div>
                        <div className="block-with-text">
                            {props.produto.descricao}
                        </div>

                        <div className="col mt-2">
                            <label>Quantidade:</label>
                            <select className="form-control">
                                <option></option>
                            </select>
                        </div>
                        <div className="col mt-2">
                            <label>Cor:</label>
                            <select className="form-control">
                                <option></option>
                            </select>
                        </div>
                        <div className="col mt-2">
                            <label>Total do item calculado:</label>
                            <input
                                type="text"
                                disabled
                                className="form-control"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
