import React, { ReactNode } from 'react';
import { useAuthContext } from '../../../hooks/useAuthContext';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Header from './components/Header';
import SiderBar from './components/Sidebar';
import Footer from './components/Footer';


type LayoutPrivateProps = {
    children: ReactNode;
}
export default function LayoutPrivateDefault({ children }: LayoutPrivateProps) {

    const { ehUsuarioIndustria, ehUsuarioVendedor, ehUsuarioRevenda } = useAuthContext();

    const handleMiniClick = () => {
        document.body.classList.toggle('sidebar-mini');
    };

    const handleClickNavbarToggle = () => {
        document.documentElement.classList.toggle('nav-open');
        document.querySelector('#openMenu')?.classList.toggle('toggled');
    };

    return (
        <>
            <SiderBar />
            <PerfectScrollbar>
                <div className={`main-panel ${ehUsuarioVendedor() ? 'egShop' : (ehUsuarioRevenda() ? 'egShop' : '')}`}>
                    <Header
                        handleMiniClick={handleMiniClick}
                        handleClickNavbarToggle={
                            handleClickNavbarToggle
                        }
                    />
                    <div className="content">{children} </div>
                    <Footer />
                </div>
            </PerfectScrollbar>
        </>
    );
}


