import React from 'react';
import styles from './CotacaoStatus.module.css';


interface StatusProps {
    status: Number;
}

export default function PedidoStatus({ status }: StatusProps) {

    const tpStatus = [
        {
            key:1,
            value: "Pedido Aberto"
        },
        {
            key:2,
            value: "Pedido Enviado"
        },
        {
            key:3,
            value: "Pedido Cancelado"
        },
        {
            key:4,
            value: "Pedido Confirmado"
        },
    ]

    switch (status) {
        default: {
            return (
                <>
                   <span className={`${styles.iconAberto}`} title='Aberto'/>
                   <span>{tpStatus.find(tipo => tipo.key === status)?.value}</span>
                </>
            );
        }
        case 2: {
            return (
                <>
                    <span className={`${styles.iconSolicitado}`} title='Solicitado'/>
                    <span>{tpStatus.find(tipo => tipo.key === status)?.value}</span>
                </>
            )
        }
        case 3: {
            return (
                <>
                    <span className={`${styles.iconCancelado}`} title='Cancelada'/>
                    <span>{tpStatus.find(tipo => tipo.key === status)?.value}</span>
                </>
            )
        }
        case 4: {
            return (
                <>
                    <span className={`${styles.iconConfirmado}`} title='Confirmado'/>
                    <span>{tpStatus.find(tipo => tipo.key === status)?.value}</span>
                </>
            )
        }
    }


}
