import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNotifyContext } from '~/hooks/useNotifyContext';
import { useOrcamentoTecnicoVendaContext } from '~/hooks/useOrcamentoTecnicoVendaContext';
import tipoPrecificacaoOrcamentoTecnicoVendaConsts from '~/consts/tipoPrecificacaoOrcamentoTecnicoVendaConsts';
import { Row, Col } from 'reactstrap';
import { FaList } from 'react-icons/fa';
import webApi from '~/services/webapi';
import OrcamentoTecnicoVendaItem from './OrcamentoTecnicoVendaItem';
import { useOrcamentoContext } from '~/hooks/useOrcamentoContext';
import OrcamentoTecnicoVendaDetalhe from './OrcamentoTecnicoVendaDetalhe';
import ModalProduct from '../../../components/Modal';

function OrcamentoTecnicoVendaListaItem() {
    const [isOpen, setIsOpen] = useState(false);

    // params
    const { codOrcamentoTecnico, versao } = useParams();

    // hooks
    const notificacao = useNotifyContext();
    // const {} = useResponsive();

    const {
        tipoPrecificacao,
        orcamentoTecnicoItens,
        salvarAlteracoesOrcamento,
        setSalvarAlteracoesOrcamento,
    } = useOrcamentoTecnicoVendaContext();

    const { permiteEdicaoOrcamentoTecnicoVendas } = useOrcamentoContext();

    // functions
    async function salvarOrcamentoTecnico() {
        if (permiteEdicaoOrcamentoTecnicoVendas()) {
            try {
                const itens = orcamentoTecnicoItens.map((item) => {
                    return {
                        codOrcamentoTecnicoItem: item.codOrcamentoTecnicoItem,
                        valorTotal: item.valorTotal,
                    };
                });

                await webApi.post(
                    `orcamentoTecnico/${codOrcamentoTecnico}/versao/${versao}/alterar-total-orcamento-tecnico-precificacao-direta`,
                    {
                        itens,
                    }
                );

                notificacao.sucesso('Dados orçamento salvos com sucesso!');
            } catch (err) {
                notificacao.respostaHttp(err);
            }
        }
    }

    useEffect(() => {
        if (
            tipoPrecificacao ===
            tipoPrecificacaoOrcamentoTecnicoVendaConsts.direta
        ) {
            if (salvarAlteracoesOrcamento) {
                salvarOrcamentoTecnico();
                setSalvarAlteracoesOrcamento(false);
            }
        }
    }, [salvarAlteracoesOrcamento]);

    return (
        <>
            <Row
                className="mx-0 "
                style={{
                    justifyContent: 'space-between',
                }}
            >
                <Col
                    id="detalheItem"
                    className={
                        tipoPrecificacao ===
                        tipoPrecificacaoOrcamentoTecnicoVendaConsts.direta
                            ? 'cards-itens-proposta p-1 m-0'
                            : 'cards-itens-proposta-painel pr-0 m-0'
                    }
                    Xl={9}
                    lg={9}
                    md={12}
                    xs={12}
                >
                    <div className="d-flex col-12 p-0">
                        {orcamentoTecnicoItens.map((item) => (
                            <OrcamentoTecnicoVendaItem
                                key={item.codOrcamentoTecnicoItem}
                                item={item}
                            />
                        ))}
                    </div>
                </Col>
                <Col xl={3} lg={3} className="px-0 d-none d-lg-flex pr-1">
                    <OrcamentoTecnicoVendaDetalhe />
                </Col>
                <Col md={12} className="px-0 pt-3 d-contents d-lg-none m-0">
                    <button
                        type="button"
                        className="form-control boxButton btn-outline-primary"
                        onClick={() => setIsOpen(true)}
                    >
                        <FaList className="mr-2 mb-1" />
                        Meu valor de venda
                    </button>
                </Col>
            </Row>
            <ModalProduct
                open={isOpen}
                onClose={() => setIsOpen(false)}
                title="Detalhes da Venda"
                onAdvance={() => ''}
                onBack={() => ''}
                size="md"
            >
                <OrcamentoTecnicoVendaDetalhe />
            </ModalProduct>
        </>
    );
}
export default OrcamentoTecnicoVendaListaItem;
