import React, { useEffect, useState } from 'react';
import PageContent from '~/components/PageContent';

import { useAuthContext } from 'hooks/useAuthContext';
import { Row } from 'reactstrap';
import { useNotifyContext } from '~/hooks/useNotifyContext';
import ModalProduct from 'components/Modal';
import webApi from '~/services/webapi';
import CatalogoForm from '../Formulario/index';
import { useOrcamentoContext } from '~/hooks/useOrcamentoContext';
import CabecalhoEgShop from '../../../components/Cabecalho/CabecalhoEgShop';
import GridData from '../../../components/GridData/index.tsx';
import ListaItemAvulso from './components/listaItens';
import SearchesGrid from './components/searchesGrid';
import './itensstyles.css';

export default function CatalogoLista() {
    const { state } = useAuthContext();
    const notificacao = useNotifyContext();
    const { isItemAvulso, setIsItemAvulso } = useOrcamentoContext();
    const [produtos, setProdutos] = useState([]);
    const [dataItem, setDataItem] = useState('');
    const [isModal, setIsModal] = useState(false);

    const [filtro, setFiltro] = useState(null);
    const [filtroSegmento, setFiltroSegmento] = useState(null);

    const [tipos, setTipos] = useState([]);

    const getProdutos = async () => {
        const response = await webApi.get(
            `/produto/catalogo/${state.catalogo}/produtos`,
            {
                params: {
                    filtro,
                    tipoProduto: filtroSegmento,
                },
            }
        );
        const value = response.map((item) => ({
            codigo: item.codigo,
            ativo: item.ativo,
            barra: item.barra,
            codCatalogo: item.codCatalogo,
            id: item.codProduto,
            codigoErp: item.codigoErp,
            descricao: item.descricao,
            imagem: item.imagem,
            imagemUrl: item.imagemUrl,
            peso: item.peso,
            quantidade: item.quantidade,
            tipoProduto: item.tipoProduto,
            tipoUnidade: item.tipoUnidade,
        }));
        setProdutos(value);
    };

    const handleCloseModal = () => {
        setDataItem('');
        setIsModal(false);
        getProdutos();
    };

    async function getTipos() {
        const response = await webApi.get('/Enums/tipo-produtos');
        const data = response.map((tipo) => ({
            label: tipo.key,
            name: tipo.value,
        }));
        setTipos(data);
    }

    const handleOpenModal = (codigo) => {
        setDataItem(codigo);
        setIsModal(true);
        if (codigo === '') {
            notificacao.alerta('Selecione o Tipo do Produto!');
        }
    };

    const handleActive = async (dados, status) => {
        const item = {
            codProduto: dados.id,
            codCatalogo: dados.codCatalogo,
            ativo: status.state.value,
        };
        await webApi.patch(
            `/Produto/${dados.id}/catalogo/${dados.codCatalogo}/ativar-desativar`,
            item
        );
        getProdutos();
    };

    const handleDelete = async (id) => {
        try {
            await webApi.delete(`/Produto/${id}/excluir-produto`);
            notificacao.sucesso('Item excluido com sucesso');
            getProdutos();
        } catch (error) {
            notificacao.erro(error);
        }
    };

    function filtrarExtra(parametroFiltro) {
        if (parametroFiltro === '0') {
            setFiltroSegmento('');
        } else {
            setFiltroSegmento(parametroFiltro);
        }
    }

    useEffect(() => {
        if (isItemAvulso === true) handleCloseModal();
        setIsItemAvulso(false);
    }, [isItemAvulso]);

    useEffect(() => {
        getTipos();
        getProdutos();
    }, [filtroSegmento, filtro]);

    return (
        <div className="col-12">
            <CabecalhoEgShop
                title="Catalogo Itens Avulsos"
                onRegisterNew={() => handleOpenModal('')}
                iconBtn={<i className="icon-h" />}
                descricaoBtn="Cadastrar item avulso"
            />
            <div className="m-0 col-12">
                <PageContent className="m-0 mr-2 container">
                    <SearchesGrid
                        isSelect
                        label="Filtrar"
                        valueLabel="flex"
                        data={tipos}
                        valueSelect={filtroSegmento}
                        onChangeSelect={filtrarExtra}
                        isSearch
                        value={filtro}
                        onChange={(e) => setFiltro(e.target.value)}
                        iconeColor="#6b2c5f"
                    />
                    {produtos.length === 0 ? (
                        <div className="cardList my-3">
                            <strong>Nenhum Registro</strong>
                        </div>
                    ) : (
                        <>
                            <Row className="recordTotal">
                                {' '}
                                <p className="m-0">
                                    Total de Registro: {produtos.length}
                                </p>
                            </Row>
                            {produtos.map((item, index) => (
                                <div key={Number(index)}>
                                    <GridData
                                        dados={item}
                                        onEdit={handleOpenModal}
                                        onTrash={handleDelete}
                                        registro={produtos.length}
                                        numBtn={2}
                                    >
                                        <ListaItemAvulso
                                            dados={item}
                                            tipo={tipos}
                                            onActive={handleActive}
                                        />
                                    </GridData>
                                </div>
                            ))}
                        </>
                    )}
                </PageContent>
            </div>
            <ModalProduct
                open={isModal}
                size="xl"
                title={
                    dataItem === ''
                        ? 'Cadastrar Item Avulso'
                        : 'Editar Item Avulso'
                }
                onClose={handleCloseModal}
            >
                <CatalogoForm data={dataItem} />
            </ModalProduct>
        </div>
    );
}
