import React from 'react';
import { useOrcamentoContext } from '../../../../../hooks/useOrcamentoContext.tsx';
import Desenhador from '../../../../OrcamentoItens/components/FormularioItem/components/Desenhador';

export default function DataCroqui() {
    const { data, isAtivoTecnico, dataSolicitacao } = useOrcamentoContext();

    return (
        <div className="mt-4">
            <Desenhador
                image={!isAtivoTecnico ? data?.imagem || "" : dataSolicitacao?.imagem || "" }
                setImage={() => ''}
                setImageChanged={() => ''}
                readOnly={false}
            />
        </div>
    );
}
