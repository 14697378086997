import React from 'react';
import { Row, Col, Label } from 'reactstrap';
import './styles.css'

interface IDadosProps {
    id: number,
    descricao: string,
    tipo:number,
 }

 interface IItemDadosProps {
     dados:IDadosProps;
 };

 export default function ItemParametrizacao({dados}:IItemDadosProps) {
    return(
        <Row className="listCard px-0">
            <Col xl={2} lg={2}>
                <Label>Descrição</Label>
                <div><strong>{dados.descricao}</strong></div>
            </Col>
            <Col xl={2} lg={2}>
                <Label>Tipo</Label>
                <div><strong>{dados.tipo}</strong></div>
            </Col>
        </Row>
    )
}

