import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import PageContent from '~/components/PageContent';
import GridData from 'components/GridData';
import { Row } from 'reactstrap';
import webApi from '~/services/webapi';
import { useAuthContext } from '../../../hooks/useAuthContext';
import CabecalhoEgShop from '../../../components/Cabecalho/CabecalhoEgShop.tsx';
import ItemDados from '../components/ListaVendedor';
import './styles.css';

export default function ListaDadosVendedores() {
    // states
    const { state } = useAuthContext();
    const history = useHistory();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRecords, setTotalRecords] = useState(0);

    // functions
    const handleClickNew = () => {
        history.push('/vendedores/novo');
    };

    const handleEditNew = (codVendedor) => {
        history.push(`/vendedores/${codVendedor}/editar`);
    };

    useEffect(() => {
        setLoading(true);
        (async () => {
            const result = await webApi.get(
                `/Vendedor/revenda/${state.codRevenda}`
            );
            const value = result.map((item) => ({
                ativo: item.ativo,
                bairro: item.bairro,
                cep: item.cep,
                cidade: item.cidade,
                codUsuario: item.codUsuario,
                id: item.codVendedor,
                cpf: item.cpf,
                email: item.email,
                endereco: item.endereco,
                nome: item.nome,
                numero: item.numero,
                telefone: item.telefone,
                uf: item.uf,
            }));
            setData(value);
            setTotalRecords(result.length);
            setLoading(false);
        })();
    }, []);

    return (
        <>
            <CabecalhoEgShop
                title="Lista de Vendedores"
                onRegisterNew={() => handleClickNew()}
                iconBtn={<i className="fas fa-user-tie" />}
                descricaoBtn="Cadastrar Vendedor"
            />
            <div className="m-0 col-12">
                <PageContent className="m-0 container">
                    {totalRecords === 0 ? (
                        <div className="cardList my-3">
                            <strong>Nenhum Registro</strong>
                        </div>
                    ) : (
                        <>
                            <Row className="rowRecord">
                                {' '}
                                <p>Total de Registro: {totalRecords}</p>
                            </Row>

                            <div>
                                {data.map((item, index) => (
                                    <div key={Number(index)}>
                                        <GridData
                                            dados={item}
                                            onEdit={handleEditNew}
                                            registro={totalRecords}
                                            numBtn={1}
                                        >
                                            <ItemDados dados={item} />
                                        </GridData>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </PageContent>
            </div>
        </>
    );
}
