import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import webApi from '../../services/webapi.ts';

export default function OrcamentoDadosCliente() {
    const { codOrcamentoTecnico, versao } = useParams();
    const [state, setState] = useState({});

    useEffect(() => {
        (async () => {
            const result = await webApi.get(
                `orcamentoTecnico/${codOrcamentoTecnico}/versao/${versao}/dados-cabecalho`
            );
            setState(result);
        })();
    }, []);

    return (
        <>
            <Row className="container-vendas p-0 d-flex align-items-center">
                <Col lg={4} md={12} sm={12} xs={12} className="m-0">
                    <span className="titulo-pagina mr-5">Meu orçamento</span>
                </Col>
                <Col lg={4} md={6} sm={6} xs={12}>
                    <span className="nome-info mr-4">
                        <strong className="mr-1">Nome:</strong>
                        {state.nomeCliente}
                    </span>
                </Col>
                <Col lg={4} md={6} sm={6} xs={12}>
                    <span className="conteudo-info">
                        <strong className="mr-1">Obra:</strong>
                        {state.projetoNome}
                    </span>
                </Col>
            </Row>
        </>
    );
}
