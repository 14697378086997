import React from  'react'
import "./styles.css"

type IFileUploadXLSX = Omit<React.ComponentProps<'input'>, 'type' | 'accept'> & {
   error?: string;
   filename: string;
};

export default function FileUploadXLSX({error, filename, ...props}: IFileUploadXLSX) {
    return (
        <div className='container-custom-input-file'>
            <label htmlFor="import-xlsx" className="custom-input-label">
                <i className='fa fa-upload mr-2'/>
                Escolher arquivo
            </label>
            <input {...props} className="custom-input-file" type="file" id="import-xlsx" accept=".xls,.xlsx" />
            <span>{filename}</span>
            {error && <label>{error}</label>}
        </div>
    )
}
