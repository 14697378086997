import React, { useState, useRef, useMemo, useEffect } from 'react';
import { Button, Row, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import PageContent from '~/components/PageContent';
import OrcamentoNavegacaoSuperior from '~/components/OrcamentoNavegacaoSuperior';
import NavigationSubArea from '~/components/OrcamentoNavegacao';
import { DataTable } from '~/components/DataTable';
import history from '~/services/history';
import BuscaPorTipologia from './components/BuscaPorTipologia';
import FormularioItem from './components/FormularioItem';
import webapi from '~/services/webapi';
import { useOrcamentoContext } from '~/hooks/useOrcamentoContext';

export default function OrcamentoItem() {
    // hooks
    const { permiteEdicaoOrcamento } = useOrcamentoContext();

    // consts
    const { codOrcamento } = useParams();

    // states
    const [alertDelete, setAlertDelete] = useState(null);
    const [alertCopy, setAlertCopy] = useState(null);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [reload, setReload] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageCount, setPageCount] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [isOpenSearchProduct, setIsOpenSearchProduct] = useState(false);
    const [isOpenFormProduct, setIsOpenFormProduct] = useState(false);

    // refs
    const fetchIdRef = useRef(0);

    // functions
    const handleValid = async () => {
        return true;
    };

    const redefinirPosicaoElementoTotalRegistro = () => {
        const cl = document.getElementById('totalregistros');
        cl.classList.remove('float-right');
        cl.classList.add('float-left');
    };

    const handleReloadTable = () => {
        const fetchId = ++fetchIdRef.current;
        setReload(fetchId);
    };

    const handleOpenSearchProduct = () => {
        setIsOpenSearchProduct(true);
    };

    const handleCloseSearchProduct = () => {
        setIsOpenSearchProduct(false);
    };

    const handleOpenFormProduct = (row) => {
        setSelectedRecord(null);

        if (row) {
            const { codOrcamentoItem } = row.original;
            setSelectedRecord(codOrcamentoItem);
        }

        setIsOpenFormProduct(true);
    };

    const handleCloseFormProduct = () => {
        setIsOpenFormProduct(false);
        setSelectedRecord(null);
    };

    const handleClickNext = async () => {
        history.push(`/orcamentos/${codOrcamento}/adicionais`);
    };

    const handleClickPrevious = async () => {
        history.push(`/orcamentos/${codOrcamento}/cores`);
    };

    const handleClickDelete = ({ codOrcamentoItem }) => {
        setAlertDelete(
            <SweetAlert
                show
                warning
                style={{ display: 'block' }}
                title="Você tem certeza?"
                confirmBtnBsStyle="danger"
                cancelBtnText="Não"
                confirmBtnText="Sim"
                showCancel
                reverseButtons
                onConfirm={async () => {
                    await webapi.delete(
                        `/orcamentos/${codOrcamento}/itens/${codOrcamentoItem}`
                    );
                    setAlertDelete(null);
                    handleReloadTable();
                }}
                onCancel={() => {
                    setAlertDelete(null);
                }}
            >
                Tem certeza que deseja excluir? Você não poderá reverter essa
                ação!
            </SweetAlert>
        );
    };

    const handleClickCopy = ({ codOrcamentoItem }) => {
        setAlertCopy(
            <SweetAlert
                show
                warning
                style={{ display: 'block' }}
                title="Você tem certeza?"
                confirmBtnBsStyle="success"
                showCancel
                reverseButtons
                onConfirm={async () => {
                    await webapi.post(
                        `/orcamentos/${codOrcamento}/itens/${codOrcamentoItem}/duplicar`
                    );
                    setAlertCopy(null);
                    handleReloadTable();
                }}
                onCancel={() => {
                    setAlertCopy(null);
                }}
            >
                Tem certeza que deseja duplicar o registro? Você não poderá
                reverter essa ação!
            </SweetAlert>
        );
    };

    const getItems = React.useCallback(
        ({ pageSize, pageIndex, sort, desc, globalFilter }) => {
            setLoading(true);
            (async () => {
                const result = await webapi.post(
                    `/orcamentos/${codOrcamento}/itens`,
                    {
                        page: pageIndex,
                        take: pageSize,
                        filter: globalFilter,
                        sort,
                        desc,
                    }
                );
                const { records, total } = result;
                setData(records);
                setTotalRecords(total);
                setPageCount(Math.ceil(total / pageSize));
                setLoading(false);
            })();
        },
        []
    );

    useEffect(() => {
        redefinirPosicaoElementoTotalRegistro();
    }, []);

    const columns = useMemo(() => [
        {
            Header: 'Croqui',
            accessor: 'image',
            disableSortBy: true,
            Cell: ({ row }) => {
                const { urlImagem } = row.original;
                return (
                    <div className="text-left">
                        <img
                            alt=""
                            src={urlImagem}
                            style={{
                                maxHeight: '80px',
                            }}
                        />
                    </div>
                );
            },
        },
        {
            Header: 'Código',
            accessor: 'code',
            disableSortBy: true,
        },
        {
            Header: 'Descrição',
            accessor: 'description',
            disableSortBy: true,
        },
        {
            Header: 'Linha',
            accessor: 'line',
            disableSortBy: true,
        },
        {
            Header: 'Tipo',
            accessor: 'type',
            disableSortBy: true,
        },
        {
            Header: 'Quantidade',
            accessor: 'amount',
            disableSortBy: true,
        },

        {
            Header: 'Largura',
            accessor: 'width',
            disableSortBy: true,
        },
        {
            Header: 'Altura',
            accessor: 'height',
            disableSortBy: true,
        },
        {
            Header: 'Status',
            accessor: 'budgetRequestItemState',
            disableSortBy: true,
            Cell: ({ row }) => (
                <div>
                    {row.original.budgetRequestItemState === 1 && (
                        <button
                            type="button"
                            className="cursor-default btn btn-icon btn-round"
                            title="Rascunho"
                        >
                            <i className="icon-icon-edita-info" />
                        </button>
                    )}
                    {row.original.budgetRequestItemState === 2 && (
                        <button
                            type="button"
                            className="cursor-default btn btn-icon btn-round btn-success"
                            title="Concluído"
                        >
                            <i className="icon-icon-ckeck" />
                        </button>
                    )}
                </div>
            ),
        },
        {
            Header: 'Ações',
            accessor: 'actions',
            disableSortBy: true,
            Cell: ({ row }) => (
                <div
                    className="text-left d-flex"
                    style={{ justifyContent: 'space-evenly' }}
                >
                    <button
                        type="button"
                        rel="tooltip"
                        hidden={!permiteEdicaoOrcamento()}
                        className="btn btn-icon btn-icon-mini btn-neutral"
                        title="Editar"
                        onClick={() => handleOpenFormProduct(row)}
                    >
                        <i
                            className="icon-icon-edita"
                            style={{ color: '#57607A' }}
                        />
                    </button>
                    <button
                        type="button"
                        rel="tooltip"
                        className="btn btn-icon btn-icon-mini btn-red-over"
                        title="Duplicar"
                        hidden={!permiteEdicaoOrcamento()}
                        onClick={() => handleClickCopy(row.original)}
                    >
                        <i
                            className="icon-icon-copia"
                            style={{ color: '#57607A' }}
                        />
                    </button>
                    <button
                        type="button"
                        rel="tooltip"
                        className="btn btn-icon btn-icon-mini btn-red-over"
                        title="Excluir"
                        hidden={!permiteEdicaoOrcamento()}
                        onClick={() => handleClickDelete(row.original)}
                    >
                        <i
                            className="icon-icon-lixeira"
                            style={{ color: '#57607A' }}
                        />
                    </button>
                    <button
                        type="button"
                        rel="tooltip"
                        hidden={permiteEdicaoOrcamento()}
                        className="btn btn-icon btn-icon-mini btn-neutral"
                        title="Visualizar"
                        onClick={() => handleOpenFormProduct(row)}
                    >
                        <i className="fas fa-search" />
                    </button>
                </div>
            ),
        },
    ]);

    return (
        <>
            <PageContent className="no-border">
                <OrcamentoNavegacaoSuperior />
            </PageContent>

            <PageContent>
                <NavigationSubArea
                    active="items"
                    handleValid={() => handleValid()}
                />
                <Row>
                    <Col md="12 text-center text-sm-left mb-3 ml-3">
                        <Button
                            color=""
                            className="notfButton"
                            disabled={!permiteEdicaoOrcamento()}
                            style={{ backgroundColor: '#4EAA79' }}
                            onClick={() => handleOpenSearchProduct()}
                        >
                            <i className="icon-icon-busca mr-1" />
                            Escolher Tipologias
                        </Button>
                        <Button
                            className="notfButton ml-2"
                            style={{ backgroundColor: '#3073CB' }}
                            onClick={() => handleOpenFormProduct()}
                            disabled={!permiteEdicaoOrcamento()}
                        >
                            <i className="icon-icon-h mr-1" />
                            Adicionar Item Especial
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="col-12 col-md-12 table-responsive-xl">
                            <DataTable
                                columns={columns}
                                data={data}
                                fetchData={getItems}
                                loading={loading}
                                pageCount={pageCount}
                                total={totalRecords}
                                includePageSize={false}
                                includePagination={false}
                                reload={reload}
                            />
                        </div>
                    </Col>
                </Row>

                <div
                    className="mt-2 ml-2"
                    style={{ marginLeft: '-25px', fontSize: '18px' }}
                >
                    <div className="pull-left">
                        <Button
                            type="button"
                            role="tablist"
                            className="boxButtonAvancar"
                            style={{ backgroundColor: '#ABAFBC' }}
                            onClick={() => {
                                handleClickPrevious();
                            }}
                        >
                            <i
                                className="icon-icon-voltar mr-1"
                                style={{ fontSize: '18px' }}
                            />
                            {' Voltar'}
                        </Button>
                    </div>
                    <div
                        className="pull-right mr-3 mb-1"
                        style={{ fontSize: '18px' }}
                    >
                        <Button
                            type="button"
                            role="tablist"
                            className="boxButtonAvancar"
                            style={{ backgroundColor: '#3073CB' }}
                            onClick={() => handleClickNext()}
                        >
                            Avançar{' '}
                            <i
                                className="icon-icon-avancar ml-1"
                                style={{ fontSize: '18px' }}
                            />
                        </Button>
                    </div>
                </div>
                <BuscaPorTipologia
                    isOpen={isOpenSearchProduct}
                    handleCloseModal={handleCloseSearchProduct}
                    handleReloadTable={handleReloadTable}
                />
                <FormularioItem
                    selectedRecord={selectedRecord}
                    isOpen={isOpenFormProduct}
                    handleCloseModal={handleCloseFormProduct}
                    handleReloadTable={handleReloadTable}
                />
                {alertDelete}
                {alertCopy}
            </PageContent>
        </>
    );
}
