import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Row, Button } from 'reactstrap';
import PageContent from '~/components/PageContent';
import OrcamentoNavegacaoSuperior from '~/components/OrcamentoNavegacaoSuperior';
import { getSingleErrorMessage } from '~/utils';
import NavigationSubArea from '../../components/OrcamentoNavegacao';
import history from '~/services/history';
import { useOrcamentoContext } from '~/hooks/useOrcamentoContext';
import webApi from '~/services/webapi';
import Parametro from './components/Parametros';

export default function OrcamentoAdicionais() {
    // hooks
    const { permiteEdicaoOrcamento } = useOrcamentoContext();

    // consts
    const { codOrcamento } = useParams();

    // states
    const [parameters, setParamaters] = useState([]);
    const [hasChange, setHasChange] = useState(false);
    const [alertSend, setAlertSend] = useState(null);
    const [alertMessage, setAlertMessage] = useState(null);

    // functions
    const handleValid = () => {
        if (permiteEdicaoOrcamento()) return true;
        return true;
    };

    const handleSave = async () => {
        if (permiteEdicaoOrcamento()) {
            await webApi.post(`/orcamentos/${codOrcamento}/com-vendedor`, {
                parametros: parameters,
            });
        }
    };

    const handleClickNext = async () => {
        if (permiteEdicaoOrcamento()) {
            await webApi.post(`/orcamentos/${codOrcamento}/parametros`, {
                parametros: parameters,
            });
        }
        history.push(`/orcamentos/${codOrcamento}/observacoes`);
    };

    const handleClickPrevious = async () => {
        history.push(`/orcamentos/${codOrcamento}/itens`);
    };

    const handleClickSend = async () => {
        setAlertSend(
            <SweetAlert
                show
                info
                style={{ display: 'block' }}
                title="Você tem certeza?"
                confirmBtnBsStyle="success"
                cancelBtnText="Não"
                confirmBtnText="Sim"
                closeOnClickOutside={false}
                showCancel
                reverseButtons
                onConfirm={async () => {
                    try {
                        await webApi.put(
                            `/orcamentos/${codOrcamento}/com-vendedor`
                        );
                        setAlertSend(null);
                        handleShowMessage(
                            'success',
                            'Sucesso',
                            'Orçamento solicitado com sucesso',
                            () => {
                                history.push(`/orcamentos`);
                            }
                        );
                    } catch (error) {
                        setAlertSend(null);
                        handleShowMessage(
                            'warning',
                            'Não foi possível realizar esta ação',
                            getSingleErrorMessage(error)
                        );
                    }
                }}
                onCancel={() => {
                    setAlertSend(null);
                }}
            >
                Tem certeza que deseja realizar o envio desta solicitação? Você
                não poderá reverter essa ação!
            </SweetAlert>
        );
    };

    const handleShowMessage = (type, title, message, handleClickConfirm) => {
        setAlertMessage(
            <SweetAlert
                show
                type={type}
                title={title}
                style={{ display: 'block' }}
                confirmBtnBsStyle="success"
                onConfirm={() => {
                    if (handleClickConfirm) handleClickConfirm();
                    setAlertMessage(null);
                }}
            >
                {message}
            </SweetAlert>
        );
    };

    useEffect(() => {
        const fetchData = async () => {
            const result = await webApi.get(
                `/orcamentos/${codOrcamento}/parametros`
            );
            setParamaters(result);
        };

        fetchData();
    }, [codOrcamento]);

    return (
        <>
            <PageContent className="no-border">
                <OrcamentoNavegacaoSuperior />
            </PageContent>

            <PageContent titlePage="Orçamentos">
                <NavigationSubArea
                    active="additional"
                    handleSave={() => handleSave()}
                    handleValid={() => handleValid()}
                >
                    <form>
                        <Row>
                            {parameters.map((par) => (
                                <Parametro
                                    setHasChange={setHasChange}
                                    setParamaters={setParamaters}
                                    parameters={parameters}
                                    key={par.parameterName}
                                    parameter={par}
                                    disabled={!permiteEdicaoOrcamento()}
                                />
                            ))}
                        </Row>
                        <div
                            className="mt-2 "
                            style={{ marginLeft: '-25px', fontSize: '18px' }}
                        >
                            <div className="pull-left">
                                <Button
                                    type="button"
                                    role="tablist"
                                    className="boxButtonAvancar"
                                    style={{ backgroundColor: '#ABAFBC' }}
                                    onClick={() => {
                                        handleClickPrevious();
                                    }}
                                >
                                    <i
                                        className="icon-icon-voltar mr-1"
                                        style={{ fontSize: '18px' }}
                                    />
                                    {' Voltar'}
                                </Button>
                            </div>
                            <div
                                className="pull-right mr-3 mb-1"
                                style={{ fontSize: '18px' }}
                            >
                                <Button
                                    type="button"
                                    role="tablist"
                                    style={{ backgroundColor: '#3073CB' }}
                                    className="boxButtonAvancar"
                                    onClick={() => handleClickNext()}
                                >
                                    Avançar{' '}
                                    <i
                                        className="icon-icon-avancar"
                                        style={{ fontSize: '18px' }}
                                    />
                                </Button>
                                {permiteEdicaoOrcamento() && (
                                    <Button
                                        style={{
                                            backgroundColor: '#3073CB',
                                            height: '45px',
                                        }}
                                        color="primary"
                                        onClick={() => handleClickSend()}
                                        className="boxButton ml-3 mr-3"
                                    >
                                        Solicitar Orçamento{' '}
                                        <i className="icon-icon-ckeck ml-1" />
                                    </Button>
                                )}
                            </div>
                        </div>
                    </form>
                </NavigationSubArea>
                {alertSend}
                {alertMessage}
            </PageContent>
        </>
    );
}
