import React from 'react';
import {
    Label,
} from 'reactstrap';

export interface ITextAreaComponentProps{
    label?: string,
    labelExtra?: string;
    valueLabel?: string;
    innerRef?:   any,
    name?: string,
    value?: string,
    placeholder?: string,
    isShadow?: boolean;
    rows?: number;
    disabled?: boolean;
    onInput: (value: React.FormEventHandler<HTMLInputElement> | undefined) => void;
};


export default function TextAreaComponent({label, labelExtra, valueLabel, innerRef, name, disabled, placeholder, isShadow, rows, onInput}:ITextAreaComponentProps){
    return(
        <div style={{display:valueLabel}} className='my-2'>
            <div className="d-flex justify-content-between">
                <Label style={{ color: "#57607A"}}>{label}</Label>
                <Label style={{ color: "#57607A"}}>{labelExtra}</Label>
            </div>
            <textarea
                ref={innerRef}
                name={name}
                placeholder={placeholder}
                className={isShadow ? "form-control form-control-sm" : "form-control"}
                style={{borderRadius:"10px"}}
                onInput={() => onInput}
                rows={rows}
                disabled={disabled}
            />
        </div>
    );
};
