import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import ItemProduto from './components/ItemProduto';
import webApi from '~/services/webapi';
import './styles.css';
import history from '~/services/history';
import PageContent from '~/components/PageContent';
import Cabecalho from '../../components/Cabecalho/index';
import { situacaoPedidoIndustriaConsts } from "../../consts/situacaoOrcamentoIndustriaConsts";

export default function PedidoEdicao(props) {
    const { state } = props.location;

    if (state.status != situacaoPedidoIndustriaConsts.pedidoAberto) {
        history.push('/pedidos');
    }

    const [filtro, setFiltro] = useState(null);
    const [filtroSegmento, setFiltroSegmento] = useState(null);
    const [itens, setItens] = useState([]);

    function editarPedido() {
        history.push({
            pathname: `/pedido/resumo`,
            state: { ...props.location.state },
        });
    }

    function filtroSelecao(value){
        setFiltroSegmento(value);
    }

    const dados = [
        {
            label: 1,
            name:"Perfil",
        },
        {
            label: 2,
            name:"Componentes",
        },
    ];

    useEffect(() => {
        (async () => {
            const result = await webApi.get('produto', {
                params: {
                    filtro: filtro,
                    tipoProduto: filtroSegmento,
                },
            });
            setItens(result);
        })();
    }, [filtroSegmento, filtro]);

    return (
        <>
            <Cabecalho
                isButton
                iconeBtn={<i className="icon-icon-adc-carrinho" />}
                isAtivo={props.location.state.codCotacao === 0 ? true : false}
                title="Visualizar Itens"
                styles="buttonAvulso"
                onClick={() => editarPedido()}
                isSelect
                label='Filtrar'
                valueLabel="flex"
                data={dados}
                valueSelect={filtroSegmento}
                onChangeSelect={filtroSelecao}
                isSearch
                value={filtro}
                onChange={(e) => setFiltro(e.target.value)}
                iconeColor='#048fc1db'
            />
            <PageContent>
                <Row style={{borderBottom:'1px solid #ABAFBC'}}>
                    <Col className='title-avulso'>
                        <p>Catalogo de <strong>Materiais avulsos</strong></p>
                    </Col>
                </Row>
                <Container fluid className="p-4">
                    <div className="ml-4 mb-2 mt-4" style={{ fontSize: '12px' }}>
                        {itens.length >= 100 &&
                            '* Exibindo 100 registros mais recentes'}
                    </div>
                    <div className="d-flex flex-row flex-wrap">
                        <>
                            {itens?.map((item, index) => (
                                <ItemProduto key={index} item={item} {...props} />
                            ))}
                        </>
                    </div>
                </Container>
            </PageContent>
        </>
    );
}


