import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import {
    Button,
    FormFeedback,
    Row,
    Col,
    Label,
    Input,
    Badge,
} from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import CpfCnpj from '@react-br-forms/cpf-cnpj-mask';
import * as Yup from 'yup';
import PageContent from '~/components/PageContent';
import Switch from 'react-bootstrap-switch';
import { DataTable } from '~/components/DataTable';
import UserFormIndustry from './Usuarios/Formulario';
import RelatorioIndustria from './Relatorios/RelatorioIndustria';
import { useNotifyContext } from '~/hooks/useNotifyContext';
import {
    getIndustria,
    pustCadastroCliente,
    postCadastroCliente,
    getListaUsuario,
    getListaExtranetIntegracoes,
} from '../../../services/modules/Industria/cliente.js';
import SelectComponent from './../../../components/Select/index';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { getListaVendedores } from '../../../services/modules/Vendedor/index';
import webapi from '~/services/webapi';
import { handleZipCode } from '../../../shared/function/index.ts';

const schema = Yup.object({
    bR_RazaoSocial: Yup.string().required('Campo obrigatório'),
    bR_NomeFantasia: Yup.string().required('Campo obrigatório'),
    bR_CNPJ: Yup.string().required('Campo obrigatório'),
    address: Yup.object({
        street: Yup.string().required('Campo obrigatório'),
        district: Yup.string().required('Campo obrigatório'),
        number: Yup.string().required('Campo obrigatório'),
        state: Yup.string().required('Campo obrigatório'),
        city: Yup.string().required('Campo obrigatório'),
    }),
    contact: Yup.object({
        email: Yup.string().email('Formato de e-mail inválido').nullable(),
    }),
});

const defaultValues = {
    industryId: 0,
    bR_CNPJ: '',
    bR_RazaoSocial: '',
    bR_NomeFantasia: '',
    address: {
        district: '',
        number: '',
        street: '',
        state: '',
        stateCode: '',
        city: '',
        cityCode: '',
    },
    contact: {
        name: '',
        email: '',
        cellPhone: '',
    },
    vendedorCliente: 0,
    apportionmentByItem: false,
    active: false,
};

export default function IndustriaEdit() {
    const { industryId } = useParams();
    const history = useHistory();
    const { state } = useAuthContext();
    const notificacao = useNotifyContext();
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenRelatorio, setIsOpenRelatorio] = useState(false);
    const [active, setActive] = useState(false);
    const [rateio, setRateio] = useState(false);
    const [canAdd, setCanAdd] = useState(false);
    const [license, setLicense] = useState('');
    const [licenseIcon, setLicenseIcon] = useState('');
    const [vendedores, setVendedores] = useState([]);
    const [vendedor, setVendedor] = useState('');
    const [alertMessage, setAlertMessage] = useState(null);

    const {
        register,
        triggerValidation,
        getValues,
        setValue,
        errors,
        control,
        watch,
    } = useForm({
        mode: 'onChange',
        defaultValues,
        validationSchema: schema,
    });

    const handleClickSave = async () => {
        const valid = await triggerValidation();

        if (valid) {
            const industryData = {
                codVendedor: getValues('vendedorCliente'),
                codIndustria: Number(industryId),
                razaoSocial: getValues('bR_RazaoSocial'),
                nomeFantasia: getValues('bR_NomeFantasia'),
                cnpj: getValues('bR_CNPJ'),
                address: {
                    logradouro: getValues('address.street'),
                    numero: getValues('address.number'),
                    bairro: getValues('address.district'),
                    cidade: getValues('address.city'),
                    codigoCidade: 0,
                    cep: getValues('address.postalCode'),
                    estado: getValues('address.state'),
                    codigoEstado: 0,
                    complemento: '',
                },
                contact: {
                    nome: getValues('contact.name'),
                    email: getValues('contact.email'),
                    celular: getValues('contact.cellPhone'),
                    contatoTelefoneAlternativo: getValues('contact.cellPhone'),
                },
                rateioPorItem: rateio,
                ativo: active,
            };
            try {
                if (industryId) {
                    await pustCadastroCliente(industryId, industryData);
                } else {
                    const result = await postCadastroCliente(industryData);
                    history.push(`/industrias/${result.codIndustria}/editar`);
                }
                notificacao.sucesso('Dados salvos com sucesso');
            } catch (error) {
                notificacao.respostaHttp(error);
            }
        }
    };

    const handleNewUser = () => {
        setIsOpen(true);
        setSelectedUser(null);
    };

    const handleToogleModal = () => {
        setIsOpen(!isOpen);
        setSelectedUser(null);
    };

    const handleReloadTable = () => {
        setReload(!reload);
    };

    const handleChange = (value) => {
        if (industryId && vendedor != value) {
            handleTrocarVendedor(vendedor, value);
        } else {
            setValue('vendedorCliente', value);
        }
    };

    const getUsers = useCallback(() => {
        setLoading(true);
        (async () => {
            if (industryId) {
                try {
                    const result = await getListaUsuario(industryId);
                    if (result && result.length > 0) {
                        setUsers(result);
                    } else {
                        setUsers([]);
                    }
                } catch (err) {
                    setUsers([]);
                }
            }
            setLoading(false);
        })();
    }, [reload]);

    const handleTrocarVendedor = (vendedor, value) => {
        setAlertMessage(
            <SweetAlert
                show
                info
                title="Deseja alterar o vendedor?"
                cancelBtnText="Não"
                confirmBtnText="Sim"
                confirmBtnBsStyle="success"
                showCancel
                onConfirm={() => {
                    setValue('vendedorCliente', value);
                    setAlertMessage(null);
                }}
                onCancel={() => {
                    setValue('vendedorCliente', vendedor);
                    setAlertMessage(null);
                }}
            >
                {' '}
                Trocando o vendedor desse cliente, as propostas enviadas,
                fechadas, recusadas, serão transferidas para o novo vendedor,
                deseja continuar?{' '}
                <strong style={{ color: 'red' }}>Click em salvar!</strong>
            </SweetAlert>
        );
    };
    const handleNovoVendedor = () => {
        setAlertMessage(
            <SweetAlert
                show
                info
                title="Não existe vendedor cadastrado!"
                cancelBtnText="Não"
                confirmBtnText="Sim"
                confirmBtnBsStyle="success"
                showCancel
                onConfirm={() => history.push('/vendedores/novo')}
                onCancel={() => history.push('/industrias')}
            >
                {' '}
                Deseja cadastrar?
            </SweetAlert>
        );
    };

    const handleOpenUserForm = (userId) => {
        if (userId) {
            setIsOpen(true);
            setSelectedUser(userId);
        }
    };

    function loadForm(payload) {
        const listaVendedor = payload.vendedores.map((item) => ({
            label: item.codVendedor,
            name: item.nome,
        }));
        setVendedores(listaVendedor);
        const {
            cnpj,
            razaoSocial,
            nomeFantasia,
            endereco,
            contato,
            ativo,
            apportionmentByItem,
            vendedor,
        } = payload;

        setValue('bR_CNPJ', cnpj);
        setValue('bR_RazaoSocial', razaoSocial);
        setValue('bR_NomeFantasia', nomeFantasia);
        setValue('address.street', endereco.logradouro);
        setValue('address.number', endereco.numero);
        setValue('address.district', endereco.bairro);
        setValue('address.postalCode', endereco.cep);
        setValue('address.city', endereco.cidade);
        setValue('address.state', endereco.estado);
        setValue('contact.name', contato.nome);
        setValue('contact.email', contato.email);
        setValue('contact.cellPhone', contato.celular);
        setValue('vendedorCliente', vendedor?.codVendedor ?? 0);
        setValue('active', ativo);
        setValue('apportionmentByItem', apportionmentByItem);
        setValue('apportionmentByItem', apportionmentByItem);
        setActive(ativo);
        setRateio(apportionmentByItem);
        setVendedor(vendedor?.codVendedor);
    }

    const handleChangePostalCode = async (e) => {
        const postalCode = e.replace(/\s/g, '');
        if (postalCode.length === 9) {
            const result = await webapi.get(
                `endereco/endereco-via-cep/${postalCode}`
            );
            const { logradouro, bairro, uf, cidade } = result;
            if (logradouro) {
                setValue('address.street', logradouro);
                setValue('address.district', bairro);
                setValue('address.state', uf);
                setValue('address.city', cidade);
            }
        }
    };

    const usersColumns = useMemo(() => [
        {
            Header: 'Nome',
            accessor: 'nome',
        },
        {
            Header: 'Email',
            accessor: 'email',
        },
        {
            Header: 'Código Revenda',
            accessor: 'linkCodigoRevenda',
        },
        {
            Header: 'Celular',
            accessor: 'celular',
        },

        {
            Header: 'Status',
            accessor: 'ativo',
            Cell: ({ row }) =>
                row.values.ativo ? (
                    <Badge color="success">Ativo</Badge>
                ) : (
                    <Badge color="danger">Inativo</Badge>
                ),
        },
        {
            Header: 'Opções',
            accessor: 'options',
            disableSortBy: true,
            Cell: ({ row }) => {
                return (
                    <Button
                        color="info"
                        onClick={() =>
                            handleOpenUserForm(row.original.codUsuario)
                        }
                    >
                        Editar
                    </Button>
                );
            },
        },
    ]);

    useEffect(() => {
        const fetchDataExtranet = async () => {
            const result = await getListaExtranetIntegracoes();
            const { available, licences, status } = result;

            if (available > 0) {
                setLicense(`${status.title} ${available}/${licences}`);
            } else if (available === 0) {
                setLicense(status.title);
            }
            setLicenseIcon(status.info);
            setCanAdd(available > 0);
        };
        fetchDataExtranet();
    }, []);

    useEffect(() => {
        async function dataVendedores() {
            try {
                const dataVendedor = await getListaVendedores(state.codRevenda);
                if (dataVendedor.length > 0) {
                    const listaVendedor = dataVendedor.map((item) => ({
                        label: item.codVendedor,
                        name: item.nome,
                    }));
                    setVendedores(listaVendedor);
                } else {
                    handleNovoVendedor();
                }
            } catch (error) {
                notificacao.respostaHttp(error);
            }
        }
        async function loadData() {
            try {
                const result = await getIndustria(industryId);
                loadForm(result);
            } catch (error) {
                notificacao.respostaHttp(error);
            }
        }
        !industryId && dataVendedores();
        !!industryId && loadData();
    }, []);

    return (
        <>
            <h3 style={{ color: '#66615b', marginLeft: 30 }}>
                {industryId ? 'Editar Cliente' : 'Cadastrar Cliente'}
            </h3>
            <PageContent>
                <div className="toolbar">
                    <Button
                        className="ml-n1 btn btn-secondary"
                        data-origin-title="Voltar"
                        onClick={() => history.push('/industrias')}
                        style={{ height: '44px' }}
                    >
                        <i className="fas fa-arrow-circle-left mr-2" /> Voltar
                    </Button>

                    <Button
                        className="btn btn-success float-right"
                        data-original-title="Salvar"
                        onClick={() => handleClickSave()}
                        style={{ height: '44px' }}
                    >
                        <i className="fas fa-save mr-2" /> Salvar
                    </Button>
                    <Button
                        className="mr-2 btn btn-secondary float-right"
                        data-original-title="Salvar"
                        onClick={() => setIsOpenRelatorio(true)}
                        disabled={!industryId}
                        style={{ height: '44px' }}
                    >
                        <i className="fas fa-file mr-2" />
                        Liberação de relatórios
                    </Button>
                </div>
                <form>
                    <Row>
                        <Col md="3" className="mt-3">
                            <Label>CNPJ / CPF :</Label>
                            <Controller
                                innerRef={register}
                                control={control}
                                className={
                                    !!errors?.bR_CNPJ?.message
                                        ? 'form-control form-control-sm invalid is-invalid '
                                        : 'form-control form-control-sm'
                                }
                                name="bR_CNPJ"
                                placeholder="CNPJ / CPF"
                                as={CpfCnpj}
                                autoComplete="off"
                            />
                            <FormFeedback invalid>
                                {errors?.bR_CNPJ?.message}
                            </FormFeedback>
                        </Col>

                        <Col md="5" className="mt-3">
                            <Label>Nome / Razão Social:</Label>
                            <Input
                                innerRef={register}
                                type="text"
                                className="form-control form-control-sm"
                                name="bR_RazaoSocial"
                                placeholder="Razão Social"
                                invalid={errors?.bR_RazaoSocial?.message}
                            />
                            <FormFeedback>
                                {errors?.bR_RazaoSocial?.message}
                            </FormFeedback>
                        </Col>
                        <Col md="4" className="mt-3">
                            <Label>Nome Fantasia:</Label>
                            <Input
                                innerRef={register}
                                type="text"
                                className="form-control form-control-sm"
                                control={control}
                                name="bR_NomeFantasia"
                                placeholder="Nome Fantasia"
                                invalid={errors?.bR_NomeFantasia?.message}
                            />
                            <FormFeedback>
                                {errors?.bR_NomeFantasia?.message}
                            </FormFeedback>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="2" className="mt-3">
                            <Label>CEP:</Label>
                            <Input
                                innerRef={register}
                                type="text"
                                className="form-control form-control-sm"
                                invalid={errors?.address?.postalCode?.message}
                                name="address.postalCode"
                                placeholder="..."
                                onChange={(event) =>
                                    handleChangePostalCode(event.target.value)
                                }
                                maxLength={9}
                                onKeyUp={(e) => handleZipCode(e)}
                            />
                            <FormFeedback>
                                {errors?.address?.postalCode?.message}
                            </FormFeedback>
                        </Col>
                        <Col md="4" className="mt-3">
                            <Label>Endereço :</Label>
                            <Input
                                innerRef={register}
                                type="text"
                                className="form-control form-control-sm"
                                invalid={errors?.address?.street?.message}
                                name="address.street"
                                placeholder="..."
                            />
                            <FormFeedback>
                                {errors?.address?.street?.message}
                            </FormFeedback>
                        </Col>
                        <Col md="1" className="mt-3">
                            <Label>Número :</Label>
                            <Input
                                innerRef={register}
                                type="text"
                                className="form-control form-control-sm"
                                invalid={errors?.address?.number?.message}
                                name="address.number"
                                placeholder="Número"
                            />
                            <FormFeedback>
                                {errors?.address?.number?.message}
                            </FormFeedback>
                        </Col>
                        <Col md="2" className="mt-3">
                            <Label>Bairro:</Label>
                            <Input
                                innerRef={register}
                                type="text"
                                className="form-control form-control-sm"
                                invalid={errors?.address?.district?.message}
                                name="address.district"
                                placeholder="Bairro"
                            />
                            <FormFeedback>
                                {errors?.address?.district?.message}
                            </FormFeedback>
                        </Col>
                        <Col md="1" className="mt-3">
                            <Label>Estado:</Label>
                            <Input
                                innerRef={register}
                                type="text"
                                className="form-control form-control-sm"
                                invalid={errors?.address?.state?.message}
                                name="address.state"
                                placeholder="Estado"
                            />
                            <FormFeedback>
                                {errors?.address?.state?.message}
                            </FormFeedback>
                        </Col>
                        <Col md="2" className="mt-3">
                            <Label>Cidade:</Label>
                            <Input
                                innerRef={register}
                                type="text"
                                className="form-control form-control-sm"
                                invalid={errors?.address?.city?.message}
                                name="address.city"
                                placeholder="Cidade"
                            />
                            <FormFeedback>
                                {errors?.address?.city?.message}
                            </FormFeedback>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="3" className="mt-3">
                            <Label>Nome Contato:</Label>
                            <Input
                                innerRef={register}
                                type="text"
                                className="form-control form-control-sm"
                                control={control}
                                name="contact.name"
                                placeholder="Nome"
                            />
                            <FormFeedback>
                                {errors?.contact?.name?.message}
                            </FormFeedback>
                        </Col>
                        <Col md="3" className="mt-3">
                            <Label>E-mail:</Label>
                            <Input
                                innerRef={register}
                                type="text"
                                className="form-control form-control-sm"
                                invalid={errors?.contact?.email?.message}
                                name="contact.email"
                                placeholder="E-mail"
                            />
                            <FormFeedback>
                                {errors?.contact?.email?.message}
                            </FormFeedback>
                        </Col>
                        <Col md="3" className="mt-3">
                            <Label>Telefone:</Label>
                            <Input
                                innerRef={register}
                                type="text"
                                className="form-control form-control-sm"
                                control={control}
                                name="contact.cellPhone"
                                placeholder="Telefone"
                            />
                            <FormFeedback>
                                {errors?.contact?.cellPhone?.message}
                            </FormFeedback>
                        </Col>
                        <Col md="3" className="mt-3">
                            <SelectComponent
                                label="Vendedor:"
                                innerRef={register}
                                name="vendedorCliente"
                                isShadow={true}
                                data={vendedores}
                                onChange={handleChange}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="2" className="mt-3">
                            <Label className="mr-2">
                                <div>Permite Rateio por Item</div>
                                <Switch
                                    className="form-control form-control-sm"
                                    name="apportionmentByItem"
                                    offColor="danger"
                                    offText={
                                        <i className="nc-icon nc-simple-remove" />
                                    }
                                    onColor="success"
                                    onText={
                                        <i className="nc-icon nc-check-2" />
                                    }
                                    onChange={(e) => {
                                        setRateio(e.state.value);
                                    }}
                                    value={rateio}
                                />
                            </Label>
                        </Col>
                        <Col md="2" className="mt-3">
                            <Label className="mr-2">
                                <div>Status do Cliente</div>
                                <Switch
                                    className="form-control form-control-sm"
                                    name="active"
                                    offColor="warning"
                                    offText={
                                        <i className="nc-icon nc-simple-remove" />
                                    }
                                    onColor="success"
                                    onText={
                                        <i className="nc-icon nc-check-2" />
                                    }
                                    onChange={(e) => {
                                        setActive(e.state.value);
                                    }}
                                    value={active}
                                />
                            </Label>
                        </Col>
                    </Row>
                </form>
                <div className="toolbar">
                    <h4>Usuários</h4>
                    <Button
                        className="btn btn-sm btn-success"
                        onClick={handleNewUser}
                        disabled={!industryId || !canAdd}
                    >
                        + Criar Usuário
                    </Button>
                    <span className={`ml-2 text-${licenseIcon}`}>
                        {licenseIcon === 'success' && (
                            <i className="fas fa-check-circle mr-1" />
                        )}
                        {licenseIcon === 'warning' && (
                            <i className="fas fa-minus-circle mr-1" />
                        )}
                        {licenseIcon === 'danger' && (
                            <i className="fas fa-exclamation-triangle mr-1" />
                        )}
                        {license}
                    </span>
                </div>
                <DataTable
                    columns={usersColumns}
                    data={users}
                    loading={loading}
                    reload={reload}
                    fetchData={getUsers}
                    includePagination={false}
                    includeFilter={false}
                    includeTotalRecords={false}
                    includePageSize={false}
                />
                <UserFormIndustry
                    isOpen={isOpen}
                    industryId={industryId}
                    selectedUser={selectedUser}
                    handleCloseModal={handleToogleModal}
                    handleTableReload={handleReloadTable}
                    reload={reload}
                />

                <RelatorioIndustria
                    isOpen={isOpenRelatorio}
                    industryId={industryId}
                    handleCloseModal={() => setIsOpenRelatorio(false)}
                />
            </PageContent>
            {alertMessage}
        </>
    );
}
