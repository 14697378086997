import webApi from 'services/webapi';
import { getErrorsByApi } from 'shared/helpers/getErrorsByApi';
import { ApiResponse } from 'shared/interface/api';
import { IGetOcultarOrcamento } from 'shared/interface/orcamento';

export const ocultarOrcamentos = async (
    codOrcamentoConfiguracao: number,
    ocultarConfiguracao: boolean
) => {
    try {
        await webApi.put(
            `ocultar-configuracao?codOrcamentoConfiguracao=${codOrcamentoConfiguracao}&ocultarConfiguracao=${ocultarConfiguracao}`,
            {
                codOrcamentoConfiguracao,
                ocultarConfiguracao,
            }
        );
    } catch (error: any) {
        getErrorsByApi(
            error,
            'Tivemos problemas internos ao ocultar o orçamento'
        );
    }
};

export const getObterConfigs = async () => {
    try {
        const response: ApiResponse<IGetOcultarOrcamento[]> = await webApi.get(
            '/Obter-Todas-Configuracoes'
        );
        return response;
    } catch (error: any) {
        getErrorsByApi(
            error,
            'Tivemos problemas internos ao obter todas as configurações'
        );
    }
};
