import React from 'react';
import { Label, Col, Button } from 'reactstrap';
import { Action } from '../../enum/index';
import './styles.css';
interface IDadosProps {
    id: number;
    Razao_Social: string;
    Nome_Fantasia: string;
    Contato: string;
    Cidade: string;
    Status: boolean;
    Estado: string;
}

interface IGridProps {
    dados: IDadosProps;
    children: React.ReactNode;
    numBtn: number;
    onEdit: (id: number) => void;
    onCopy: (id: number) => void;
    onTrash: (id: number) => void;
}

export default function GridData({
    dados,
    children,
    numBtn,
    onEdit,
    onCopy,
    onTrash,
}: IGridProps) {
    return (
        <>
            <div className="row rowList my-2 ">
                <Col
                    lg={
                        numBtn === Action.zeroBtn
                            ? 12
                            : numBtn === Action.umBtn
                            ? 11
                            : 10
                    }
                    className="px-0"
                >
                    {children}
                </Col>
                {numBtn !== Action.zeroBtn && (
                    <>
                        <Col
                            lg={
                                numBtn === Action.zeroBtn
                                    ? 0
                                    : numBtn === Action.umBtn
                                    ? 1
                                    : 2
                            }
                            className="px-4 px-lg-0"
                        >
                            <Label>Ações</Label>
                            <div className="rowBtn">
                                {(numBtn === Action.umBtn ||
                                    numBtn === Action.doisBtn ||
                                    numBtn === Action.tresBtn) && (
                                    <Button
                                        onClick={() => onEdit(dados.id)}
                                        className="btn btn-link m-0 mr-2"
                                    >
                                        <i className="fa fa-pen" />
                                    </Button>
                                )}
                                {numBtn === Action.tresBtn && (
                                    <Button
                                        onClick={() => onCopy(dados.id)}
                                        className="btn btn-link m-0 mr-2"
                                    >
                                        <i className="fa fa-copy" />
                                    </Button>
                                )}
                                {(numBtn === Action.doisBtn ||
                                    numBtn === Action.tresBtn ||
                                    numBtn === Action.excluirBtn) && (
                                    <Button
                                        onClick={() => onTrash(dados.id)}
                                        className="btn btn-link m-0"
                                    >
                                        <i className="fa fa-trash" />
                                    </Button>
                                )}
                            </div>
                        </Col>
                    </>
                )}
            </div>
        </>
    );
}
