import React, { createContext, useContext, useState, ReactNode } from 'react';
import authApi from '../services/authapi';
import webapi from '../services/webapi';
import history from '../services/history';

type AuthProps = {
    children: ReactNode;
};

type UserStoreProps = {
    catalogo: Number;
    codIndustria: Number;
    codPerfilUsuario: Number;
    codRevenda: Number;
    codTipoUsuario: Number;
    codUsuario: Number;
    nome: string;
    quantidadeNotificacoes: Number;
    token: string;
    autenticado: boolean;
    logo: any;
};

type UserProps = {
    email: string;
    password: string;
    inativarSessaoAnterior?: boolean;
};

type AuthContextProps = {
    autenticado: boolean;
    state: UserStoreProps;
    autenticar: ({ email, password }: UserProps) => void;
    signOut: () => void;
    ehUsuarioRevenda: () => boolean;
    ehUsuarioIndustria: () => boolean;
    ehUsuarioAdministrador: () => boolean;
    ehUsuarioVendedor: () => boolean;
    atualizarQuantidadeNotificacoes: (quantidadeNotificacoes: Number) => void;
};

const AuthContext = createContext({} as AuthContextProps);

const AuthContextProvider = ({ children }: AuthProps) => {

    const [state, setState] = useState<UserStoreProps>(() => {
        const storage = JSON.parse(
            sessionStorage.getItem('@CemMob:AuthContext') as string
        );

        if (storage) {
            webapi.defaults.headers.common[
                'Authorization'
            ] = `Bearer ${storage.token}`;

            return { ...storage };
        }

        return {} as UserStoreProps;
    });

    function ehUsuarioAdministrador() {
        return state.codPerfilUsuario === 1;
    }

    function ehUsuarioRevenda() {
        return state.codTipoUsuario === 2;
    }

    function ehUsuarioIndustria() {
        return state.codTipoUsuario === 3;
    }

    function ehUsuarioVendedor() {
        return state.codTipoUsuario === 4;
    }

    function atualizarQuantidadeNotificacoes(quantidade: Number) {
        setState({ ...state, quantidadeNotificacoes: quantidade });
    }

    const autenticar = async ({ email, password, inativarSessaoAnterior }: UserProps) => {
        const request: UserStoreProps = await authApi.post(
            'autenticacao/cemmob',
            {
                email,
                password,
                inativarSessaoAnterior,
            }
        );

        const {
            codIndustria,
            codPerfilUsuario,
            codRevenda,
            codTipoUsuario,
            codUsuario,
            nome,
            token,
            catalogo,
            logo,
        } = request;

        webapi.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        setState({
            ...state,
            catalogo: catalogo,
            codIndustria: codIndustria,
            codPerfilUsuario: codPerfilUsuario,
            codRevenda: codRevenda,
            codTipoUsuario: codTipoUsuario,
            codUsuario: codUsuario,
            logo: logo,
            nome: nome,
            token: token,
            autenticado: true,
        });

        sessionStorage.setItem(
            '@CemMob:AuthContext',
            JSON.stringify({
                ...state,
                catalogo: catalogo,
                codIndustria: codIndustria,
                codPerfilUsuario: codPerfilUsuario,
                codRevenda: codRevenda,
                codTipoUsuario: codTipoUsuario,
                codUsuario: codUsuario,
                nome: nome,
                token: token,
                autenticado: true,
                logo: logo,
            })
        );

        if (codTipoUsuario === 4) {
            history.push('/orcamentoVendedor');
        }else if(codTipoUsuario === 2) {
            history.push('/industrias');
        }else {
            history.push('/dashboard');
        }
    };

    function signOut() {
        sessionStorage.removeItem('@CemMob:AuthContext');
        setState({} as UserStoreProps);
        webapi.post('/Sessoes/Sair');
    }

    return (
        <AuthContext.Provider
            value={{
                autenticado: state.autenticado,
                state,
                autenticar,
                signOut,
                ehUsuarioRevenda,
                ehUsuarioIndustria,
                ehUsuarioAdministrador,
                atualizarQuantidadeNotificacoes,
                ehUsuarioVendedor,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

function useAuthContext() {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error('hook must be used with an Provider');
    }

    return context;
}

export { AuthContextProvider, useAuthContext };
