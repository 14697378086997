import React, { useState, useEffect, useRef, useCallback } from 'react';
import { PaperScope, Tool, Path, Raster, PointText } from 'paper';
import {
    Button,
    ButtonDropdown,
    DropdownMenu,
    DropdownToggle,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Col,
    Card,
    CardImg,
    CardBody,
} from 'reactstrap';
import './styles.css';

export default function Desenhador({
    image,
    setImage,
    readOnly,
    setImageChanged,
}) {
    const [isOpen, setIsOpen] = useState(false);
    const [images, setImages] = useState([]);

    const canvas = useRef();
    const paper = useRef();
    const paperRaster = useRef();
    const paperStyle = useRef();
    const paperPath = useRef();
    const paperStroke = useRef();
    const papperPaths = useRef();
    const papperPathSelect = useRef();

    const [dropdownStroke, setDropdownStroke] = useState(false);
    const [dropdownColor, setDropdownColor] = useState(false);
    const [cursorStyle, setCursorStyle] = useState('crosshair');

    const toggleStroke = () => setDropdownStroke(!dropdownStroke);
    const toggleColor = () => setDropdownColor(!dropdownColor);

    function handleClickStyle(s) {
        if (s === 'erase') setCursorStyle('cell');
        else if (s === 'move') setCursorStyle('default');
        else setCursorStyle('crosshair');
        paperStyle.current = s;
    }

    function handleClickUndo() {
        const element = papperPaths.current[papperPaths.current.length - 1];
        if (element !== undefined) {
            element.remove();
            papperPaths.current.pop();
        }
    }

    const handleClickSelectImage = useCallback((resource) => {
        const getBase64FromUrl = async (url) => {
            const data = await fetch(url);
            const blob = await data.blob();
            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsBinaryString(blob);
                reader.onloadend = function () {
                    const base64data = reader.result;
                    resolve(base64data);
                };
            });
        };

        getBase64FromUrl(resource).then((m) => {
            setImage(btoa(m));
            setIsOpen(false);
        });
    }, []);

    function handleClickStroke(s) {
        paperStroke.current = { ...paperStroke.current, width: s };
    }

    function handleClickStrokeColor(s) {
        paperStroke.current = { ...paperStroke.current, color: s };
    }

    const readAsBinaryString = (e) => {
        const file = e.target.files[0];

        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                resolve(event.target.result);
            };

            reader.onerror = (err) => {
                reject(err);
            };

            reader.readAsBinaryString(file);
        });
    };

    function raster() {
        if (paperRaster.current != null) paperRaster.current.clear();
        paperRaster.current = new Raster('raster');
        paperRaster.current.position = paper.current.view.center;
    }

    const handleChangeImage = (e) => {
        readAsBinaryString(e).then((buffer) => {
            setImage(btoa(buffer));
        });
    };

    useEffect(() => {
        paper.current = new PaperScope();

        canvas.current = document.getElementById('croqui');

        paper.current.setup(canvas.current);

        paperStyle.current = '';

        paperStroke.current = { color: 'black', width: 1.5 };

        papperPaths.current = [];

        paper.current.project.clear();

        paper.current.view.draw();

        raster();

        const background = new Path.Rectangle({
            point: [0, 0],
            size: [
                paper.current.view.size.width,
                paper.current.view.size.height,
            ],
            strokeColor: 'white',
            fillColor: 'white',
        });

        background.sendToBack();

        const tool = new Tool();

        tool.onMouseDown = (event) => {
            setImageChanged(true);

            if (paperStyle.current === 'move') {
                return;
            }

            if (paperStyle.current === 'text') {
                paperPath.current = new PointText({
                    point: event.point,
                    fillColor: 'black',
                    fontFamily: 'Courier New',
                    fontWeight: 'bold',
                    content: 'Teste',
                    fontSize: 25,
                    rotation: 90,
                });
                paperPath.current.removeOnDrag();
            } else {
                paperPath.current = new Path({
                    segments: [event.point],
                    strokeColor: paperStroke.current.color,
                    strokeWidth: paperStroke.current.width,
                });
                paperPath.current.add(event.point);
            }
        };

        tool.onMouseDrag = (event) => {
            if (paperStyle.current === 'line') {
                paperPath.current = new Path.Line({
                    from: event.downPoint,
                    to: event.point,
                    strokeColor: paperStroke.current.color,
                    strokeWidth: paperStroke.current.width,
                });
            }

            if (paperStyle.current === 'erase') {
                paperPath.current.strokeColor = 'white';
                paperPath.current.strokeWidth = 10;
                paperPath.current.add(event.point);
            }

            if (paperStyle.current === 'circle') {
                paperPath.current = new Path.Circle({
                    center: event.downPoint,
                    radius: event.downPoint.subtract(event.point).length,
                    fillColor: null,
                    strokeColor: paperStroke.current.color,
                    strokeWidth: paperStroke.current.width,
                });
            }

            if (paperStyle.current === 'circle-solid') {
                paperPath.current = new Path.Circle({
                    center: event.downPoint,
                    radius: event.downPoint.subtract(event.point).length,
                    fillColor: paperStroke.current.color,
                    strokeColor: paperStroke.current.color,
                    strokeWidth: paperStroke.current.width,
                });
            }

            if (paperStyle.current === 'rectangle') {
                paperPath.current = new Path.Rectangle({
                    from: event.downPoint,
                    to: event.point,
                    fillColor: 'white',
                    strokeColor: paperStroke.current.color,
                    strokeWidth: paperStroke.current.width,
                });
            }

            if (paperStyle.current === 'rectangle-solid') {
                paperPath.current = new Path.Rectangle({
                    from: event.downPoint,
                    to: event.point,
                    strokeColor: paperStroke.current.color,
                    strokeWidth: paperStroke.current.width,
                    fillColor: paperStroke.current.color,
                });

                paperPath.current.onClick = () => {
                    papperPathSelect.current = paperPath.current;
                };
            }

            if (
                paperStyle.current === 'free' ||
                paperStyle.current === 'erase'
            ) {
                paperPath.current.add(event.point);
            } else paperPath.current.removeOnDrag();
        };

        tool.onMouseUp = () => {
            if (paperStyle.current !== 'move') {
                papperPaths.current.push(paperPath.current);
            }
        };
    }, [image]);

    useEffect(() => {
        // async function fetchData() {
        //     const request = await api.get('budget-requests/images');
        //     setImages(request.data);
        // }
        // fetchData();
    }, []);

    return (
        <>
            <input
                accept="image/*"
                type="file"
                hidden
                id="select-image"
                onChange={(e) => handleChangeImage(e)}
            />
            {readOnly && (
                <div className="row">
                    <div className="col-md-6 col-lg-7 col-xl-10 ml-md-auto mr-md-auto iconesDesenhador offset-md-1 text-center">
                        <Button
                            className=" ml-xl-n2 mr-xl-2"
                            outline
                            color="secondary"
                            onClick={() => handleClickStyle('free')}
                            title="Livre"
                        >
                            <i className="icon-icon-edita fa-lg" />
                        </Button>
                        <Button
                            className="mr-xl-2"
                            outline
                            color="secondary"
                            onClick={() => handleClickStyle('rectangle')}
                            title="Retângulo"
                        >
                            <i className="far fa-square fa-lg" />
                        </Button>
                        <Button
                            className="mr-xl-2"
                            outline
                            color="secondary"
                            onClick={() => handleClickStyle('rectangle-solid')}
                            title="Retângulo Sólido"
                        >
                            <i className="fas fa-square fa-lg" />
                        </Button>
                        <Button
                            className="mr-xl-2"
                            outline
                            color="secondary"
                            onClick={() => handleClickStyle('circle')}
                            title="Círculo"
                        >
                            <i className="far fa-circle fa-lg" />
                        </Button>
                        <Button
                            className="mr-xl-2"
                            outline
                            color="secondary"
                            onClick={() => handleClickStyle('circle-solid')}
                            title="Círculo Sólido"
                        >
                            <i className="fas fa-circle fa-lg" />
                        </Button>
                        <Button
                            className="mr-xl-2"
                            outline
                            color="secondary"
                            onClick={() => handleClickStyle('line')}
                            title="Linha"
                        >
                            <i className="fas fa-minus fa-lg" />
                        </Button>

                        {/* 6 */}

                        <label
                            className="btn btn-secondary mr-xl-2"
                            htmlFor="select-image"
                            title="Incluir Figura"
                        >
                            <i className="far fa-image fa-lg" />
                        </label>
                        <Button
                            className="mr-xl-2"
                            outline
                            color="secondary "
                            onClick={() => handleClickUndo()}
                            title="Desfazer"
                        >
                            <i className="fas fa-undo fa-lg" />
                        </Button>
                        <Button
                            className="mr-xl-2"
                            outline
                            color="secondary "
                            onClick={() => setIsOpen(true)}
                            title="Importar Desenho"
                        >
                            <i className="fas fa-cloud-upload-alt fa-lg" />
                        </Button>
                        <Button
                            className="mr-xl-2"
                            outline
                            color="secondary "
                            onClick={() => handleClickStyle('erase')}
                            title="Borracha"
                        >
                            <i className="fas fa-eraser fa-lg" />
                        </Button>
                        <ButtonDropdown
                            className="mr-xl-2"
                            isOpen={dropdownStroke}
                            toggle={toggleStroke}
                            title="Espessura da Linha"
                        >
                            <DropdownToggle caret color="secondary">
                                <i className="fas fa-grip-lines fa-lg" />
                            </DropdownToggle>
                            <DropdownMenu className="mr-xl-2">
                                <DropdownItem
                                    onClick={() => handleClickStroke(1.5)}
                                >
                                    1
                                </DropdownItem>
                                <DropdownItem
                                    onClick={() => handleClickStroke(3)}
                                >
                                    2
                                </DropdownItem>
                                <DropdownItem
                                    onClick={() => handleClickStroke(5)}
                                >
                                    4
                                </DropdownItem>
                            </DropdownMenu>
                        </ButtonDropdown>
                        <ButtonDropdown
                            isOpen={dropdownColor}
                            toggle={toggleColor}
                            title="Cor da Linha"
                        >
                            <DropdownToggle caret color="secondary">
                                <i className="icon-icon-cor fa-lg" />
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem
                                    onClick={() =>
                                        handleClickStrokeColor('black')
                                    }
                                >
                                    Preto
                                </DropdownItem>
                                <DropdownItem
                                    onClick={() =>
                                        handleClickStrokeColor('red')
                                    }
                                >
                                    Vermelho
                                </DropdownItem>
                                <DropdownItem
                                    onClick={() =>
                                        handleClickStrokeColor('blue')
                                    }
                                >
                                    Azul
                                </DropdownItem>
                                <DropdownItem
                                    onClick={() =>
                                        handleClickStrokeColor('green')
                                    }
                                >
                                    Verde
                                </DropdownItem>
                            </DropdownMenu>
                        </ButtonDropdown>
                    </div>
                </div>
            )}
            <div className="row">
                <img
                    id="raster"
                    src={`data:image/jpeg;base64,${image}`}
                    style={{ display: 'none' }}
                    alt=""
                />
                <div
                    id="container"
                    className="col-md-10 offset-md-1 text-left "
                >
                    <canvas
                        className="styleDesenhador"
                        id="croqui"
                        height="480px"
                        width="880"
                        style={{
                            border: '2px',
                            borderStyle: 'solid',
                            cursor: cursorStyle,
                            borderColor: '#E4E4E4',
                        }}
                    />
                </div>
            </div>
            <Modal isOpen={isOpen} size="xl" scrollable>
                <ModalHeader>
                    <button
                        type="button"
                        className="close"
                        onClick={() => setIsOpen(false)}
                    >
                        <i className="nc-icon nc-simple-remove" />
                    </button>
                    <span className="title title-up">
                        <span> Selecionar Imagem</span>
                    </span>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        {images.map((e) => (
                            <Col md={3} key={Math.random()}>
                                <Card>
                                    <CardImg
                                        top
                                        width="100%"
                                        src={e}
                                        alt="Card image cap"
                                    />
                                    <CardBody>
                                        <Button
                                            block
                                            onClick={() =>
                                                handleClickSelectImage(e)
                                            }
                                        >
                                            Selecionar
                                        </Button>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <div className="left-side " />
                    <div className="right-side">
                        <button
                            className="btn pull-right"
                            role="tablist"
                            onClick={() => setIsOpen(false)}
                        >
                            Fechar
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
}
