import React from 'react';
import styled from 'styled-components';

const FieldErrorMessage = styled.div`
    display: block !important;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
`;

export default function FieldValidation({ error }) {
    return (
        <>{!!error && <FieldErrorMessage>{error.message}</FieldErrorMessage>}</>
    );
}
