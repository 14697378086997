export const  formatMoneyMask = (amount: number) => {
    return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    }).format(amount || 0);
};

export function removeMoneyMask(money: string): number {
    const value = extractNumber(money);
	return Number(value) / 100;
}

export function formatMoney(amount: number) {
    const decimal = amount.toFixed(2).replace(".", ",");
    const regex = "\\d(?=(\\d{3})+\\D)";
    return decimal.replace(new RegExp(regex, "g"), "$&.");
}

export const moneyMask = (amount?: string, prefix = "") => {
	if (!amount) return "";
	const value = Number(extractNumber(amount)) / 100;
	return prefix + formatMoney(value);
};

export const extractNumber = (value: string): string => {
	return value.replace(/[^0-9]/g, "");
};

export const formtJson = (value: any) => {
    return JSON.parse(value);
};

export function useResponsive() {
    var largura = window.screen.width;

    const isMobile = largura <= 430;
    const isTablet = largura >= 768 && largura <= 1023;
    const isDesktop = largura >= 1024 && largura <= 1445;
    const isScreenBig = largura > 1446;

    return {
		isMobile,
		isTablet,
		isDesktop,
        isScreenBig,
	}
};

export const handleZipCode = (event:any) => {
    let input = event.target
    input.value = zipCodeMask(input.value)
  }

export const zipCodeMask = (value:any) => {
    if (!value) return ""
    value = value.replace(/\D/g,'')
    value = value.replace(/(\d{5})(\d)/,'$1-$2')
    return value
  }
