import React, { useEffect, useState } from 'react';
import webApi from '~/services/webapi';
import { Row, Col } from 'reactstrap';
import PageContent from '~/components/PageContent';
import CabecalhoEgShop from '../../components/Cabecalho/CabecalhoEgShop.tsx';
import Modal from '../../components/Modal';
import FileUploadXLSX from 'components/FileUploadXLSX';
import { useNotifyContext } from '~/hooks/useNotifyContext';
import { useFileUploadXLSX } from 'components/FileUploadXLSX/hooks/useFileUploadXLSX';
import GridData from '../../components/GridData';

export default function TabelaPreco() {
    const [tabelas, setTabelas] = useState([]);
    const [isModal, setIsModal] = useState(false);

    const { handleFileChange, handleReadFile, clearFile, error, selectedFile } =
        useFileUploadXLSX();

    const notificacao = useNotifyContext();

    useEffect(() => {
        getTabelas();
    }, []);

    function validateFileContent(data) {
        const regex = /^-?\d+(.\d+)?$/;

        return data.every(
            (item) =>
                item[0] !== null &&
                item[1] !== null &&
                item[2] !== null &&
                regex.test(item[2])
        );
    }

    async function onFileSave() {
        const data = await handleReadFile();

        if (!data.length) {
            notificacao.erro('Não foi possível ler o arquivo');
            return;
        }

        data.shift();

        if (!data.length) {
            notificacao.erro('Arquivo inválido');
            return;
        }

        const isValid = validateFileContent(data);

        if (!isValid) {
            notificacao.erro('Arquivo inválido');
            return;
        }

        const payload = data.map((item) => ({
            codigo: item[0],
            codcor: item[1].toString(),
            preco: Number(item[2].toString().replace(',', '.')),
        }));

        try {
            await webApi.post('/ArquivosExcel/salvarExcel', {
                nomeArquivo: selectedFile.name.split('.')[0],
                conteudo: payload,
            });
        } catch {
            notificacao.erro('Não foi possível salvar o arquivo');
            return;
        }

        handleModal();
        clearFile();
        getTabelas();
    }

    async function getTabelas() {
        const response = await webApi.get('/ArquivosExcel/obterExcel');
        setTabelas(response);
    }

    function handleModal() {
        setIsModal((prevState) => !prevState);
    }

    async function handleDelete(id) {
        try {
            await webApi.delete(`/ArquivosExcel/deletarExcel/${id}`);
            notificacao.sucesso('Item excluido com sucesso');
            getTabelas();
        } catch (error) {
            notificacao.erro(error);
        }
    }

    return (
        <div className="col-12">
            <CabecalhoEgShop
                title="Lista de Tabela de Preço - Itens Avulsos"
                onRegisterNew={() => handleModal()}
                iconBtn={<i className="fas fa-tasks" />}
                descricaoBtn="Cadastrar Nova Tabela"
            />
            <div className="m-0 col-12">
                <PageContent className="m-0 container">
                    {tabelas.length === 0 ? (
                        <div className="cardList my-3">
                            <strong>Nenhum Registro</strong>
                        </div>
                    ) : (
                        <>
                            <Row className="rowRecord">
                                {' '}
                                <p className="m-0">
                                    Total de Registro: {tabelas.length}
                                </p>
                            </Row>
                            <div>
                                {tabelas.map((item, index) => (
                                    <div key={Number(index)}>
                                        <GridData
                                            dados={item}
                                            onTrash={handleDelete}
                                            registro={tabelas.length}
                                            numBtn={4}
                                        >
                                            <Col lg={11}>
                                                <label>Nome</label>
                                                <p>{item.nome}</p>
                                            </Col>
                                        </GridData>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </PageContent>
            </div>
            <Modal
                open={isModal}
                size="md"
                title="Upload tabela de preços"
                onClose={() => {
                    handleModal();
                    clearFile();
                }}
                onSave={onFileSave}
                isButton
            >
                <FileUploadXLSX
                    onChange={handleFileChange}
                    filename={selectedFile?.name || ''}
                    error={error}
                />
            </Modal>
        </div>
    );
}
