import React, { useState, useMemo } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import webapi from '~/services/webapi';
import { DataTable } from '~/components/DataTable';

export default function BuscaPorVidro({
    isOpen,
    handleCloseModal,
    setSelectedGlass,
}) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);

    const handleClickSelectedGlass = (row) => {
        setSelectedGlass(row.original);
        handleCloseModal();
    };

    const columns = useMemo(
        () => [
            {
                Header: '',
                id: 'selected',
                disableSortBy: true,
                Cell: ({ row }) => (
                    <div className="form-check text-center">
                        <label className="form-check-label">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                onClick={() => handleClickSelectedGlass(row)}
                            />
                            <span className="form-check-sign" />
                        </label>
                    </div>

                ),
            },
            {
                Header: 'Código',
                accessor: 'code',
            },
            {
                Header: 'Descrição',
                accessor: 'description',
            },
            {
                Header: 'Referência',
                accessor: 'reference',
            },
        ],
        []
    );

    const getItems = React.useCallback(
        ({ pageSize, pageIndex, sort, desc, globalFilter }) => {
            setLoading(true);
            (async () => {
                const result = await webapi.post(`/vidros`, {
                    page: pageIndex,
                    filter: globalFilter,
                    take: pageSize,
                    sort,
                    desc,
                });
                const { records, total } = result;
                setData(records);
                setTotalRecords(total);
                setPageCount(Math.ceil(total / pageSize));
                setLoading(false);
            })();
        },
        []
    );

    return (
        <Modal isOpen={isOpen} toggle={() => handleCloseModal()} size="xl">
            <ModalHeader toggle={() => handleCloseModal()}>
                <span className="title title-up">Escolher Item</span>
            </ModalHeader>
            <ModalBody>
                <DataTable
                    columns={columns}
                    data={data}
                    fetchData={getItems}
                    loading={loading}
                    pageCount={pageCount}
                    total={totalRecords}
                    includePageSize={false}
                    defaultPageSize={15}
                />
            </ModalBody>
            <ModalFooter>
                <div className="right-side">
                    <button
                        type="button"
                        className="boxButton btn btn-link"
                        onClick={() => handleCloseModal()}
                    >
                        Cancelar
                    </button>
                </div>
            </ModalFooter>
        </Modal>
    );
}
