import React from 'react';
import { Modal, ModalHeader, ModalFooter, Button, ModalBody, Row, Col } from 'reactstrap';

interface IModalProductProps {
    children: React.ReactNode;
    title: string;
    open: boolean;
    isActive:number;
    count: number;
    size?:string;
    isButton?: boolean;
    onClose: () => void;
    onSave: () => void;
};

export default function ModalProduct({ children, size, title, open,  onClose, onSave, isButton }:IModalProductProps) {
   return (
        <Modal isOpen={open} size={size} className='col-12' md={12} sm={12}>
            <ModalHeader>
                <button type="button" className="close" onClick={onClose}>
                    <i className="nc-icon nc-simple-remove" />
                </button>
                <span className="title title-up">
                    {title}
                    <br />
                </span>
            </ModalHeader>
            <ModalBody>
                {children}
            </ModalBody>
            {isButton &&
                <ModalFooter>
                        <Col >
                            <div className="left-side w-100">
                                <button
                                    className="boxButton btn btn-link col-12"
                                    role="tablist"
                                    onClick={onClose}
                                >
                                    Fechar
                                </button>
                            </div>
                        </Col>
                        <div className="divider" />
                        <Col>
                            <div className="right-side w-100">
                                <button
                                    className="boxButton btn btn-success btn-link"
                                    role="tablist"
                                    onClick={onSave}
                                >
                                    Salvar
                                </button>
                            </div>
                        </Col>
                </ModalFooter>
            }
        </Modal>
    );
}




