import React from 'react';
import { Row, Col, Label } from 'reactstrap';
import './styles.css'

interface IDadosProps {
    id:number,
    Razao_Social: string,
    Nome_Fantasia: string,
    Contato: string,
    Cidade: string,
    Status: boolean,
    Estado: string,
 }

 interface IItemDadosProps {
     dados:IDadosProps;
 };

 export default function ItemDados({dados}:IItemDadosProps) {

    return(
        <Row className="listCard">
            <Col xl={2} lg={2} sm={6} xs={12}>
                <Label>Nome Fantasia</Label>
                <div><strong>{dados.Nome_Fantasia}</strong></div>
            </Col>
            <Col xl={3} lg={3} sm={6} xs={12}>
                <Label>Razão Social</Label>
                <div><strong>{dados.Razao_Social}</strong></div>
            </Col>
            <Col xl={2} lg={2} xs={12}>
                <Label>Contato</Label>
                <div><strong>{dados.Contato}</strong></div>
            </Col>
            <Col xl={2} lg={2} xs={6}>
                <Label>Cidade</Label>
                <div><strong>{dados.Cidade}</strong></div>
            </Col>
            <Col xl={1} lg={1} xs={6}>
                <Label>Estado</Label>
                <div><strong>{dados.Estado}</strong></div>
            </Col>
            <Col xl={2} lg={2} sm={4} xs={12}>
                <Label>Status</Label>
                <div>
                    {dados.Status ?
                    <label className='statusAtivo'>
                        Ativo
                    </label>
                    :
                    <label className='statusInativo'>
                        Inativo
                    </label>
                    }
                </div>
            </Col>
        </Row>
    )
}

