import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import './itemProduto.css';
import { situacaoCotacaoIndustriaConsts } from '../../consts/situacaoOrcamentoIndustriaConsts';

export default function QuantidadeItem({ valor, pedido }) {
    const [quantidade, setQuantidade] = useState(0);

    function incrementarQuantidade() {
        setQuantidade(quantidade + 1);
    }

    function decrementarQuantidade() {
        if (quantidade > 0) {
            setQuantidade(quantidade - 1);
        }
    }
    useEffect(() => {
        setQuantidade(valor);
    }, []);
    return (
        <>
            <label> Quantidade</label>
            <div className="qtdItem form-group mt-n1">
                <button
                    className="menos icon-icon-menos iconeMenos"
                    onClick={() => decrementarQuantidade()}
                    disabled={
                        pedido?.status !==
                        situacaoCotacaoIndustriaConsts.cotacaoAberta || pedido.codPedido !== null
                    }
                />
                <NumberFormat
                    id="qtdItem"
                    className="inputNumber"
                    value={quantidade}
                    decimalScale={0}
                    allowNegative={false}
                    max={999}
                    decimalSeparator=","
                    thousandSeparator="."
                    onValueChange={(values) => {
                        const { value } = values;
                        setQuantidade(parseInt(value));
                    }}
                />
                <button
                    className="mais icon-icon-h iconeMais"
                    onClick={() => incrementarQuantidade()}
                    disabled={
                        pedido?.status !==
                        situacaoCotacaoIndustriaConsts.cotacaoAberta || pedido.codPedido !== null
                    }
                />
            </div>
        </>
    );
}
