import axios from 'axios';

const webApi = axios.create({
    baseURL: process.env.REACT_APP_API_WEBAPI,
});

webApi.interceptors.response.use(
    function (response) {
        return response.data.data ? response.data.data : response.data;
    },
    function (error) {
        if (error.response.status === 401 || error.response.status === 503) {
             sessionStorage.clear();
             window.location.replace("/");
        } else  {
            return Promise.reject(error);
        }
    }
);

export default webApi;
