import React, { useEffect, useState } from 'react';
import iPendentes from './img/time.svg';
import iEnviadas from './img/prancheta.svg';
import iFechadas from './img/certinho.svg';
import iRecebidas from './img/baixando.svg';
import webApi from '~/services/webapi';
import DatePicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import { format, startOfMonth, startOfYear } from 'date-fns';
import { situacaoOrcamentoIndustriaConsts, situacaoCotacaoIndustriaConsts, situacaoPedidoIndustriaConsts } from '~/consts/situacaoOrcamentoIndustriaConsts';
import { Indicador } from '../components/Indicador';
import { IndicadorResponsive } from '../components/Indicador/cardResponsive';
import { Label, Row, Col } from 'reactstrap';
import './DashboardIndustria.module.css';
import BarChart from '../components/BarChart';
import {CardStatus} from '../components/ColumnChart';

registerLocale('ptBR', ptBR);

export const options = {
    backgroundColor: 'transparent',
    colors: ['#4EAA63'],
    prefix: 'R$',
    display:'none',
};


export default function DashboardIndustria() {
    const [dateRange, setDateRange] = useState([
        startOfMonth(new Date()),
        new Date(),
    ]);
    const [startDate, endDate] = dateRange;

    const [
        valorTotalOrcamentosFechadosPorPeriodo,
        setValorTotalOrcamentosFechadosPorPeriodo,
    ] = useState([]);

   const [quantidadeIndicador, setQuantidadeIndicador] =
        useState({
            propostaAberta: 0,
            propostaFechada: 0,
            avulsoPendente: 0,
            avulsoAprovado: 0,
        });

    const [quantidadeOrcamentoPorStatus, setQuantidadeOrcamentoPorStatus] =
        useState({
            cotacaoEnviada: 0,
            analiseTecnica: 0,
            analiseConcluida: 0,
            cotacaoRealizada: 0,
            pedidoAberto: 0,
            pedidoSolicitado: 0,
            pedidoFinalizado: 0,
        });


    async function obterQuantidadeOrcamentoPorStatus(dataInicial, dataFinal) {
        const result = await webApi.get(
            `dashboard/industria/quantidade-orcamento-status`,
            {
                params: {
                    dataInicial: dataInicial,
                    dataFinal: dataFinal,
                },
            }
        );

        //ESQUADRIA
        const orcamentoRecebido = result?.find(
            (i) =>
                i.flSituacao ==
                situacaoOrcamentoIndustriaConsts.orcamentoRecebido && i.tipo === "O"
        );

        const orcamentoEnviado = result?.find(
            (i) =>
                i.flSituacao ==
                situacaoOrcamentoIndustriaConsts.orcamentoEnviado && i.tipo === "O"
        );

        const propostaAberta = result?.find(
            (i) =>
                i.flSituacao ==
                situacaoOrcamentoIndustriaConsts.orcamentoRetornado && i.tipo === "O"
        );

        const pedidoAberto = result?.find(
            (i) =>
            i.flSituacao ==
            situacaoOrcamentoIndustriaConsts.pedidoAberto && i.tipo === "O"
        );

        const pedidoSolicitado = result?.find(
            (i) =>
                i.flSituacao ==
                situacaoOrcamentoIndustriaConsts.pedidoSolicitado && i.tipo === "O"
        );

        const pedidoFechado = result?.find(
            (i) =>
                i.flSituacao ==
                situacaoOrcamentoIndustriaConsts.pedidoFinalizado && i.tipo === "O"
        );

        const orcamentoComVendedor = result?.find(
            (i) =>
                i.flSituacao ==
                situacaoOrcamentoIndustriaConsts.orcamentoComVendedor && i.tipo === "O"
        );

        const recebidoPeloVendedor = result?.find(
        (i) =>
            i.flSituacao ==
            situacaoOrcamentoIndustriaConsts.recebidoPeloVendedor && i.tipo === "O"
        );

        //AVULSO
        const cotacaoEnviada = result?.find(
            (i) =>
            i.flSituacao ==
            situacaoCotacaoIndustriaConsts.cotacaoEnviada && i.tipo === "C"
        );

        const cotacaoRecebida = result?.find(
            (i) =>
            i.flSituacao ==
            situacaoCotacaoIndustriaConsts.cotacaoRecebida && i.tipo === "C"
        );

        const cotacaoAprovada = result?.find(
            (i) =>
            i.flSituacao ==
            situacaoCotacaoIndustriaConsts.cotacaoAprovada && i.tipo === "C"
        );

        const pedidoAprovada = result?.find(
            (i) =>
            i.flSituacao ==
            situacaoPedidoIndustriaConsts.pedidoFechado && i.tipo === "P"
        );

        const avulsosPendentes = Number(cotacaoEnviada?.quantidade  || 0) + Number(cotacaoRecebida?.quantidade  || 0) + Number(cotacaoAprovada?.quantidade  || 0);
        const propostasFechadas = Number(pedidoAberto?.quantidade || 0) + Number(pedidoSolicitado?.quantidade || 0) + Number(pedidoFechado?.quantidade || 0);
        const analise = Number(orcamentoRecebido?.quantidade || 0) + Number(recebidoPeloVendedor?.quantidade || 0) + Number(orcamentoEnviado?.quantidade || 0);

        setQuantidadeIndicador({
            ...quantidadeIndicador,
            propostaAberta: Number(propostaAberta?.quantidade || 0),
            propostaFechada: Number(propostasFechadas || 0),
            avulsoPendente: Number(avulsosPendentes || 0),
            avulsoAprovado: Number(pedidoAprovada?.quantidade || 0),
        });

        setQuantidadeOrcamentoPorStatus({
            ...quantidadeOrcamentoPorStatus,
            cotacaoEnviada: Number(orcamentoComVendedor?.quantidade || 0),
            analiseTecnica:Number(analise || 0),
            cotacaoRealizada:Number(propostaAberta?.quantidade || 0),
            pedidoAberto: Number(pedidoAberto?.quantidade || 0),
            pedidoSolicitado: Number(pedidoSolicitado?.quantidade || 0),
            pedidoFinalizado: Number(pedidoFechado?.quantidade || 0),
        });

        const somaValor = Number(pedidoAberto?.totalOrcamentoAcumulado || 0) + Number(pedidoSolicitado?.totalOrcamentoAcumulado || 0) + Number(pedidoFechado?.totalOrcamentoAcumulado || 0);
        const acumuladoPeriodo = [["Valor Total", Number(somaValor || 0)]];
        if (acumuladoPeriodo.length > 0 ) {
            setValorTotalOrcamentosFechadosPorPeriodo([
                ['', 'Orçamentos Fechados '],
                ...acumuladoPeriodo,
            ]);
        } else {
            setValorTotalOrcamentosFechadosPorPeriodo([
                ['', 'Orçamentos Fechados'],
                [0,0],
            ]);
        }
    }

    const dataIndicador = [
        {
            icone:iPendentes,
            texto:"Proposta com Cliente: Aberta",
            total:quantidadeIndicador.propostaAberta || 0,
            tipo:"PROPOSTAPENDENTE",
        },
        {
            icone:iRecebidas,
            texto:"Proposta com Cliente: Fechada",
            total:quantidadeIndicador.propostaFechada || 0,
            tipo:"PROPOSTAFECHADA",
        },
        {
            icone:iEnviadas,
            texto:"Avulsos: Cotações Pendentes",
            total:quantidadeIndicador.avulsoPendente || 0,
            tipo:"PEDIDOPENDENTE",
        },
        {
            icone:iFechadas,
            texto:"Avulsos: Pedidos aprovados",
            total: quantidadeIndicador.avulsoAprovado || 0,
            tipo:"PEDIDOACEITE",
        },
    ];

    const dataEsquadria = [
        [ "", "", { role: 'style' }],
        [
            'Cotação Enviada',
            quantidadeOrcamentoPorStatus.cotacaoEnviada,
            '#a43b93',
        ],
        [
            'Em Analise Técnica',
            quantidadeOrcamentoPorStatus.analiseTecnica,
            '#58104c',
        ],
        [
            'Cotação Realizada',
            quantidadeOrcamentoPorStatus.cotacaoRealizada,
            '#3073CB',
        ],
        [
            'Pedido Aberto',
            quantidadeOrcamentoPorStatus.pedidoAberto,
            'rgb(32, 222, 158)',
        ],
        [
            'Pedido Solicitado',
            quantidadeOrcamentoPorStatus.pedidoSolicitado,
            '#3ca13b',
        ],
        [
            'Pedido Confirmado',
            quantidadeOrcamentoPorStatus.pedidoFinalizado,
            '#087706',
        ],
    ]

    useEffect(() => {
        (async () => {
            let dataInicialFormatada, dataFinalFormatada;
            if (startDate && endDate) {
                dataInicialFormatada = format(startDate, 'yyyy-MM-dd');
                dataFinalFormatada = format(endDate, 'yyyy-MM-dd');
            }

            if (dataInicialFormatada && dataFinalFormatada) {
                await obterQuantidadeOrcamentoPorStatus(
                    dataInicialFormatada,
                    dataFinalFormatada
                );
            }
        })();
    }, [endDate]);


    return (
        <>
            <header>
                <link
                    rel="stylesheet"
                    href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css"
                    integrity="sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO"
                    crossOrigin="anonymous"
                />
                <style>
                    @import
                    url('https://fonts.googleapis.com/css2?family=Inter:wght@300;500;700&display=swap');
                </style>
                <Row>
                    <Col>
                        <Label>Selecione o período</Label>
                        <DatePicker
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                                if (!update[0] && !update[1]) {
                                    setDateRange([
                                        startOfYear(new Date()),
                                        new Date(),
                                    ]);
                                } else {
                                    setDateRange(update);
                                }
                            }}
                            isClearable={true}
                            placeholderText="Filtrar por período"
                            locale="ptBR"
                            dateFormat="dd/MM/yyyy"
                        />
                    </Col>
                </Row>
            </header>
            <hr style={{marginBottom:0}}/>
            <p style={{fontSize:12,textAlign:'end', marginTop:0}}>Dados conforme período selecionado</p>
            <main>

                <div className='d-none d-lg-block'>
                    <Row className='indicador'>
                        {dataIndicador.map(({icone, texto, total, tipo}, index) => (
                            <Col className="px-0 mx-1" key={index}>
                                <Indicador
                                    icone={icone}
                                    texto={texto}
                                    total={total ?? 0}
                                    tipo={tipo}
                                />
                            </Col>
                        ))}
                    </Row>
                </div>
                <div className='d-block d-lg-none'>
                    <Row>
                        {dataIndicador.map(({ texto, total, tipo}, index) => (
                            <Col md={4} sm={6} xs={12} key={index}>
                                <IndicadorResponsive
                                    texto={texto}
                                    total={total ?? 0}
                                    tipo={tipo}
                                />
                            </Col>
                        ))}
                    </Row>
                </div>
                <div>
                    <Row className='indicador'>
                        <Col Col className="px-0 mx-1">
                            <BarChart title='Status dos Orçamentos (Módulo Esquadrias)' data={dataEsquadria || []}/>
                        </Col>
                        <Col className="px-0 mx-1">
                            <CardStatus
                                title='Orçamentos fechados por Período'
                                data={valorTotalOrcamentosFechadosPorPeriodo || []}
                                options={options}
                            />
                        </Col>
                    </Row>
                </div>
            </main>
        </>
    );
}
