import React, {useState, useEffect} from 'react';
import {
    Label,
    Button,
} from 'reactstrap';
import imgUpload from './img/698.png';

export interface IFileUploadComponentProps{
    label?: string,
    valueLabel?: string;
    src?: string,
    onSelectImage: (img: any) => void;
};


export default function FileUploadComponent({label, valueLabel, src, onSelectImage}:IFileUploadComponentProps){
    const [newImg, setNewImg] = useState<any>(src);

    const onSelectFile = (e:any) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => setNewImg(reader.result));
            reader.readAsDataURL(e.target.files[0]);
            setNewImg(reader.result);
        }
    };

    const handleImage = () => {
        setNewImg(src)
    };

    onSelectImage(newImg);

    useEffect(() => {
        if(src){
            handleImage();
        }else{
            onSelectImage(newImg);
        }
    }, [src]);

    return(
        <div style={{display:valueLabel}} className='my-2'>
            <Label className='mb-4'>{label}</Label>
            <div
                className="enviarImgT mb-4 mt-n3"
                style={{ height: '170px' }}
            >
                {newImg && (
                    <div
                        className="d-flex flex-column align-items-center justify-content-center"
                        style={{ height: '140px' }}
                    >
                        <img
                            src={newImg}
                            alt="test"
                            style={{
                                maxHeight: '100px',
                            }}
                        />
                        <Button
                            color="red"
                            size="sm"
                            block
                            onClick={() => setNewImg(null)}
                        >
                            Remover
                        </Button>
                    </div>
                )}
                {!newImg && (
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        <span
                            className="btn btn-ligth btn-file"
                            style={{
                                background: 'transparent',
                                height: 'auto',
                            }}
                        >
                            <span
                                className="fileinput-new"
                                style={{
                                    color: 'white',
                                    height: 'auto',
                                    alignContent: 'center',
                                }}
                            >
                                <img
                                    src={imgUpload}
                                    style={{
                                        maxHeight: '100px',
                                    }}
                                    alt="Upload de imagens"
                                />
                            </span>
                            <input
                                accept=".jpg, .png"
                                type="file"
                                id="imagem"
                                onChange={(e) => onSelectFile(e)}
                                style={{ alignContent: 'center' }}
                            />
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};
