import React, { ReactNode } from 'react';


type LayoutPuplicProps = {
    children: ReactNode;
}

function LayoutPublicDefault({ children }: LayoutPuplicProps) {
    return (
        <div className="login-page">
            {children}
        </div>
    );
}

export default LayoutPublicDefault;
