import { createGlobalStyle } from 'styled-components';

import 'bootstrap/dist/css/bootstrap.css';
import '../components/react-bootstrap-switch/react-bootstrap-switch.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import '../assets/css/theme.css';
import '../assets/fonts/fontawesome/css/all.css';
import '../assets/css/custom.css';
import '../assets/css/egShop.css';

export default createGlobalStyle`
    * {
        font-Family: 'Inter, sans-serif',
    }
    body{
        -webkit-font-smoothing:antialiased;
    }

    div::-webkit-scrollbar {
        width: 8px;
    }

    div::-webkit-scrollbar-track {
        background: #ededed;
    }

    div::-webkit-scrollbar-thumb {
        background-color: #66615b;
        border-radius: 20px;
        border: 1px solid #6e6ea9;
    }

    @media (max-width: 992px) {
        div::-webkit-scrollbar-track {
            border-radius: 20px;
        }
    }

`;
