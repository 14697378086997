import webApi from '../../webapi';

const postPedidoFecharVendedor = async (codigo, params) => {
    const data = await webApi.post(`pedido/${codigo}/fechar-pedido`, {
        params,
    });
    return data;
};

const getPedidos = async () => {
    const data = await webApi.get('pedido/pedidos');
    return data;
};

const postBaixarXML = async (codigo) => {
    const data = await webApi.get(`pedido/${codigo}/exportar-xml-pedido`);
    return data;
};

export { postPedidoFecharVendedor, getPedidos, postBaixarXML };
