import React from "react";
import { Row, Col } from "reactstrap";
import { ICoresAvulco, ISelect } from "shared/interface/itensAvulsos";

interface IItemAvulsoProps {
	dados: ICoresAvulco;
	tipo: ISelect;
}

export default function ListaCores({ dados, tipo }: IItemAvulsoProps) {
	return (
		<Row className="listCard mr-5">
			<Col lg={2}>
				<label>Cor</label>
				<p>{dados.cor}</p>
			</Col>
			<Col lg={4}>
				<label>Descrição</label>
				<p className="desc">{dados.descricao}</p>
			</Col>
			<Col lg={2}>
				<label>Tipo de Produto</label>
				<p>{tipo.find((i: any) => i.label === dados.tipoProduto)?.name}</p>
			</Col>
		</Row>
	);
}
