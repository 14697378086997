import React from 'react';

import { Switch } from 'react-router-dom';
import controleAcessoRotasConsts from '~/consts/controleAcessoRotasConsts';
import PrimeiroAcesso from '~/pages/PrimeiroAcesso';
import ClienteLista from '~/pages/Clientes/Lista';
import Conta from '~/pages/Conta';
import Support from '~/pages/Support';
import Tutorial from '~/pages/Tutorial';
import RecuperarSenha from '~/pages/RecuperarSenha';
import AlterarLogoRevenda from '~/pages/AlterarLogo';
import OrcamentoCliente from '~/pages/OrcamentoCliente';
import OrcamentoObra from '~/pages/OrcamentoObra';
import OrcamentoCor from '~/pages/OrcamentoCores';
import OrcamentoItem from '~/pages/OrcamentoItens';
import OrcamentoAdicionais from '~/pages/OrcamentoAdicionais';
import OrcamentoObservacao from '~/pages/OrcamentoObservacao';
import OrcamentoAnexo from '~/pages/OrcamentoAnexos';
import OrcamentoPedido from '~/pages/OrcamentoTecnicoPedido';
import OrcamentoTecnicoCores from '~/pages/OrcamentoTecnicoCores';
import OrcamentoTecnicoItens from '~/pages/OrcamentoTecnicoItens';
import OrcamentoTecnicoObservacao from '~/pages/OrcamentoTecnicoObservacao';
import OrcamentoTecnicoVenda from '~/pages/OrcamentoTecnicoVenda';
import OrcamentoTecnicoProducao from '~/pages/OrcamentoTecnicoProducao';
import OrcamentoVendedorDetalhesItem from '~/pages/OrcamentoVendedor/DetalhesItem';
import Notification from '~/pages/Notificacoes';
import AlterarLogo from '~/pages/AlterarLogo';
import Tipologias from '~/pages/Tipologias';
import Vidros from '~/pages/Vidros';
import CoresComponentes from '~/pages/CorComponentes';
import CoresPerfis from '~/pages/CorPerfis';
import CoresVidros from '~/pages/CorVidros';
import Parametrizacao from '~/pages/Parametros';
import Produtos from '~/pages/Produtos/';
import Carrinho from '~/pages/Produtos/Carrinho';
import IndustriaLista from '~/pages/Industrias/IndustriaLista';
import IndustriaFormulario from '~/pages/Industrias/Formulario';
import AlterarSenha from '~/pages/EsqueciSenha';
import CatalogoLista from '~/pages/Catalogo/Lista';
import CatalogoFormulario from '~/pages/Catalogo/Formulario';
import VendedoresLista from '~/pages/Vendedores/Lista';
import VendedoresFormulario from '~/pages/Vendedores/Formulario';
import OrcamentoVendedor from '~/pages/OrcamentoVendedor';
import Dashboard from '~/pages/Dashboard';
import Usuarios from '~/pages/Usuarios';
import OrcamentoLista from '~/pages/OrcamentoLista';
import Acesso from '~/pages/Acesso';
import Route from './Route';
import PedidoLista from '~/pages/PedidoLista';
import PedidoEdicao from '~/pages/PedidoEdicao';
import PedidoResumo from '~/pages/PedidoResumo';
import TabelaPreco from '~/pages/TabelaPreco';
import ConfigurarOrcamento from 'pages/ConfigurarOrcamento';
import CoresAvulso from 'pages/CorAvulso';

export default function Routes() {
    return (
        <Switch>
            {/* Acessos */}

            <Route Pages={Acesso} path="/" exact />

            <Route Pages={PrimeiroAcesso} path="/primeiro-acesso" />

            <Route Pages={RecuperarSenha} path="/recuperar-senha" exact />

            <Route
                Pages={AlterarSenha}
                path="/reset-password/:identificacaoUsuario"
                exact
            />

            <Route Pages={Conta} path="/conta" isPrivate exact />

            {/* Comum */}

            <Route Pages={Dashboard} path="/dashboard" exact isPrivate />

            <Route Pages={Support} path="/support" exact isPrivate />

            <Route Pages={Tutorial} path="/tutorial" exact isPrivate />

            <Route Pages={Notification} path="/notificacoes" exact isPrivate />
            <Route Pages={AlterarLogo} path="/logo" exact isPrivate />

            {/* Industria */}

            <Route
                Pages={OrcamentoLista}
                path="/orcamentos"
                isPrivate
                exact
                controleAcesso={controleAcessoRotasConsts.somenteIndustria}
            />

            <Route
                Pages={OrcamentoCliente}
                path="/orcamentos/:codOrcamento?/cliente"
                isPrivate
                exact
                controleAcesso={controleAcessoRotasConsts.somenteIndustria}
            />

            <Route
                Pages={OrcamentoObra}
                path="/orcamentos/:codOrcamento/obra"
                isPrivate
                exact
                controleAcesso={controleAcessoRotasConsts.somenteIndustria}
            />

            <Route
                Pages={OrcamentoCor}
                path="/orcamentos/:codOrcamento/cores"
                isPrivate
                exact
                controleAcesso={controleAcessoRotasConsts.somenteIndustria}
            />

            <Route
                Pages={OrcamentoItem}
                path="/orcamentos/:codOrcamento/itens"
                isPrivate
                exact
                controleAcesso={controleAcessoRotasConsts.somenteIndustria}
            />

            <Route
                Pages={OrcamentoAdicionais}
                path="/orcamentos/:codOrcamento/adicionais"
                isPrivate
                exact
                controleAcesso={controleAcessoRotasConsts.somenteIndustria}
            />

            <Route
                Pages={OrcamentoObservacao}
                path="/orcamentos/:codOrcamento/observacoes"
                isPrivate
                exact
                controleAcesso={controleAcessoRotasConsts.somenteIndustria}
            />

            <Route
                Pages={OrcamentoAnexo}
                path="/orcamentos/:codOrcamento/anexos/"
                isPrivate
                exact
                controleAcesso={controleAcessoRotasConsts.somenteIndustria}
            />

            <Route
                Pages={OrcamentoPedido}
                path="/orcamentos-tecnicos/:codOrcamentoTecnico/versao/:versao/pedido"
                exact
                isPrivate
                controleAcesso={controleAcessoRotasConsts.somenteIndustria}
            />

            {/* Orçamento Técnico */}

            <Route
                Pages={OrcamentoTecnicoCores}
                path="/orcamentos-tecnicos/:codOrcamentoTecnico/versao/:versao/cores"
                isPrivate
                exact
                controleAcesso={controleAcessoRotasConsts.somenteIndustria}
            />

            <Route
                Pages={OrcamentoTecnicoItens}
                path="/orcamentos-tecnicos/:codOrcamentoTecnico/versao/:versao/itens"
                isPrivate
                exact
                controleAcesso={controleAcessoRotasConsts.somenteIndustria}
            />
            <Route
                Pages={OrcamentoTecnicoObservacao}
                path="/orcamentos-tecnicos/:codOrcamentoTecnico/versao/:versao/observacoes"
                isPrivate
                exact
                controleAcesso={controleAcessoRotasConsts.somenteIndustria}
            />

            <Route
                Pages={OrcamentoTecnicoVenda}
                path="/orcamentos-tecnicos/:codOrcamentoTecnico/versao/:versao/vendas"
                isPrivate
                exact
                controleAcesso={controleAcessoRotasConsts.somenteIndustria}
            />

            <Route
                Pages={OrcamentoTecnicoProducao}
                path="/orcamentos-tecnicos/:codOrcamentoTecnico/versao/:versao/producao"
                isPrivate
                exact
                controleAcesso={controleAcessoRotasConsts.somenteIndustria}
            />

            <Route
                Pages={ClienteLista}
                path="/clientes"
                isPrivate
                controleAcesso={controleAcessoRotasConsts.somenteIndustria}
            />

            <Route
                Pages={PedidoLista}
                path="/pedidos"
                exact
                isPrivate
                controleAcesso={controleAcessoRotasConsts.somenteIndustria}
            />

            <Route
                Pages={PedidoEdicao}
                path="/pedido/edicao"
                exact
                isPrivate
                controleAcesso={controleAcessoRotasConsts.somenteIndustria}
            />

            <Route
                Pages={PedidoResumo}
                path="/pedido/resumo"
                exact
                isPrivate
                controleAcesso={controleAcessoRotasConsts.somenteIndustria}
            />

            {/* Revenda */}

            <Route
                Pages={VendedoresLista}
                path="/vendedores"
                exact
                isPrivate
                controleAcesso={controleAcessoRotasConsts.somenteRevenda}
            />

            <Route
                Pages={VendedoresFormulario}
                path="/vendedores/novo"
                exact
                isPrivate
                controleAcesso={controleAcessoRotasConsts.somenteRevenda}
            />

            <Route
                Pages={VendedoresFormulario}
                path="/vendedores/:codVendedor/editar"
                exact
                isPrivate
                controleAcesso={controleAcessoRotasConsts.somenteRevenda}
            />

            <Route
                Pages={Tipologias}
                path="/tipologias"
                exact
                isPrivate
                controleAcesso={controleAcessoRotasConsts.somenteRevenda}
            />

            <Route
                Pages={Vidros}
                path="/vidros"
                exact
                isPrivate
                controleAcesso={controleAcessoRotasConsts.somenteRevenda}
            />

            <Route
                Pages={CoresComponentes}
                path="/cores/componentes"
                exact
                isPrivate
                controleAcesso={controleAcessoRotasConsts.somenteRevenda}
            />

            <Route
                Pages={CoresPerfis}
                path="/cores/perfis"
                exact
                isPrivate
                controleAcesso={controleAcessoRotasConsts.somenteRevenda}
            />

            <Route
                Pages={CoresVidros}
                path="/cores/vidros"
                exact
                isPrivate
                controleAcesso={controleAcessoRotasConsts.somenteRevenda}
            />

            <Route
                Pages={TabelaPreco}
                path="/tabelaPreco"
                exact
                isPrivate
                controleAcesso={controleAcessoRotasConsts.somenteRevenda}
            />

            <Route
                Pages={ConfigurarOrcamento}
                path="/configuracoes/orcamento"
                exact
                isPrivate
                controleAcesso={controleAcessoRotasConsts.somenteRevenda}
            />

            <Route
                Pages={Parametrizacao}
                path="/configuracoes/parametrizacao"
                exact
                isPrivate
                controleAcesso={controleAcessoRotasConsts.somenteRevenda}
            />

            <Route
                Pages={Usuarios}
                path="/configuracoes/usuarios"
                exact
                isPrivate
                controleAcesso={controleAcessoRotasConsts.somenteRevenda}
            />

            <Route
                Pages={IndustriaLista}
                path="/industrias"
                exact
                isPrivate
                acessoRevenda
            />

            <Route
                Pages={IndustriaFormulario}
                path="/industrias/:industryId/editar"
                exact
                isPrivate
                controleAcesso={controleAcessoRotasConsts.somenteRevenda}
            />

            <Route
                Pages={IndustriaFormulario}
                path="/industrias/novo"
                exact
                isPrivate
                controleAcesso={controleAcessoRotasConsts.somenteRevenda}
            />

            <Route
                Pages={AlterarLogoRevenda}
                path="/logoRevenda"
                exact
                isPrivate
            />

            <Route Pages={Produtos} path="/Produtos" exact isPrivate />

            <Route Pages={Carrinho} path="/Carrinho" exact isPrivate />

            <Route Pages={CatalogoLista} path="/catalogo" exact isPrivate />
            <Route
                Pages={CatalogoFormulario}
                path="/catalogo/formulario/:codProduto?"
                exact
                isPrivate
            />

            <Route
                Pages={CoresAvulso}
                path="/coresAvulso"
                exact
                isPrivate
                controleAcesso={controleAcessoRotasConsts.somenteRevenda}
            />

            {/* Vendedor */}
            <Route
                Pages={OrcamentoVendedor}
                path="/orcamentoVendedor"
                exact
                isPrivate
            />
            <Route
                Pages={OrcamentoVendedorDetalhesItem}
                path="/orcamentoVendedor/:codOrcamento/versao/:versao/detalhes"
                exact
                isPrivate
            />
        </Switch>
    );
}
