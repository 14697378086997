import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import PageContent from '~/components/PageContent';
import webApi from '~/services/webapi';
import GridData from '../../components/GridData/index.tsx';
import ItemDados from './components/ListaDados.tsx';
import CabecalhoEgShop from '../../components/Cabecalho/CabecalhoEgShop.tsx';

export default function IndustriaLista() {
    const history = useHistory();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageCount, setPageCount] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);

    const [canAdd, setCanAdd] = useState(false);
    const [license, setLicense] = useState('');
    const [licenseIcon, setLicenseIcon] = useState('');

    // functions
    const handleClickNew = () => {
        history.push('/industrias/novo');
    };

    const handleEditNew = (industryId) => {
        history.push(`/industrias/${industryId}/editar`);
    };

    // hooks
    useEffect(() => {
        const fetchDataExtranet = async () => {
            const result = await webApi.get(
                'extranetintegracoes/module-information'
            );

            const { available, licences, status } = result;

            if (available > 0) {
                setLicense(`${status.title} ${available}/${licences}`);
            } else if (available === 0) {
                setLicense(status.title);
            }

            setLicenseIcon(status.info);
            setCanAdd(available > 0);
        };
        fetchDataExtranet();
    }, [totalRecords]);

    useEffect(() => {
        setLoading(true);
        (async () => {
            const result = await webApi.post(
                '/industrias/ObterListaIndustrias',
                {}
            );
            const { records, total, page } = result;
            const value = records.map((item) => ({
                id: item.industryId,
                Razao_Social: item.bR_RazaoSocial,
                Nome_Fantasia: item.bR_NomeFantasia,
                Cidade: item.city,
                Contato: item.name,
                Status: item.active,
                Estado: item.state,
            }));
            setData(value);
            setTotalRecords(total);
            setPageCount(page);
            setLoading(false);
        })();
    }, []);

    return (
        <>
            <CabecalhoEgShop
                title="Lista de Industrias"
                onRegisterNew={() => handleClickNew()}
                iconBtn={<i className="fas fa-users" />}
                descricaoBtn="Cadastrar Cliente"
            />
            <div className="m-0 col-12">
                <PageContent className="m-0 container">
                    <Row>
                        <Col md={12} xl={12}>
                            <p className={`licencas text-${licenseIcon}`}>
                                {licenseIcon === 'success' && (
                                    <i className="fas fa-check-circle mr-1" />
                                )}
                                {licenseIcon === 'warning' && (
                                    <i className="fas fa-minus-circle mr-1" />
                                )}
                                {licenseIcon === 'danger' && (
                                    <i className="fas fa-exclamation-triangle mr-1" />
                                )}
                                {license}
                            </p>
                        </Col>
                    </Row>
                    {totalRecords <= 0 ? (
                        <div className="cardList my-3">
                            <strong>Nenhum Registro</strong>
                        </div>
                    ) : (
                        <>
                            <Row className="rowRecord">
                                {' '}
                                <p>Total de Registro: {totalRecords}</p>
                            </Row>
                            <div>
                                {data.map((item, index) => (
                                    <div key={Number(index)}>
                                        <GridData
                                            dados={item}
                                            onEdit={handleEditNew}
                                            registro={totalRecords}
                                            numBtn={1}
                                        >
                                            <ItemDados dados={item} />
                                        </GridData>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </PageContent>
            </div>
        </>
    );
}
