import React from 'react';
import { Pagination as PaginationReactstrap } from 'reactstrap';
import PropTypes from 'prop-types';

const Pagination = ({
    includePagination,
    pageCount,
    pageIndex,
    gotoPage,
    nextPage,
    previousPage,
}) => {
    const rows = [];
    for (let i = 0; i < pageCount; i += 1) {
        rows.push(i);
    }
    return includePagination ? (
        <PaginationReactstrap
            className="d-flex float-right mt-3"
            style={{
                alignItems: 'baseline',
                justifyContent: 'space-between',
            }}
        >
            <p className="mr-1">
                Página: {pageIndex === 0 ? 1 : pageIndex} de {pageCount}
            </p>
            <i
                className="icon-icon-esquerda"
                onClick={() => {
                    if (pageIndex > 1) {
                        previousPage();
                    }
                }}
            />
            <i className="icon-icon-diretita" onClick={() => nextPage()} />
        </PaginationReactstrap>
    ) : null;
};

Pagination.propTypes = {
    includePagination: PropTypes.bool.isRequired,
    pageIndex: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    gotoPage: PropTypes.func.isRequired,
};

export default Pagination;
