import React, {useEffect, useState} from 'react';
import SelectComponent, {  ISelect } from '../../../../components/Select/index';
import { Button, Col, Input, InputGroup, InputGroupText, Row } from 'reactstrap';
import Search from '../../../../components/Search';

interface ICabecalhoProps{
    isSelect?: boolean;
    label?: string;
    valueLabel?: string;
    data?: ISelect[];
    valueSelect?: string;
    onChangeSelect: (valueSelect: any) => void | null;

    isSearch?: boolean;
    iconeColor?: any;
    value?: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
};


export default function SearchesGrid({
    isSelect,
    isSearch,
    label,
    valueLabel,
    data,
    valueSelect,
    onChangeSelect,
    iconeColor,
    value,
    onChange,
     }:ICabecalhoProps){

        return(
            <Row style={{justifyContent:"end"}} className='m-0'>
                {isSelect && data &&
                    <Col xl={4} lg={5} md={5} sm={5} xs={12} style={{marginBottom:12}} className='m-0 p-0 mr-1'>
                        <SelectComponent
                            label={label}
                            valueLabel={valueLabel}
                            data={data}
                            value={valueSelect}
                            onChange={onChangeSelect}
                            isShadow
                        />
                    </Col>
                }
                {isSearch &&
                    <Col xl={4} lg={5} md={5} sm={5} xs={12} style={{display:"flex", marginBottom:12}} className='m-0 p-0 ml-1'>
                        <InputGroup className='p-1'>
                            <Input
                                type="search"
                                placeholder="Pesquisa..."
                                value={value}
                                onChange={onChange}
                                style={{
                                    borderRadius: '0px',
                                    borderTopLeftRadius: '8px',
                                    borderBottomLeftRadius: '8px',
                                }}
                            />
                            <InputGroupText
                                style={{
                                    borderRadius: '0px',
                                    borderTopRightRadius: '8px',
                                    borderBottomRightRadius: '8px',
                                }}
                            >
                                <i
                                    className="icon-icon-busca"
                                    style={{
                                        fontSize: 18,
                                    }}
                                />
                            </InputGroupText>
                        </InputGroup>
                    </Col>
                }
            </Row>
    );
};
