import React, { useRef, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Input } from 'reactstrap';
import * as Yup from 'yup';
import { useNotifyContext } from 'hooks/useNotifyContext';
import background from '~/assets/img/bg/bg-login.jpg';
import defaultUserImage from '~/assets/img/default-user.png';
import BoxMessage from '~/components/BoxMessage';
import { getSingleErrorMessage } from '~/utils';
import FieldValidation from '~/components/FieldValidation';
import webApi from '~/services/webapi';

export default function Conta() {
    const notificacao = useNotifyContext();

    const [message, setMessage] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [user, setUser] = useState({});
    const [codeLink, setCodeLink] = useState({
        code: '',
        enableLink: false,
    });

    const fetchIdRef = useRef(0);

    const { register, handleSubmit, errors, formState } = useForm({
        validationSchema: Yup.object({
            novaSenha: Yup.string().when('senhaAtual', {
                is: (val) => val !== '',
                then: Yup.string().required('Campo Obrigatório'),
            }),
            confirmarSenha: Yup.string().oneOf(
                [Yup.ref('novaSenha'), null],
                'Senha não confere'
            ),
        }),
    });

    const onSubmit = async (data) => {
        try {
            await webApi.post('usuario/alterar-senha', data);

            updateProfileImage();

            notificacao.sucesso('Dados salvos com sucesso');
        } catch (error) {
            notificacao.respostaHttp(error);
        }
    };

    const updateProfileImage = async () => {
        if (selectedFile != null) {
            const data = new FormData();
            data.append('file', selectedFile);
            await webApi.post('account/alter-profile-image', data);
            notificacao.sucesso('Dados salvos com sucesso');

            setSelectedFile(null);
        }
    };

    const readAsBinaryString = (e) => {
        const file = e.target.files[0];

        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                resolve(event.target.result);
            };

            reader.onerror = (err) => {
                reject(err);
            };

            reader.readAsBinaryString(file);
        });
    };

    const handleChangeImage = (e) => {
        setSelectedFile(e.target.files[0]);
        readAsBinaryString(e).then((buffer) => {
            setUser({
                ...user,
                image: btoa(buffer),
            });
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            const response = await webApi.get('account');
            setUser(response.data);
        };
        fetchData();
    }, []);

    return (
        <div className="content">
            <div className="row">
                <div className="col-lg-8 ml-auto mr-auto">
                    <div className="card card-user">
                        <div className="image">
                            <img src={background} alt="..." />
                        </div>
                        <div className="card-body">
                            {/* <div className="author">
                                <label htmlFor="fileUpload">
                                    {!user.image && (
                                        <img
                                            className="avatar border-gray"
                                            src={defaultUserImage}
                                            alt="..."
                                        />
                                    )}
                                    {user.image && (
                                        <img
                                            className="avatar border-gray"
                                            src={`data:image/png;base64, ${user.image}`}
                                            alt="..."
                                        />
                                    )}
                                </label>
                                <h5 className="title">{user.name}</h5>
                                <Input
                                    hidden
                                    id="fileUpload"
                                    type="file"
                                    onChange={(e) => handleChangeImage(e)}
                                />
                                <p className="description">{user.email}</p>
                            </div> */}
                            <p className="description text-center">
                                {user.bR_RazaoSocial}
                            </p>
                            <hr />
                            <p
                                className="text-center mt-1 mb-4"
                                style={{
                                    fontSize: '24px',
                                    fontFamily: 'Inter, sans-serif',
                                    color: '#57607A',
                                }}
                            >
                                Trocar{' '}
                                <strong style={{ color: '#41898C' }}>
                                    Senha
                                </strong>
                            </p>
                            <form className="form">
                                {message ? (
                                    <BoxMessage>{message}</BoxMessage>
                                ) : null}

                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Senha Atual</label>
                                            <Input
                                                name="senhaAtual"
                                                type="password"
                                                innerRef={register}
                                                maxLength={15}
                                                className={
                                                    errors.senhaAtual
                                                        ? 'form-control form-control-sm invalid is-invalid '
                                                        : 'form-control form-control-sm'
                                                }
                                                placeholder="Senha Atual..."
                                            />
                                            <FieldValidation
                                                error={errors?.senhaAtual}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Nova Senha</label>
                                            <Input
                                                innerRef={register}
                                                name="novaSenha"
                                                type="password"
                                                maxLength={15}
                                                className={
                                                    errors.novaSenha
                                                        ? 'form-control form-control-sm invalid is-invalid '
                                                        : 'form-control form-control-sm'
                                                }
                                                placeholder="Nova Senha..."
                                            />
                                            <FieldValidation
                                                error={errors?.novaSenha}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Confirmar Nova Senha</label>
                                            <Input
                                                innerRef={register}
                                                name="confirmarSenha"
                                                type="password"
                                                maxLength={15}
                                                className={
                                                    errors.confirmarSenha
                                                        ? 'form-control form-control-sm invalid is-invalid '
                                                        : 'form-control form-control-sm'
                                                }
                                                placeholder="Confirmar Nova Senha..."
                                            />
                                            <FieldValidation
                                                error={errors?.confirmarSenha}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12 text-center">
                                        <label className="ml-3">
                                            Sua senha deve ter no mínimo 08
                                            caracteres entre letras números e
                                            caracteres especiais (@, $, %,...)."
                                        </label>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="card-footer">
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="boxButton btn btn-success btn-link"
                                    onClick={handleSubmit((e) => onSubmit(e))}
                                >
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
