import React, { useState, useMemo } from 'react';
import { DataTable } from '~/components/DataTable';
import PageContent from '~/components/PageContent';
import { Row } from 'reactstrap';
import webapi from '~/services/webapi';

export default function CoresPerfis() {
    // states
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageCount, setPageCount] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [filtro, setFiltro] = useState('');
    const [reloadTable, setReloadTable] = useState(false);

    // functions
    function filtrar(parametroFiltro) {
        setFiltro(parametroFiltro);
        setReloadTable(!reloadTable);
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Código',
                accessor: 'code',
                isSorted: true,
                isSortedDesc: true,
            },
            {
                Header: 'Descrição',
                accessor: 'description',
            },
        ],
        []
    );

    const getColors = React.useCallback(
        ({ pageSize, pageIndex, sort, desc }) => {
            setLoading(true);
            (async () => {
                const result = await webapi.post(`cores/perfis`, {
                    page: pageIndex,
                    take: pageSize,
                    filter: filtro,
                    sort,
                    desc,
                });
                const { records, total } = result;
                setData(records);
                setTotalRecords(total);
                setPageCount(Math.ceil(total / pageSize));
                setLoading(false);
            })();
        },
        [reloadTable]
    );

    return (
        <>
            <Row className="mx-1">
                <h3>
                    Cores de{' '}
                    <strong style={{ color: '#6b2c5f' }}>perfil</strong>
                </h3>
            </Row>
            <PageContent>
                <DataTable
                    columns={columns}
                    data={data}
                    fetchData={getColors}
                    loading={loading}
                    pageCount={pageCount}
                    total={totalRecords}
                    includePageSize={false}
                    includePagination={false}
                    reload={reloadTable}
                />
            </PageContent>
        </>
    );
}
