import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useOrcamentoTecnicoVendaContext } from '~/hooks/useOrcamentoTecnicoVendaContext';
import * as yup from 'yup';
import FieldValidation from '~/components/FieldValidation';
import { Input } from 'reactstrap';
import NumberFormat from 'react-number-format';
import OrcamentoTecnicoVendaCustosAdicionaisItem from './OrcamentoTecnicoVendaCustosAdicionaisItem';
import shortid from 'shortid';
import { useParams } from 'react-router-dom';
import webApi from '~/services/webapi';
import { useOrcamentoContext } from '~/hooks/useOrcamentoContext';

export default function OrcamentoTecnicoVendaCustosAdicionaisLista() {
    // params
    const { codOrcamentoTecnico, versao } = useParams();

    // hooks
    const {
        orcamentoTecnicoCustoAdicionais,
        atualizarOrcamentoTecnicoCustosAdicionais,
    } = useOrcamentoTecnicoVendaContext();

    const { permiteEdicaoOrcamentoTecnicoVendas } = useOrcamentoContext();

    const { control, register, handleSubmit, errors, reset } = useForm({
        mode: 'onBlur',
        validationSchema: yup.object().shape({
            descricao: yup.string().required('Campo obrigatório'),
            custo: yup.number().required('Campo obrigatório').nullable(),
        }),
    });

    // functions
    const onSubmit = (data) => {
        if (permiteEdicaoOrcamentoTecnicoVendas()) {
            const custosAdicionais = [
                ...orcamentoTecnicoCustoAdicionais,
                { ...data, identificador: shortid.generate() },
            ];
            atualizarOrcamentoTecnicoCustosAdicionais(custosAdicionais);
            reset({ descricao: null, custo: 0 });
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grupo-tipo-campo valor-total">
                    <div className="campo-adicional-orcamento">
                        <label className="servico-adicional">
                            Nome do serviço
                        </label>
                        <br />
                        <Input
                            type="text"
                            innerRef={register}
                            name="descricao"
                            disabled={!permiteEdicaoOrcamentoTecnicoVendas()}
                        />
                        <FieldValidation error={errors?.descricao} />
                    </div>
                    <div className="campo-valor-revenda ">
                        <label>Meu valor de venda</label>
                        <br />
                        <Controller
                            as={NumberFormat}
                            name="custo"
                            control={control}
                            placeholder="R$ 0,00"
                            decimalScale={2}
                            fixedDecimalScale
                            prefix="R$ "
                            decimalSeparator=","
                            thousandSeparator="."
                            onChangeName="onValueChange"
                            disabled={!permiteEdicaoOrcamentoTecnicoVendas()}
                            onChange={([e]) => {
                                return e.floatValue;
                            }}
                        />

                        <FieldValidation error={errors?.custo} />
                    </div>
                    <div>
                        <button
                            type="submit"
                            title="Adicionar"
                            className="btn btn-icon btn-icon-mini btn-neutral"
                            disabled={!permiteEdicaoOrcamentoTecnicoVendas()}
                        >
                            <i className="fa fa-plus"></i>
                        </button>
                    </div>
                </div>
            </form>
            {orcamentoTecnicoCustoAdicionais.map((custoAdicional, indice) => (
                <OrcamentoTecnicoVendaCustosAdicionaisItem
                    key={indice}
                    custoAdicional={custoAdicional}
                />
            ))}
        </>
    );
}
