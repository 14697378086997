import React from 'react';

import { Card, CardBody, Container, Row } from 'reactstrap';

export default function PageContent({
    titlePage = '',
    children,
    className = '',
}) {
    return (
        <Row>
            <Container className="col-12">
                <Card className={`col-12 ${className} m-0`}>
                    <CardBody className="col-12">{children}</CardBody>
                </Card>
            </Container>
        </Row>
    );
}
