import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Input, InputGroup, InputGroupText } from 'reactstrap';

import authapi from '~/services/authapi';
import FieldValidation from '~/components/FieldValidation';
import { useNotifyContext } from '~/hooks/useNotifyContext';
import styles from './senha.module.css';

export default function RecuperarSenha() {
    // hooks
    const notificacao = useNotifyContext();

    const { register, handleSubmit, errors, formState, reset } = useForm({
        validationSchema: yup.object().shape({
            email: yup
                .string()
                .email('Formato de e-mail inválido')
                .required('Campo obrigatório'),
        }),
    });

    // states
    const [loading, setLoading] = useState(false);

    // functions
    const onSubmit = async (data) => {
        try {
            setLoading(true);
            await authapi.post('autenticacao/recuperar-senha', {
                Email: data.email,
            });
            notificacao.sucesso(
                'Solicitação enviada com sucesso, verifique seu e-mail.'
            );
            reset();
        } catch (error) {
            notificacao.respostaHttp(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container">
            <div className="col-lg-4 col-md-6 ml-auto mr-auto">
                <form className="form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="card card-login boxButton mt-5">
                        <div className="card-header ">
                            <div className="card-header ">
                                <h4
                                    className={`header text-center ${styles.title}`}
                                >
                                    Esqueceu Sua Senha?
                                </h4>
                                <p
                                    className={`card-description text-center ${styles.pDesc}`}
                                >
                                    Insira seu email no campo abaixo e
                                    enviaremos um email com o link para
                                    redefinir sua senha.
                                </p>
                            </div>
                        </div>
                        <div className="card-body ">
                            <InputGroup>
                                <InputGroupText className={`${styles.box}`}>
                                    <i className="nc-icon nc-email-85" />
                                </InputGroupText>
                                <Input
                                    name="email"
                                    type="text"
                                    className={`${styles.box}`}
                                    placeholder="Email..."
                                    innerRef={register}
                                    maxLength={120}
                                />
                                <FieldValidation error={errors?.email} />
                            </InputGroup>
                        </div>
                        <div className="card-footer text-center">
                            <button
                                type="submit"
                                className={`btn-block mb-3 ${styles.button}`}
                                disabled={loading}
                            >
                                {loading && (
                                    <i
                                        className="fa fa-refresh fa-spin fa-1x"
                                        style={{ marginRight: '5px' }}
                                    />
                                )}
                                Solicitar Senha
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
