import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import PageContent from '~/components/PageContent';
import OrcamentoNavegacaoSuperior from '~/components/OrcamentoNavegacaoSuperior';
import ButtonSpin from '~/components/ButtonSpin';
import { useNotifyContext } from '~/hooks/useNotifyContext';
import { Col, Row } from 'reactstrap';
import {
    getRelatorioTecnicoDownload,
    getRelatorioTecnico,
} from '../../services/modules/Orcamento/orcamentoTecnico';

export default function OrcamentoTecnicoProducao() {
    // params
    const { codOrcamentoTecnico, versao } = useParams();

    const [data, setData] = useState([]);

    const notificacao = useNotifyContext();

    async function handleClickDownload({
        nomeRelatorio,
        codOrcamentoTecnicoRelatorio,
    }) {
        try {
            const result = await getRelatorioTecnicoDownload(
                codOrcamentoTecnico,
                versao,
                codOrcamentoTecnicoRelatorio
            );
            const downloadUrl = window.URL.createObjectURL(new Blob([result]));
            const link = document.createElement('a');

            link.href = downloadUrl;
            link.setAttribute('download', `${nomeRelatorio}.pdf`);

            document.body.appendChild(link);

            link.click();
            link.remove();
        } catch (error) {
            notificacao.respostaHttp(error);
        }
    }

    useEffect(() => {
        async function fetch() {
            const result = await getRelatorioTecnico(
                codOrcamentoTecnico,
                versao
            );
            setData(result);
        }
        fetch();
    }, []);

    return (
        <PageContent titlePage="Orçamento - Produção">
            <OrcamentoNavegacaoSuperior />
            <div className="tab-content text-center" id="default">
                <div
                    className="tab-pane active wizard"
                    role="tabpanel"
                    aria-expanded="true"
                    id="levantamento"
                />
            </div>
            <div className="row">
                <div className="col-sm-12 p-3">
                    {data.length === 0 ? (
                        <Row>
                            <Col md={12} className="text-center">
                                <h4>
                                    Nenhum relatório de produção disponível pela
                                    revenda.
                                </h4>
                            </Col>
                        </Row>
                    ) : (
                        <div className="tab-pane active text-left">
                            <div className="row mr-5">
                                {data.map((item) => (
                                    <div
                                        className="col-sm-3 mt-3"
                                        key={item.codOrcamentoTecnicoRelatorio}
                                    >
                                        <div className="card card-pricing ">
                                            <div className="card-header">
                                                <h6 className="card-category">
                                                    {item.nomeRelatorio}
                                                </h6>
                                            </div>
                                            <div className="card-body">
                                                <div className="card-icon icon-red p-2">
                                                    <i className="far fa-file-pdf icon" />
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                                <ButtonSpin
                                                    title="Download"
                                                    className="btn btn-primary"
                                                    ìcon="fas fa-file-download"
                                                    onClick={() =>
                                                        handleClickDownload(
                                                            item
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </PageContent>
    );
}
