import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
    Input,
    Label,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useNotifyContext } from 'hooks/useNotifyContext';
import authApi from '~/services/authapi';
import FieldValidation from '~/components/FieldValidation';
import styles from './acesso.module.css';
import { Link } from 'react-router-dom';

export default function PrimeiroAcesso() {
    // states
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    // functions
    const onSubmit = async () => {
        try {
            setLoading(true);
            const dadosAcesso = {
                email: getValues('email'),
                code: getValues('code'),
                password: getValues('password'),
                passwordConfirm: getValues('passwordConfirm'),
            }

            await authApi.post('Autenticacao/primeiro-acesso', dadosAcesso );
            notificacao.sucesso('Senha de acesso configurado com sucesso.');
            reset();
        } catch (error) {
            history.push('/primeiro-acesso');
            notificacao.respostaHttp(error);
        } finally {
            setLoading(false);
        }
    };

    // hooks
    const notificacao = useNotifyContext();

    const { register, handleSubmit, errors, getValues, reset, formState } = useForm({
        validationSchema: yup.object().shape({
            email: yup
                .string()
                .email('Formato de e-mail inválido')
                .required('Campo obrigatório'),
            code: yup.string().required('Campo obrigatório'),
            password: yup.string().required('Campo obrigatório'),
            passwordConfirm: yup
                .string()
                .oneOf([yup.ref('password'), null], 'Senha não confere'),
        }),


    });

    return (
        <>
            <div className="container">
                <div className="col-lg-4 col-md-6 ml-auto mr-auto">
                    <form className="form" onSubmit={handleSubmit(onSubmit)}>
                        <div className="card card-login boxButton mt-5">
                            <div className="card-header ">
                                <div className="card-header ">
                                    <h4
                                        className={`header text-center ${styles.title}`}
                                    >
                                        Meu Primeiro Acesso
                                    </h4>
                                    <p
                                        className={`card-description text-center ${styles.pDesc}`}
                                    >
                                        Insira seu email no campo abaixo junto
                                        com seu código de acesso. Depois crie
                                        uma nova senha.
                                    </p>
                                </div>
                            </div>
                            <div className="card-body ">
                                <InputGroup>
                                    <InputGroupText className={`${styles.box}`}>
                                        <i className="nc-icon nc-email-85" />
                                    </InputGroupText>

                                    <Input
                                        name="email"
                                        type="text"
                                        className={`${styles.box}`}
                                        placeholder="Email..."
                                        innerRef={register}
                                        maxLength={120}
                                    />
                                    <FieldValidation error={errors?.email} />
                                </InputGroup>
                                <InputGroup>
                                    <InputGroupText className={`${styles.box}`}>
                                        <i className="nc-icon nc-lock-circle-open" />
                                    </InputGroupText>

                                    <Input
                                        name="code"
                                        type="text"
                                        className={`${styles.box}`}
                                        placeholder="Código de Acesso..."
                                        innerRef={register}
                                        maxLength={20}
                                    />
                                    <FieldValidation error={errors?.code} />
                                </InputGroup>
                                <InputGroup>
                                    <InputGroupText className={`${styles.box}`}>
                                        <i className="nc-icon nc-key-25" />
                                    </InputGroupText>

                                    <Input
                                        name="password"
                                        type="password"
                                        className={`${styles.box}`}
                                        placeholder="Nova Senha..."
                                        innerRef={register}
                                        maxLength={20}
                                    />
                                    <FieldValidation error={errors?.password} />
                                </InputGroup>
                                <InputGroup>
                                    <InputGroupText className={`${styles.box}`}>
                                        <i className="nc-icon nc-key-25" />
                                    </InputGroupText>

                                    <Input
                                        name="passwordConfirm"
                                        type="password"
                                        className={`${styles.box}`}
                                        placeholder="Confirmar Nova Senha..."
                                        innerRef={register}
                                        maxLength={20}
                                    />
                                    <FieldValidation
                                        error={errors?.passwordConfirm}
                                    />
                                </InputGroup>
                                <Label className={`${styles.desc}`}>
                                    Sua senha deve ter no mínimo 08 caracteres
                                    entre letras números e caracteres especiais
                                    (@, $, %,...).
                                </Label>
                            </div>
                            <div className="card-footer text-center">
                                <Label>
                                    Ao entrar, concordo com os{' '}
                                    <a href="termos-de-uso.pdf">
                                        Termos e Políticas da Alumisoft Sistemas
                                    </a>
                                    .
                                </Label>
                                <Link
                                to='/'>
                                    <button
                                        type="submit"
                                        className={`btn-block mb-3 ${styles.button}`}
                                        disabled={loading}
                                        onClick={onSubmit}
                                    >
                                        {loading && (
                                            <i
                                                className="fa fa-refresh fa-spin fa-1x"
                                                style={{ marginRight: '5px' }}
                                            />
                                        )}
                                        Entrar
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
