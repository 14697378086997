import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Nav, NavItem, Row } from 'reactstrap';
import StatusOrcamento from '~/components/OrcamentoStatus/StatusOrcamento';
import { useOrcamentoContext } from '~/hooks/useOrcamentoContext';
import { situacaoOrcamentoIndustriaConsts } from '../../consts/situacaoOrcamentoIndustriaConsts';

export default function OrcamentoNavegacaoSuperior() {
    const { orcamentoStateContext } = useOrcamentoContext();
    const location = useLocation();
    const [activeLink, setActiveLink] = useState('');

    useEffect(() => {
        const { pathname } = location;
        if (pathname.includes('vendas')) {
            setActiveLink('custoVenda');
        } else if (pathname.includes('pedido')) {
            setActiveLink('pedido');
        } else if (pathname.includes('producao')) {
            setActiveLink('producao');
        } else {
            setActiveLink('levantamento');
        }
    }, [location]);

    const handleLinkClick = (linkName) => {
        setActiveLink(linkName);
    };

    return (
        <div className="wizard">
            <div className="col">
                <Row>
                    <div className="col-10 m-0 p-0">
                        <Nav
                            tabs
                            className="nav mb-3 m nav-pills-primary nav-navbar-left"
                            style={{ paddingLeft: '-10px' }}
                        >
                            <NavItem>
                                <Link
                                    className={`nav-link ${
                                        activeLink === 'levantamento'
                                            ? 'active'
                                            : ''
                                    }`}
                                    to={`/orcamentos/${orcamentoStateContext.codOrcamento}/cliente/`}
                                    onClick={() =>
                                        handleLinkClick('levantamento')
                                    }
                                >
                                    Levantamento
                                </Link>
                            </NavItem>

                            {(orcamentoStateContext.situacaoOrcamentoIndustria ===
                                situacaoOrcamentoIndustriaConsts.orcamentoRetornado ||
                                orcamentoStateContext.situacaoOrcamentoIndustria ===
                                    situacaoOrcamentoIndustriaConsts.orcamentoRevisao ||
                                orcamentoStateContext.situacaoOrcamentoIndustria ===
                                    situacaoOrcamentoIndustriaConsts.propostaEnviada ||
                                orcamentoStateContext.situacaoOrcamentoIndustria ===
                                    situacaoOrcamentoIndustriaConsts.propostaRejeitada ||
                                orcamentoStateContext.situacaoOrcamentoIndustria ===
                                    situacaoOrcamentoIndustriaConsts.propostaFechada ||
                                orcamentoStateContext.situacaoOrcamentoIndustria ===
                                    situacaoOrcamentoIndustriaConsts.propostaRevisao ||
                                orcamentoStateContext.situacaoOrcamentoIndustria ===
                                    situacaoOrcamentoIndustriaConsts.pedidoAberto ||
                                orcamentoStateContext.situacaoOrcamentoIndustria ===
                                    situacaoOrcamentoIndustriaConsts.pedidoSolicitado ||
                                orcamentoStateContext.situacaoOrcamentoIndustria ===
                                    situacaoOrcamentoIndustriaConsts.pedidoFinalizado) && (
                                <NavItem>
                                    <Link
                                        className={`nav-link ${
                                            activeLink === 'custoVenda'
                                                ? 'active'
                                                : ''
                                        }`}
                                        to={`/orcamentos-tecnicos/${orcamentoStateContext.codOrcamentoTecnico}/versao/${orcamentoStateContext.versaoOrcamentoTecnico}/vendas`}
                                        onClick={() =>
                                            handleLinkClick('custoVenda')
                                        }
                                    >
                                        Custo / Venda
                                    </Link>
                                </NavItem>
                            )}

                            {(orcamentoStateContext.situacaoOrcamentoIndustria ===
                                situacaoOrcamentoIndustriaConsts.propostaFechada ||
                                orcamentoStateContext.situacaoOrcamentoIndustria ===
                                    situacaoOrcamentoIndustriaConsts.pedidoAberto ||
                                orcamentoStateContext.situacaoOrcamentoIndustria ===
                                    situacaoOrcamentoIndustriaConsts.pedidoSolicitado ||
                                orcamentoStateContext.situacaoOrcamentoIndustria ===
                                    situacaoOrcamentoIndustriaConsts.pedidoFinalizado) && (
                                <NavItem>
                                    <Link
                                        className={`nav-link ${
                                            activeLink === 'pedido'
                                                ? 'active'
                                                : ''
                                        }`}
                                        to={`/orcamentos-tecnicos/${orcamentoStateContext.codOrcamentoTecnico}/versao/${orcamentoStateContext.versaoOrcamentoTecnico}/pedido`}
                                        onClick={() =>
                                            handleLinkClick('pedido')
                                        }
                                    >
                                        Pedido
                                    </Link>
                                </NavItem>
                            )}

                            {(orcamentoStateContext.situacaoOrcamentoIndustria ===
                                situacaoOrcamentoIndustriaConsts.pedidoSolicitado ||
                                orcamentoStateContext.situacaoOrcamentoIndustria ===
                                    situacaoOrcamentoIndustriaConsts.pedidoFinalizado) && (
                                <NavItem>
                                    <Link
                                        className={`nav-link ${
                                            activeLink === 'producao'
                                                ? 'active'
                                                : ''
                                        }`}
                                        to={`/orcamentos-tecnicos/${orcamentoStateContext.codOrcamentoTecnico}/versao/${orcamentoStateContext.versaoOrcamentoTecnico}/producao`}
                                        onClick={() =>
                                            handleLinkClick('producao')
                                        }
                                    >
                                        Produção
                                    </Link>
                                </NavItem>
                            )}
                        </Nav>
                    </div>
                    <div className="col-2 m-0 p-0 d-flex align-items-center">
                        <StatusOrcamento
                            className="justify-content-right"
                            situacaoOrcamentoIndustria={
                                orcamentoStateContext.situacaoOrcamentoIndustria
                            }
                        />
                    </div>
                </Row>
            </div>
        </div>
    );
}
