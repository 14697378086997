import React from 'react';
import styles from './CotacaoStatus.module.css';


interface StatusProps {
    status: Number;
}

export default function CotacaoStatus({ status }: StatusProps) {
    const tpStatus = [
        {
            key:1,
            value: "Cotação em Rascunho"
        },
        {
            key:2,
            value: "Cotação Enviado"
        },
        {
            key:3,
            value: "Cotação Realizada"
        },
        {
            key:4,
            value: "Cotação Cancelada"
        },
        {
            key:5,
            value: "Pedido Solicitado"
        },
        {
            key:6,
            value: "Cotação Rejeitada"
        },
    ]

    switch (status) {
        default: {
            return (
                <>
                    <span className={`${styles.iconRascunho}`} title='Rascunho'/>
                    <span>{tpStatus.find(tipo => tipo.key === status)?.value}</span>
                </>
            );
        }
        case 2: {
            return (
                <>
                    <span className={`${styles.iconEnviado}`} title='Enviada'/>
                    <span>{tpStatus.find(tipo => tipo.key === status)?.value}</span>
                </>
            )
        }
        case 3: {
            return (
                <>
                    <span className={`${styles.iconRealizada}`} title='Realizada'/>
                    <span>{tpStatus.find(tipo => tipo.key === status)?.value}</span>
                </>
            )
        }
        case 4: {
            return (
                <>
                    <span className={`${styles.iconCancelado}`} title='Cancelada'/>
                    <span>{tpStatus.find(tipo => tipo.key === status)?.value}</span>
                </>
            )
        }
        case 5: {
            return (
                <>
                    <span className={`${styles.iconAprovado}`} title='Aprovada'/>
                    <span>{tpStatus.find(tipo => tipo.key === status)?.value}</span>
                </>
            )
        }
        case 6: {
            return (
                <>
                    <span className={`${styles.iconCancelado}`} title='Cancelada'/>
                    <span>{tpStatus.find(tipo => tipo.key === status)?.value}</span>
                </>
            )
        }
    }


}
