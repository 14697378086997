import React from 'react';

function BoxMessage(props) {
    const { children, type } = props;

    return (
        <div className={`alert alert-${!type ? 'danger' : type}`} role="alert">
            {children}
        </div>
    );
}

export default BoxMessage;
