import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useParams } from 'react-router-dom';
import tipoPrecificacaoOrcamentoTecnicoVendaConsts from '~/consts/tipoPrecificacaoOrcamentoTecnicoVendaConsts';

import { useNotifyContext } from '~/hooks/useNotifyContext';
import { useOrcamentoTecnicoVendaContext } from '~/hooks/useOrcamentoTecnicoVendaContext';
import { useOrcamentoContext } from '~/hooks/useOrcamentoContext';
import webApi from '~/services/webapi';
import OrcamentoTecnicoVendaCustosAdicionaisLista from './OrcamentoTecnicoVendaCustosAdicionaisLista';

export default function OrcamentoTecnicoVendaPainel() {
    // params
    const { codOrcamentoTecnico, versao } = useParams();

    // hooks
    const {
        tipoPrecificacao,
        orcamentoTecnicoValores,
        totalValorOrcamentoTecnico,
        orcamentoTecnicoCustoAdicionais,
        atualizarTotalValorInsumos,
        salvarAlteracoesOrcamento,
        setSalvarAlteracoesOrcamento,
    } = useOrcamentoTecnicoVendaContext();

    const notificacao = useNotifyContext();

    const { permiteEdicaoOrcamentoTecnicoVendas } = useOrcamentoContext();

    // state painel
    const [totalCustoRevenda, setTotalCustoRevenda] = useState(0);

    const [incluirCustosKit, setIncluirCustoKit] = useState(
        orcamentoTecnicoValores.temCustoKit
    );
    const [incluirCustosSuperficie, setIncluirCustoSuperficie] = useState(
        orcamentoTecnicoValores.temCustoChapaTela
    );

    // states perfis
    const [custoRevendaPerfil, setCustoRevendaPerfil] = useState(0);
    const [lucroRevendaPerfil, setLucroRevendaPerfil] = useState(0);
    const [valorVendaRevendaPerfil, setValorVendaRevendaPerfil] = useState(0);

    // states componentes
    const [custoRevendaComponente, setCustoRevendaComponente] = useState(0);
    const [lucroRevendaComponente, setLucroRevendaComponente] = useState(0);
    const [valorVendaRevendaComponente, setValorVendaRevendaComponente] =
        useState(0);

    // states componentes
    const [custoRevendaVidro, setCustoRevendaVidro] = useState(0);
    const [lucroRevendaVidro, setLucroRevendaVidro] = useState(0);
    const [valorVendaRevendaVidro, setValorVendaRevendaVidro] = useState(0);

    // states superficie
    const [custoRevendaSuperficie, setCustoRevendaSuperficie] = useState(0);
    const [lucroRevendaSuperficie, setLucroRevendaSuperficie] = useState(0);
    const [valorVendaRevendaSuperficie, setValorVendaRevendaSuperficie] =
        useState(0);

    // states superficie
    const [custoRevendaKit, setCustoRevendaKit] = useState(0);
    const [lucroRevendaKit, setLucroRevendaKit] = useState(0);
    const [valorVendaRevendaKit, setValorVendaRevendaKit] = useState(0);

    function calcularCustoRevendaDoInsumo() {
        let fator = 0;
        if (orcamentoTecnicoValores.temCustoPerfil) fator += 1;
        if (orcamentoTecnicoValores.temCustoComponente) fator += 1;
        if (orcamentoTecnicoValores.temCustoVidro) fator += 1;
        if (orcamentoTecnicoValores.temCustoKit) fator += 1;
        if (orcamentoTecnicoValores.temCustoChapaTela) fator += 1;

        if (fator === 0) return orcamentoTecnicoValores.totalCusto;

        return orcamentoTecnicoValores.totalCusto / fator;
    }

    // functions perfis
    function definirCustoRevendaPerfilInicial() {
        if (orcamentoTecnicoValores) {
            if (
                orcamentoTecnicoValores.temCustoPerfil &&
                orcamentoTecnicoValores.custoRevendaPerfil === 0
            )
                setCustoRevendaPerfil(calcularCustoRevendaDoInsumo());
            else {
                setCustoRevendaPerfil(
                    orcamentoTecnicoValores.custoRevendaPerfil
                );
            }
            setLucroRevendaPerfil(orcamentoTecnicoValores.lucroRevendaPerfil);
        }
    }

    function atualizarValorVendaRevendaPerfil(margem) {
        const valor = calcularValorVendaRevenda(custoRevendaPerfil, margem);
        setValorVendaRevendaPerfil(valor);
        setLucroRevendaPerfil(margem);
    }

    useEffect(() => {
        atualizarValorVendaRevendaPerfil(lucroRevendaPerfil);
        calcularTotalCustoRevenda();
    }, [custoRevendaPerfil]);

    useEffect(() => {
        calcularValorTotalInsumos();
    }, [valorVendaRevendaPerfil]);

    // functions componentes
    function definirCustoRevendaComponenteInicial() {
        if (orcamentoTecnicoValores) {
            if (
                orcamentoTecnicoValores.temCustoComponente &&
                orcamentoTecnicoValores.custoRevendaComponente === 0
            )
                setCustoRevendaComponente(calcularCustoRevendaDoInsumo());
            else {
                setCustoRevendaComponente(
                    orcamentoTecnicoValores.custoRevendaComponente
                );
            }
            setLucroRevendaComponente(
                orcamentoTecnicoValores.lucroRevendaComponente
            );
        }
    }

    function atualizarValorVendaRevendaComponente(margem) {
        const valor = calcularValorVendaRevenda(custoRevendaComponente, margem);
        setValorVendaRevendaComponente(valor);
        setLucroRevendaComponente(margem);
    }

    useEffect(() => {
        atualizarValorVendaRevendaComponente(lucroRevendaComponente);
        calcularTotalCustoRevenda();
    }, [custoRevendaComponente]);

    useEffect(() => {
        calcularValorTotalInsumos();
    }, [valorVendaRevendaComponente]);

    // functions vidros
    function definirCustoRevendaVidroInicial() {
        if (orcamentoTecnicoValores) {
            if (
                orcamentoTecnicoValores.temCustoVidro &&
                orcamentoTecnicoValores.custoRevendaVidro === 0
            )
                setCustoRevendaVidro(calcularCustoRevendaDoInsumo());
            else {
                setCustoRevendaVidro(orcamentoTecnicoValores.custoRevendaVidro);
            }
            setLucroRevendaVidro(orcamentoTecnicoValores.lucroRevendaVidro);
        }
    }

    function atualizarValorVendaRevendaVidro(margem) {
        const valor = calcularValorVendaRevenda(custoRevendaVidro, margem);
        setValorVendaRevendaVidro(valor);
        setLucroRevendaVidro(margem);
    }

    useEffect(() => {
        atualizarValorVendaRevendaVidro(lucroRevendaVidro);
        calcularTotalCustoRevenda();
    }, [custoRevendaVidro]);

    useEffect(() => {
        calcularValorTotalInsumos();
    }, [valorVendaRevendaVidro]);

    // functions superficies
    function definirCustoRevendaSuperficieInicial() {
        if (orcamentoTecnicoValores) {
            if (
                orcamentoTecnicoValores.temCustoChapaTela &&
                orcamentoTecnicoValores.custoRevendaSuperficie === 0
            ) {
                setCustoRevendaSuperficie(calcularCustoRevendaDoInsumo());
            } else {
                setCustoRevendaSuperficie(
                    orcamentoTecnicoValores.custoRevendaSuperficie
                );
            }
            setLucroRevendaSuperficie(
                orcamentoTecnicoValores.lucroRevendaSuperficie
            );

            setIncluirCustoSuperficie(
                orcamentoTecnicoValores.custoRevendaSuperficie > 0 ||
                    orcamentoTecnicoValores.temCustoChapaTela > 0
            );
        }
    }

    function atualizarValorVendaRevendaSuperficie(margem) {
        const valor = calcularValorVendaRevenda(custoRevendaSuperficie, margem);
        setValorVendaRevendaSuperficie(valor);
        setLucroRevendaSuperficie(margem);
    }

    useEffect(() => {
        atualizarValorVendaRevendaSuperficie(lucroRevendaSuperficie);
        calcularTotalCustoRevenda();
    }, [custoRevendaSuperficie]);

    useEffect(() => {
        calcularValorTotalInsumos();
    }, [valorVendaRevendaSuperficie]);

    // functions kits
    function definirCustoRevendaKitInicial() {
        if (orcamentoTecnicoValores) {
            if (
                orcamentoTecnicoValores.temCustoKit &&
                orcamentoTecnicoValores.custoRevendaKit === 0
            )
                setCustoRevendaKit(calcularCustoRevendaDoInsumo());
            else {
                setCustoRevendaKit(orcamentoTecnicoValores.custoRevendaKit);
            }
            setLucroRevendaKit(orcamentoTecnicoValores.lucroRevendaKit);
            setIncluirCustoKit(
                orcamentoTecnicoValores.custoRevendaKit > 0 ||
                    orcamentoTecnicoValores.temCustoKit > 0
            );
        }
    }

    function atualizarValorVendaRevendaKit(margem) {
        const valor = calcularValorVendaRevenda(custoRevendaKit, margem);
        setValorVendaRevendaKit(valor);
        setLucroRevendaKit(margem);
    }

    useEffect(() => {
        atualizarValorVendaRevendaKit(lucroRevendaKit);
        calcularTotalCustoRevenda();
    }, [custoRevendaKit]);

    useEffect(() => {
        calcularValorTotalInsumos();
    }, [valorVendaRevendaKit]);

    function calcularValorVendaRevenda(valorInsumo, margem) {
        let valor = valorInsumo;
        if (margem !== 0) {
            valor = valorInsumo + valorInsumo * (margem / 100);
        }
        return valor;
    }

    function calcularTotalCustoRevenda() {
        setTotalCustoRevenda(
            custoRevendaPerfil +
                custoRevendaComponente +
                custoRevendaSuperficie +
                custoRevendaVidro +
                custoRevendaKit
        );
    }

    function calcularValorTotalInsumos() {
        const valorInsumos =
            valorVendaRevendaPerfil +
            valorVendaRevendaComponente +
            valorVendaRevendaVidro +
            valorVendaRevendaKit +
            valorVendaRevendaSuperficie;

        atualizarTotalValorInsumos(valorInsumos);
    }

    useEffect(() => {
        definirCustoRevendaPerfilInicial();
        definirCustoRevendaComponenteInicial();
        definirCustoRevendaVidroInicial();
        definirCustoRevendaSuperficieInicial();
        definirCustoRevendaKitInicial();
    }, [orcamentoTecnicoValores]);

    async function salvarPainelOrcamento() {
        if (permiteEdicaoOrcamentoTecnicoVendas()) {
            try {
                let dados = {
                    custoRevendaPerfil,
                    lucroRevendaPerfil,
                    custoRevendaComponente,
                    lucroRevendaComponente,
                    lucroRevendaVidro,
                    custoRevendaVidro,
                    custoRevendaKit: 0,
                    lucroRevendaKit: 0,
                    custoRevendaSuperficie: 0,
                    lucroRevendaSuperficie: 0,
                };

                if (incluirCustosSuperficie) {
                    dados = {
                        ...dados,
                        custoRevendaSuperficie,
                        lucroRevendaSuperficie,
                    };
                }

                if (incluirCustosKit) {
                    dados = {
                        ...dados,
                        custoRevendaKit,
                        lucroRevendaKit,
                    };
                }

                if (orcamentoTecnicoCustoAdicionais) {
                    const custosAdicionais =
                        orcamentoTecnicoCustoAdicionais.map(
                            ({ identificador, ...rest }) => {
                                return rest;
                            }
                        );
                    dados = {
                        ...dados,
                        custosAdicionais,
                    };
                }

                await webApi.post(
                    `orcamentoTecnico/${codOrcamentoTecnico}/versao/${versao}/alterar-total-orcamento-tecnico-valor-insumo`,
                    dados
                );

                notificacao.sucesso('Dados orçamento salvos com sucesso!');
            } catch (error) {
                notificacao.respostaHttp(error);
            }
        }
    }

    useEffect(() => {
        if (
            tipoPrecificacao ===
            tipoPrecificacaoOrcamentoTecnicoVendaConsts.painelOrcamento
        ) {
            if (salvarAlteracoesOrcamento) {
                salvarPainelOrcamento();
                setSalvarAlteracoesOrcamento(false);
            }
        }
    }, [salvarAlteracoesOrcamento]);

    return (
        <>
            {tipoPrecificacao ===
                tipoPrecificacaoOrcamentoTecnicoVendaConsts.painelOrcamento && (
                <div className="box-painel-orcamento">
                    <div className="painel-orcamento">
                        <h3>
                            Painel de <span>Orçamentos</span>
                        </h3>
                        <label className="margem-direita-20">
                            Calcule Também:{' '}
                        </label>
                        <input
                            type="checkbox"
                            id="superficie"
                            name="superficie"
                            className="margem-direita-5"
                            disabled={
                                orcamentoTecnicoValores.temCustoChapaTela ||
                                !permiteEdicaoOrcamentoTecnicoVendas()
                            }
                            defaultChecked={
                                orcamentoTecnicoValores.custoRevendaSuperficie ||
                                orcamentoTecnicoValores.temCustoChapaTela
                            }
                            onClick={(e) => {
                                setIncluirCustoSuperficie(e.target.checked);
                            }}
                        />
                        <label className="margem-direita-20">Superfície</label>

                        <input
                            type="checkbox"
                            id="kits"
                            name="kits"
                            className="margem-direita-5"
                            disabled={
                                orcamentoTecnicoValores.temCustoKit ||
                                !permiteEdicaoOrcamentoTecnicoVendas()
                            }
                            defaultChecked={
                                orcamentoTecnicoValores.custoRevendaKit ||
                                orcamentoTecnicoValores.custoKit
                            }
                            onClick={(e) => {
                                setIncluirCustoKit(e.target.checked);
                            }}
                        />
                        <label>Kits</label>

                        <div className="campos-painel">
                            {/* PERFIL */}
                            <div className="grupo-tipo-campo">
                                <div className="campo-tipo">
                                    <label>
                                        Perfil{' '}
                                        {orcamentoTecnicoValores.temCustoPerfil
                                            ? '(meu custo de revenda)'
                                            : ''}
                                    </label>
                                    <br />

                                    <NumberFormat
                                        placeholder="R$ 0,00"
                                        className={
                                            orcamentoTecnicoValores.temCustoPerfil
                                                ? 'input-bloqueado'
                                                : ''
                                        }
                                        value={custoRevendaPerfil}
                                        decimalScale={2}
                                        fixedDecimalScale
                                        disabled={
                                            orcamentoTecnicoValores.temCustoPerfil ||
                                            !permiteEdicaoOrcamentoTecnicoVendas()
                                        }
                                        prefix="R$ "
                                        decimalSeparator=","
                                        thousandSeparator="."
                                        onValueChange={(values) =>
                                            setCustoRevendaPerfil(
                                                Number(values.value)
                                            )
                                        }
                                    />
                                </div>

                                <div className="campo-margem">
                                    <label>% Margem</label>
                                    <br />
                                    <NumberFormat
                                        placeholder="0 %"
                                        value={lucroRevendaPerfil}
                                        decimalScale={2}
                                        disabled={
                                            !permiteEdicaoOrcamentoTecnicoVendas()
                                        }
                                        fixedDecimalScale
                                        suffix="%"
                                        decimalSeparator=","
                                        onValueChange={(values) =>
                                            atualizarValorVendaRevendaPerfil(
                                                Number(values.value)
                                            )
                                        }
                                        isAllowed={(values) => {
                                            const {
                                                formattedValue,
                                                floatValue,
                                            } = values;
                                            return (
                                                formattedValue === '' ||
                                                (floatValue <= 1000 &&
                                                    floatValue >= -1000)
                                            );
                                        }}
                                    />
                                </div>

                                <div className="campo-valor-revenda">
                                    <label>Meu valor de venda</label>
                                    <br />
                                    <NumberFormat
                                        placeholder="R$ 0,00"
                                        value={valorVendaRevendaPerfil}
                                        decimalScale={2}
                                        fixedDecimalScale
                                        prefix="R$ "
                                        decimalSeparator=","
                                        thousandSeparator="."
                                        onValueChange={(values) =>
                                            setValorVendaRevendaPerfil(
                                                Number(values.value)
                                            )
                                        }
                                        disabled
                                    />
                                </div>
                            </div>

                            {/* COMPONENTE */}
                            <div className="grupo-tipo-campo">
                                <div className="campo-tipo">
                                    <label>
                                        Componente{' '}
                                        {orcamentoTecnicoValores.temCustoComponente
                                            ? '(meu custo de revenda)'
                                            : ''}
                                    </label>
                                    <br />

                                    <NumberFormat
                                        placeholder="R$ 0,00"
                                        className={
                                            orcamentoTecnicoValores.temCustoComponente
                                                ? 'input-bloqueado'
                                                : ''
                                        }
                                        value={custoRevendaComponente}
                                        decimalScale={2}
                                        fixedDecimalScale
                                        disabled={
                                            orcamentoTecnicoValores.temCustoComponente
                                        }
                                        prefix="R$ "
                                        decimalSeparator=","
                                        thousandSeparator="."
                                        onValueChange={(values) =>
                                            setCustoRevendaComponente(
                                                Number(values.value)
                                            )
                                        }
                                    />
                                </div>

                                <div className="campo-margem">
                                    <label>% Margem</label>
                                    <br />
                                    <NumberFormat
                                        placeholder="0 %"
                                        value={lucroRevendaComponente}
                                        decimalScale={2}
                                        disabled={
                                            !permiteEdicaoOrcamentoTecnicoVendas()
                                        }
                                        fixedDecimalScale
                                        suffix="%"
                                        decimalSeparator=","
                                        onValueChange={(values) =>
                                            atualizarValorVendaRevendaComponente(
                                                Number(values.value)
                                            )
                                        }
                                        isAllowed={(values) => {
                                            const {
                                                formattedValue,
                                                floatValue,
                                            } = values;
                                            return (
                                                formattedValue === '' ||
                                                (floatValue <= 1000 &&
                                                    floatValue >= -1000)
                                            );
                                        }}
                                    />
                                </div>

                                <div className="campo-valor-revenda">
                                    <label>Meu valor de venda</label>
                                    <br />
                                    <NumberFormat
                                        placeholder="R$ 0,00"
                                        value={valorVendaRevendaComponente}
                                        decimalScale={2}
                                        fixedDecimalScale
                                        prefix="R$ "
                                        decimalSeparator=","
                                        thousandSeparator="."
                                        disabled
                                    />
                                </div>
                            </div>

                            {/* VIDRO */}
                            <div className="grupo-tipo-campo">
                                <div className="campo-tipo">
                                    <label>
                                        Vidro{' '}
                                        {orcamentoTecnicoValores.temCustoVidro
                                            ? '(meu custo de revenda)'
                                            : ''}
                                    </label>
                                    <br />

                                    <NumberFormat
                                        placeholder="R$ 0,00"
                                        className={
                                            orcamentoTecnicoValores.temCustoVidro
                                                ? 'input-bloqueado'
                                                : ''
                                        }
                                        value={custoRevendaVidro}
                                        decimalScale={2}
                                        fixedDecimalScale
                                        disabled={
                                            orcamentoTecnicoValores.temCustoVidro ||
                                            !permiteEdicaoOrcamentoTecnicoVendas()
                                        }
                                        prefix="R$ "
                                        decimalSeparator=","
                                        thousandSeparator="."
                                        onValueChange={(values) =>
                                            setCustoRevendaVidro(
                                                Number(values.value)
                                            )
                                        }
                                    />
                                </div>

                                <div className="campo-margem">
                                    <label>% Margem</label>
                                    <br />
                                    <NumberFormat
                                        placeholder="0 %"
                                        value={lucroRevendaVidro}
                                        decimalScale={2}
                                        fixedDecimalScale
                                        disabled={
                                            !permiteEdicaoOrcamentoTecnicoVendas()
                                        }
                                        suffix="%"
                                        decimalSeparator=","
                                        onValueChange={(values) =>
                                            atualizarValorVendaRevendaVidro(
                                                Number(values.value)
                                            )
                                        }
                                        isAllowed={(values) => {
                                            const {
                                                formattedValue,
                                                floatValue,
                                            } = values;
                                            return (
                                                formattedValue === '' ||
                                                (floatValue <= 1000 &&
                                                    floatValue >= -1000)
                                            );
                                        }}
                                    />
                                </div>

                                <div className="campo-valor-revenda">
                                    <label>Meu valor de venda</label>
                                    <br />
                                    <NumberFormat
                                        placeholder="R$ 0,00"
                                        value={valorVendaRevendaVidro}
                                        decimalScale={2}
                                        fixedDecimalScale
                                        prefix="R$ "
                                        decimalSeparator=","
                                        thousandSeparator="."
                                        disabled
                                    />
                                </div>
                            </div>

                            {/* SUPERFÍCIE */}
                            {incluirCustosSuperficie && (
                                <div className="grupo-tipo-campo">
                                    <div className="campo-tipo">
                                        <label>
                                            Superfície{' '}
                                            {orcamentoTecnicoValores.temCustoChapaTela
                                                ? '(meu custo da revenda)'
                                                : ''}
                                        </label>
                                        <br />

                                        <NumberFormat
                                            placeholder="R$ 0,00"
                                            className={
                                                orcamentoTecnicoValores.temCustoChapaTela
                                                    ? 'input-bloqueado'
                                                    : ''
                                            }
                                            value={custoRevendaSuperficie}
                                            decimalScale={2}
                                            fixedDecimalScale
                                            disabled={
                                                orcamentoTecnicoValores.temCustoChapaTela ||
                                                !permiteEdicaoOrcamentoTecnicoVendas()
                                            }
                                            prefix="R$ "
                                            decimalSeparator=","
                                            thousandSeparator="."
                                            onValueChange={(values) =>
                                                setCustoRevendaSuperficie(
                                                    Number(values.value)
                                                )
                                            }
                                        />
                                    </div>

                                    <div className="campo-margem">
                                        <label>% Margem</label>
                                        <br />
                                        <NumberFormat
                                            placeholder="0 %"
                                            value={lucroRevendaSuperficie}
                                            decimalScale={2}
                                            fixedDecimalScale
                                            suffix="%"
                                            disabled={
                                                !permiteEdicaoOrcamentoTecnicoVendas()
                                            }
                                            decimalSeparator=","
                                            onValueChange={(values) =>
                                                atualizarValorVendaRevendaSuperficie(
                                                    Number(values.value)
                                                )
                                            }
                                            isAllowed={(values) => {
                                                const {
                                                    formattedValue,
                                                    floatValue,
                                                } = values;
                                                return (
                                                    formattedValue === '' ||
                                                    (floatValue <= 1000 &&
                                                        floatValue >= -1000)
                                                );
                                            }}
                                        />
                                    </div>

                                    <div className="campo-valor-revenda">
                                        <label>Meu valor de venda</label>
                                        <br />
                                        <NumberFormat
                                            placeholder="R$ 0,00"
                                            value={valorVendaRevendaSuperficie}
                                            decimalScale={2}
                                            fixedDecimalScale
                                            disabled
                                            prefix="R$ "
                                            decimalSeparator=","
                                            thousandSeparator="."
                                        />
                                    </div>
                                </div>
                            )}

                            {/* KIT */}
                            {incluirCustosKit && (
                                <div className="grupo-tipo-campo">
                                    <div className="campo-tipo">
                                        <label>Kits</label>
                                        <br />

                                        <NumberFormat
                                            placeholder="R$ 0,00"
                                            className={
                                                orcamentoTecnicoValores.temCustoKit
                                                    ? 'input-bloqueado'
                                                    : ''
                                            }
                                            value={custoRevendaKit}
                                            decimalScale={2}
                                            fixedDecimalScale
                                            disabled={
                                                orcamentoTecnicoValores.temCustoKit ||
                                                !permiteEdicaoOrcamentoTecnicoVendas()
                                            }
                                            prefix="R$ "
                                            decimalSeparator=","
                                            thousandSeparator="."
                                            onValueChange={(values) =>
                                                setCustoRevendaKit(
                                                    Number(values.value)
                                                )
                                            }
                                        />
                                    </div>

                                    <div className="campo-margem">
                                        <label>% Margem</label>
                                        <br />
                                        <NumberFormat
                                            placeholder="0 %"
                                            value={lucroRevendaKit}
                                            decimalScale={2}
                                            fixedDecimalScale
                                            suffix="%"
                                            disabled={
                                                !permiteEdicaoOrcamentoTecnicoVendas()
                                            }
                                            decimalSeparator=","
                                            onValueChange={(values) =>
                                                atualizarValorVendaRevendaKit(
                                                    Number(values.value)
                                                )
                                            }
                                            isAllowed={(values) => {
                                                const {
                                                    formattedValue,
                                                    floatValue,
                                                } = values;
                                                return (
                                                    formattedValue === '' ||
                                                    (floatValue <= 1000 &&
                                                        floatValue >= -1000)
                                                );
                                            }}
                                        />
                                    </div>

                                    <div className="campo-valor-revenda">
                                        <label>Meu valor de venda</label>
                                        <br />
                                        <NumberFormat
                                            placeholder="R$ 0,00"
                                            value={valorVendaRevendaKit}
                                            decimalScale={2}
                                            fixedDecimalScale
                                            disabled
                                            prefix="R$ "
                                            decimalSeparator=","
                                            thousandSeparator="."
                                        />
                                    </div>
                                </div>
                            )}

                            {/* ADICIONAL */}

                            <OrcamentoTecnicoVendaCustosAdicionaisLista />

                            <hr />

                            <div className="grupo-tipo-campo valor-total">
                                <div className="campo-adicional-orcamento">
                                    <label className="servico-adicional">
                                        Total do Custo
                                    </label>
                                    <br />
                                    <NumberFormat
                                        className="input-bloqueado"
                                        placeholder="R$ 0,00"
                                        value={totalCustoRevenda}
                                        decimalScale={2}
                                        disabled
                                        fixedDecimalScale
                                        prefix="R$ "
                                        decimalSeparator=","
                                        thousandSeparator="."
                                    />
                                </div>
                                <div className="campo-valor-revenda ">
                                    <label>Total da Venda (proposta)</label>
                                    <br />
                                    <NumberFormat
                                        className="input-bloqueado"
                                        placeholder="R$ 0,00"
                                        value={totalValorOrcamentoTecnico}
                                        decimalScale={2}
                                        disabled
                                        fixedDecimalScale
                                        prefix="R$ "
                                        decimalSeparator=","
                                        thousandSeparator="."
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
