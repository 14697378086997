import webApi from '../../webapi';

const pustCadastroCliente = async (idIndustria, params) => {
    const data = await webApi.put(
        `/Industrias/${idIndustria}/atualizar`,
        params
    );
    return data;
};

const postCadastroCliente = async (params) => {
    const data = await webApi.post(`/Industrias`, params);
    return data;
};

const getIndustria = async (idIndustria) => {
    const data = await webApi.get(`industrias/${idIndustria}`);
    return data;
};

const getListaUsuario = async (idIndustria) => {
    const data = await webApi.get(`Industrias/${idIndustria}/listar-usuarios`);
    return data;
};
const getListaExtranetIntegracoes = async () => {
    const data = await webApi.get('extranetintegracoes/module-information');
    return data;
};

export {
    getIndustria,
    pustCadastroCliente,
    postCadastroCliente,
    getListaUsuario,
    getListaExtranetIntegracoes,
};
