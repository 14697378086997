import React from 'react';
import PageContent from '~/components/PageContent';
import Itens from './Itens';

export default function Produtos() {
    return (
        <>
            <PageContent titlePage="Produtos">
                <div className="row">
                    <div className="col-12 pr-0">
                        <Itens />
                    </div>
                </div>
            </PageContent>
        </>
    );
}
