import React from 'react';

export default function Footer() {
    return (
        <footer className="footer footer-black footer-white">
            <div className="container-fluid">
                <div className="row">
                    <nav className="footer-nav">
                        <ul>
                            <li>
                                <a
                                    href="docs/termos-de-uso.pdf"
                                    target="_blank"
                                >
                                    Termos de Uso
                                </a>
                            </li>
                        </ul>
                    </nav>
                    <div className="credits ml-auto">
                        <span className="copyright">
                            © 2023 - Desenvolvido por{' '}
                            <a href="http://www.alumisoft.com.br/">
                                Alumisoft Sistemas
                            </a>
                            .
                        </span>
                    </div>
                </div>
            </div>
        </footer>
    );
}
