import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    ReactNode,
} from 'react';
import webApi from 'services/webapi';
import { situacaoOrcamentoIndustriaConsts } from '../consts/situacaoOrcamentoIndustriaConsts';



interface OrcamentoContextProps {
    observacao: string,
    codPedido?: number | null,
    codOrcamento: Number,
    situacaoOrcamentoIndustria: Number,
    situacaoOrcamentoRevenda: Number,
    codOrcamentoTecnico: Number,
    versaoOrcamentoTecnico: Number,
    clienteContatoEmail: string,
    codIndustria?: Number,
    nomeObra?: string,
    codigoModulo?: Number,
    codigoSequencial?: string,
}

interface OrcamentoContextProviderProps {
    children: ReactNode;
}
interface OrcamentoProps {
    definirOrcamentoSelecionado: (
        observacao: string,
        codPedido: number | null,
        codOrcamento: Number,
        situacaoOrcamentoIndustria: Number,
        situacaoOrcamentoRevenda: Number,
        codOrcamentoTecnico: Number,
        versaoOrcamentoTecnico: Number,
        clienteContatoEmail: string,
        codIndustria?: Number,
        nomeObra?: string,
        codigoModulo?:Number,
        codigoSequencial?: string) => void;
    limparOrcamentoSelecionado: () => void;
    alterarSituacaoOrcamentoIndustria: (situacaoOrcamentoIndustria: Number) => void;
    alterarSituacaoPedidoIndustria: (situacaoOrcamentoIndustria: Number, codPedido: number) => void;
    permiteEdicaoOrcamento: () => void;
    permiteEdicaoOrcamentoTecnicoVendas: () => void;
    permiteEdicaoOrcamentoTecnicoPedido: () => void;
    orcamentoStateContext: OrcamentoContextProps;
    valueLine: any;
    setValueLine: (value: any) => void;
    data: any;
    setData: React.Dispatch<any>;
    valorTotal: any;
    setValorTotal: React.Dispatch<any>;
    codOrcamentoTecnicoItem: any;
    setCodOrcamentoTecnicoItem: React.Dispatch<any>;
    dataSolicitacao: any;
    setDataSolicitacao: React.Dispatch<any>;
    isAtivoTecnico: boolean;
    setIsAtivoTecnico: React.Dispatch<React.SetStateAction<boolean>>;
    isItemAvulso: boolean;
    setIsItemAvulso: React.Dispatch<React.SetStateAction<boolean>>;
}



const OrcamentoContext = createContext({} as OrcamentoProps);

const initial = {
    observacao: "",
    codPedido: null,
    codOrcamento: 0,
    situacaoOrcamentoIndustria: 1,
    situacaoOrcamentoRevenda: 1,
    codOrcamentoTecnico: 0,
    versaoOrcamentoTecnico: 0,
    clienteContatoEmail: "",
    codIndustria: 0,
    nomeObra: "",
    codigoModulo: 1,
    codigoSequencial: "",
};

const OrcamentoContextProvider = ({ children }: OrcamentoContextProviderProps) => {
    const [ valueLine, setValueLine] = useState<any>();
    const [ data, setData] = useState<any>();
    const [ dataSolicitacao, setDataSolicitacao] = useState<any>();
    const [valorTotal, setValorTotal] = useState<any>();
    const [ isAtivoTecnico, setIsAtivoTecnico] = useState(true);
    const [ isItemAvulso, setIsItemAvulso] = useState(false);
    const [codOrcamentoTecnicoItem, setCodOrcamentoTecnicoItem] = useState<any>();
    const [orcamentoStateContext, setOrcamentoStateContext] = useState<OrcamentoContextProps>(
        () => {
            const storage = JSON.parse(
                sessionStorage.getItem('@CemMob:OrcamentoContext') as string
            );

            if (!storage) {
                return { ...initial };
            }

            return { ...storage };
        }
    );



    function definirOrcamentoSelecionado(
        observacao: string,
        codPedido: number | null,
        codOrcamento: Number,
        situacaoOrcamentoIndustria: Number,
        situacaoOrcamentoRevenda: Number,
        codOrcamentoTecnico: Number,
        versaoOrcamentoTecnico: Number,
        clienteContatoEmail: string,
        codIndustria?: Number,
        nomeObra?: string,
        codigoModulo?:Number,
        codigoSequencial?: string,

    ) {
        setOrcamentoStateContext({
            observacao,
            codPedido,
            codOrcamento,
            situacaoOrcamentoIndustria,
            situacaoOrcamentoRevenda,
            codOrcamentoTecnico,
            versaoOrcamentoTecnico,
            clienteContatoEmail,
            codIndustria,
            nomeObra,
            codigoModulo,
            codigoSequencial,
        });
    }

    function limparOrcamentoSelecionado() {
        setOrcamentoStateContext({ ...initial });
    }

    function alterarSituacaoOrcamentoIndustria(situacaoOrcamentoIndustria: Number) {
        setOrcamentoStateContext({
            ...orcamentoStateContext,
            situacaoOrcamentoIndustria: situacaoOrcamentoIndustria,
        });
    }


    function alterarSituacaoPedidoIndustria(situacaoOrcamentoIndustria: Number, codPedido?: number) {
        setOrcamentoStateContext({
            ...orcamentoStateContext,
            codPedido: codPedido,
            situacaoOrcamentoIndustria: situacaoOrcamentoIndustria,
        });
    }

    function permiteEdicaoOrcamento() {
        return (
            orcamentoStateContext.situacaoOrcamentoIndustria ===
            situacaoOrcamentoIndustriaConsts.orcamentoRascunho
        );
    }

    function permiteEdicaoOrcamentoTecnicoVendas() {
        return (
            orcamentoStateContext.situacaoOrcamentoIndustria ===
            situacaoOrcamentoIndustriaConsts.orcamentoRecebido ||
            orcamentoStateContext.situacaoOrcamentoIndustria ===
            situacaoOrcamentoIndustriaConsts.orcamentoRetornado ||
            orcamentoStateContext.situacaoOrcamentoIndustria ===
            situacaoOrcamentoIndustriaConsts.propostaEnviada
        );
    }

    function permiteEdicaoOrcamentoTecnicoPedido() {
        return (
            orcamentoStateContext.situacaoOrcamentoIndustria ==
            situacaoOrcamentoIndustriaConsts.pedidoAberto
        );
    }

    useEffect(() => {
        async function loadData() {
            if(!isAtivoTecnico && valueLine){
                const result = await webApi.get(`/Orcamentos/${orcamentoStateContext.codOrcamento}/itens/${valueLine}`);
                setData("");
                setDataSolicitacao(result);
                setIsAtivoTecnico(true);
                setCodOrcamentoTecnicoItem("");
            } else if(isAtivoTecnico && codOrcamentoTecnicoItem) {
                const result = await webApi.get(
                    `/OrcamentoTecnico/produto/${codOrcamentoTecnicoItem}`
                    );
                    setDataSolicitacao("");
                    setData(result);
                    setIsAtivoTecnico(false);
                    setValueLine("");
            }
        }
        loadData();
    }, [valueLine, codOrcamentoTecnicoItem]);

    useEffect(() => {
        if(orcamentoStateContext.codOrcamentoTecnico){
            (async () => {
                const result = await webApi.get(
                    `/OrcamentoTecnico/${orcamentoStateContext.codOrcamentoTecnico}/versao/${orcamentoStateContext.versaoOrcamentoTecnico}/valores`
                );
                setValorTotal(result);
            })();
        }
    }, []);

    useEffect(() => {
        sessionStorage.setItem(
            '@CemMob:OrcamentoContext',
            JSON.stringify(orcamentoStateContext)
        );
    }, [orcamentoStateContext]);

    return (
        <OrcamentoContext.Provider
            value={{
                definirOrcamentoSelecionado,
                limparOrcamentoSelecionado,
                alterarSituacaoOrcamentoIndustria,
                alterarSituacaoPedidoIndustria,
                permiteEdicaoOrcamento,
                permiteEdicaoOrcamentoTecnicoVendas,
                permiteEdicaoOrcamentoTecnicoPedido,
                orcamentoStateContext,
                setValueLine,
                valueLine,
                data,
                setData,
                valorTotal,
                setValorTotal,
                codOrcamentoTecnicoItem,
                setCodOrcamentoTecnicoItem,
                dataSolicitacao,
                setDataSolicitacao,
                isAtivoTecnico,
                setIsAtivoTecnico,
                isItemAvulso,
                setIsItemAvulso,

            }}
        >
            {children}
        </OrcamentoContext.Provider>
    );
};

function useOrcamentoContext() {
    const context = useContext(OrcamentoContext);

    if (!context) {
        throw new Error('hook must be used with an Provider');
    }

    return context;
}

export { OrcamentoContextProvider, useOrcamentoContext };
    function AxiosResponse<T, U>() {
        throw new Error('Function not implemented.');
    }

