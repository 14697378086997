import React from 'react';
import { Input, Label } from 'reactstrap';
import { useOrcamentoContext } from '../../../../../hooks/useOrcamentoContext.tsx';

export default function DataGeral() {
    const { data, isAtivoTecnico, dataSolicitacao } = useOrcamentoContext();

    return (
        <div>
            <div className="row">
                <div className="col-sm-4 mt-3">
                    <Label>Código:</Label>
                    <Input
                        type="text"
                        placeholder="Código..."
                        className="form-control form-control-sm"
                        value={isAtivoTecnico ? data?.codigo : dataSolicitacao?.code }
                        disabled
                    />
                </div>
                <div className="col-sm-8 mt-3">
                    <Label>Descrição:</Label>
                    <Input
                        type="text"
                        placeholder="Descrição..."
                        className="form-control form-control-sm"
                        value={isAtivoTecnico ?  data?.descricao : dataSolicitacao?.description}
                        disabled
                    />
                </div>
                <div className="col-sm-4 mt-3">
                    <Label>Projetista:</Label>
                    <Input
                        type="text"
                        placeholder="Projetista..."
                        className="form-control form-control-sm"
                        value={isAtivoTecnico ?  data?.projetista : dataSolicitacao?.designer || ""}
                        disabled
                    />
                </div>
                <div className="col-sm-4 mt-3">
                    <Label>Linha:</Label>
                    <Input
                        type="text"
                        placeholder="Linha..."
                        className="form-control form-control-sm"
                        value={isAtivoTecnico ?  data?.linha : dataSolicitacao?.line }
                        disabled
                    />
                </div>
                <div className="col-sm-4 mt-3">
                    <Label>Tipo:</Label>
                    <Input
                        type="text"
                        placeholder="Tipo..."
                        className="form-control form-control-sm"
                        value={isAtivoTecnico ?  data?.tipo : dataSolicitacao?.type }
                        disabled
                    />
                </div>
                <div className="col-sm-3 mt-3">
                    <Label>Localização:</Label>
                    <Input
                        type="text"
                        placeholder="Localização..."
                        className="form-control form-control-sm"
                        value={isAtivoTecnico ?  data?.localizacao : dataSolicitacao?.localization }
                        disabled
                    />
                </div>
                <div className="col-sm-3 mt-3">
                    <Label>Quantidade:</Label>
                    <Input
                        type="text"
                        placeholder="Quantidade..."
                        className="form-control form-control-sm"
                        value={isAtivoTecnico ?  data?.quantidade : dataSolicitacao?.amount }
                        disabled
                    />
                </div>
                <div className="col-sm-3 mt-3">
                    <Label>Largura (mm):</Label>
                    <Input
                        type="text"
                        placeholder="Largura (mm)..."
                        className="form-control form-control-sm"
                        value={isAtivoTecnico ?  data?.largura : dataSolicitacao?.width }
                        disabled
                    />
                </div>
                <div className="col-sm-3 mt-3">
                    <Label>Altura (mm):</Label>
                    <Input
                        type="text"
                        placeholder="Altura (mm)..."
                        className="form-control form-control-sm"
                        value={isAtivoTecnico ?  data?.altura : dataSolicitacao?.height }
                        disabled
                    />
                </div>
            </div>
        </div>
    );
}
