import React, {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useState,
} from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { SweetAlertRenderProps } from 'react-bootstrap-sweetalert/dist/types';

interface SweetAlertContextProps {
    exibirDialogoSimNao: (
        isOpen: boolean,
        titulo: String,
        onConfirm: () => void,
        onCancel: () => void | null,
        message: String,

    ) => void;
}

interface SweetAlertProviderProps {
    children: ReactNode;
}

const SweetAlertContext = createContext({} as SweetAlertContextProps);

const SweetAlertContextProvider = ({ children }: SweetAlertProviderProps) => {
    const [alertMessage, setAlertMessage] = useState(<></>);
// Novo Valor
    function exibirDialogoSimNao(
        isOpen: boolean,
        titulo: String,
        onConfirm: () => void,
        onCancel: () => void | null = () => {},
        message: String,

    ) {
        setAlertMessage(
            <SweetAlert
                show
                type="info"
                title={titulo}
                showCancel
                reverseButtons
                cancelBtnBsStyle="warning"
                cancelBtnText="Não"
                confirmBtnText="Sim"
                confirmBtnBsStyle="success"
                onConfirm={() => {
                    onConfirm();
                    setAlertMessage(<></>);
                }}
                onCancel={() => {
                    onCancel();
                    setAlertMessage(<></>);
                }}
            />
        );
    }

    return (
        <SweetAlertContext.Provider value={{ exibirDialogoSimNao }}>
            <>
                {children}
                {alertMessage}
            </>
        </SweetAlertContext.Provider>
    );
};

function useSweetAlertContext() {
    const context = useContext(SweetAlertContext);

    if (!context) {
        throw new Error('hook must be used with an Provider');
    }

    return context;
}

export { SweetAlertContextProvider, useSweetAlertContext };
