import { CotacaoProps } from "shared/interface/cotacao";
import { IOrcamentoVendedor, OrcamentoItemProps } from "shared/interface/orcamento";
import { IPedido } from "shared/interface/pedido";
import { formtJson } from '../function/index';

export function formatDadosOrcamentoMap(value:OrcamentoItemProps):IOrcamentoVendedor{
    return{
       tipo: 1,
       codIndustria: value.codIndustria,
       codOrcamento: value.codOrcamento,
       codOrcamentoTecnico: value.codOrcamentoTecnico,
       codRevenda: value.codRevenda,
       codUsuario: value.codUsuario,
       codigoSequencial: value.codigoSequencial,
       dataCriacao: value.dataCriacao,
       nomeCliente: value.nomeCliente,
       nomeUsuario: value.nomeUsuario,
       nomeIndustria: value.nomeIndustria,
       projetoNome: value.projetoNome,
       situacaoOrcamentoIndustria: value.situacaoOrcamentoIndustria,
       situacaoOrcamentoRevenda: value.situacaoOrcamentoRevenda,
       totalCusto: value.totalCusto,
       totalOrcamentoTecnico: value.totalOrcamentoTecnico,
       versao: value.versao,
       nomeObra: value.nomeObra,
       totalCustoVendedor: value.totalCustoVendedor,
       clienteContatoEmail: "",
       codPedido: null,
       itens: [],
       observacao: value.observacao,
    }
}


export function formatDadosCotacaoMap(value:CotacaoProps):IOrcamentoVendedor{
    return{
       tipo: 2,
       codIndustria: value.industria.codIndustria,
       codOrcamento: value.codCotacao,
       codOrcamentoTecnico: 0,
       codRevenda: value.industria.codIndustria,
       codUsuario: undefined,
       codigoSequencial: String(value.codCotacao),
       dataCriacao: value.dataCotacao,
       nomeCliente: value.nomeUsuario,
       nomeUsuario: value.nomeUsuario,
       nomeIndustria: value.industria.razaoSocial,
       projetoNome: "",
       situacaoOrcamentoIndustria: value.status,
       situacaoOrcamentoRevenda: value.status,
       totalCusto: value.valorCotado,
       totalOrcamentoTecnico: undefined,
       versao: 1,
       nomeObra: "",
       totalCustoVendedor: value.valor,
       clienteContatoEmail: "",
       codPedido: value.codPedido,
       itens: value.itens,
       observacao: value.observacao,
    }
}

export function formatDadosPedidoMap(value:IPedido):IOrcamentoVendedor{
    const items = formtJson(value.itensCotado);
    return{
       tipo: 2,
       codOrcamento: value.codCotacao,
       codPedido: value.codPedido,
       dataCriacao: value.dataPedido,
       codIndustria: value.industria.codIndustria,
       nomeIndustria: value.industria.razaoSocial,
       nomeCliente: value.nomeUsuarioPedido,
       nomeUsuario: value.nomeUsuarioPedido,
       situacaoOrcamentoIndustria: value.status,
       totalCusto: value.valor,
       codigoSequencial: String(value.codCotacao),
       itens: items,
       observacao: value.observacao,

       codOrcamentoTecnico: 0,
       codRevenda: 0,
       codUsuario: undefined,
       projetoNome:  "",
       situacaoOrcamentoRevenda: 0,
       totalOrcamentoTecnico: undefined,
       versao: 1,
       nomeObra: "",
       totalCustoVendedor: 0,
       clienteContatoEmail: "",
    }
}

export function formatDadosPedidoIndustria(value:any):any{
    return{
        codItemCotacao: value.CodItemCotacao,
        codProduto: value.Produto.CodProduto,
        codigo: value.Produto.Codigo,
        codigoErp: value.Produto.CodigoErp,
        descricao: value.Produto.Descricao,
        imagem: value.Produto.Imagem,
        imagemUrl: value.Produto.ImagemUrl,
        item:{
            barra: value.Produto.Barra,
            peso: value.Produto.Peso,
            quantidade: value.Produto.Quantidade,
            tipo: value.Produto.TipoProduto,
            unidade: value.Produto.Unidade,
        },
        tipoUnidade: value.Produto.TipoUnidade,
        quantidadeItem: value.Quantidade,
        valor: value.Valor,
        codCorProduto: value.Cor.CorCorProduto,
        corDescricao: value.Cor.Descricao,
        valorCor: value.Cor.Valor,
    }
};

export function formatDadosCotacaoIndustria(value:any):any{

    return{
        codItemCotacao: value.codItemCotacao,
        codProduto: value.produto.codProduto,
        codigo: value.produto.codigo,
        codigoErp: value.produto.codigoErp,
        descricao: value.produto.descricao,
        imagem: value.produto.imagem,
        imagemUrl: value.produto.imagemUrl,
        item:{
            barra: value.produto.barra,
            peso: value.produto.peso,
            quantidade: value.produto.quantidade,
            tipo: value.produto.tipoProduto,
            unidade: value.produto.unidade,
        },
        tipoUnidade: value.produto.tipoUnidade,
        quantidadeItem: value.quantidade,
        valor: value.valor,
        CodCorProduto: value.cor.corCorProduto,
        corDescricao: value.cor.descricao,
        valorCor: value.cor.valor,
    }
};
