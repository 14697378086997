import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import PerfectScrollbar from 'react-perfect-scrollbar';
import logoEgshop from '~/assets/img/logoEgshop.svg';
import logoEgshopSmall from '~/assets/img/logoEgshop-small.svg';
import logoCemmob from '~/assets/img/logoCemmob.svg';
import logoCemmobSmall from '~/assets/img/logoCemmob-small.svg';
import { useAuthContext } from '~/hooks/useAuthContext';

function SiderBar(props) {
    const {
        ehUsuarioIndustria,
        ehUsuarioRevenda,
        ehUsuarioVendedor,
        signOut,
        state,
    } = useAuthContext();
    const [alertMessage, setAlertMessage] = useState();
    const menuItem = {};

    function handleSetMenuItem(item) {
        // dispatch(setActiveIconSideBar(item));
    }

    const handleSignOut = () => {
        setAlertMessage(
            <SweetAlert
                show
                title="Sair?"
                showCancel
                reverseButtons
                cancelBtnBsStyle="danger"
                cancelBtnText="Não"
                confirmBtnText="Sim, Sair"
                confirmBtnBsStyle="success"
                onConfirm={() => {
                    signOut();
                }}
                onCancel={() => {
                    setAlertMessage(null);
                }}
            >
                Tem certeza que deseja sair?
            </SweetAlert>
        );
    };

    return (
        <PerfectScrollbar>
            <div
                className={`sidebar sidebar-sync ${
                    ehUsuarioVendedor()
                        ? 'egShop'
                        : ehUsuarioRevenda()
                        ? 'egShop'
                        : ''
                }`}
                data-color="primary"
                data-active-color="primary"
            >
                <div className="logo">
                    <Link to="/" className="simple-text logo-mini">
                        <div className="logo-image-small">
                            {ehUsuarioIndustria() ? (
                                <img src={logoCemmobSmall} alt="" width="34" />
                            ) : (
                                <img src={logoEgshopSmall} alt="" width="34" />
                            )}
                        </div>
                    </Link>
                    <Link
                        to="/logo"
                        className="simple-text logo-normal"
                        title="Alterar logotipo"
                    >
                        {state.logo ? (
                            <img
                                src={`data:image/png;base64, ${state.logo}`}
                                alt=""
                                width="122"
                            />
                        ) : ehUsuarioIndustria() ? (
                            <img src={logoCemmob} alt="" width="122" />
                        ) : (
                            <img src={logoEgshop} alt="" width="122" />
                        )}
                    </Link>
                </div>
                <div className="sidebar-wrapper">
                    <ul className="nav mt-0">
                        {ehUsuarioIndustria() && (
                            <li
                                className={
                                    menuItem === 'dashboard' ? 'active' : null
                                }
                            >
                                <Link
                                    to="/dashboard"
                                    onClick={() =>
                                        handleSetMenuItem('dashboard')
                                    }
                                >
                                    <i className="icon-dashborad" />
                                    <p>Dashboard</p>
                                </Link>
                            </li>
                        )}
                        {ehUsuarioIndustria() && (
                            <li
                                className={
                                    menuItem === 'budgetrequest'
                                        ? 'active'
                                        : null
                                }
                            >
                                <Link
                                    to="/orcamentos"
                                    onClick={() =>
                                        handleSetMenuItem('budgetrequest')
                                    }
                                >
                                    <i className="icon-orcamentos" />
                                    <p>Orçamentos</p>
                                </Link>
                            </li>
                        )}
                        {ehUsuarioVendedor() && (
                            <li
                                className={
                                    menuItem === 'budgetrequest'
                                        ? 'active'
                                        : null
                                }
                            >
                                <Link
                                    to="/orcamentoVendedor"
                                    onClick={() =>
                                        handleSetMenuItem('budgetrequest')
                                    }
                                >
                                    <i className="icon-orcamentos" />
                                    <p>Orçamentos</p>
                                </Link>
                            </li>
                        )}
                        {ehUsuarioIndustria() && (
                            <li
                                className={
                                    menuItem === 'budgetrequest'
                                        ? 'active'
                                        : null
                                }
                            >
                                <Link
                                    to="/pedidos"
                                    onClick={() => handleSetMenuItem('pedidos')}
                                >
                                    <i className="fas fa-calculator" />
                                    <p>Pedido Avulso</p>
                                </Link>
                            </li>
                        )}
                        {ehUsuarioIndustria() && (
                            <li
                                className={
                                    menuItem === 'client' ? 'active' : null
                                }
                            >
                                <Link
                                    to="/clientes"
                                    onClick={() => handleSetMenuItem('cliente')}
                                >
                                    <i className="icon-clientes" />
                                    <p>Clientes</p>
                                </Link>
                            </li>
                        )}
                        {ehUsuarioRevenda() && (
                            <li
                                className={
                                    menuItem === 'industrias' ? 'active' : null
                                }
                            >
                                <Link
                                    to="/industrias"
                                    onClick={() =>
                                        handleSetMenuItem('industrias')
                                    }
                                >
                                    <i className="fas fa-users" />
                                    <p>Clientes</p>
                                </Link>
                            </li>
                        )}
                        {ehUsuarioRevenda() && (
                            <li
                                className={
                                    menuItem === 'vendedores' ? 'active' : null
                                }
                            >
                                <Link
                                    to="/vendedores"
                                    onClick={() =>
                                        handleSetMenuItem('vendedores')
                                    }
                                >
                                    <i className="fas fa-user-tie" />
                                    <p>Vendedores</p>
                                </Link>
                            </li>
                        )}
                        {ehUsuarioRevenda() && (
                            <li>
                                <a data-toggle="collapse" href="#cores">
                                    <i className="icon-esquadria" />
                                    <p>
                                        Esquadrias
                                        <b className="caret" />
                                    </p>
                                </a>
                                <div className="collapse mr-2" id="cores">
                                    <ul className="nav">
                                        <li
                                            className={
                                                menuItem === 'vidros'
                                                    ? 'active'
                                                    : null
                                            }
                                        >
                                            <Link
                                                to="/tipologias"
                                                onClick={() =>
                                                    handleSetMenuItem(
                                                        'tipologias'
                                                    )
                                                }
                                            >
                                                <i className="icon-esquadria sidebar-mini-icon" />
                                                <span className="sidebar-normal">
                                                    Lista
                                                </span>
                                            </Link>
                                        </li>
                                        <li
                                            className={
                                                menuItem === 'vidros'
                                                    ? 'active'
                                                    : null
                                            }
                                        >
                                            <Link
                                                to="/vidros"
                                                onClick={() =>
                                                    handleSetMenuItem('vidros')
                                                }
                                            >
                                                <i className="egf egf-vidro sidebar-mini-icon" />
                                                <span className="sidebar-normal">
                                                    Vidros
                                                </span>
                                            </Link>
                                        </li>
                                        <li
                                            className={
                                                menuItem === 'cores-perfis'
                                                    ? 'active'
                                                    : null
                                            }
                                        >
                                            <Link
                                                to="/cores/perfis"
                                                onClick={() =>
                                                    handleSetMenuItem(
                                                        'cores-perfis'
                                                    )
                                                }
                                            >
                                                <i className="egf egf-perfil sidebar-mini-icon" />
                                                <span className="sidebar-normal">
                                                    Cores Perfis
                                                </span>
                                            </Link>
                                        </li>
                                        <li
                                            className={
                                                menuItem === 'cores-componente'
                                                    ? 'active'
                                                    : null
                                            }
                                        >
                                            <Link
                                                to="/cores/componentes"
                                                onClick={() =>
                                                    handleSetMenuItem(
                                                        'cores-componente'
                                                    )
                                                }
                                            >
                                                <i className="egf egf-componente sidebar-mini-icon" />
                                                <span className="sidebar-normal">
                                                    Cores Componentes
                                                </span>
                                            </Link>
                                        </li>
                                        <li
                                            className={
                                                menuItem === 'cores-vidros'
                                                    ? 'active'
                                                    : null
                                            }
                                        >
                                            <Link
                                                to="/cores/vidros"
                                                onClick={() =>
                                                    handleSetMenuItem(
                                                        'cores-vidros'
                                                    )
                                                }
                                            >
                                                <i className="egf egf-vidro sidebar-mini-icon" />
                                                <span className="sidebar-normal">
                                                    Cores Vidros
                                                </span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        )}
                        {ehUsuarioRevenda() && (
                            <li>
                                <a data-toggle="collapse" href="#tabelaPreco">
                                    <i className="far fa-list-alt" />
                                    <p>
                                        Itens Avulsos
                                        <b className="caret" />
                                    </p>
                                </a>

                                <div className="collapse mr-2" id="tabelaPreco">
                                    <ul className="nav">
                                        <li
                                            className={
                                                menuItem === 'catalogo'
                                                    ? 'active'
                                                    : null
                                            }
                                        >
                                            <Link
                                                to="/catalogo"
                                                onClick={() =>
                                                    handleSetMenuItem(
                                                        'catalogo'
                                                    )
                                                }
                                            >
                                                <i className="far fa-list-alt sidebar-mini-icon" />
                                                <span className="sidebar-normal">
                                                    Lista
                                                </span>
                                            </Link>
                                        </li>
                                        <li
                                            className={
                                                menuItem === 'tabelaPreco'
                                                    ? 'active'
                                                    : null
                                            }
                                        >
                                            <Link
                                                to="/tabelaPreco"
                                                onClick={() =>
                                                    handleSetMenuItem(
                                                        'tabelaPreco'
                                                    )
                                                }
                                            >
                                                <i className="fas fa-tasks sidebar-mini-icon" />
                                                <span className="sidebar-normal">
                                                    Tabela de Preço
                                                </span>
                                            </Link>
                                        </li>
                                        <li
                                            className={
                                                menuItem === 'corAvulso'
                                                    ? 'active'
                                                    : null
                                            }
                                        >
                                            <Link
                                                to="/coresAvulso"
                                                onClick={() =>
                                                    handleSetMenuItem(
                                                        'corAvulso'
                                                    )
                                                }
                                            >
                                                <i className="fas fa-paint-brush sidebar-mini-icon" />
                                                <span className="sidebar-normal">
                                                    Cores
                                                </span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        )}
                        {ehUsuarioRevenda() && (
                            <li
                                className={
                                    menuItem === 'parameters' ? 'active' : null
                                }
                            >
                                <a
                                    className="btn-rotate"
                                    data-toggle="collapse"
                                    href="#configuracoes"
                                >
                                    <i className="nc-icon nc-settings-gear-65" />
                                    <p>
                                        Configurações <b className="caret" />
                                    </p>
                                </a>
                                <div className="collapse " id="configuracoes">
                                    <ul className="nav">
                                        <li
                                            className={
                                                menuItem === 'orcamento'
                                                    ? 'active'
                                                    : null
                                            }
                                        >
                                            <Link
                                                to="/configuracoes/orcamento"
                                                onClick={() =>
                                                    handleSetMenuItem(
                                                        'orcamento'
                                                    )
                                                }
                                            >
                                                <i className="fas icon-orcamentos sidebar-mini-icon" />
                                                <span className="sidebar-normal">
                                                    Orçamentos
                                                </span>
                                            </Link>
                                        </li>
                                        <li
                                            className={
                                                menuItem === 'parametrizacao'
                                                    ? 'active'
                                                    : null
                                            }
                                        >
                                            <Link
                                                to="/configuracoes/parametrizacao"
                                                onClick={() =>
                                                    handleSetMenuItem(
                                                        'parametrizacao'
                                                    )
                                                }
                                            >
                                                <i className="fas fa-tasks sidebar-mini-icon" />
                                                <span className="sidebar-normal">
                                                    Parametrização
                                                </span>
                                            </Link>
                                        </li>
                                        <li
                                            className={
                                                menuItem === 'usuarios'
                                                    ? 'active'
                                                    : null
                                            }
                                        >
                                            <Link
                                                to="/configuracoes/usuarios"
                                                onClick={() =>
                                                    handleSetMenuItem(
                                                        'usuarios'
                                                    )
                                                }
                                            >
                                                <i className="fas fa-users  sidebar-mini-icon" />
                                                <span className="sidebar-normal">
                                                    Usuários
                                                </span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        )}
                        {ehUsuarioIndustria() && (
                            <li
                                className={
                                    menuItem === 'notification'
                                        ? 'active'
                                        : null
                                }
                            >
                                <Link
                                    to="/notificacoes"
                                    onClick={() =>
                                        handleSetMenuItem('notification')
                                    }
                                >
                                    <i className="icon-alerta" />
                                    <p>Notificações</p>
                                </Link>
                            </li>
                        )}
                        <li>
                            <a data-toggle="collapse" href="#ajuda">
                                <i className="icon-ajuda" />
                                <p>
                                    Ajuda <b className="caret" />
                                </p>
                            </a>
                            <div className="collapse " id="ajuda">
                                <ul className="nav">
                                    <li>
                                        <Link
                                            to="/support"
                                            onClick={() =>
                                                handleSetMenuItem('support')
                                            }
                                        >
                                            <i className="far fa-envelope sidebar-mini-icon" />
                                            <span className="sidebar-normal">
                                                Contato
                                            </span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="/tutorial"
                                            onClick={() =>
                                                handleSetMenuItem('tutorial')
                                            }
                                        >
                                            <i className="fas fa-graduation-cap sidebar-mini-icon" />
                                            <span className="sidebar-normal">
                                                Tutorial
                                            </span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li
                            className={
                                menuItem === 'notification' ? 'active' : null
                            }
                        >
                            <a onClick={() => handleSignOut()}>
                                <i className="nc-icon nc-single-02" />
                                <p>Sair</p>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            {alertMessage}
        </PerfectScrollbar>
    );
}

export default SiderBar;
