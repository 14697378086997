import React from 'react';
import { Label, Input } from 'reactstrap';

export interface ISelectProps {
    label?: string;
    valueLabel?: string;
    innerRef?: React.Ref<HTMLInputElement | HTMLTextAreaElement>;
    name?: string;
    value?: string;
    disabled?: boolean;
    data: ISelect[];
    isShadow?: boolean;
    width?: string;
    onChange: (value: any) => void;
}

export interface ISelect {
    label: number;
    name: string;
}

export default function SelectComponent({
    label,
    valueLabel,
    width,
    innerRef,
    name,
    data,
    isShadow,
    disabled,
    onChange,
}: ISelectProps) {
    return (
        <div style={{ display: valueLabel, alignItems: 'baseline' }}>
            <div className="d-flex justify-content-between">
                <Label
                    style={{
                        color: '#57607A',
                        width: width,
                        textAlign: 'center',
                        alignItems: 'baseline',
                    }}
                    className="mr-1"
                >
                    {label}
                </Label>
            </div>
            <Input
                innerRef={innerRef}
                disabled={disabled}
                type="select"
                name={name}
                className={
                    isShadow
                        ? 'form-control form-control-sm'
                        : 'form-control form-control-sm boxButton'
                }
                onChange={(e) => onChange(e.target.value)}
            >
                <option value="0">Selecione...</option>
                {data.map((item) => {
                    return (
                        <option key={item.label} value={Number(item.label)}>
                            {item.name}
                        </option>
                    );
                })}
            </Input>
        </div>
    );
}
