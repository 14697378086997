import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';

const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
    return (
        <>
            <Input
                style={{ width: '100%' }}
                maxLength={30}
                className="pull-right filter-input"
                value={globalFilter || ''}
                onChange={(e) => {
                    setGlobalFilter(e.target.value || undefined);
                }}
                placeholder="Pesquisar ..."
            />
        </>
    );
};

GlobalFilter.propTypes = {
    setGlobalFilter: PropTypes.func.isRequired,
};

export default GlobalFilter;
