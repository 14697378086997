import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Label, FormFeedback, Button } from 'reactstrap';
import { useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import * as yup from 'yup';

import webapi from '~/services/webapi';
import history from '~/services/history';
import PageContent from '~/components/PageContent';
import NavigationSubArea from '~/components/OrcamentoNavegacao';
import { reactSelectCustomStyles } from '~/styles/react-select';
import OrcamentoNavegacaoSuperior from '~/components/OrcamentoNavegacaoSuperior';
import { useOrcamentoContext } from '~/hooks/useOrcamentoContext';

const schema = yup.object({
    profileColor: yup.object().nullable().shape({
        label: yup.string().nullable(),
        value: yup.string().nullable(),
    }),
    componentColor: yup.object().nullable().shape({
        label: yup.string().nullable(),
        value: yup.string().nullable(),
    }),
    glassColor: yup.object().nullable().shape({
        label: yup.string().nullable(),
        value: yup.string().nullable(),
    }),
});

const defaultValues = {
    profileColor: '',
    componentColor: '',
    glassColor: '',
};

export default function OrcamentoCor() {
    // hooks
    const { permiteEdicaoOrcamento } = useOrcamentoContext();

    const {
        register,
        triggerValidation,
        getValues,
        setValue,
        control,
        errors,
    } = useForm({
        mode: 'onChange',
        defaultValues,
        validationSchema: schema,
    });

    // consts
    const { codOrcamento } = useParams();

    // states
    const [useExternalInternalColor, setUseExternalInternalColor] =
        useState(false);

    // functions
    const callStore = async () => {
        const valid = await triggerValidation();
        if (valid) {
            const {
                profileColor: {
                    value: profileColor,
                    label: profileColorName,
                } = {},
                profileExternalColor: {
                    value: profileExternalColor,
                    label: profileExternalColorName,
                } = {},
                profileInternalColor: {
                    value: profileInternalColor,
                    label: profileInternalColorName,
                } = {},
                externalInternalColor,
                componentColor: {
                    value: componentColor,
                    label: componentColorName,
                } = {},
                glassColor: { value: glassColor, label: glassColorName } = {},
            } = getValues();

            await webapi.post(`/orcamentos/cor`, {
                codOrcamento: codOrcamento,
                profileColor,
                profileColorName,
                externalInternalColor,
                profileExternalColor,
                profileExternalColorName,
                profileInternalColor,
                profileInternalColorName,
                componentColor,
                componentColorName,
                glassColor,
                glassColorName,
            });
        }
    };

    const handleValid = async () => {
        if (permiteEdicaoOrcamento()) {
            const valid = await triggerValidation();
            return valid;
        }
    };

    const handleSave = async () => {
        if (permiteEdicaoOrcamento()) {
            await callStore();
        }
    };

    const handleClickNext = async () => {
        const urlToPush = `/orcamentos/${codOrcamento}/itens`;
        if (permiteEdicaoOrcamento()) {
            const valid = await triggerValidation();
            if (valid) {
                await callStore();
                history.push(urlToPush);
            }
        } else history.push(urlToPush);
    };

    const handleClickPrevious = async () => {
        const urlToPush = `/orcamentos/${codOrcamento}/obra`;
        if (!permiteEdicaoOrcamento()) {
            await callStore();
            history.push(`/orcamentos/${codOrcamento}/obra`);
        } else history.push(urlToPush);
    };

    const obterCoresPerfis = async (filter) => {
        const result = await webapi.post(`/cores/perfis`, { filter: filter });
        const colors = result.records.map((m) => {
            return {
                label: m.description,
                value: m.code,
            };
        });

        return colors;
    };

    const obterCoresComponentes = async (filter) => {
        const result = await webapi.post(`/cores/componentes`, {
            filter: filter,
        });
        const colors = result.records.map((m) => {
            return {
                label: m.description,
                value: m.code,
            };
        });

        return colors;
    };

    const obterCoresVidros = async (filter) => {
        const result = await webapi.post(`/cores/vidros`, { filter: filter });
        const colors = result.records.map((m) => {
            return {
                label: m.description,
                value: m.code,
            };
        });
        return colors;
    };

    useEffect(() => {
        const fetchData = async () => {
            const result = await webapi.get(`/orcamentos/${codOrcamento}/cor`);

            const {
                profileColor,
                profileColorName,
                externalInternalColor,
                profileExternalColor,
                profileExternalColorName,
                profileInternalColor,
                profileInternalColorName,
                componentColor,
                componentColorName,
                glassColor,
                glassColorName,
            } = result;

            if (profileColor) {
                setValue('profileColor', {
                    value: profileColor,
                    label: profileColorName,
                });
                setValue('profileColorName', profileColorName);
            }

            setValue('externalInternalColor', externalInternalColor);
            setUseExternalInternalColor(externalInternalColor);

            if (profileExternalColor) {
                setValue('profileExternalColor', {
                    value: profileExternalColor,
                    label: profileExternalColorName,
                });
                setValue('profileExternalColorName', profileExternalColorName);
            }

            if (profileInternalColor) {
                setValue('profileInternalColor', {
                    value: profileInternalColor,
                    label: profileInternalColorName,
                });
                setValue('profileInternalColorName', profileInternalColorName);
            }

            if (componentColor) {
                setValue('componentColor', {
                    value: componentColor,
                    label: componentColorName,
                });
                setValue('componentColorName', componentColorName);
            }

            if (glassColor) {
                setValue('glassColor', {
                    value: glassColor,
                    label: glassColorName,
                });
                setValue('glassColorName', glassColorName);
            }
        };
        if (codOrcamento) fetchData();
    }, []);

    return (
        <>
            <PageContent className="no-border">
                <OrcamentoNavegacaoSuperior />
            </PageContent>

            <PageContent>
                <NavigationSubArea
                    handleValid={() => handleValid()}
                    handleSave={() => handleSave()}
                    active="Color"
                >
                    <form>
                        <div className="row">
                            <div className="col-sm-8 mt-3 pintura">
                                <Label
                                    style={{
                                        color: '#57607A',
                                        fontSize: '16px',
                                    }}
                                >
                                    Pintura / Cor dos Perfis
                                </Label>
                                <Controller
                                    innerRef={register}
                                    name="profileColor"
                                    styles={reactSelectCustomStyles}
                                    classNamePrefix="react-select"
                                    placeholder="Pintura / Cor dos Perfis:..."
                                    defaultOptions
                                    loadOptions={(filter) =>
                                        obterCoresPerfis(filter)
                                    }
                                    onChange={([selected]) => {
                                        return selected;
                                    }}
                                    as={AsyncSelect}
                                    control={control}
                                    isClearable={true}
                                    noOptionsMessage={() => null}
                                    isDisabled={!permiteEdicaoOrcamento()}
                                />
                                <FormFeedback>
                                    {errors?.profileColor?.message}
                                </FormFeedback>
                                <input
                                    type="hidden"
                                    ref={register}
                                    name="profileColorName"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-8 mt-3">
                                <div className="form-check pintura">
                                    <label className="form-check-label">
                                        <input
                                            ref={register}
                                            name="externalInternalColor"
                                            className="form-check-input"
                                            type="checkbox"
                                            data-toggle="collapse"
                                            onChange={() =>
                                                setUseExternalInternalColor(
                                                    !useExternalInternalColor
                                                )
                                            }
                                            disabled={!permiteEdicaoOrcamento()}
                                        />
                                        <span className="form-check-sign"></span>
                                        COR INTERNA/EXTERNA
                                    </label>
                                </div>
                            </div>
                        </div>
                        {useExternalInternalColor && (
                            <div className="row">
                                <div className="col-sm-6 mt-3 pintura">
                                    <Label
                                        style={{
                                            color: '#57607A',
                                            fontSize: '16px',
                                        }}
                                    >
                                        Cor Externa
                                    </Label>
                                    <Controller
                                        name="profileExternalColor"
                                        styles={reactSelectCustomStyles}
                                        classNamePrefix="react-select"
                                        placeholder="Cor Externa..."
                                        defaultOptions
                                        loadOptions={(filter) =>
                                            obterCoresPerfis(filter)
                                        }
                                        onChange={([selected]) => {
                                            return selected;
                                        }}
                                        as={AsyncSelect}
                                        control={control}
                                        isClearable={true}
                                        className={
                                            !!errors?.name?.message
                                                ? 'invalid is-invalid '
                                                : ''
                                        }
                                        noOptionsMessage={() => null}
                                        isDisabled={!permiteEdicaoOrcamento()}
                                    />
                                    <input
                                        type="hidden"
                                        ref={register}
                                        name="profileExternalColorName"
                                    />
                                </div>
                                <div className="col-sm-6 mt-3">
                                    <Label
                                        style={{
                                            color: '#57607A',
                                            fontSize: '16px',
                                        }}
                                    >
                                        Cor Interna
                                    </Label>
                                    <Controller
                                        name="profileInternalColor"
                                        styles={reactSelectCustomStyles}
                                        classNamePrefix="react-select"
                                        placeholder="Cor Interna..."
                                        defaultOptions
                                        loadOptions={(filter) =>
                                            obterCoresPerfis(filter)
                                        }
                                        onChange={([selected]) => {
                                            return selected;
                                        }}
                                        as={AsyncSelect}
                                        control={control}
                                        isClearable={true}
                                        className={
                                            !!errors?.name?.message
                                                ? 'invalid is-invalid '
                                                : ''
                                        }
                                        noOptionsMessage={() => null}
                                        isDisabled={!permiteEdicaoOrcamento()}
                                    />
                                    <input
                                        type="hidden"
                                        ref={register}
                                        name="profileInternalColorName"
                                        style={{ marginLeft: '-25px' }}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="row">
                            <div className="col-sm-5 mt-3 pintura">
                                <Label
                                    style={{
                                        color: '#57607A',
                                        fontSize: '16px',
                                    }}
                                >
                                    Cor Componentes
                                </Label>
                                <Controller
                                    name="componentColor"
                                    styles={reactSelectCustomStyles}
                                    classNamePrefix="react-select"
                                    placeholder="Cor Componentes..."
                                    defaultOptions
                                    loadOptions={(filter) =>
                                        obterCoresComponentes(filter)
                                    }
                                    onChange={([selected]) => {
                                        return selected;
                                    }}
                                    as={AsyncSelect}
                                    control={control}
                                    isClearable={true}
                                    className={
                                        !!errors?.componentColor?.message
                                            ? 'invalid is-invalid '
                                            : ''
                                    }
                                    noOptionsMessage={() => null}
                                    rules={{ required: true }}
                                    isDisabled={!permiteEdicaoOrcamento()}
                                />
                                <FormFeedback>
                                    {errors?.componentColor?.message}
                                </FormFeedback>
                                <input
                                    type="hidden"
                                    ref={register}
                                    name="componentColorName"
                                />
                            </div>
                            <div className="col-sm-5 mt-3">
                                <Label
                                    style={{
                                        color: '#57607A',
                                        fontSize: '16px',
                                    }}
                                >
                                    Cor Vidros:
                                </Label>
                                <Controller
                                    name="glassColor"
                                    styles={reactSelectCustomStyles}
                                    classNamePrefix="react-select"
                                    placeholder="Cor Vidros..."
                                    defaultOptions
                                    loadOptions={(filter) =>
                                        obterCoresVidros(filter)
                                    }
                                    onChange={([selected]) => {
                                        return selected;
                                    }}
                                    as={AsyncSelect}
                                    control={control}
                                    isClearable={true}
                                    className={
                                        !!errors?.glassColor?.message
                                            ? 'invalid is-invalid '
                                            : ''
                                    }
                                    noOptionsMessage={() => null}
                                    rules={{ required: true }}
                                    isDisabled={!permiteEdicaoOrcamento()}
                                />
                                <FormFeedback>
                                    {errors?.glassColor?.message}
                                </FormFeedback>
                                <input
                                    type="hidden"
                                    ref={register}
                                    name="glassColorName"
                                />
                            </div>
                        </div>
                        <div className="mt-3" style={{ fontSize: '18px' }}>
                            <div className="pull-left">
                                <Button
                                    type="button"
                                    role="tablist"
                                    className="boxButtonAvancar pintura"
                                    style={{ backgroundColor: '#ABAFBC' }}
                                    onClick={() => {
                                        handleClickPrevious();
                                    }}
                                >
                                    <i
                                        className="icon-icon-voltar mr-1"
                                        style={{ fontSize: '18px' }}
                                    />
                                    {' Voltar'}
                                </Button>
                            </div>
                            <div
                                className="pull-right"
                                style={{ fontSize: '18px' }}
                            >
                                <Button
                                    type="button"
                                    role="tablist"
                                    className="boxButtonAvancar"
                                    style={{ backgroundColor: '#3073CB' }}
                                    onClick={() => handleClickNext()}
                                >
                                    Avançar{' '}
                                    <i
                                        className="icon-icon-avancar ml-1"
                                        style={{ fontSize: '18px' }}
                                    />
                                </Button>
                            </div>
                        </div>
                    </form>
                </NavigationSubArea>
            </PageContent>
        </>
    );
}
