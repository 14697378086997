import React from 'react';
import styles from './Indicador.module.css';
import { Col, Row, Card, CardBody, CardTitle} from 'reactstrap';

interface IndicadorResponsiveProps {
    total: Number;
    texto: string;
    status: any;
    tipo: 'PROPOSTAPENDENTE' | 'PEDIDOPENDENTE' | 'PROPOSTAFECHADA' | 'PROPOSTAREJEITADA' | 'PEDIDOACEITE' | 'SOLICITADA';
}

export function IndicadorResponsive({ total, texto, tipo, status }: IndicadorResponsiveProps) {
    return (
        <>
            <Col className="px-0 pl-2 pr-2 pr-sm-0">
                <Card>
                    <CardBody>
                        <Row>
                            <Col>
                                <p>
                                    <b className={`
                                    ${tipo === 'PROPOSTAPENDENTE' ?
                                            ` ${styles.b1}` : ''}
                                    ${tipo === 'PEDIDOPENDENTE' ?
                                            ` ${styles.b2}` : ''}
                                    ${tipo === 'PEDIDOACEITE' ?
                                            ` ${styles.b3}` : ''}
                                    ${tipo === 'PROPOSTAREJEITADA' ?
                                            ` ${styles.b4}` : ''}
                                    ${tipo === 'PROPOSTAFECHADA' ?
                                            ` ${styles.b5}` : ''}
                                    ${tipo === 'SOLICITADA' ?
                                            ` ${styles.b6}` : ''}`}>{texto}</b>
                                </p>
                            </Col>
                            <Col>
                                <CardTitle className={`${styles.numeros}`}> {String(total)}</CardTitle>
                            </Col>
                        </Row>
                    </CardBody>
                        <hr className={`
                    ${tipo === 'PROPOSTAPENDENTE' ?
                            ` ${styles.borde1}` : ''}
                    ${tipo === 'PEDIDOPENDENTE' ?
                            ` ${styles.borde2}` : ''}
                    ${tipo === 'PEDIDOACEITE' ?
                            ` ${styles.borde3}` : ''}
                    ${tipo === 'PROPOSTAREJEITADA' ?
                            ` ${styles.borde4}` : ''}
                    ${tipo === 'PROPOSTAFECHADA' ?
                            ` ${styles.borde5}` : ''}
                    ${tipo === 'SOLICITADA' ?
                            ` ${styles.borde6}` : ''}`} />
                </Card>
            </Col>
        </>
    )
}
