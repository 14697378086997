import React, { useRef, useEffect, useState } from 'react';
import {
    Label,
    Button,
    FormGroup,
    ModalBody,
    ModalFooter,
    Col,
    Row,
} from 'reactstrap';
import FormFieldErrorValidation from '../../../components/FormFieldErrorValidation';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import webapi from '../../../services/webapi';
import { useNotifyContext } from '../../../hooks/useNotifyContext';
import Switch from 'react-bootstrap-switch';


export interface IDadosProps {
    active: boolean,
    authenticate: boolean,
    codPerfilUsuario:number,
    codUsuario:number,
    email:string,
    name: string,
    roleAdmin: string,
 }

interface IUsuario {
    idUsuario:any;
    onRefresh:(value:boolean) => void;
    onClose: () => void;
}

function FormularioUsuario({idUsuario, onRefresh, onClose}:IUsuario){
    const notificacao = useNotifyContext();
    const formRef = useRef(idUsuario);

    const [ loadingForm, setLoadingForm] = useState(false);

    const handleClickEdit = async (idUsuario:any) => {
        console.log('codUsuario', idUsuario);

        const result:IDadosProps = await webapi.get(`revenda/usuarios/${idUsuario}`);
        formRef.current.setValues(result);
    };

    useEffect(() => {
        if(idUsuario !== 0) handleClickEdit(idUsuario);
    },[])

console.log("idUsuario", idUsuario);
    return(
        <>
        <ModalBody>
                <Formik
                    innerRef={formRef}
                    initialValues={{
                        name: '',
                        email: '',
                        active: false,
                        roleAdmin: false,
                    }}
                    validateOnChange={false}
                    validateOnBlur
                    validationSchema={Yup.object({
                        name: Yup.string().required(
                            'Campo Obrigatório'
                        ),
                        email: Yup.string()
                            .email('Formato de e-mail inválido')
                            .required('Campo Obrigatório'),
                    })}
                    onSubmit={async (values, { setSubmitting }) => {
                        try {
                            setLoadingForm(true);
                            const {
                                name,
                                email,
                                active,
                                roleAdmin,
                            } = values;

                            const requestData = {
                                codUsuario: idUsuario,
                                name,
                                email,
                                active,
                                roleAdmin,
                            };

                            if (idUsuario > 0)
                                await webapi.put(
                                    'revenda/usuarios',
                                    requestData
                                );
                            else
                                await webapi.post(
                                    'revenda/usuarios',
                                    requestData
                                );

                            onClose();
                            onRefresh(false);
                            setLoadingForm(false);
                            setSubmitting(false);
                        } catch (error) {
                            notificacao.respostaHttp(error);
                        }
                    }}
                >
                    {(formik) => (
                        <form onSubmit={formik.handleSubmit}>
                            <div className="row mb-3">
                                <div className="col-md-12 mt-3">
                                    <FormGroup>
                                        <Label
                                            style={{
                                                color: '#57607A',
                                                fontSize: '16px',
                                            }}
                                        >
                                            Nome
                                        </Label>
                                        <Field
                                            type="text"
                                            name="name"
                                            placeholder="Nome"
                                            style={{
                                                boxShadow:
                                                    'rgb(198 203 212) 0px 6px 15px -10px',
                                                borderRadius:
                                                    '10px',
                                            }}
                                            className={
                                                formik.errors
                                                    .name &&
                                                formik.touched.name
                                                    ? 'form-control form-control-sm invalid is-invalid '
                                                    : 'form-control form-control-sm'
                                            }
                                        />
                                        <FormFieldErrorValidation
                                            error={
                                                formik.errors.name
                                            }
                                            touched={
                                                formik.touched.name
                                            }
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label
                                            style={{
                                                color: '#57607A',
                                                fontSize: '16px',
                                            }}
                                        >
                                            E-mail
                                        </Label>
                                        <Field
                                            type="text"
                                            name="email"
                                            placeholder="E-mail"
                                            style={{
                                                boxShadow:
                                                    'rgb(198 203 212) 0px 6px 15px -10px',
                                                borderRadius:
                                                    '10px',
                                            }}
                                            className={
                                                formik.errors
                                                    .email &&
                                                formik.touched.email
                                                    ? 'form-control form-control-sm invalid is-invalid '
                                                    : 'form-control form-control-sm'
                                            }
                                            disabled={
                                                idUsuario &&
                                                'disabled'
                                            }
                                        />
                                        <FormFieldErrorValidation
                                            error={
                                                formik.errors.email
                                            }
                                            touched={
                                                formik.touched.email
                                            }
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                            <Row>
                                <Col>
                                    <FormGroup className="pull-right">
                                        <Label>
                                            <span className="mr-2">
                                                Status
                                            </span>
                                            <Switch
                                                value={
                                                    formik.values
                                                        .active
                                                }
                                                offColor=""
                                                offText={
                                                    <i className="nc-icon nc-simple-remove" />
                                                }
                                                onColor="success"
                                                onChange={(
                                                    elm,
                                                    state
                                                ) =>
                                                    formik.setFieldValue(
                                                        'active',
                                                        state
                                                    )
                                                }
                                                onText={
                                                    <i className="nc-icon nc-check-2" />
                                                }
                                            />
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup className="pull-right">
                                        <Label>
                                            <span className="mr-2">
                                                Administrador
                                            </span>
                                            <Switch
                                                value={
                                                    formik.values
                                                        .roleAdmin
                                                }
                                                offColor="success"
                                                offText={
                                                    <i className="nc-icon nc-simple-remove" />
                                                }
                                                onColor="success"
                                                onChange={(
                                                    elm,
                                                    state
                                                ) =>
                                                    formik.setFieldValue(
                                                        'roleAdmin',
                                                        state
                                                    )
                                                }
                                                onText={
                                                    <i className="nc-icon nc-check-2" />
                                                }
                                            />
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </form>
                    )}
                </Formik>
            </ModalBody>
            <ModalFooter>
                <div className="left-side">
                    <button
                        type="button"
                        className="btn btn-link"
                        onClick={() => onClose()}
                    >
                        Fechar
                    </button>
                </div>
                <div className="divider" />
                <div className="right-side">
                    <Button
                        className="boxButton btn btn-success btn-link"
                        onClick={() =>
                            formRef.current.handleSubmit()
                        }
                        disabled={loadingForm}
                    >
                        {loadingForm && (
                            <i
                                className="fa fa-refresh fa-spin fa-1x"
                                style={{ marginRight: '5px' }}
                            />
                        )}
                        Salvar
                    </Button>
                </div>
            </ModalFooter>
        </>
    )
};

export default FormularioUsuario;
