import React from 'react';
import {
    Input,
    InputGroup,
    InputGroupText,
    Col,
    Row,
} from 'reactstrap';

interface ISearch{
    value: string | undefined;
    onChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
}
export default function Search({value, onChange}:ISearch) {
    return(
        <Row className="mx-0" style={{ justifyContent:'end'}}>
        <Col xl={4} lg={5} md={6} sm={6} xs={12} >
            <InputGroup>
                <Input
                    type="search"
                    placeholder="Pesquisa..."
                    value={value}
                    onChange={onChange}
                />
                <InputGroupText
                    style={{
                        borderRadius: '0px',
                        borderTopRightRadius: '3px',
                        borderBottomRightRadius: '3px',
                    }}
                >
                    <i
                        className="icon-icon-busca"
                        style={{
                            fontSize: 18,
                        }}
                    />
                </InputGroupText>
            </InputGroup>
        </Col>
    </Row>
    );
};
