import React, { useState } from 'react';
import { Button } from 'reactstrap';

export default function ButtonSpin({
    className,
    title,
    ìcon,
    onClick,
    disabled,
}) {
    const [loading, setLoading] = useState(false);

    async function handleClick() {
        setLoading(true);
        try {
            await onClick();
        } finally {
            setLoading(false);
        }
    }

    return (
        <Button
            className={className}
            onClick={() => handleClick()}
            disabled={loading || disabled}
        >
            {loading && (
                <i
                    className="fa fa-refresh fa-spin fa-1x"
                    style={{ marginRight: '5px' }}
                />
            )}
            {!loading && <i className={ìcon} />} {title}
        </Button>
    );
}
