import React from 'react';
import { situacaoOrcamentoIndustriaConsts } from '~/consts/situacaoOrcamentoIndustriaConsts';
import styles from '../CotacaoStatus/CotacaoStatus.module.css';

export default function StatusOrcamento({
    situacaoOrcamentoIndustria,
    className = '',
}) {
    return (
        <>
            {situacaoOrcamentoIndustria ===
                situacaoOrcamentoIndustriaConsts.orcamentoRascunho && (
                <>
                    <span
                        className={`${styles.iconRascunho}`}
                        title="Rascunho"
                    />
                    <span>Cotação em Rascunho</span>
                </>
            )}
            {(situacaoOrcamentoIndustria ===
                situacaoOrcamentoIndustriaConsts.orcamentoEnviado ||
                situacaoOrcamentoIndustria ===
                    situacaoOrcamentoIndustriaConsts.orcamentoRecebido) && (
                <div className="d-flex align-items-center">
                    <span
                        className={`${styles.iconEnviado}`}
                        title="Em Analise"
                    />
                    <span>Cotação em Analise Técnica</span>
                </div>
            )}

            {situacaoOrcamentoIndustria ===
                situacaoOrcamentoIndustriaConsts.orcamentoRetornado && (
                <>
                    <span
                        className={`${styles.iconRealizada}`}
                        title="Realizada"
                    />
                    <span>Cotação Realizada</span>
                </>
            )}

            {situacaoOrcamentoIndustria ===
                situacaoOrcamentoIndustriaConsts.orcamentoRejeitado && (
                <>
                    <span
                        className={`${styles.iconCancelado}`}
                        title="Rejeitada"
                    />
                    <span>Cotação Rejeitada</span>
                </>
            )}

            {situacaoOrcamentoIndustria ===
                situacaoOrcamentoIndustriaConsts.orcamentoRevisao && (
                <div
                    className={`orcamentos8 align-items-center justify-content-start ${className}`}
                >
                    Revisão
                </div>
            )}

            {situacaoOrcamentoIndustria ===
                situacaoOrcamentoIndustriaConsts.propostaEnviada && (
                <div
                    className={`orcamentos5 align-items-center justify-content-start ${className}`}
                >
                    Proposta Enviada
                </div>
            )}

            {situacaoOrcamentoIndustria ===
                situacaoOrcamentoIndustriaConsts.propostaRejeitada && (
                <>
                    <span
                        className={`${styles.iconCancelado}`}
                        title="Rejeitada"
                    />
                    <span>Cotação Rejeitada</span>
                </>
            )}
            {situacaoOrcamentoIndustria ===
                situacaoOrcamentoIndustriaConsts.pedidoAberto && (
                <>
                    <span className={`${styles.iconAberto}`} title="Aberto" />
                    <span>Pedido Aberto</span>
                </>
            )}

            {situacaoOrcamentoIndustria ===
                situacaoOrcamentoIndustriaConsts.pedidoSolicitado && (
                <>
                    <span
                        className={`${styles.iconSolicitado}`}
                        title="Solicitado"
                    />
                    <span>Pedido Solicitado</span>
                </>
            )}

            {situacaoOrcamentoIndustria ===
                situacaoOrcamentoIndustriaConsts.pedidoFinalizado && (
                <>
                    <span
                        className={`${styles.iconConfirmado}`}
                        title="Confirmado"
                    />
                    <span>Pedido Confirmado</span>
                </>
            )}

            {situacaoOrcamentoIndustria ===
                situacaoOrcamentoIndustriaConsts.orcamentoComVendedor && (
                <>
                    <span className={`${styles.iconEnviado}`} title="Enviada" />
                    <span>Cotação Enviada</span>
                </>
            )}
            {situacaoOrcamentoIndustria ===
                situacaoOrcamentoIndustriaConsts.recebidoPeloVendedor && (
                <>
                    <span
                        className={`${styles.iconEnviado}`}
                        title="Analisado"
                    />
                    <span>Analise Técnica Concluída</span>
                </>
            )}
        </>
    );
}
