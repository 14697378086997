import React, { useMemo, useState, useEffect } from 'react';
import PageContent from '~/components/PageContent';
import { DataTable } from '~/components/DataTable';
import { useOrcamentoContext } from 'hooks/useOrcamentoContext';
import { useParams } from 'react-router-dom';
import {
    Button,
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import { getSingleErrorMessage } from '~/utils';
import classnames from 'classnames';
import webApi from '~/services/webapi';
import history from '~/services/history';
import ModalProduct from '../../../../../components/Modal/index.tsx';
import DataGeral from '../ModalDetail/dataGeral';
import DataColor from '../ModalDetail/dataColor';
import DataGlass from '../ModalDetail/dataVidro';
import DataCroqui from '../ModalDetail/dataCroqui';
import DataObservacao from '../ModalDetail/dataObservacao';

const dataItemDetail = [
    {
        activeTab: 1,
        title: 'Geral',
        element: <DataGeral />,
        icon: <i className="far fa-list-alt mr-2" />,
    },
    {
        activeTab: 2,
        title: 'Cores',
        element: <DataColor />,
        icon: <i className="fas fa-fill-drip mr-2" />,
    },
    {
        activeTab: 3,
        title: 'Vidro',
        element: <DataGlass />,
        icon: <i className="fas egf egf-vidro mr-2" />,
    },
    {
        activeTab: 4,
        title: 'Croqui',
        element: <DataCroqui />,
        icon: <i className="far fa-image mr-2" />,
    },
    {
        activeTab: 5,
        title: 'Observações',
        element: <DataObservacao />,
        icon: <i className="far fa-clipboard mr-2" />,
    },
];

export default function MyBudgetRequest() {
    const { codOrcamento } = useParams();
    const [isActive, setIsActive] = useState(1);
    const [dataItem, setDataItem] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRecords, setTotalRecords] = useState(0);
    const [alertSend, setAlertSend] = useState(null);
    const [alertMessage, setAlertMessage] = useState(null);
    const [isOpenFormProduct, setIsOpenFormProduct] = useState(false);

    const { orcamentoStateContext, setValueLine, setIsAtivoTecnico } =
        useOrcamentoContext();

    const handleOpenDetailProduct = (codOrcamentoItem) => {
        setIsAtivoTecnico(false);
        setValueLine(codOrcamentoItem);
        setIsOpenFormProduct(true);
    };

    const handleCloseModal = () => {
        setIsOpenFormProduct(false);
        setIsActive(1);
    };

    const handleShowMessage = (type, title, message, handleClickConfirm) => {
        setAlertMessage(
            <SweetAlert
                show
                type={type}
                title={title}
                style={{ display: 'block' }}
                confirmBtnBsStyle="success"
                onConfirm={() => {
                    if (handleClickConfirm) handleClickConfirm();
                    setAlertMessage(null);
                }}
            >
                {message}
            </SweetAlert>
        );
    };

    const columnsPedido = useMemo(
        () => [
            {
                Header: 'Croqui',
                accessor: 'image',
                disableSortBy: true,
                Cell: ({ row }) => {
                    const { urlImagem } = row.original;
                    return (
                        <div className="text-center">
                            <img
                                alt=""
                                src={urlImagem}
                                style={{
                                    maxHeight: '80px',
                                }}
                            />
                        </div>
                    );
                },
            },
            {
                Header: 'Codigo',
                accessor: 'code',
            },
            {
                Header: 'Descrição',
                accessor: 'description',
            },
            {
                Header: 'Linha',
                accessor: 'line',
            },
            {
                Header: 'Quantidade',
                accessor: 'amount',
            },
            {
                Header: 'Largura',
                accessor: 'width',
            },
            {
                Header: 'Altura',
                accessor: 'height',
            },
            {
                Header: 'Visualizar',
                accessor: 'actions',
                disableSortBy: true,
                Cell: ({ row }) => (
                    <div className="text-center">
                        <button
                            type="button"
                            rel="tooltip"
                            title="Visualizar"
                            className="btn btn-icon btn-icon-mini btn-neutral"
                            onClick={() =>
                                handleOpenDetailProduct(
                                    row.original.codOrcamentoItem
                                )
                            }
                        >
                            <i className=" icon-icon-ver" />
                        </button>
                    </div>
                ),
            },
        ],
        []
    );
    const handleEnviarCem = () => {
        setAlertSend(
            <SweetAlert
                show
                info
                style={{ display: 'block' }}
                title="O orçamento será calculado no CEM. Deseja continuar?"
                confirmBtnBsStyle="success"
                cancelBtnText="Não"
                confirmBtnText="Sim"
                showCancel
                closeOnClickOutside={false}
                reverseButtons
                onConfirm={async () => {
                    try {
                        await webApi.put(`/Orcamentos/${codOrcamento}/enviado`);
                        setAlertSend(null);
                        handleShowMessage(
                            'success',
                            'Sucesso',
                            'Orçamento enviado com sucesso',
                            () => {
                                history.push(`/orcamentoVendedor`);
                            }
                        );
                    } catch (error) {
                        setAlertSend(null);
                        handleShowMessage(
                            'warning',
                            'Não foi possível realizar esta ação',
                            getSingleErrorMessage(error)
                        );
                    }
                }}
                onCancel={() => {
                    setAlertSend(null);
                }}
            />
        );
    };

    const handleRejeitarSolicitacao = () => {
        setAlertSend(
            <SweetAlert
                show
                danger
                style={{ display: 'block' }}
                title="Você tem certeza?"
                confirmBtnBsStyle="success"
                cancelBtnText="Não"
                confirmBtnText="Sim"
                showCancel
                closeOnClickOutside={false}
                reverseButtons
                onConfirm={async () => {
                    try {
                        await webApi.put(
                            `/Orcamentos/${codOrcamento}/proposta-rejeitada`
                        );
                        setAlertSend(null);
                        handleShowMessage(
                            'success',
                            'Sucesso',
                            'Orçamento rejeitado com sucesso',
                            () => {
                                history.push(`/orcamentoVendedor`);
                            }
                        );
                    } catch (error) {
                        setAlertSend(null);
                        handleShowMessage(
                            'warning',
                            'Não foi possível realizar esta ação',
                            getSingleErrorMessage(error)
                        );
                    }
                }}
                onCancel={() => {
                    setAlertSend(null);
                }}
            >
                Tem certeza que deseja rejeitar esta solicitação? Você não
                poderá reverter essa ação!
            </SweetAlert>
        );
    };

    const getItensOrcamento = React.useCallback(() => {
        setLoading(true);
        (async () => {
            const result = await webApi.post(
                `/Orcamentos/${codOrcamento}/itens`,
                {
                    page: 1,
                    take: 10,
                    sort: '',
                    filter: '',
                    desc: true,
                    extraFilterOne: '',
                }
            );
            const { records, total } = result;
            setDataItem(records);
            setTotalRecords(total);
            setLoading(false);
        })();
    }, []);

    useEffect(() => {
        getItensOrcamento();
    }, []);

    return (
        <div>
            <PageContent titlePage="Orçamento detalhado">
                <span className="titulo-pagina">Orçamento</span>
                <hr />
                <DataTable
                    columns={columnsPedido}
                    data={dataItem}
                    fetchData={getItensOrcamento}
                    loading={loading}
                    pageCount={0}
                    reload={false}
                    extraFilterOneInitialValue="true"
                    extraFilterOneTitle="Filtrar por Status"
                    includePageSize={false}
                    includePagination={false}
                    title="Lista de Itens"
                    total={totalRecords}
                />
            </PageContent>
            <Col>
                <Col md="12">
                    {orcamentoStateContext.situacaoOrcamentoIndustria ===
                        14 && (
                        <>
                            <Button
                                className="btn btn-red mb-4"
                                onClick={() => handleRejeitarSolicitacao()}
                            >
                                <i className="far fa-trash-alt" /> Rejeitar
                                solicitação
                            </Button>
                            <Button
                                className="btn btn-azul mb-4 pull-right mr-4"
                                onClick={() => handleEnviarCem()}
                            >
                                <i className="far fa-share-square" /> Enviar
                                para o CEM
                            </Button>
                        </>
                    )}
                </Col>
            </Col>
            <ModalProduct
                open={isOpenFormProduct}
                onClose={handleCloseModal}
                title="Detalhes do Item"
                onAdvance={() => ''}
                onBack={() => ''}
                size="xl"
                isActive={isActive}
                count={dataItemDetail.length}
            >
                <div className="wizard">
                    <Nav
                        tabs
                        style={{
                            paddingRight: '25px',
                            paddingLeft: '25px',
                            paddingTop: '25PX',
                        }}
                        className="nav  nav-pills-primary nav-justified"
                    >
                        {dataItemDetail.map(({ title, activeTab, icon }) => (
                            <NavItem key={activeTab}>
                                <NavLink
                                    className={classnames({
                                        active: isActive === activeTab,
                                    })}
                                    onClick={(e) => setIsActive(activeTab)}
                                >
                                    {icon}
                                    {title}
                                </NavLink>
                            </NavItem>
                        ))}
                    </Nav>
                </div>
                <TabContent
                    activeTab={isActive}
                    style={{
                        paddingRight: '25px',
                        paddingLeft: '25px',
                        paddingBottom: '25px',
                    }}
                >
                    {dataItemDetail.map(({ element, activeTab }) => (
                        <TabPane key={activeTab} tabId={activeTab}>
                            {element}
                        </TabPane>
                    ))}
                </TabContent>
            </ModalProduct>
            {alertSend}
            {alertMessage}
        </div>
    );
}
