import React, { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import PageContent from '~/components/PageContent';
import OrcamentoNavegacaoSuperior from '~/components/OrcamentoNavegacaoSuperior';
import { DataTable } from '~/components/DataTable';
import classnames from 'classnames';
import NavigationSubArea from '~/components/OrcamentoTecnicoNavegacao';
import { useOrcamentoContext } from 'hooks/useOrcamentoContext';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import history from '~/services/history';
import ModalProduct from '../../components/Modal/index.tsx';
import DataGeral from '../OrcamentoVendedor/DetalhesItem/components/ModalDetail/dataGeral';
import DataColor from '../OrcamentoVendedor/DetalhesItem/components/ModalDetail/dataColor';
import DataGlass from '../OrcamentoVendedor/DetalhesItem/components/ModalDetail/dataVidro';
import DataCroqui from '../OrcamentoVendedor/DetalhesItem/components/ModalDetail/dataCroqui';
import { getItensOrcamentoTecnico } from '../../services/modules/Orcamento/orcamentoTecnico';

const dataItemDetail = [
    {
        activeTab: 1,
        title: 'Geral',
        element: <DataGeral />,
        icon: <i className="far fa-list-alt mr-2" />,
    },
    {
        activeTab: 2,
        title: 'Cores',
        element: <DataColor />,
        icon: <i className="fas fa-fill-drip mr-2" />,
    },
    {
        activeTab: 3,
        title: 'Vidro',
        element: <DataGlass />,
        icon: <i className="fas egf egf-vidro mr-2" />,
    },
    {
        activeTab: 4,
        title: 'Croqui',
        element: <DataCroqui />,
        icon: <i className="far fa-image mr-2" />,
    },
];

export default function OrcamentoTecnicoItens() {
    const { codOrcamentoTecnico, versao } = useParams();

    // states
    const [isOpenFormProduct, setIsOpenFormProduct] = useState(false);
    const [isActive, setIsActive] = useState(1);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [reload, setReload] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const { setCodOrcamentoTecnicoItem, setIsAtivoTecnico } =
        useOrcamentoContext();

    // functions
    const handleChange = async () => {
        return true;
    };

    const handleOpenFormProduct = (row) => {
        setSelectedRecord(null);

        if (row) {
            const { codOrcamentoTecnicoItem } = row.original;
            setSelectedRecord(codOrcamentoTecnicoItem);
            setIsAtivoTecnico(true);
            setCodOrcamentoTecnicoItem(row.original.codOrcamentoTecnicoItem);
        }

        setIsOpenFormProduct(true);
    };

    const handleCloseFormProduct = () => {
        setIsOpenFormProduct(false);
        setSelectedRecord(null);
        setIsActive(1);
    };

    const handleClickNext = async () => {
        history.push(
            `/orcamentos-tecnicos/${codOrcamentoTecnico}/versao/${versao}/observacoes`
        );
    };

    const handleClickPrevious = async () => {
        history.push(
            `/orcamentos-tecnicos/${codOrcamentoTecnico}/versao/${versao}/cores`
        );
    };

    const getItems = React.useCallback(() => {
        setLoading(true);
        (async () => {
            const result = await getItensOrcamentoTecnico(
                codOrcamentoTecnico,
                versao
            );
            setData(result);
            setLoading(false);
        })();
    }, []);

    const columns = useMemo(
        () => [
            {
                Header: 'Croqui',
                accessor: 'image',
                Cell: ({ row }) => {
                    const { urlImagem } = row.original;
                    return (
                        <div className="text-left">
                            <img
                                alt=""
                                src={urlImagem}
                                style={{
                                    maxHeight: '80px',
                                }}
                            />
                        </div>
                    );
                },
            },
            {
                Header: 'Código',
                accessor: 'codigo',
            },
            {
                Header: 'Descrição',
                accessor: 'descricao',
            },
            {
                Header: 'Linha',
                accessor: 'linha',
            },
            {
                Header: 'Quantidade',
                accessor: 'quantidade',
            },

            {
                Header: 'Largura',
                accessor: 'largura',
            },
            {
                Header: 'Altura',
                accessor: 'altura',
            },
            {
                Header: 'Peso',
                accessor: 'peso',
            },
            {
                Header: 'Ações',
                accessor: 'actions',
                disableSortBy: true,
                Cell: ({ row }) => (
                    <div className="text-center">
                        <button
                            type="button"
                            rel="tooltip"
                            className="boxButton btn btn-icon btn-icon-mini btn-neutral"
                            title="Editar"
                            onClick={() => handleOpenFormProduct(row)}
                        >
                            <i className="fas fa-search" />
                        </button>
                    </div>
                ),
            },
        ],
        []
    );

    return (
        <>
            <PageContent className="no-border">
                <OrcamentoNavegacaoSuperior />
            </PageContent>

            <PageContent titlePage="Orçamento - Itens">
                <NavigationSubArea
                    active="items"
                    handleChange={() => handleChange()}
                />

                <div className="row">
                    <div className="col-md-12">
                        <DataTable
                            columns={columns}
                            data={data}
                            fetchData={getItems}
                            loading={loading}
                            pageCount={pageCount}
                            total={totalRecords}
                            reload={reload}
                            includeFilter={false}
                            includePagination={false}
                            includePageSize={false}
                            includeTotalRecords={false}
                        />
                    </div>
                </div>
                <div className="m-3">
                    <div className="pull-left">
                        <button
                            type="button"
                            className="boxButtonAvancar btn"
                            role="tablist"
                            onClick={() => handleClickPrevious()}
                        >
                            <i className="far fa-arrow-alt-circle-left" />
                            {' Voltar'}
                        </button>
                    </div>
                    <div className="pull-right">
                        <button
                            type="button"
                            className="boxButtonAvancar btn"
                            role="tablist"
                            onClick={() => handleClickNext()}
                        >
                            Avançar{' '}
                            <i className="far fa-arrow-alt-circle-right" />
                        </button>
                    </div>
                </div>
                <ModalProduct
                    open={isOpenFormProduct}
                    title="Item"
                    onClose={handleCloseFormProduct}
                    onAdvance={() => ''}
                    onBack={() => ''}
                    size="xl"
                    isActive={isActive}
                    count={dataItemDetail.length}
                >
                    <div className="wizard">
                        <Nav
                            tabs
                            style={{
                                paddingRight: '25px',
                                paddingLeft: '25px',
                                paddingTop: '25PX',
                            }}
                            className="nav  nav-pills-primary nav-justified"
                        >
                            {dataItemDetail.map(
                                ({ title, activeTab, icon }) => (
                                    <NavItem key={activeTab}>
                                        <NavLink
                                            className={classnames({
                                                active: isActive === activeTab,
                                            })}
                                            onClick={() =>
                                                setIsActive(activeTab)
                                            }
                                        >
                                            {icon}
                                            {title}
                                        </NavLink>
                                    </NavItem>
                                )
                            )}
                        </Nav>
                    </div>
                    <TabContent
                        activeTab={isActive}
                        style={{
                            paddingRight: '25px',
                            paddingLeft: '25px',
                            paddingBottom: '25px',
                        }}
                    >
                        {dataItemDetail.map(({ element, activeTab }) => (
                            <TabPane key={activeTab} tabId={activeTab}>
                                {element}
                            </TabPane>
                        ))}
                    </TabContent>
                </ModalProduct>
            </PageContent>
        </>
    );
}
