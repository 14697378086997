import React, { useEffect, useState } from 'react';
import icone1 from './img/avatar03.png';
import icone2 from './img/Caminho 61.png';

import SweetAlert from 'react-bootstrap-sweetalert';
import { useAuthContext } from '~/hooks/useAuthContext';
import { Link, useLocation } from 'react-router-dom';
import webApi from '~/services/webapi';
import history from '~/services/history';

function Header(props) {
    const location = useLocation();
    const {
        signOut,
        ehUsuarioIndustria,
        state,
        atualizarQuantidadeNotificacoes,
    } = useAuthContext();
    const { quantidadeNotificacoes } = state;
    const titlePage = '';
    const [alertMessage, setAlertMessage] = useState();
    const [isAtivo, setAtivo] = useState(false);

    useEffect(() => {
        (async () => {
            if (ehUsuarioIndustria()) {
                const result = await webApi.get('notificacao/novas');
                if (result?.quantidade) {
                    atualizarQuantidadeNotificacoes(result.quantidade);
                }
            }
        })();
    }, [location]);

    const handleSignOut = () => {
        setAlertMessage(
            <SweetAlert
                show
                title="Sair?"
                showCancel
                reverseButtons
                cancelBtnBsStyle="danger"
                cancelBtnText="Não"
                confirmBtnText="Sim, Sair"
                confirmBtnBsStyle="success"
                onConfirm={() => {
                    signOut();
                }}
                onCancel={() => {
                    setAlertMessage(null);
                }}
            >
                Tem certeza que deseja sair?
            </SweetAlert>
        );
    };

    const notificationPage = () => {
        history.push(`/notificacoes`);
        window.location.reload();
    };

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-absolute fixed-top navbar-transparent navbar-sync">
                <div className="container-fluid" style={{ maxHeight: '10px' }}>
                    <div className="navbar-wrapper ">
                        <div className="navbar-minimize">
                            <button
                                id="minimizeSidebar"
                                className="btn btn-icon"
                                onClick={props.handleMiniClick}
                            >
                                <i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini"></i>
                                <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular"></i>
                            </button>
                        </div>
                        <div className="navbar-toggle">
                            <button
                                id="openMenu"
                                type="button"
                                className="navbar-toggler"
                                onClick={props.handleClickNavbarToggle}
                            >
                                <span className="navbar-toggler-bar bar1"></span>
                                <span className="navbar-toggler-bar bar2"></span>
                                <span className="navbar-toggler-bar bar3"></span>
                            </button>
                        </div>
                        <a className="navbar-brand">{titlePage}</a>
                    </div>
                    <div className="navbar float-right divUsuario">
                        <div className="navbar usuarioName d-inline">
                            <span>{state.nome}</span>
                            <br />
                            <div className="usuarioClass">
                                <span>Adminstrador</span>
                            </div>
                        </div>
                        <img
                            className="imgUsuario mt-n3 mr-3 ml-n2"
                            src={icone1}
                        />

                        <div className="dropdown dropleft mb-3 ml-n2 mr-2">
                            <span
                                src={icone2}
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <img src={icone2} />
                            </span>
                            <div className="dropdown-menu">
                                <Link className="dropdown-item" to="/conta">
                                    Trocar Senha
                                </Link>
                                <Link
                                    className="dropdown-item"
                                    to="/logo"
                                    href="#"
                                >
                                    Alterar Logotipo
                                </Link>
                                <a
                                    className="dropdown-item"
                                    onClick={() => handleSignOut()}
                                >
                                    Sair
                                </a>
                            </div>
                        </div>

                        {isAtivo && (
                            <div className="bordaVertical ml-3">
                                <i className="icon-icon-carrinho ml-3 mr-2 iconeCarrinho">
                                    <p className="NotificacaoCarrinho ml-n1">
                                        3
                                    </p>
                                </i>
                            </div>
                        )}

                        <div className="bordaVertical">
                            <i
                                className="icon-icon-alerta ml-3 mr-2 iconeAlerta"
                                onClick={() => notificationPage()}
                            >
                                {(quantidadeNotificacoes &&
                                    quantidadeNotificacoes) > 0 && (
                                    <p className="NotificacaoAlerta ml-n1">
                                        {quantidadeNotificacoes}
                                    </p>
                                )}
                            </i>
                        </div>
                        {isAtivo && (
                            <div className="bordaVertical d-flex">
                                <i className="icon-icon-troca ml-3 mr-3 iconeTroca" />
                                <select className="spanTrocar ml-n1">
                                    <option> Trocar revenda</option>
                                </select>
                            </div>
                        )}
                    </div>
                </div>
            </nav>
            {alertMessage}
        </>
    );
}

export default Header;
