import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import CpfCnpj from '@react-br-forms/cpf-cnpj-mask';
import PhoneInput from 'react-phone-number-input/input';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Label, Input, FormFeedback, Row, Col, Button } from 'reactstrap';
import AsyncCreatableSelect from 'react-select/async-creatable';
import SweetAlert from 'react-bootstrap-sweetalert';
import NumberFormat from 'react-number-format';

import webapi from '~/services/webapi';
import history from '~/services/history';
import PageContent from '~/components/PageContent';
import OrcamentoNavegacaoSuperior from '~/components/OrcamentoNavegacaoSuperior';
import NavigationSubArea from '../../components/OrcamentoNavegacao';
import { reactSelectCustomStyles } from '~/styles/react-select';
import { useOrcamentoContext } from '~/hooks/useOrcamentoContext';

const schema = yup.object({
    name: yup.object().nullable().required('Campo obrigatório').shape({
        label: yup.string().required().nullable(),
        value: yup.string().required().nullable(),
    }),
    bR_CNPJ: yup
        .string()
        .test('bR_CNPJ', 'Número de documento inválido', (value) => {
            if (value) {
                const numbersOnly = value.replace(/\D/g, '');
                if (numbersOnly.length === 11) {
                    return cpf.isValid(numbersOnly);
                } else if (numbersOnly.length === 14) {
                    return cnpj.isValid(numbersOnly);
                } else return false;
            }
            return true;
        })
        .nullable(),
});

const defaultValues = {
    codOracamento: null,
    name: '',
    code: '',
    bR_NomeFantasia: '',
    bR_RazaoSocial: '',
    bR_CNPJ: '',
    addressStreet: '',
    addressPostalCode: null,
    addressNumber: '',
    addressComplement: '',
    addressDistrict: '',
    addressState: '',
    addressStateCode: 0,
    addressCity: '',
    addressCityCode: 0,
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    contactPhoneAlternative: '',
};

export default function OrcamentoCliente() {
    // hooks
    const {
        limparOrcamentoSelecionado,
        permiteEdicaoOrcamento,
    } = useOrcamentoContext();

    // consts
    const { codOrcamento } = useParams(0);

    // states
    const [alertClientExist, setAlertClientExist] = useState(null);
    const [filtro, setFiltro] = useState('');

    // functions
    const handleValid = async () => {
        const valid = await triggerValidation();
        return valid;
    };

    const clienteExiste = async () => {
        const { name } = getValues();
        const result = await webapi.get(
            `/industriaCliente?nome=${name?.value}`
        );
        const { codIndustriaCliente } = result;
        return codIndustriaCliente != undefined;
    };

    const handleClickNext = async () => {
        if (permiteEdicaoOrcamento()) {
            const valid = await triggerValidation();
            if (valid) {
                if ((await clienteExiste()) === false) {
                    await createClient();
                }
            }
        }

        const valid = triggerValidation();
        if (valid) {
            const clientData = {
                ...getValues(),
                name: getValues().name.label,
                addressStateCode: parseInt(getValues().addressStateCode),
                addressCityCode: parseInt(getValues().addressCityCode),
                codOrcamento: codOrcamento ? codOrcamento : null,
            };

            const result = await webapi.post('/orcamentos/cliente', clientData);

            if (!codOrcamento) {
                history.push(`/orcamentos/${result.codOrcamento}/obra`);
            } else {
                history.push(`/orcamentos/${codOrcamento}/obra`);
            }
        }
    };

    const getClients = async (filtro) => {
        const result = await webapi.get(`/Orcamentos/clientes/${filtro}`);
        const options = result.map((c) => ({
            value: c.razaoSocial,
            label: c.razaoSocial,
            object: c,
        }));

        return options;
    };

    const createClient = () => {
        if (getValues().name) {
            const nomeCliente = getValues().name.value;
            return new Promise(async (resolve) => {
                setAlertClientExist(
                    <SweetAlert
                        show
                        info
                        style={{ display: 'block' }}
                        title="Adicionar Cliente"
                        confirmBtnBsStyle="success"
                        showCancel
                        reverseButtons
                        onConfirm={async () => {
                            const {
                                bR_CNPJ,
                                addressStreet,
                                addressState,
                                addressStateCode,
                                addressCity,
                                addressCityCode,
                                addressComplement,
                                addressDistrict,
                                addressNumber,
                                addressPostalCode,
                                contactName,
                                contactEmail,
                                contactPhone,
                            } = getValues();

                            const newCliente = {
                                razaoSocial: nomeCliente,
                                nomeFantasia: nomeCliente,
                                cnpj: bR_CNPJ,
                                endereco: {
                                    logradouro: addressStreet,
                                    bairro: addressDistrict,
                                    numero: addressNumber,
                                    cidade: addressCity,
                                    codigoCidade: parseInt(addressCityCode),
                                    estado: addressState,
                                    codigoEstado: parseInt(addressStateCode),
                                    complemento: addressComplement,
                                    cep: addressPostalCode,
                                },
                                contato: {
                                    nome: contactName,
                                    email: contactEmail,
                                    telefone: contactPhone,
                                },
                            };

                            await webapi.post(`/industriaCliente`, newCliente);
                            setAlertClientExist(null);
                            resolve(true);
                        }}
                        onCancel={async () => {
                            setAlertClientExist(null);
                            resolve(true);
                        }}
                    >
                        Deseja adicionar este cliente ao cadastro de clientes?
                    </SweetAlert>
                );
            });
        }
    };

    const onClientNameChanged = (selected) => {
        if (selected) {
            setValue('code', selected.object?.codIndustriaCliente);
            setValue('bR_CNPJ', selected.object?.cnpj);
            setValue('bR_NomeFantasia', selected.object?.bR_NomeFantasia);
            setValue('bR_razaoSocial', selected.object?.bR_razaoSocial);
            setValue('addressDistrict', selected.object?.endereco.bairro);
            setValue('addressNumber', selected.object?.endereco.numero);
            setValue('addressPostalCode', selected.object?.endereco.cep);
            setValue('addressStreet', selected.object?.endereco.logradouro);
            setValue('addressState', selected.object?.endereco.estado);
            setValue('addressStateCode', selected.object?.stateCode);
            setValue('addressCity', selected.object?.endereco.cidade);
            setValue('contactName', selected.object?.contato.nome);
            setValue('contactPhone', selected.object?.contato.telefone);
            setValue('contactEmail', selected.object?.contato.email);
            setValue('cityCode', selected.object?.endereco.cidade);
            setValue('stateCode', selected.object?.endereco);
            setValue(
                'addressComplement',
                selected.object?.endereco.complemento
            );
        }

        return {
            label: selected.label,
            value: selected.label,
            object: selected.object,
        };
    };

    // hooks
    const {
        register,
        triggerValidation,
        getValues,
        setValue,
        errors,
        control,
        reset,
    } = useForm({
        mode: 'onChange',
        defaultValues,
        validationSchema: schema,
    });

    useEffect(() => {
        const fetchData = async () => {
            const result = await webapi.get(
                `/orcamentos/${codOrcamento}/cliente`
            );

            setValue('name', {
                label: result.name,
                value: result.name,
            });

            delete result.name;

            reset(result);
        };

        if (codOrcamento) fetchData();
    }, []);

    const handleChangePostalCode = async (e) => {
        const postalCode = e.replace(/\s/g, '');
        if (postalCode.length === 8) {
            const result = await webapi.get(
                `endereco/endereco-via-cep/${postalCode}`
            );
            const { logradouro, bairro, uf, cidade } = result;
            if (logradouro) {
                setValue('addressStreet', logradouro);
                setValue('addressDistrict', bairro);
                setValue('addressState', uf);
                setValue('addressCity', cidade);
            }
        }
    };

    return (
        <>
            <PageContent className="no-border">
                <OrcamentoNavegacaoSuperior />
            </PageContent>
            <PageContent>
                <NavigationSubArea
                    active="Client"
                    handleValid={() => handleValid()}
                    handleSave={() => handleClickNext()}
                >
                    <form>
                        <Row>
                            <Col sm="12" className="mt-3 nomContato">
                                <Label
                                    style={{
                                        color: '#57607A',
                                        fontSize: '16px',
                                    }}
                                >
                                    Nome do Cliente
                                </Label>
                                <Controller
                                    name="name"
                                    styles={reactSelectCustomStyles}
                                    style={{
                                        color: '#57607A',
                                        fontSize: '16px',
                                    }}
                                    classNamePrefix="react-select"
                                    placeholder="Digite o nome do cliente..."
                                    loadOptions={getClients}
                                    onChange={([selected]) =>
                                        onClientNameChanged(selected)
                                    }
                                    onCreateOption={async (item) => {
                                        setValue('name', {
                                            label: item,
                                            value: item,
                                        });
                                    }}
                                    as={AsyncCreatableSelect}
                                    control={control}
                                    isClearable={true}
                                    rules={{ required: true }}
                                    className={
                                        !!errors?.name?.message
                                            ? 'invalid is-invalid '
                                            : ''
                                    }
                                    formatCreateLabel={(value) => (
                                        <span>{value}</span>
                                    )}
                                    isDisabled={!permiteEdicaoOrcamento()}
                                />
                                <FormFeedback>
                                    {errors?.name?.message}
                                </FormFeedback>
                            </Col>
                        </Row>
                        <Row>
                            <Col Col sm="3" className="mt-3 cpfContato">
                                <Label
                                    style={{
                                        color: '#57607A',
                                        fontSize: '16px',
                                    }}
                                >
                                    CPF{' '}
                                </Label>
                                <Controller
                                    control={control}
                                    maxLength={18}
                                    name="bR_CNPJ"
                                    placeholder="CNPJ / CPF..."
                                    as={CpfCnpj}
                                    className={
                                        !!errors?.bR_CNPJ?.message
                                            ? 'form-control form-control-sm invalid is-invalid '
                                            : 'form-control form-control-sm'
                                    }
                                    autoComplete="off"
                                    disabled={!permiteEdicaoOrcamento()}
                                    style={{
                                        boxShadow:
                                            'rgb(198 203 212) 0px 6px 15px -10px',
                                        borderRadius: '10px',
                                    }}
                                />
                                <FormFeedback>
                                    {errors?.bR_CNPJ?.message}
                                </FormFeedback>
                            </Col>
                            <Col sm="3" className="mt-3">
                                <Label
                                    style={{
                                        color: '#57607A',
                                        fontSize: '16px',
                                    }}
                                >
                                    Nome de Contato
                                </Label>
                                <Input
                                    innerRef={register}
                                    maxLength={80}
                                    type="text"
                                    name="contactName"
                                    placeholder="Nome do Contato..."
                                    className="form-control-sm"
                                    disabled={!permiteEdicaoOrcamento()}
                                    autoComplete="off"
                                    style={{
                                        boxShadow:
                                            'rgb(198 203 212) 0px 6px 15px -10px',
                                        borderRadius: '10px',
                                    }}
                                />
                            </Col>
                            <Col sm="3" className="mt-3">
                                <Label
                                    style={{
                                        color: '#57607A',
                                        fontSize: '16px',
                                    }}
                                >
                                    Telefone
                                </Label>
                                <Controller
                                    maxLength={15}
                                    type="text"
                                    name="contactPhone"
                                    placeholder="Telefone..."
                                    className="form-control form-control-sm"
                                    as={PhoneInput}
                                    control={control}
                                    country="BR"
                                    disabled={!permiteEdicaoOrcamento()}
                                    autoComplete="off"
                                    style={{
                                        boxShadow:
                                            'rgb(198 203 212) 0px 6px 15px -10px',
                                        borderRadius: '10px',
                                    }}
                                />
                            </Col>
                            <Col sm="3" className="mt-3">
                                <Label
                                    style={{
                                        color: '#57607A',
                                        fontSize: '16px',
                                    }}
                                >
                                    E-mail
                                </Label>
                                <Input
                                    innerRef={register}
                                    maxLength={80}
                                    type="text"
                                    name="contactEmail"
                                    placeholder="E-mail..."
                                    className="form-control form-control-sm"
                                    disabled={!permiteEdicaoOrcamento()}
                                    autoComplete="off"
                                    style={{
                                        boxShadow:
                                            'rgb(198 203 212) 0px 6px 15px -10px',
                                        borderRadius: '10px',
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="4" className="mt-3 cepContato">
                                <Label
                                    style={{
                                        color: '#57607A',
                                        fontSize: '16px',
                                    }}
                                >
                                    CEP
                                </Label>
                                <Controller
                                    control={control}
                                    maxLength={8}
                                    name="addressPostalCode"
                                    placeholder="CEP..."
                                    as={NumberFormat}
                                    className="form-control form-control-sm"
                                    decimalScale={0}
                                    allowLeadingZeros={true}
                                    isNumericString={true}
                                    autoComplete="off"
                                    style={{
                                        boxShadow:
                                            'rgb(198 203 212) 0px 6px 15px -10px',
                                        borderRadius: '10px',
                                    }}
                                    disabled={!permiteEdicaoOrcamento()}
                                    onBlur={([event]) =>
                                        handleChangePostalCode(
                                            event.target.value
                                        )
                                    }
                                />

                                <FormFeedback>
                                    {errors?.addressPostalCode?.message}
                                </FormFeedback>
                            </Col>
                            <Col sm="4" className="mt-3">
                                <Label
                                    style={{
                                        color: '#57607A',
                                        fontSize: '16px',
                                    }}
                                >
                                    Endereço
                                </Label>
                                <Input
                                    innerRef={register}
                                    maxLength={120}
                                    type="text"
                                    name="addressStreet"
                                    placeholder="Endereço..."
                                    className="form-control-sm"
                                    disabled={!permiteEdicaoOrcamento()}
                                    autoComplete="off"
                                    style={{
                                        boxShadow:
                                            'rgb(198 203 212) 0px 6px 15px -10px',
                                        borderRadius: '10px',
                                    }}
                                />
                            </Col>
                            <Col sm="4" className="mt-3">
                                <Label
                                    style={{
                                        color: '#57607A',
                                        fontSize: '16px',
                                    }}
                                >
                                    Número
                                </Label>
                                <Input
                                    innerRef={register}
                                    maxLength={30}
                                    type="text"
                                    name="addressNumber"
                                    placeholder="Numero..."
                                    className="form-control-sm"
                                    disabled={!permiteEdicaoOrcamento()}
                                    autoComplete="off"
                                    style={{
                                        boxShadow:
                                            'rgb(198 203 212) 0px 6px 15px -10px',
                                        borderRadius: '10px',
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="3" className="mt-3 comContato">
                                <Label
                                    style={{
                                        color: '#57607A',
                                        fontSize: '16px',
                                    }}
                                >
                                    Complemento
                                </Label>
                                <Input
                                    innerRef={register}
                                    maxLength={80}
                                    type="text"
                                    name="addressComplement"
                                    placeholder="Complemento..."
                                    className="form-control-sm"
                                    disabled={!permiteEdicaoOrcamento()}
                                    autoComplete="off"
                                    style={{
                                        boxShadow:
                                            'rgb(198 203 212) 0px 6px 15px -10px',
                                        borderRadius: '10px',
                                    }}
                                />
                            </Col>
                            <Col sm="3" className="mt-3">
                                <Label
                                    style={{
                                        color: '#57607A',
                                        fontSize: '16px',
                                    }}
                                >
                                    Bairro
                                </Label>
                                <Input
                                    innerRef={register}
                                    maxLength={80}
                                    type="text"
                                    name="addressDistrict"
                                    placeholder="Bairro..."
                                    className="form-control-sm"
                                    disabled={!permiteEdicaoOrcamento()}
                                    style={{
                                        boxShadow:
                                            'rgb(198 203 212) 0px 6px 15px -10px',
                                        borderRadius: '10px',
                                    }}
                                />
                            </Col>
                            <Col sm="3" className="mt-3">
                                <Label
                                    style={{
                                        color: '#57607A',
                                        fontSize: '16px',
                                    }}
                                >
                                    Estado
                                </Label>
                                <Input
                                    innerRef={register}
                                    maxLength={80}
                                    type="text"
                                    name="addressState"
                                    placeholder="Estado..."
                                    className="form-control-sm"
                                    disabled={!permiteEdicaoOrcamento()}
                                    autoComplete="off"
                                    style={{
                                        boxShadow:
                                            'rgb(198 203 212) 0px 6px 15px -10px',
                                        borderRadius: '10px',
                                    }}
                                />
                                <Input
                                    type="hidden"
                                    innerRef={register}
                                    name="addressStateCode"
                                />
                            </Col>
                            <Col md="3" className="mt-3">
                                <Label
                                    style={{
                                        color: '#57607A',
                                        fontSize: '16px',
                                    }}
                                >
                                    Cidade
                                </Label>
                                <Input
                                    innerRef={register}
                                    maxLength={80}
                                    type="text"
                                    name="addressCity"
                                    placeholder="Cidade..."
                                    className="form-control-sm"
                                    disabled={!permiteEdicaoOrcamento()}
                                    autoComplete="off"
                                    style={{
                                        boxShadow:
                                            'rgb(198 203 212) 0px 6px 15px -10px',
                                        borderRadius: '10px',
                                    }}
                                />
                                <Input
                                    type="hidden"
                                    innerRef={register}
                                    name="addressCityCode"
                                />
                            </Col>
                        </Row>
                        <div className="mt-3" style={{ fontSize: '18px' }}>
                            <div className="pull-left">
                                <Button
                                    type="button"
                                    role="tablist"
                                    className="bntVoltar"
                                    style={{
                                        backgroundColor: '#ABAFBC',
                                        borderRadius: '10px',
                                        boxShadow:
                                            '0px 10px 15px -2px rgb(198 203 212)',
                                    }}
                                    onClick={() => {
                                        history.push('/orcamentos');
                                        limparOrcamentoSelecionado();
                                    }}
                                >
                                    <i
                                        className="icon-icon-voltar mr-1"
                                        style={{ fontSize: '18px' }}
                                    />
                                    {' Voltar'}
                                </Button>
                            </div>
                            <div
                                className="pull-right mb-3"
                                style={{ fontSize: '18px' }}
                            >
                                <Button
                                    type="button"
                                    role="tablist"
                                    className="btnSalvar"
                                    style={{
                                        backgroundColor: '#3073CB',
                                        borderRadius: '10px',
                                        boxShadow:
                                            '0px 10px 15px -2px rgb(198 203 212)',
                                    }}
                                    onClick={() => handleClickNext()}
                                >
                                    Avançar{' '}
                                    <i
                                        className="icon-icon-avancar ml-1 "
                                        style={{ fontSize: '18px' }}
                                    />
                                </Button>
                            </div>
                        </div>
                    </form>
                </NavigationSubArea>
                {alertClientExist}
            </PageContent>
        </>
    );
}
