import React, { useEffect, useState } from 'react';
import styles from './materiaisAvulsos.module.css';
import webApi from '~/services/webapi';
import ItemProduto from './components/ItemProduto';
import { Col, Container, Row, Button } from 'reactstrap';
import history from '~/services/history';
import { useNotifyContext } from '~/hooks/useNotifyContext';
import PageContent from '~/components/PageContent';
import SweetAlert from 'react-bootstrap-sweetalert';
import CotacaoStatus from 'components/CotacaoStatus';
import InputComponent from '../../components/Input';
import { errosMessage } from '../../shared/messages/errorMessages';
import { situacaoCotacaoIndustriaConsts, situacaoPedidoIndustriaConsts, situacaoOrcamentoIndustriaConsts } from "../../consts/situacaoOrcamentoIndustriaConsts";
import PedidoStatus from '../../components/CotacaoStatus/pedido';
import { formtJson } from "../../shared/function/index";
import { formatDadosPedidoIndustria, formatDadosCotacaoIndustria } from "../../shared/map/mapRequest";
const { OBSERVACAO } = errosMessage;

export default function PedidoLista({ location }) {
    const { state } = location;

    const [pedido, setPedido] = useState([]);
    const [alertMessage, setAlertMessage] = useState(false);
    const [quantidade, setQuantidade] = useState(0);
    const [ data, setData ] = useState();
    const [ isAtivo, setIsAtivo ] = useState(false);
    const [alertSend, setAlertSend] = useState(null);
    const [obsRejeitar, setObsRejeitar] = useState('');
    const [isRejeitar, setIsRejeitar] = useState(false);


    const notificacao = useNotifyContext();

    function enviarCotacao() {
        setAlertMessage(data.status === situacaoCotacaoIndustriaConsts.cotacaoAberta);
    }

    function editarCotacao() {
        history.push({
            pathname: `/pedido/edicao`,
            state: { ...state },
        });
    }

    async function AprovarCotacao() {

        const result = await webApi.patch(`cotacao/${state.codCotacao}/aprovar-cotacao`, {
                codCotacao: state.codCotacao,
                codErp: 1,
                status: state.status,
                notificacao: `Cotacão ${state.codCotacao} aprovada`,
        });
        notificacao.sucesso(
            `Cotação ${state.codCotacao} aprovada com sucesso!`
        );
        history.push(`/pedidos`);
    }

    async function carregarPedido() {
        const result = await webApi.get(
            state.status === 1
                ? `cotacao/${state.codCotacao}/cotacao-aberta`
                : `cotacao/${state.codCotacao}/cotacao-enviada-cotada`
        );
        setQuantidade(result.itens.length);
        const cotacaoData =  result.itens.map((item) => {return formatDadosCotacaoIndustria(item)});
        setPedido(cotacaoData);
        setData(result);
    }


    async function detalhePedido() {
        const result = await webApi.get(`/pedido/${state.codPedido}`);
        const formatPedido = formtJson(result.itensCotado);
        const pedidosData = formatPedido.Itens.map((item) => {return formatDadosPedidoIndustria(item)})
        setQuantidade(pedidosData.length);
        setPedido(pedidosData);
        setData(result);
    }

    const handleRejeitarCotacao = async (value) => {
        if (value) {
            try {
                await webApi.patch(
                    `/Cotacao/${state.codCotacao}/cancelar-cotacao`,
                    {
                        codCotacao: state.codCotacao,
                        notificacao: value,
                    }
                );
                notificacao.sucesso(
                    `Cotação ${state.codCotacao} rejeitada!`
                );
                setAlertSend(null);
                history.push(`/pedidos`);
            } catch (error) {
                setAlertSend(null);
                notificacao.error(error);
            }
        } else {
            notificacao.alerta(OBSERVACAO);
            setAlertSend(null);
        }
    };

    const handleRejeitar = () => {
        setAlertSend(
            <SweetAlert
                show
                danger
                style={{ display: 'block' }}
                title="Deseja rejeitar essa cotação?"
                confirmBtnBsStyle="success"
                cancelBtnText="Não"
                confirmBtnText="Sim"
                showCancel
                closeOnClickOutside={false}
                reverseButtons
                onConfirm={() => setIsRejeitar(true)}
                onCancel={() => setAlertSend(null)}
            >
                <Col>
                    <InputComponent
                        label="Observação"
                        name="observacao"
                        isShadow
                        onchange={(e) => setObsRejeitar(e.target.value)}
                        placeholder="Digite uma observação..."
                    />
                </Col>
            </SweetAlert>
        );
    };

    useEffect(() => {
        if (isRejeitar === true) handleRejeitarCotacao(obsRejeitar);
    }, [isRejeitar]);

    useEffect(() => {
        (async () => {
            if((state.status === situacaoCotacaoIndustriaConsts.cotacaoRejeitada ||
                state.status === situacaoCotacaoIndustriaConsts.cotacaoCancelada) &&
                (state.codPedido === undefined || state.codPedido === null)){
                const formatCotacao = state.cotacao.itens.map((item) => {return formatDadosCotacaoIndustria(item)})
                setQuantidade(state.cotacao.itens.length);
                setPedido(formatCotacao)
            }else{
                if(state.codPedido === undefined || state.codPedido === null){
                    await carregarPedido();}
                else{
                    await detalhePedido();
                }
            }
        })();
    }, []);

    return (
        <>
            <PageContent>
                <Container fluid className="my-2">
                    <Row className={`${styles.titulo}`}>
                         <Col className={`${styles.title}`}>
                            Cotação de<strong> Materiais Avulsos</strong>
                        </Col>
                    </Row>
                </Container>
                <Row className='justify-content-between'>
                    <Col sm={12} md={12} lg={5} className='m-0' id={`${styles.container}`}>
                        {pedido.map((item, index) => (
                            <ItemProduto
                                key={index}
                                item={item}
                                carregarPedido={carregarPedido}
                                pedido={data}
                            />
                        ))}
                    </Col>

                    <Col className='ml-0'  id={`${styles.container}`}>
                        <Container className={`${styles.box} boxAvulco`}>
                            <Row >
                                <Col lg={5} className='d-flex align-items-center justify-items-center'>
                                    {state.codPedido === null || state.codPedido === undefined ?
                                     <CotacaoStatus status={state.status}/>
                                     :
                                     <PedidoStatus status={state.status} />
                                    }

                                </Col>
                                {(state.status === situacaoCotacaoIndustriaConsts.cotacaoRejeitada || state.status === situacaoOrcamentoIndustriaConsts.orcamentoRejeitado) &&
                                    (state.codPedido === undefined || state.codPedido === null) && (
                                    <Col lg={7} style={{backgroundColor:"#e92e0916", borderRadius:10}} className='p-2 boxAvulco'>
                                        <p><strong>Observação: </strong>{state.observacao}</p>
                                    </Col>
                                )}
                                <Col className='justify-items-end pr-0'>
                                    {(state.status === situacaoCotacaoIndustriaConsts.cotacaoAberta ||
                                        state.status === situacaoCotacaoIndustriaConsts.cotacaoEnviada) &&
                                        (state.codPedido === undefined || state.codPedido === null) && (
                                        <Button
                                            className="btn btn-success boxButton w-100"
                                            onClick={() => enviarCotacao()}
                                            disabled={state.status === situacaoCotacaoIndustriaConsts.cotacaoEnviada}
                                        >
                                            <i className="icon-ckeck mt-1 ml-n2 mr-2" />
                                            Enviar cotação para revenda
                                        </Button>
                                    )}
                                    {state.status === situacaoCotacaoIndustriaConsts.cotacaoRecebida && (
                                        <div className='d-flex justify-content-between'>
                                            <Col className='pr-0'>
                                                <Button
                                                    className="btn btn-success boxButton w-100"
                                                    onClick={() => AprovarCotacao()}
                                                >
                                                    <i className="icon-ckeck mt-1 ml-n2 mr-2" />
                                                    <span>Aprovar</span>
                                                </Button>
                                            </Col>
                                            <Col className='pr-0'>
                                                <Button
                                                    className='boxButton btn-red w-100'
                                                    onClick={() => handleRejeitar()}
                                                >
                                                    <i className="icon-icon-lixeira mt-1 ml-n2 mr-2" />
                                                    <span>Rejeitar</span>
                                                </Button>
                                            </Col>
                                        </div>
                                    )}
                                    {(state.status === situacaoCotacaoIndustriaConsts.cotacaoAprovada) &&
                                        (state.codPedido !== undefined || state.codPedido !== null) && (
                                        <span className={`boxAvulco ${styles.bFechar}`}>Aguardando pedido</span>
                                    )}
                                </Col>
                            </Row>
                            <Row >
                                <Col xl={7} style={{borderBottom:'1px solid #ABAFBC'}} className='pr-0'>
                                    <p className={`${styles.pOrçamento} mt-4`}>
                                        Resumo da cotação
                                    </p>
                                    <p className={`mt-3 ${styles.pColor}`}>
                                        <strong>Código:</strong> {data?.codigo}
                                    </p>
                                    {(state.codPedido === undefined ||
                                        state.codPedido === null) &&
                                        (situacaoCotacaoIndustriaConsts.cotacaoAberta === state.status ||
                                        situacaoCotacaoIndustriaConsts.cotacaoEnviada === state.status ||
                                        situacaoCotacaoIndustriaConsts.cotacaoCancelada === state.status ||
                                        situacaoCotacaoIndustriaConsts.cotacaoRejeitada === state.status) ? <></>:
                                        <Row>
                                            <Col>
                                                <p className={`${styles.pCusto}`}>Custo total</p>
                                            </Col>
                                            <Col>
                                                <p className={`${styles.pTotal}`}> {new Intl.NumberFormat('pt-BR', {
                                                    style: 'currency',
                                                    currency: 'BRL',
                                                }).format(data?.valor || 0)}</p>
                                            </Col>
                                        </Row>
                                    }
                                </Col>
                                <Col>
                                    {(state.status == situacaoCotacaoIndustriaConsts.cotacaoAberta) &&
                                        (state?.codPedido === null || state?.codPedido === undefined) && (
                                        <button
                                            className={`d-flex mt-3 ${styles.bConti}`}
                                            onClick={() => editarCotacao()}
                                        >
                                            <i
                                                className={`icon-voltar mr-2 ${styles.iconeEs}`}
                                            />
                                            <p>Continuar comprando.</p>
                                        </button>
                                    )}
                                </Col>

                            </Row>

                            <Row className='mb-4 mt-0 pt-0'>
                                <Col>
                                    <p className={`${styles.pProduto}`}>
                                        Total de produto: {quantidade}
                                    </p>
                                </Col>
                                {isAtivo &&
                                    <Col>
                                        <button className={`${styles.bImpre}`} disabled>
                                            <i className={`icon-imprime mr-2 ${styles.iconeIm}`} />
                                            <p>Imprimir</p>

                                        </button>
                                    </Col>
                                }
                            </Row>

                            {pedido?.map((item, index) => (
                                <Row key={index} className={`mt-3`} id={`${styles.linha}`}>
                                    <Col lg={4}>
                                        <span className={`${styles.pGold}`}>
                                            {item?.codigo}
                                        </span>
                                    </Col>
                                    <Col>
                                        <p className={`${styles.pQtd}`}>
                                            <strong>Qtd. </strong>
                                            {item?.quantidadeItem}
                                        </p>
                                    </Col>
                                    <Col>
                                        {(item?.item.tipo == 1 || item?.item.tipo == "1") && (
                                            <p className={`${styles.pQtd}`}>
                                                {item?.item.barra} mm
                                            </p>
                                        )}
                                        {(item?.item.tipo == 2 || item?.item.tipo == "2") && (
                                            <p className={`${styles.pQtd}`}>
                                                {item?.item.quantidade} - {item?.item.unidade}(s)
                                            </p>
                                        )}
                                    </Col>
                                    <Col>
                                        <p className={`${styles.pQtd}`}>
                                            {item?.corDescricao}
                                        </p>
                                    </Col>
                                </Row>
                            ))}
                        </Container>
                    </Col>
                </Row>

                <SweetAlert
                    show={alertMessage}
                    type="info"
                    title="Enviar orçamento"
                    showCancel
                    reverseButtons
                    cancelBtnBsStyle="warning"
                    cancelBtnText="Não"
                    confirmBtnText="Sim"
                    confirmBtnBsStyle="success"
                    onConfirm={async () => {
                        await webApi.patch(
                            `cotacao/${data.codCotacao}/enviar-cotacao`
                        );
                        notificacao.sucesso('Orçamento enviado com sucesso.');
                        setAlertMessage(false);
                        history.push('/pedidos');
                    }}
                    onCancel={() => {
                        setAlertMessage(false);
                    }}
                >
                    Tem certeza que deseja enviar este orçamento?
                </SweetAlert>
                {alertSend}
            </PageContent>
        </>
    );
}
