import React, { useState, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Row, Col, Button } from 'reactstrap';
import { getSingleErrorMessage } from '~/utils';
import { DataTable } from '~/components/DataTable';
import PageContent from '~/components/PageContent';
import NavigationSubArea from '~/components/OrcamentoNavegacao';
import OrcamentoNavegacaoSuperior from '~/components/OrcamentoNavegacaoSuperior';
import history from '~/services/history';
import { useOrcamentoContext } from '~/hooks/useOrcamentoContext';
import webApi from '~/services/webapi';

export default function OrcamentoAnexo() {
    // hooks
    const { permiteEdicaoOrcamento, orcamentoStateContext } =
        useOrcamentoContext();

    // consts
    const { codOrcamento } = useParams();

    // states
    const [reload, setReload] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageCount, setPageCount] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [alertMessage, setAlertMessage] = useState(null);
    const [alertDelete, setAlertDelete] = useState(null);
    const [alertSend, setAlertSend] = useState(null);

    // refs
    const fetchIdRef = useRef(0);

    // functionsss
    const handleReloadTable = () => {
        const fetchId = ++fetchIdRef.current;
        setReload(fetchId);
    };

    const handleValid = () => {
        return true;
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Nome',
                accessor: 'nome',
            },
            {
                Header: 'Anexado Em',
                accessor: 'dataCriacao',
                Cell: ({ row }) => (
                    <div>
                        {format(
                            new Date(row.original.dataCriacao),
                            'dd/MM/yyyy hh:mm'
                        )}
                    </div>
                ),
            },
            {
                Header: 'Ações',
                accessor: 'actions',
                disableSortBy: true,
                Cell: ({ row }) => (
                    <div className="text-left">
                        <button
                            type="button"
                            rel="tooltip"
                            className="btn btn-icon btn-icon-mini btn-red-over"
                            title="Download"
                            onClick={() => handleClickDownload(row.original)}
                        >
                            <i className="fas fa-file-download" />
                        </button>
                        <button
                            type="button"
                            rel="tooltip"
                            className="btn btn-icon btn-icon-mini btn-red-over"
                            title="Excluir"
                            onClick={() => handleClickDelete(row.original)}
                            disabled={!permiteEdicaoOrcamento()}
                        >
                            <i className="far fa-trash-alt" />
                        </button>
                    </div>
                ),
            },
        ],
        []
    );

    const getAttachments = React.useCallback(() => {
        setLoading(true);
        (async () => {
            const result = await webApi.get(
                `/orcamentos/${codOrcamento}/anexos`
            );
            setData(result);
            setLoading(false);
        })();
    }, []);

    const handleShowMessage = (type, title, message, handleClickConfirm) => {
        setAlertMessage(
            <SweetAlert
                show
                type={type}
                title={title}
                style={{ display: 'block' }}
                confirmBtnBsStyle="success"
                onConfirm={() => {
                    if (handleClickConfirm) handleClickConfirm();
                    setAlertMessage(null);
                }}
            >
                {message}
            </SweetAlert>
        );
    };

    const handleClickDelete = ({ codOrcamentoAnexo }) => {
        setAlertDelete(
            <SweetAlert
                show
                warning
                style={{ display: 'block' }}
                title="Você tem certeza?"
                confirmBtnBsStyle="danger"
                cancelBtnText="Não"
                confirmBtnText="Sim"
                showCancel
                reverseButtons
                onConfirm={async () => {
                    await webApi.delete(
                        `/orcamentos/${codOrcamento}/anexos/${codOrcamentoAnexo}`
                    );
                    setAlertDelete(null);
                    handleReloadTable();
                }}
                onCancel={() => {
                    setAlertDelete(null);
                }}
            >
                Tem certeza que deseja excluir? Você não poderá reverter essa
                ação!
            </SweetAlert>
        );
    };

    const handleClickSend = () => {
        setAlertSend(
            <SweetAlert
                show
                info
                style={{ display: 'block' }}
                title="Você tem certeza?"
                confirmBtnBsStyle="success"
                cancelBtnText="Não"
                confirmBtnText="Sim"
                closeOnClickOutside={false}
                showCancel
                reverseButtons
                onConfirm={async () => {
                    try {
                        await webApi.put(
                            `/orcamentos/${codOrcamento}/com-vendedor`
                        );
                        setAlertSend(null);
                        handleShowMessage(
                            'success',
                            'Sucesso',
                            'Orçamento solicitado com sucesso',
                            () => {
                                history.push(`/orcamentos`);
                            }
                        );
                    } catch (error) {
                        setAlertSend(null);
                        handleShowMessage(
                            'warning',
                            'Não foi possível realizar esta ação',
                            getSingleErrorMessage(error)
                        );
                    }
                }}
                onCancel={() => {
                    setAlertSend(null);
                }}
            >
                Tem certeza que deseja realizar o envio desta solicitação? Você
                não poderá reverter essa ação!
            </SweetAlert>
        );
    };

    const handleClickDownload = async ({ nome, codOrcamentoAnexo }) => {
        const result = await webApi.get(
            `/orcamentos/${codOrcamento}/anexos/${codOrcamentoAnexo}`,
            { responseType: 'blob' }
        );

        const downloadUrl = window.URL.createObjectURL(new Blob([result]));

        const link = document.createElement('a');

        link.href = downloadUrl;

        link.setAttribute('download', nome);

        document.body.appendChild(link);

        link.click();

        link.remove();
    };

    const handleClickAdd = async (e) => {
        try {
            const formData = new FormData();
            formData.append('arquivo', e.target.files[0]);
            await webApi.post(`/orcamentos/${codOrcamento}/anexos`, formData);
            handleReloadTable();
        } catch (error) {
            const message = getSingleErrorMessage(error);

            handleShowMessage(
                'warning',
                'Não foi possível incluir o arquivo selecionado',
                message
            );
        }
    };

    return (
        <>
            <PageContent className="no-border">
                <OrcamentoNavegacaoSuperior />
            </PageContent>

            <PageContent>
                <NavigationSubArea
                    active="attachment"
                    handleValid={() => handleValid()}
                >
                    <form>
                        <Row
                            className=" mt-2 text-left text-sm-left"
                            style={{ marginLeft: '-35px' }}
                        >
                            <Col md="1.5" className='ml-3'>
                                <span
                                    className="notfButton btn btn-primary btn-file"
                                    disabled={!permiteEdicaoOrcamento()}
                                    style={{
                                        backgroundColor: '#ABAFBC',
                                        fontSize: '14px',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <i className="icon-icon-h mr-1" />
                                    <span
                                        className="fileinput-new"
                                        style={{
                                            color: '#FFF',
                                        }}
                                    >
                                        {' '}
                                        Adicionar Anexo
                                    </span>
                                    <input
                                        type="file"
                                        name="..."
                                        onChange={(e) => handleClickAdd(e)}
                                        disabled={!permiteEdicaoOrcamento()}
                                    />
                                </span>
                            </Col>
                        </Row>
                        <Row className="mr-3" style={{ marginLeft: '-35px' }}>
                            <Col md="12">
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    fetchData={getAttachments}
                                    loading={loading}
                                    pageCount={pageCount}
                                    total={totalRecords}
                                    reload={reload}
                                    includeFilter={false}
                                    includePagination={false}
                                    includePageSize={false}
                                    includeTotalRecords={false}
                                />
                            </Col>
                        </Row>
                        <div
                            className="mt-1"
                            style={{ marginLeft: '-25px', fontSize: '18px' }}
                        >
                            <div className="pull-left ml-1">
                                <Button
                                    type="button"
                                    role="tablist"
                                    className="boxButtonAvancar"
                                    style={{ backgroundColor: '#ABAFBC' }}
                                    onClick={() => {
                                        history.push(
                                            `/orcamentos/${codOrcamento}/observacoes`
                                        );
                                    }}
                                >
                                    <i
                                        className="icon-icon-voltar mr-1"
                                        style={{ fontSize: '18px' }}
                                    />
                                    Voltar
                                </Button>
                            </div>
                            <div className="pull-right mr-3">
                                {orcamentoStateContext.codOrcamentoTecnico !==
                                    0 && (
                                    <Button
                                        type="button"
                                        role="tablist"
                                        className="boxButtonAvancar"
                                        style={{ backgroundColor: '#3073CB' }}
                                        onClick={() => {
                                            history.push(
                                                `/orcamentos-tecnicos/${orcamentoStateContext.codOrcamentoTecnico}/versao/${orcamentoStateContext.versaoOrcamentoTecnico}/vendas`
                                            );
                                        }}
                                    >
                                        Avançar{' '}
                                        <i
                                            className="icon-icon-avancar"
                                            style={{ fontSize: '18px' }}
                                        />
                                    </Button>
                                )}
                                {permiteEdicaoOrcamento() && (
                                    <Button
                                        style={{
                                            backgroundColor: '#3073CB',
                                            height: '45px',
                                        }}
                                        color="primary"
                                        onClick={() => handleClickSend()}
                                        className="boxButton ml-3 mr-3"
                                    >
                                        Solicitar Orçamento{' '}
                                        <i className="icon-icon-ckeck ml-1" />
                                    </Button>
                                )}
                            </div>
                        </div>
                    </form>
                </NavigationSubArea>
                {alertMessage}
                {alertDelete}
                {alertSend}
            </PageContent>
        </>
    );
}
