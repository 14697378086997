import React from 'react';
import NumberFormat from 'react-number-format';
import { Input } from 'reactstrap';
import { useOrcamentoTecnicoVendaContext } from '~/hooks/useOrcamentoTecnicoVendaContext';

export default function OrcamentoTecnicoVendaCustosAdicionaisItem(props) {
    // hooks
    const {
        orcamentoTecnicoCustoAdicionais,
        atualizarOrcamentoTecnicoCustosAdicionais,
    } = useOrcamentoTecnicoVendaContext();

    function removerCustoAdicional() {
        const custosAdicionais = orcamentoTecnicoCustoAdicionais.filter(
            (item) => item !== props.custoAdicional
        );
        atualizarOrcamentoTecnicoCustosAdicionais(custosAdicionais);
    }

    return (
        <>
            <div className="grupo-tipo-campo valor-total">
                <div className="campo-adicional-orcamento">
                    <label className="servico-adicional">Nome do serviço</label>
                    <br />
                    <Input
                        type="text"
                        value={props.custoAdicional.descricao}
                        disabled
                    />
                </div>
                <div className="campo-valor-revenda ">
                    {props.indice}
                    <label>Meu valor de venda</label>
                    <br />
                    <NumberFormat
                        className="input-bloqueado"
                        placeholder="R$ 0,00"
                        value={props.custoAdicional.custo}
                        decimalScale={2}
                        disabled
                        fixedDecimalScale
                        prefix="R$ "
                        decimalSeparator=","
                        thousandSeparator="."
                    />
                </div>
                <div>
                    <button
                        onClick={() => removerCustoAdicional()}
                        title="Excluir"
                        className="btn btn-icon btn-icon-mini btn-neutral"
                    >
                        <i className="fa fa-trash" />
                    </button>
                </div>
            </div>
        </>
    );
}
