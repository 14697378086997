import React, { useEffect, useState } from 'react';
import './styless.css';
import webApi from '~/services/webapi';
import { format, startOfYear } from 'date-fns';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ptBR from 'date-fns/locale/pt-BR';
import { Col, Row, Label } from 'reactstrap';
import { Indicador } from '../components/Indicador/index';
import { CardStatus } from '../components/ColumnChart';
import { IndicadorResponsive } from '../components/Indicador/cardResponsive';
import { CardPeriodo } from '../components/Periodo';
import { CardAcumulado } from '../components/Acumulado';
import { CardTop5 } from "../components/Top5";

registerLocale('ptBR', ptBR);

export const formatters = [
    {
        type: 'NumberFormat',
        column: 1,
        options: {
            prefix: 'R$',
            negativeColor: 'red',
            negativeParens: true,
            width: 0,
        },
    },
];

export const formatters1 = [
    {
        type: 'NumberFormat',
        column: 1,
        options: {
            prefix: 'R$',
            width: 0,
        },
    },
];

export default function DashboardRevenda() {
    const [dateRange, setDateRange] = useState([
        startOfYear(new Date()),
        new Date(),
    ]);
    const [startDate, endDate] = dateRange;
    const [totalClientes, setTotalClientes] = useState(0);

    const [ cardOrcamento, setCardOrcamento ] = useState({
        clientes: 0,
        esquadriaOrcamentoRecebido: 0,
        esquadriaPedidoAceito: 0,
        avulsoOrcamentoRecebido: 0,
        avulsoPedidoAceito: 0,
    });

    const [ quantidadeStatus, setQuantidadeStatus ] = useState({
        clientes: 0,
        comVendedor:0,
        solicitado:0,
        recebidoVendedor:0,
        recebido:0,
        pedidoAberto:0,
        pedidoSolicitado:0,
        pedidoFinalizado:0,
    });

    const [quantidadeOrcamentoPorStatus, setQuantidadeOrcamentoPorStatus] =
        useState({
            esquadriaOrcamentoRecebidos: 0,
            esquadriaPedidoAceitos: 0,
            avulsoOrcamentoRecebido: 0,
            avulsosPedidoAceito: 0,
        });

    const [
        valorTotalOrcamentosFechadosPorPeriodo,
        setValorTotalOrcamentosFechadosPorPeriodo,
    ] = useState([]);

    const [
        quantidadeOrcamentoFechadoPorLinha,
        setQuantidadeOrcamentoFechadoPorLinha,
    ] = useState([]);

    const [quantidadeOrcamentoPorLinha, setQuantidadeOrcamentoPorLinha] =
        useState([]);

    const [
        clienteAcumuladorOrcamentoFechado,
        setClienteAcumuladorOrcamentoFechado,
    ] = useState([]);

    const [
        clienteAcumuladorOrcamentoFechadoPorQuantidade,
        setClienteAcumuladorOrcamentoFechadoPorQuantidade,
    ] = useState([]);

    async function obterTotalClientesRevenda() {
        const result = await webApi.get(
            `dashboard/revenda/obter-total-clientes`
        );
        setTotalClientes(Number(result) || 0);
    }

    async function obterQuantidadeOrcamentoPorStatus(dataInicial, dataFinal) {
        const result = await webApi.get(
            `dashboard/revenda/quantidade-orcamento-status`,
            {
                params: {
                    dataInicial: dataInicial,
                    dataFinal: dataFinal,
                },
            }
        );
        const orcamentosEnviado = result?.find((i) => i.flSituacao == 2); //2 - Orçamento Enviado
        const orcamentosRecebido = result?.find((i) => i.flSituacao == 3); //3 - Orçamento Recebido             (Solicitado - laranja)
        const orcamentosRecusado = result?.find((i) => i.flSituacao == 4); //4 - Orçamento Rejeitado            (Vermelho - arquivo x)
        const orcamentosRetornada = result?.find((i) => i.flSituacao == 5); //5 - Orçamento Retornado           (Recebido - Roxo arquivo-seta)
        const orcamentosRevisao = result?.find((i) => i.flSituacao == 6); // 6 - Orçamento Revisão
        const propostaRejeitada = result?.find((i) => i.flSituacao == 8);// 8 - Proposta Rejeitada
        const orcamentosPedidoAberto = result?.find((i) => i.flSituacao == 11); // 11 - Pedido Aberto
        const orcamentosPedidoSolicitado = result?.find((i) => i.flSituacao == 12); // 12 - Pedido Solicitado
        const pedidoFinalizado = result?.find((i) => i.flSituacao == 13); // 13 - Pedido Finalizado             (Verde - arquivo-check)
        const orcamentosComVendedor = result?.find((i) => i.flSituacao == 14); // 14 - Orçamento com vendedor   (Roxo - boneco)
        const recebidoPeloVendedor = result?.find((i) => i.flSituacao == 15); //15 - Recebido pelo Vendedor     (Amarelo escuro)

        const recebidoEsquadrias = Number(orcamentosComVendedor?.quantidade || 0) + Number(orcamentosEnviado?.quantidade || 0)  + Number(recebidoPeloVendedor?.quantidade || 0) + Number(orcamentosRetornada?.quantidade || 0) + Number(orcamentosPedidoAberto?.quantidade || 0) + Number(orcamentosPedidoSolicitado?.quantidade || 0) + Number(pedidoFinalizado?.quantidade || 0);

        setQuantidadeStatus({
            ...quantidadeStatus,
            clientes: totalClientes || 0,
            comVendedor:Number(orcamentosComVendedor?.quantidade || 0),
            solicitado:Number(orcamentosEnviado?.quantidade || 0),
            recebidoVendedor:Number(recebidoPeloVendedor?.quantidade || 0),
            recebido:Number(orcamentosRetornada?.quantidade || 0),
            pedidoAberto:Number(orcamentosPedidoAberto?.quantidade || 0),
            pedidoSolicitado:Number(orcamentosPedidoSolicitado?.quantidade || 0),
            pedidoFinalizado:Number(pedidoFinalizado?.quantidade || 0),
        });

        setCardOrcamento({
            ...cardOrcamento,
            clientes: totalClientes || 0,
            esquadriaOrcamentoRecebido: recebidoEsquadrias || 0,
            esquadriaPedidoAceito: Number(pedidoFinalizado?.quantidade || 0),
            avulsoOrcamentoRecebido: 0,
            avulsoPedidoAceito: 0,
        });

        setQuantidadeOrcamentoPorStatus({
            ...quantidadeOrcamentoPorStatus,
            esquadriaOrcamentoRecebidos: recebidoEsquadrias,
            esquadriaPedidoAceitos: Number(pedidoFinalizado?.quantidade || 0),
            avulsoOrcamentoRecebido: 0,
            avulsosPedidoAceito: 0,
        });

    }



    async function obterTotalOrcamentosFechadosPorPeriodo(
        dataInicialFormatada,
        dataFinalFormatada
    ) {
        const result = await webApi.get(
            `dashboard/revenda/obter-total-orcamento-por-periodo`,
            {
                params: {
                    dataInicial: dataInicialFormatada,
                    dataFinal: dataFinalFormatada,
                },
            }
        );

        const periodos = result.map((item) => {
            return [item.periodo, item.totalOrcamento];
        });

        if (periodos.length > 0) {
            setValorTotalOrcamentosFechadosPorPeriodo([
                ['', 'Orçamentos Fechados: '],
                ...periodos,
            ]);
        } else {
            setValorTotalOrcamentosFechadosPorPeriodo([
                ['', ''],
                ['', 0],
            ]);
        }
    }

    async function obterQuantidadeOrcamentoFechadoPorLinha(
        dataInicialFormatada,
        dataFinalFormatada
    ) {
        const result = await webApi.get(
            `dashboard/revenda/obter-quantidade-orcamento-fechado-por-linha`,
            {
                params: {
                    dataInicial: dataInicialFormatada,
                    dataFinal: dataFinalFormatada,
                },
            }
        );
        const linhas = result.map((item) => {
            return [item.linha, item.quantidade];
        });

        setQuantidadeOrcamentoFechadoPorLinha([
            ['Orçamentos', 'Linha'],
            ...linhas,
        ]);
    }

    async function obterQuantidadeOrcamentoPorLinha(
        dataInicialFormatada,
        dataFinalFormatada
    ) {
        const result = await webApi.get(
            `dashboard/revenda/obter-quantidade-orcamento-por-linha`,
            {
                params: {
                    dataInicial: dataInicialFormatada,
                    dataFinal: dataFinalFormatada,
                },
            }
        );
        const linhas = result.map((item) => {
            return [item.linha, item.quantidade];
        });

        setQuantidadeOrcamentoPorLinha([['Orçamentos', 'Linha'], ...linhas]);
    }

    async function obterClienteAcumuladorOrcamentoFechado(
        dataInicialFormatada,
        dataFinalFormatada
    ) {
        const result = await webApi.get(
            `dashboard/revenda/obter-clientes-acumulador-orcamento-fechado`,
            {
                params: {
                    dataInicial: dataInicialFormatada,
                    dataFinal: dataFinalFormatada,
                },
            }
        );
        const clientes = result.map((item) => {
            return [
                item.cliente,
                item.totalOrcamentoTecnicoAcumulado,
                item.quantidade,
            ];
        });

        setClienteAcumuladorOrcamentoFechado([
            ['Cliente', 'Acumulado', 'Qtd Orçamentos'],
            ...clientes,
        ]);
    }

    async function obterClienteAcumuladorOrcamentoFechadoPorQuantidade(
        dataInicialFormatada,
        dataFinalFormatada
    ) {
        const result = await webApi.get(
            `dashboard/revenda/obter-clientes-acumulador-orcamento-fechado-por-quantidade`,
            {
                params: {
                    dataInicial: dataInicialFormatada,
                    dataFinal: dataFinalFormatada,
                    quantidade: 5,
                },
            }
        );
        const clientes = result.map((item) => {
            return [
                item.cliente,
                item.totalOrcamentoTecnicoAcumulado,
                item.quantidade,
            ];
        });

        setClienteAcumuladorOrcamentoFechadoPorQuantidade([
            ['Cliente', 'Acumulado', 'Qtd Orçamentos'],
            ...clientes,
        ]);
    }

    const dataIndicador = [
        {
            icon:<i className='icon-icon-clientes icon-30x' />,
            texto:"Total de Industrias",
            total:totalClientes || 0,
            tipo:"PROPOSTAPENDENTE",
            status: totalClientes,
        },
        {
            icon:<i className="icon-icon-o-recebido icon-30x" />,
            texto:"Esquadrias: Orçamentos Recebido",
            total:cardOrcamento.esquadriaOrcamentoRecebido || 0,
            tipo:"PROPOSTAFECHADA",
            status: quantidadeStatus,
        },
        {
            icon:<i className="icon-icon-o-enviado icon-30x" />,
            texto:"Esquadrias: Pedido Aceitos",
            total:cardOrcamento.esquadriaPedidoAceito || 0,
            tipo:"PEDIDOPENDENTE",
            status: quantidadeStatus,
        },
        {
            icon:<i className="icon-icon-o-espera icon-30x" />,
            texto:"Avulsos: Orçamentos Recebidos",
            total:cardOrcamento.avulsoOrcamentoRecebido ?? 0,
            tipo:"SOLICITADA",
            status: quantidadeStatus,
        },
        {
            icon:<i className="icon-icon-p-ok icon-30x" />,
            texto:"Avulsos: Pedidos Aceitos",
            total:cardOrcamento.avulsoPedidoAceito || 0,
            tipo:"PEDIDOACEITE",
            status: quantidadeStatus,
        },
     ];

     const dataStatus = [
        ['', '', { role: 'style' }],
        [
            'Esquadria: Orçamentos Recebidos',
            quantidadeOrcamentoPorStatus?.orcamentosRecebidos,
            '#308BCB',
        ],
        [
            'Esquadrias: Pedidos Aceitos',
            quantidadeOrcamentoPorStatus?.orcamentosFechados,
            '#D63698',
        ],
        [
            'Avulsos: Orçamentos Recebidos',
            quantidadeOrcamentoPorStatus?.orcamentosRecebidos,
            '#4EAA63',
        ],
        [
            'Avulsos: Pedidos Aceitos',
            quantidadeOrcamentoPorStatus?.orcamentosFechados,
            '#D63668',
        ],
    ];

    useEffect(() => {
        (async () => {
            let dataInicialFormatada, dataFinalFormatada;
            if (startDate && endDate) {
                dataInicialFormatada = format(startDate, 'yyyy-MM-dd');
                dataFinalFormatada = format(endDate, 'yyyy-MM-dd');
            }
            if (dataInicialFormatada && dataFinalFormatada) {
                await obterQuantidadeOrcamentoPorStatus(
                    dataInicialFormatada,
                    dataFinalFormatada
                );
                await obterTotalClientesRevenda();
                await obterTotalOrcamentosFechadosPorPeriodo(
                    dataInicialFormatada,
                    dataFinalFormatada
                );
                await obterQuantidadeOrcamentoFechadoPorLinha(
                    dataInicialFormatada,
                    dataFinalFormatada
                );
                await obterQuantidadeOrcamentoPorLinha(
                    dataInicialFormatada,
                    dataFinalFormatada
                );
                await obterClienteAcumuladorOrcamentoFechado(
                    dataInicialFormatada,
                    dataFinalFormatada
                );
                await obterClienteAcumuladorOrcamentoFechadoPorQuantidade(
                    dataInicialFormatada,
                    dataFinalFormatada
                );
            }
        })();
    }, [endDate]);

    return (
        <div className='col-12'>
            <header>
                <link
                    rel="stylesheet"
                    href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css"
                    integrity="sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO"
                    crossOrigin="anonymous"
                />
                <style>
                    @import
                    url('https://fonts.googleapis.com/css2?family=Inter:wght@300;500;700&display=swap');
                </style>
                <Row>
                    <Col>
                        <Label>Selecione o período</Label>
                        <DatePicker
                            placeholderText="Filtrar por período"
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            locale="ptBR"
                            dateFormat="dd/MM/yyyy"
                            onChange={(update) => {
                                if (!update[0] && !update[1]) {
                                    setDateRange([
                                        startOfYear(new Date()),
                                        new Date(),
                                    ]);
                                } else {
                                    setDateRange(update);
                                }
                            }}
                            isClearable={true}
                        />
                    </Col>
                    <Col lg={8} >
                    </Col>

                </Row>
            </header>
            <hr style={{marginBottom:0}}/>
            <p style={{fontSize:12,textAlign:'end', marginTop:0}}>Dados conforme período selecionado</p>
            <main className="dashboardRevenda">
                <div className='d-none d-lg-block'>
                    <Row>
                        {dataIndicador.map(({icon, texto, tipo, total, status}, index) => (
                            <Col className="px-0" key={index} id={tipo}>
                                <Indicador
                                    icon={icon}
                                    texto={texto}
                                    total={total || 0}
                                    tipo={tipo}
                                    status={status}
                                />
                            </Col>
                        ))}
                    </Row>
                </div>
                <div className='d-lg-none d-block'>
                    <Row>
                        {dataIndicador.map(({ texto, total, tipo, status}, index) => (
                            <Col md={4} sm={6} xs={12} key={index}>
                                <IndicadorResponsive
                                    texto={texto}
                                    total={total ?? 0}
                                    tipo={tipo}
                                    status={status}
                                />
                            </Col>
                        ))}
                    </Row>
                </div>
                {/* segunda linha dos graficos - Barras */}
                <div className='d-none d-lg-block'>
                    <Row>
                        <Col className="px-0">
                            <CardStatus
                                title={`Quantidade de Orçamentos: Status`}
                                data={dataStatus}
                            />
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={6} xs={12} className='m-0 pr-1 pb-3'>
                            <CardPeriodo
                                title={'Total de Orçamentos Fechados por Período'}
                                data={valorTotalOrcamentosFechadosPorPeriodo}
                            />
                        </Col>
                    </Row>
                </div>

                {/*  GRAFICOS TOPS */}
                <div className='d-none d-lg-block'>
                    <Row>
                        <Col className="px-0">
                            <CardAcumulado
                                title={'Clientes | $ Acumulado x Orçamentos Fechados'}
                                data={clienteAcumuladorOrcamentoFechado}
                                formatters={formatters}
                            />
                        </Col>
                            <Col xl={6} lg={6} md={6} sm={6} xs={12} className='m-0 pr-1'>
                                <CardTop5
                                    title={'Top 5 | Clientes x Orçamentos Fechados'}
                                    data={clienteAcumuladorOrcamentoFechadoPorQuantidade}
                                    formatters={formatters1}
                                />
                        </Col>
                    </Row>
                </div>
            </main>
        </div>
    );
}

