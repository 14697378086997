import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useOrcamentoTecnicoVendaContext } from '~/hooks/useOrcamentoTecnicoVendaContext';
import { getValorVendaOrcamentoTecnico } from '../../../services/modules/Orcamento/orcamentoTecnico';
import { formatMoneyMask } from '../../../shared/function/index';
import '../style.css';
import { getObterConfigs } from 'services/modules/Orcamento/orcamentoOcultar';

export default function OrcamentoTecnicoVendaDetalhe() {
    const { codOrcamentoTecnico, versao } = useParams();
    const [data, setData] = useState();
    const [ocultarDadosRevenda, setOcultarDadosRevenda] = useState(false);

    const { orcamentoTecnicoValores, totalValorOrcamentoTecnico } =
        useOrcamentoTecnicoVendaContext();

    useEffect(() => {
        (async () => {
            const orcamentosConfiguracoes = await getObterConfigs();
            const orcamentoConfPeso = orcamentosConfiguracoes.filter(
                (oc) => oc.codOrcamentoConfiguracao === 2
            )[0];
            setOcultarDadosRevenda(orcamentoConfPeso.oculto);

            const result = await getValorVendaOrcamentoTecnico(
                codOrcamentoTecnico,
                versao
            );
            setData(result);
        })();
    }, []);

    return (
        <div className="myValue">
            <div className="valueVenda">
                <label id="tituloVenda">Meu valor de venda</label>
                <label id="tituloValue">
                    {orcamentoTecnicoValores.totalCustoVendedor >
                    totalValorOrcamentoTecnico ? (
                        <>
                            {formatMoneyMask(
                                orcamentoTecnicoValores.totalCustoVendedor
                            )}
                        </>
                    ) : (
                        <>{formatMoneyMask(totalValorOrcamentoTecnico)}</>
                    )}
                </label>
            </div>
            {(orcamentoTecnicoValores.temCustoPerfil ||
                orcamentoTecnicoValores.temCustoComponente ||
                orcamentoTecnicoValores.temCustoKit ||
                orcamentoTecnicoValores.temCustoVidro ||
                orcamentoTecnicoValores.temCustoChapaTela) && (
                <div className="valueVenda">
                    <label id="totalValor">
                        Meu custo para comprar materiais de revenda
                    </label>
                    {orcamentoTecnicoValores.temCustoPerfil && (
                        <div className="itensVenda">
                            <label id="itensLabel">Perfil de alumínio</label>
                        </div>
                    )}
                    {orcamentoTecnicoValores.temCustoComponente && (
                        <div className="itensVenda">
                            <label id="itensLabel">Componente</label>
                        </div>
                    )}
                    {orcamentoTecnicoValores.temCustoKit && (
                        <div className="itensVenda">
                            <label id="itensLabel">Kit</label>
                        </div>
                    )}
                    {orcamentoTecnicoValores.temCustoVidro && (
                        <div className="itensVenda">
                            <label id="itensLabel">Vidro</label>
                        </div>
                    )}
                    {orcamentoTecnicoValores.temCustoChapaTela && (
                        <div className="itensVenda">
                            <label id="itensLabel">Superficie</label>
                        </div>
                    )}
                    <div className="totalCusto">
                        <label id="itensLabel" className="mr-3">
                            Total:
                        </label>
                        <label id="itensLabel">
                            {formatMoneyMask(
                                orcamentoTecnicoValores.totalCustoVendedor
                            )}
                        </label>
                    </div>
                </div>
            )}
            {!ocultarDadosRevenda ? (
                <div>
                    <label id="totalValor">
                        Dados técnicos enviados pela revenda
                    </label>
                    <div className="itensVenda">
                        <label id="itensLabel">Peso total de alumínio</label>
                        <label id="itensLabel">
                            {data !== undefined
                                ? `${data.totalPesoBruto} Kg`
                                : 0}
                        </label>
                    </div>
                    <div className="itensVenda">
                        <label id="itensLabel">Alumínio</label>
                        <label id="itensLabel">
                            {data !== undefined
                                ? `${data.totalQtdBarra} Br`
                                : 0}
                        </label>
                    </div>
                    <div className="itensVenda">
                        <label id="itensLabel">M² Vidros</label>
                        <label id="itensLabel">
                            {data !== undefined
                                ? `${data.totalAreaVidro} m²`
                                : 0}
                        </label>
                    </div>
                    <div className="itensVenda">
                        <label id="itensLabel">Superficies</label>
                        <label id="itensLabel">
                            {data !== undefined
                                ? `${data.totalQtdSuperficies} pç(s)`
                                : 0}
                        </label>
                    </div>
                    <div className="itensVenda">
                        <label id="itensLabel">Kits</label>
                        <label id="itensLabel">
                            {data !== undefined
                                ? `${data.totalQtdKits} pc(s)`
                                : 0}
                        </label>
                    </div>
                </div>
            ) : (
                <div className="d-none" />
            )}
        </div>
    );
}
