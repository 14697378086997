import React, { useState, useRef, useCallback, useEffect } from 'react';

import {
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    ModalFooter,
    Row,
    Col,
    Table,
    Button,
} from 'reactstrap';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import PageContent from '~/components/PageContent';
import webApi from '~/services/webapi';

export default function AlterarLogo() {
    // refs
    const imgRef = useRef(null);

    // states
    const [imageLogo, setImageLogo] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [upImg, setUpImg] = useState();
    const [crop, setCrop] = useState({
        unit: '%',
        width: 50,
        height: 50,
        x: 25,
        y: 25,
    });
    const [croppedImageUrl, setCroppedImageUrl] = useState(null);

    // functions
    const loadLogo = async () => {
        async function fetch() {
            const request = await webApi.get('usuario/obter-logo');
            if (request) setImageLogo(request);
            else setImageLogo(null);
        }
        fetch();
    };

    const getCroppedImg = (image, crop) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(
                (blob) => {
                    blob.name = 'logo';
                    resolve(blob);
                },
                'image/jpeg',
                1
            );
        });
    };

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => setUpImg(reader.result));
            reader.readAsDataURL(e.target.files[0]);
            setUpImg(null);
            setIsOpen(true);
        }
    };

    const onClickSave = async () => {
        const data = new FormData();
        data.append('file', croppedImageUrl);
        await webApi.post('usuario/alterar-logo', data);
        await loadLogo();
        setIsOpen(false);
    };

    const onCropComplete = async (c) => {
        if (imgRef.current && c.width && c.height) {
            const croppedImage = await getCroppedImg(imgRef.current, c);
            setCroppedImageUrl(croppedImage);
        }
    };

    const onClickDelete = async () => {
        await webApi.delete('usuario/remover-logo');
        setImageLogo(null);
    };

    // hooks
    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    useEffect(() => {
        if (!imageLogo) loadLogo();
    }, []);

    return (
        <PageContent titlePage="Logo da Empresa">
            <p
                className="ml-1 mt-1 mb-1"
                style={{
                    fontSize: '20px',
                    fontFamily: 'Inter, sans-serif',
                    color: '#57607A',
                    marginBottom: '-45px',
                }}
            >
                Alteração de <strong style={{ color: '#41898C' }}>Logo</strong>
            </p>

            <Col md="6" className="ml-auto mr-auto">
                <Row>
                    <Col md="12" className="mt-3">
                        <span
                            className="boxButton btn btn-file"
                            style={{ backgroundColor: '#4EAA79' }}
                        >
                            <i className="icon-icon-h ml-n2 mr-2" />
                            <span
                                className="fileinput-new"
                                style={{ color: 'white' }}
                            >
                                Alterar Logo
                            </span>
                            <input
                                accept=".jpg, .png"
                                type="file"
                                id="input"
                                onChange={(e) => onSelectFile(e)}
                                onClick={(event) => {
                                    event.target.value = null;
                                }}
                            />
                        </span>
                    </Col>
                    <Label className="text-left col-sm-12">
                        Sua imagem deve ser estar no formato "PNG" ou "JPG".
                    </Label>
                </Row>
                <div className="table-full-width table-responsive tabela-sem-barra">
                    <Table>
                        <tbody>
                            <tr>
                                <td className="td-actions text-center">
                                    <Label className="label">
                                        <img
                                            className="rounded"
                                            src={imageLogo}
                                            alt=""
                                            width="122"
                                        />
                                    </Label>
                                </td>
                                <td className="td-actions text-right">
                                    <Button
                                        className="btn btn-icon btn-icon-mini btn-red-over"
                                        onClick={onClickDelete}
                                    >
                                        <i className="icon-icon-lixeira" />
                                    </Button>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </Col>
            <Modal isOpen={isOpen} size="md">
                <ModalHeader>
                    <button
                        type="button"
                        className="close"
                        onClick={() => setIsOpen(false)}
                    >
                        <i className="nc-icon nc-simple-remove" />
                    </button>
                    <span className="title title-up">ADICIONAR IMAGEM</span>
                </ModalHeader>
                <ModalBody className="text-center">
                    <ReactCrop
                        maxWidth={600}
                        maxHeight={600}
                        src={upImg}
                        onImageLoaded={onLoad}
                        crop={crop}
                        onChange={(c) => setCrop(c)}
                        onComplete={(c) => onCropComplete(c)}
                    />
                </ModalBody>
                <ModalFooter>
                    <div className="left-side">
                        <Button
                            color="link"
                            className="boxButton"
                            onClick={() => setIsOpen(false)}
                        >
                            Cancelar
                        </Button>
                    </div>
                    <div className="divider" />
                    <div className="right-side">
                        <Button
                            className="boxButton btn btn-success btn-link"
                            onClick={onClickSave}
                        >
                            <i className="far fa-save" /> Salvar
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        </PageContent>
    );
}
