import webApi from 'services/webapi';

export const postPrecificacaoDireta = async (idTecnico, versao, params) => {
    try {
        const data = await webApi.post(
            `/OrcamentoTecnico/${idTecnico}/versao/${versao}/alterar-total-orcamento-tecnico-precificacao-direta`,
            {
                itens: params,
            }
        );
        return data;
    } catch (error) {
        return null;
    }
};

export const getRelatorioTecnico = async (codOrcamentoTecnico, versao) => {
    try {
        const data = await webApi.get(
            `/orcamentoTecnico/${codOrcamentoTecnico}/versao/${versao}/relatorios-tecnicos`
        );
        return data;
    } catch (error) {
        return null;
    }
};

export const getRelatorioTecnicoDownload = async (
    codOrcamentoTecnico,
    versao,
    codRelatorio
) => {
    const result = await webApi.get(
        `/orcamentoTecnico/${codOrcamentoTecnico}/versao/${versao}/relatorios-tecnicos/${codRelatorio}`,
        { responseType: 'blob' }
    );
    return result;
};

export const getValores = async (codOrcamentoTecnico, versao) => {
    try {
        const data = await webApi.get(
            `orcamentoTecnico/${codOrcamentoTecnico}/versao/${versao}/valores`
        );
        return data;
    } catch (error) {
        return null;
    }
};

export const getObservacao = async (codOrcamentoTecnico, versao) => {
    try {
        const data = await webApi.get(
            `/OrcamentoTecnico/${codOrcamentoTecnico}/versao/${versao}/observacoes`
        );
        return data;
    } catch (error) {
        return null;
    }
};

export const getEnviarPropostaEmail = async (
    codOrcamentoTecnico,
    versao,
    params
) => {
    try {
        const data = await webApi.get(
            `/orcamentoTecnico/${codOrcamentoTecnico}/versao/${versao}/enviar-proposta-por-email/${params}`
        );
        return data;
    } catch (error) {
        return null;
    }
};

export const getItensOrcamentoTecnico = async (codOrcamentoTecnico, versao) => {
    try {
        const data = await webApi.get(
            `/OrcamentoTecnico/${codOrcamentoTecnico}/versao/${versao}/itens`
        );
        return data;
    } catch (error) {
        return null;
    }
};

export const getValorVendaOrcamentoTecnico = async (
    codOrcamentoTecnico,
    versao
) => {
    try {
        const data = await webApi.get(
            `/OrcamentoTecnicoTotalizadores/obterOrcamentoTotalizadores/${codOrcamentoTecnico}/versao/${versao}`
        );
        return data;
    } catch (error) {
        return null;
    }
};
