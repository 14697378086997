import React, { useRef } from 'react';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import NotificationAlert from 'react-notification-alert';

import PageContent from '~/components/PageContent';
import FormFieldError from '~/components/FieldValidation';
import webApi from 'services/webapi';

export default function Support() {
    const notify = useRef();

    const { register, handleSubmit, reset, errors } = useForm({
        validationSchema: yup.object().shape({
            name: yup.string().required('Campo obrigatório'),
            subject: yup.string().required('Campo obrigatório'),
            message: yup.string().required('Campo obrigatório'),
        }),
    });

    const onSubmit = async (data) => {
        await webApi.post('support', { ...data });

        const options = {
            place: 'tr',
            message: (
                <div>
                    E-mail enviado com <b>sucesso!</b>
                </div>
            ),
            type: 'success',
            icon: 'icon-alerta',
            autoDismiss: 7,
        };
        notify.current.notificationAlert(options);
        reset();
    };

    return (
        <PageContent>
            <div className="row">
                <div className="col-sm-12">
                    <div className="table-full-width table-responsive tabela-sem-barra col-lg-6 ml-auto mr-auto">
                        <div className="card-body">
                            <h5
                                className="text-center"
                                style={{ color: '#57607A' }}
                            >
                                Tem alguma dúvida ou sugestão?
                            </h5>
                            <p
                                className="card-description text-center"
                                style={{ color: '#57607A' }}
                            >
                                Por favor, entre em contato conosco preenchendo
                                os campos abaixo que retornaremos o quanto
                                antes!
                            </p>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label
                                                style={{
                                                    color: '#57607A',
                                                    fontSize: '16px',
                                                }}
                                            >
                                                Nome
                                            </label>
                                            <input
                                                maxLength={80}
                                                name="name"
                                                type="text"
                                                ref={register}
                                                className={
                                                    errors?.name
                                                        ? 'boxInput form-control form-control-sm invalid is-invalid '
                                                        : 'boxInput form-control form-control-sm'
                                                }
                                                placeholder="Nome..."
                                            />
                                            <FormFieldError
                                                error={errors?.name}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <label
                                            style={{
                                                color: '#57607A',
                                                fontSize: '16px',
                                            }}
                                        >
                                            Assunto
                                        </label>
                                        <select
                                            name="subject"
                                            className={
                                                errors?.subject
                                                    ? 'boxAvulco form-control form-control-sm invalid is-invalid '
                                                    : 'boxAvulco form-control form-control-sm'
                                            }
                                            ref={register}
                                        >
                                            <option value="" disabled="">
                                                Selecione um assunto
                                            </option>
                                            <option value="Sugestão">
                                                Sugestão
                                            </option>
                                            <option value="Dúvida">
                                                Dúvida
                                            </option>
                                        </select>
                                        <FormFieldError
                                            error={errors?.subject}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label
                                                style={{
                                                    color: '#57607A',
                                                    fontSize: '16px',
                                                }}
                                            >
                                                Mensagem
                                            </label>
                                            <textarea
                                                maxLength={400}
                                                name="message"
                                                rows="20"
                                                className={
                                                    errors?.message
                                                        ? 'boxAvulco form-control form-control-sm invalid is-invalid '
                                                        : 'boxAvulco form-control form-control-sm'
                                                }
                                                ref={register}
                                            />
                                            <FormFieldError
                                                error={errors?.message}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className="modal-footer">
                                        <button
                                            type="submit"
                                            className="btn btn-success btn-link"
                                            style={{
                                                color: '#3073CB',
                                                fontSize: '16px',
                                            }}
                                        >
                                            Enviar Email
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <NotificationAlert ref={notify} />
        </PageContent>
    );
}
