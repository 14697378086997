import webApi from '../../webapi';

const getCotacoes = async () => {
    const data = await webApi.get(`cotacao/cotacoes`);
    return data;
};

const getCotacaoEnviadaCotada = async (codCotacao) => {
    const data = await webApi.get(
        `/cotacao/${codCotacao}/cotacao-enviada-cotada`
    );
    return data;
};

const putPropostaRejeitada = async (codOrcamento) => {
    await webApi.put(`/Orcamentos/${codOrcamento}/proposta-rejeitada`);
};

const putPedidoAberto = async (codOrcamento) => {
    await webApi.put(`orcamentos/${codOrcamento}/pedido-aberto`);
};

const putPedidoSolicitado = async (codOrcamento, params) => {
    try {
        const data = await webApi.put(
            `orcamentos/${codOrcamento}/pedido-solicitado`,
            { observacaoPedido: params }
        );
        return data;
    } catch (error) {
        return null;
    }
};

const putPedidoFechar = async (codOrcamento, params) => {
    try {
        const data = await webApi.put(
            `Orcamentos/${codOrcamento}/fechar-pedido`,
            { Observation: params }
        );
        return data;
    } catch (error) {
        return null;
    }
};

export {
    getCotacoes,
    getCotacaoEnviadaCotada,
    putPropostaRejeitada,
    putPedidoAberto,
    putPedidoSolicitado,
    putPedidoFechar,
};
