import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Label, Input, FormFeedback, Button, Row, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import styles from './orcamento.module.css';

import webapi from '~/services/webapi';
import history from '~/services/history';
import PageContent from '~/components/PageContent';
import NavigationSubArea from '../../components/OrcamentoNavegacao';
import OrcamentoNavegacaoSuperior from '~/components/OrcamentoNavegacaoSuperior';
import NumberFormat from 'react-number-format';
import PhoneInput from 'react-phone-number-input/input';
import { useOrcamentoContext } from '~/hooks/useOrcamentoContext';

const defaultValues = {
    projectName: '',
    projectCode: '',
    addressStreet: '',
    addressPostalCode: null,
    addressNumber: '',
    addressComplement: '',
    addressDistrict: '',
    addressState: '',
    addressStateCode: 0,
    addressCity: '',
    addressCityCode: 0,
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    contactPhoneAlternative: '',
};

export default function OrcamentoObra() {
    // hooks
    const { permiteEdicaoOrcamento } = useOrcamentoContext();

    const { codOrcamento } = useParams();

    // states
    const [alertDelete, setAlertDelete] = useState(null);

    // functions
    const callStore = async () => {
        const valid = await triggerValidation();
        if (valid) {
            const data = {
                ...getValues(),
                addressStateCode: parseInt(getValues().addressStateCode),
                addressCityCode: parseInt(getValues().addressCityCode),
                codOrcamento: parseInt(codOrcamento),
            };

            await webapi.post(`/orcamentos/projeto`, data);
        }
        return valid;
    };

    const handleSave = async () => {
        if (permiteEdicaoOrcamento()) await callStore();
    };

    const handleValid = async () => {
        const valid = await triggerValidation();
        return valid;
    };

    const handleClickNext = async () => {
        const urlToPush = `/orcamentos/${codOrcamento}/cores/`;
        if (permiteEdicaoOrcamento()) {
            const valid = await triggerValidation();
            if (valid) {
                const projectData = {
                    ...getValues(),
                    addressStateCode: parseInt(getValues().addressStateCode),
                    addressCityCode: parseInt(getValues().addressCityCode),
                    codOrcamento: parseInt(codOrcamento),
                };

                await webapi.post(`/orcamentos/projeto`, projectData);
                history.push(urlToPush);
            }
        } else history.push(urlToPush);
    };

    const handleClickPrevious = async () => {
        const urlToPush = `/orcamentos/${codOrcamento}/cliente`;
        if (!permiteEdicaoOrcamento()) {
            await callStore();
            history.push(urlToPush);
        } else history.push(urlToPush);
    };

    const handleClickCopyData = async () => {
        setAlertDelete(
            <SweetAlert
                show
                warning
                style={{ display: 'block' }}
                title="Você tem certeza?"
                confirmBtnBsStyle="success"
                cancelBtnText="Não"
                confirmBtnText="Sim"
                showCancel
                reverseButtons
                openAnim={true}
                onConfirm={async () => {
                    const result = await webapi.get(
                        `/orcamentos/${codOrcamento}/cliente`
                    );

                    const {
                        name,
                        contactName,
                        contactEmail,
                        contactPhone,
                        addressStreet,
                        addressPostalCode,
                        addressCity,
                        addressState,
                        addressCityCode,
                        addressStateCode,
                        addressNumber,
                        addressComplement,
                        addressDistrict,
                    } = result;
                    setValue('projectName', name);
                    setValue('contactName', contactName);
                    setValue('contactPhone', contactPhone);
                    setValue('contactEmail', contactEmail);
                    setValue('addressStreet', addressStreet);
                    setValue('addressPostalCode', addressPostalCode);
                    setValue('addressStateCode', addressStateCode);
                    setValue('addressState', addressState);
                    setValue('addressCity', addressCity);
                    setValue('addressCityCode', addressCityCode);
                    setValue('addressNumber', addressNumber);
                    setValue('addressComplement', addressComplement);
                    setValue('addressDistrict', addressDistrict);

                    setAlertDelete(null);
                }}
                onCancel={() => {
                    setAlertDelete(null);
                }}
            >
                Que deseja replicar os dados do cliente para obra? Você não
                poderá reverter essa ação!
            </SweetAlert>
        );
    };

    // hooks
    const {
        register,
        triggerValidation,
        getValues,
        setValue,
        errors,
        control,
        reset,
    } = useForm({
        mode: 'onChange',
        defaultValues,
    });

    useEffect(() => {
        const fetchData = async () => {
            const result = await webapi.get(
                `/orcamentos/${codOrcamento}/projeto`
            );
            reset(result);
        };
        if (codOrcamento) fetchData();
    }, []);

    return (
        <>
            <PageContent className="no-border">
                <OrcamentoNavegacaoSuperior />
            </PageContent>

            <PageContent>
                <NavigationSubArea
                    active="Project"
                    handleSave={() => handleSave()}
                    handleValid={() => handleValid()}
                >
                    <form>
                        <Row>
                            <Col sm="10" className={`mt-3 ${styles.nome}`}>
                                <Label
                                    style={{
                                        color: '#57607A',
                                        fontSize: '16px',
                                    }}
                                >
                                    Nome da Obra
                                </Label>
                                <Input
                                    innerRef={register}
                                    maxLength={120}
                                    type="text"
                                    name="projectName"
                                    placeholder="Nome da Obra..."
                                    className="boxInput form-control form-control-sm"
                                    invalid={!!errors?.projectName?.message}
                                    disabled={!permiteEdicaoOrcamento()}
                                    autoComplete="off"
                                />
                                <FormFeedback>
                                    {errors?.projectName?.message}
                                </FormFeedback>
                            </Col>
                            <Col sm="2" className={`mt-3 ${styles.btn}`}>
                                <Button
                                    className="boxButton ml-5"
                                    onClick={() => handleClickCopyData()}
                                    disabled={!permiteEdicaoOrcamento()}
                                    style={{
                                        backgroundColor: '#ABAFBC',
                                        height: '47px',
                                        marginTop: '1.8rem',
                                    }}
                                >
                                    Replicar Dados
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="5" className={`mt-3 ${styles.nomeC}`}>
                                <Label
                                    style={{
                                        color: '#57607A',
                                        fontSize: '16px',
                                    }}
                                >
                                    Nome de Contato
                                </Label>
                                <Input
                                    innerRef={register}
                                    maxLength={80}
                                    type="text"
                                    name="contactName"
                                    placeholder="Nome do Contato..."
                                    className="boxInput form-control-sm"
                                    disabled={!permiteEdicaoOrcamento()}
                                    autoComplete="off"
                                />
                            </Col>
                            <Col sm="3" className="mt-3">
                                <Label
                                    style={{
                                        color: '#57607A',
                                        fontSize: '16px',
                                    }}
                                >
                                    Telefone
                                </Label>
                                <Controller
                                    maxLength={15}
                                    type="text"
                                    name="contactPhone"
                                    placeholder="Telefone ..."
                                    className="boxInput form-control form-control-sm"
                                    as={PhoneInput}
                                    control={control}
                                    country="BR"
                                    disabled={!permiteEdicaoOrcamento()}
                                    autoComplete="off"
                                />
                            </Col>
                            <Col sm="4" className="mt-3">
                                <Label
                                    style={{
                                        color: '#57607A',
                                        fontSize: '16px',
                                    }}
                                >
                                    E-mail
                                </Label>
                                <Input
                                    innerRef={register}
                                    maxLength={80}
                                    type="text"
                                    name="contactEmail"
                                    placeholder="E-mail..."
                                    className="boxInput form-control form-control-sm"
                                    disabled={!permiteEdicaoOrcamento()}
                                    autoComplete="off"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="4" className={`mt-3 ${styles.Cep}`}>
                                <Label
                                    style={{
                                        color: '#57607A',
                                        fontSize: '16px',
                                    }}
                                >
                                    CEP
                                </Label>
                                <Controller
                                    control={control}
                                    maxLength={8}
                                    name="addressPostalCode"
                                    placeholder="CEP..."
                                    as={NumberFormat}
                                    className="boxInput form-control form-control-sm"
                                    decimalScale={0}
                                    allowLeadingZeros={true}
                                    isNumericString={true}
                                    autoComplete="off"
                                    disabled={!permiteEdicaoOrcamento()}
                                />

                                <FormFeedback>
                                    {errors?.addressPostalCode?.message}
                                </FormFeedback>
                            </Col>
                            <Col sm="4" className="mt-3">
                                <Label
                                    style={{
                                        color: '#57607A',
                                        fontSize: '16px',
                                    }}
                                >
                                    Endereço
                                </Label>
                                <Input
                                    innerRef={register}
                                    maxLength={120}
                                    type="text"
                                    name="addressStreet"
                                    placeholder="Endereço..."
                                    className="boxInput form-control-sm"
                                    disabled={!permiteEdicaoOrcamento()}
                                    autoComplete="off"
                                />
                            </Col>
                            <Col sm="4" className="mt-3">
                                <Label
                                    style={{
                                        color: '#57607A',
                                        fontSize: '16px',
                                    }}
                                >
                                    Numero
                                </Label>
                                <Input
                                    innerRef={register}
                                    maxLength={30}
                                    type="text"
                                    name="addressNumber"
                                    placeholder="Numero..."
                                    className="boxInput form-control-sm"
                                    disabled={!permiteEdicaoOrcamento()}
                                    autoComplete="off"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="3" className={`mt-3 ${styles.Comp}`}>
                                <Label
                                    style={{
                                        color: '#57607A',
                                        fontSize: '16px',
                                    }}
                                >
                                    Complemento
                                </Label>
                                <Input
                                    innerRef={register}
                                    maxLength={80}
                                    type="text"
                                    name="addressComplement"
                                    placeholder="Complemento..."
                                    className="boxInput form-control-sm"
                                    disabled={!permiteEdicaoOrcamento()}
                                    autoComplete="off"
                                />
                            </Col>
                            <Col sm="3" className="mt-3">
                                <Label
                                    style={{
                                        color: '#57607A',
                                        fontSize: '16px',
                                    }}
                                >
                                    Bairro
                                </Label>
                                <Input
                                    innerRef={register}
                                    maxLength={80}
                                    type="text"
                                    name="addressDistrict"
                                    placeholder="Bairro..."
                                    className="boxInput form-control-sm"
                                    disabled={!permiteEdicaoOrcamento()}
                                    autoComplete="off"
                                />
                            </Col>
                            <Col sm="3" className="mt-3">
                                <Label
                                    style={{
                                        color: '#57607A',
                                        fontSize: '16px',
                                    }}
                                >
                                    Estado
                                </Label>
                                <Input
                                    innerRef={register}
                                    name="addressState"
                                    placeholder="Numero..."
                                    className="boxInput form-control-sm"
                                    defaultValue={0}
                                    disabled={!permiteEdicaoOrcamento()}
                                    autoComplete="off"
                                    style={{
                                        boxShadow:
                                            'rgb(198 203 212) 0px 6px 15px -10px',
                                        borderRadius: '10px',
                                    }}
                                />
                                <Input
                                    type="hidden"
                                    innerRef={register}
                                    name="addressStateCode"
                                />
                            </Col>
                            <Col sm="3" className="mt-3">
                                <Label
                                    style={{
                                        color: '#57607A',
                                        fontSize: '16px',
                                    }}
                                >
                                    Cidade
                                </Label>
                                <Input
                                    innerRef={register}
                                    maxLength={80}
                                    type="text"
                                    name="addressCity"
                                    placeholder="Cidade..."
                                    className="boxInput form-control-sm"
                                    defaultValue={0}
                                    disabled={!permiteEdicaoOrcamento()}
                                    autoComplete="off"
                                    style={{
                                        boxShadow:
                                            'rgb(198 203 212) 0px 6px 15px -10px',
                                        borderRadius: '10px',
                                    }}
                                />
                                <Input
                                    type="hidden"
                                    innerRef={register}
                                    name="addressCityCode"
                                />
                            </Col>
                        </Row>
                        <div
                            className="mt-3"
                            style={{
                                fontSize: '18px',
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div className={`pull-left ${styles.btnV}`}>
                                <Button
                                    type="button"
                                    role="tablist"
                                    className="boxButtonAvancar"
                                    style={{ backgroundColor: '#ABAFBC' }}
                                    onClick={() => {
                                        handleClickPrevious();
                                    }}
                                >
                                    <i
                                        className="icon-icon-voltar mr-1"
                                        style={{ fontSize: '18px' }}
                                    />
                                    {' Voltar'}
                                </Button>
                            </div>
                            <div
                                className={`pull-right ${styles.btnA}`}
                                style={{ fontSize: '18px' }}
                            >
                                <Button
                                    type="button"
                                    className="boxButtonAvancar"
                                    role="tablist"
                                    style={{ backgroundColor: '#3073CB' }}
                                    onClick={() => handleClickNext()}
                                >
                                    Avançar{' '}
                                    <i
                                        className="icon-icon-avancar ml-1"
                                        style={{ fontSize: '18px' }}
                                    />
                                </Button>
                            </div>
                        </div>
                    </form>
                </NavigationSubArea>
                {alertDelete}
            </PageContent>
        </>
    );
}
