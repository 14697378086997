import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import controleAcessoRotasConsts from '../consts/controleAcessoRotasConsts';
import LayoutPrivateDefault from '../layout/Private/Default';
import LayoutPublicDefault from '../layout/Public/Default';

import { useAuthContext } from '../hooks/useAuthContext';

interface RouteWrapperProps {
    isPrivate: boolean;
    controleAcesso: Number;
    Pages: React.ElementType;
}

export default function RouteWrapper({

    isPrivate = false,
    controleAcesso = controleAcessoRotasConsts.revendaEIndustria,
    Pages,
    ...rest
}: RouteWrapperProps) {
    const { autenticado } = useAuthContext();

    if (isPrivate) {
        if (!autenticado) {
            return <Redirect to="/" />;
        }
    } else {
        if (autenticado) {
            return <Redirect to="/dashboard" />;
        }
    }

    const Layout = autenticado ? LayoutPrivateDefault : LayoutPublicDefault;

    return (
        <Route
            {...rest}
            render={(props) => (
                <Layout>
                    <Pages {...props} />
                </Layout>
            )}
        />
    );
}
