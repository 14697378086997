import React from 'react';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import { Chart } from 'react-google-charts';

interface IBarChart {
    title: string;
    data: ((string | number)[] | (string | {})[])[];
}

export default function BarChart({title, data}: IBarChart){
    return(
        <Col className="px-0 pl-2 pr-2 pr-sm-0">
            <Card className='m-0 w-100 h-100 boxButton'>
                <CardHeader className='text-center mt-4'><strong>{title}</strong></CardHeader>
                <CardBody>
                    <Chart
                        chartType="BarChart"
                        height={"400px"}
                        width={"99%"}
                        data={data}
                        options={{
                            legend: 'none',
                            bar: { groupWidth: '85%' },
                            backgroundColor: 'transparent',
                        }}
                    />
            </CardBody>
            <hr className="borde-1" />
        </Card>
    </Col>
    )
};
