import React, { useEffect, useState } from 'react';
import Switch from 'react-bootstrap-switch';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
    ModalBody,
    ModalFooter,
    Button,
    Row,
    Col,
    Input,
    Container,
    Label,
} from 'reactstrap';
import { useNotifyContext } from '~/hooks/useNotifyContext';
import SelectComponent from 'components/Select/index.tsx';
import FieldValidation from '~/components/FieldValidation';
import { errosMessage } from 'shared/messages/errorMessages.jsx';
import { useAuthContext } from '~/hooks/useAuthContext';
import { useOrcamentoContext } from '~/hooks/useOrcamentoContext';
import InputComponent from '../../../components/Input/index.tsx';
import TextAreaComponent from '../../../components/TextArea/index.tsx';
import webApi from '~/services/webapi';
import FileUploadComponent from '../../../components/FileUpload/index.tsx';
import './catalagoStyle.css';
import 'react-image-crop/dist/ReactCrop.css';
import ListaCor from './components/listaCor.tsx';
import ModalProduct from '../../../components/Modal/index.tsx';

const { ONLY_NUMBERS } = errosMessage;

const defaultValues = {
    codProduto: 0,
    codCatalogo: 1,
    codigo: '',
    codigoErp: '',
    descricao: '',
    peso: '',
    barra: '',
    imagem: '',
    quantidade: 0,
    medida: '',
    ativo: true,
    tipoProduto: 1,
    tipoUnidade: 1,
    cores: [],
};

export default function CatalogoForm(item) {

    const cor = {
        codCorProduto: 0,
        codItemProduto: 0,
        codigo: '',
        descricao: '',
        valor: 0,
    };

    // states
    const [upImg, setUpImg] = useState('');
    const [tipos, setTipos] = useState([]);
    const [unidades, setUnidades] = useState([]);
    const [opCores, setOpCores] = useState([]);
    const [active, setActive] = useState(false);
    const [coresLista, setCoresLista] = useState([]);
    const [corCodigo, setCorCodigo] = useState('');
    const [corDescricao, setCorDescricao] = useState('');
    const [modalObterCores, setModalOpenObterCores] = useState(false);
    const [selected, setSelected] = useState([]);
    const [isType, setIsType] = useState(false);

    const notificacao = useNotifyContext();
    const { state } = useAuthContext();
    const { setIsItemAvulso } = useOrcamentoContext();

    const { register, control, errors, setValue, getValues, watch } = useForm({
        mode: 'onChange',
        defaultValues,
        validationSchema: yup.object().shape({
            quantidade: yup.number().typeError(ONLY_NUMBERS),
        }),
    });

    async function handleModalOpenObterCores() {
        try {
            const response = await webApi.get(
                `/CorProduto/Cores/${getValues('tipoProduto')}`
            );
            setOpCores(response);
            setModalOpenObterCores(!modalObterCores);
        } catch (error) {
            notificacao.alerta('Erro ao carregar as cores!');
        }
    }

    const handleDeleteCor = (chave) => {
        const filtro = coresLista.filter((item, index) => {
            return index !== chave ? item : null;
        });
        setCoresLista(filtro);
    };

    function handleSelecionarCores(obj, novo) {
        if (obj.checked) {
            const valor = coresLista.map((item) => {
                if (
                    item.codigo == novo.codigo &&
                    item.descricao == novo.descricao
                )
                    return true;
                return false;
            });

            if (valor[0] === false || coresLista.length === 0) {
                selected.push({
                    codCorProduto: novo.codCorProduto,
                    codItemProduto: novo.codItemProduto,
                    codCorProduto: novo.codCorProduto,
                    codigo: novo.codigo,
                    descricao: novo.descricao,
                    codItemProduto: 0,
                });
            }
        } else {
            const indexOf = selected.indexOf(novo);
            selected.map((item, index) => {
                if (
                    index === indexOf &&
                    item.codCorProduto === novo.codCorProduto
                ) {
                    selected.splice(indexOf, 1);
                }
            });
        }
    }

    function handleAddCorItem() {
        const novaLista = coresLista.concat(
            selected.map((item) => ({
                codItemProduto: item.codItemProduto,
                codCorProduto: item.codCorProduto,
                codigo: item.codigo,
                descricao: item.descricao,
            }))
        );
        setSelected([]);
        setCoresLista(novaLista);
        setModalOpenObterCores(!modalObterCores);
    }

    const handleNovaCor = () => {
        if (opCores) {
            const novaLista = [
                ...opCores,
                {
                    ...cor,
                    codigo: corCodigo,
                    descricao: corDescricao,
                    codItemProduto: 0,
                },
            ];
            setOpCores(novaLista);
        } else {
            const novaLista = [
                {
                    codigo: corCodigo,
                    descricao: corDescricao,
                    codCorProduto: 0,
                    codItemProduto: 0,
                    codItemProduto: 0,
                },
            ];
            setOpCores(novaLista);
        }
        setCorCodigo('');
        setCorDescricao('');
    };

    const handleImagem = (img) => {
        setUpImg(img);
    };

    const handleOnChangeTipo = (type) => {
        setValue('tipoProduto', type);
        setIsType(true);
    };

    const handleOnChangeUn = (un) => {
        setValue('tipoUnidade', un);
    };

    const handleCloseModal = () => {
        setIsItemAvulso(true);
    };

    const handleSave = async () => {
        const pathImg = upImg.split(',', upImg.length);

        const newProduct = {
            codProduto:
                item.data > 0 ? Number(item.data) : 0,
            codCatalogo: state.catalogo,
            codigo: getValues('codigo'),
            codigoErp: getValues('codigoErp'),
            descricao: getValues('descricao'),
            imagem: pathImg[1],
            peso: getValues('peso'),
            barra: getValues('barra'),
            quantidade: Number(getValues('quantidade')),
            medida: getValues('medida'),
            ativo: active,
            tipoProduto: Number(getValues('tipoProduto')),
            tipoUnidade: Number(getValues('tipoUnidade')),
            cores: coresLista.map((c) => ({
                codCorProduto: c.codCorProduto,
                codItemProduto: c.codItemProduto,
                codRevenda: c.codRevenda,
                codigo: c.codigo,
                descricao: c.descricao,
                valor: 0,
            })),
        };
        if (item.data) {
            try {
                await webApi.put(
                    `Produto/${item.data}/atualizar`,
                    newProduct
                );
            } catch (error) {
                notificacao.respostaHttp(error);
            }
        } else {
            try {
                await webApi.post(`Produto/novo`, newProduct);
            } catch (error) {
                notificacao.respostaHttp(error);
            }
        }
        handleCloseModal();
    };

    useEffect(() => {
        (async () => {
            const response = await webApi.get('/Enums/tipo-produtos');
            const data = response.map((tipo) => ({
                label: tipo.key,
                name: tipo.value,
            }));
            setTipos(data);
        })();

        (async () => {
            const response = await webApi.get('/Enums/tipo-unidade');
            const data = response.map((un) => ({
                label: un.key,
                name: un.value,
            }));
            setUnidades(data);
        })();

        if (item.data) {
            webApi
                .get(
                    `/Produto/${item.data}/catalogo/${state.catalogo}/produto`
                )
                .then((result) => {
                    const {
                        codigo,
                        codigoErp,
                        descricao,
                        ativo,
                        tipoProduto,
                        tipoUnidade,
                        quantidade,
                        peso,
                        barra,
                        cores,
                        imagemUrl,
                    } = result;
                    setValue('codigo', codigo);
                    setValue('codigoErp', codigoErp);
                    setValue('descricao', descricao);
                    setValue('ativo', active);
                    setValue('tipoProduto', tipoProduto);
                    setValue('tipoUnidade', tipoUnidade);
                    setValue('quantidade', Number(quantidade));
                    setValue('peso', peso);
                    setValue('barra', barra);
                    setActive(ativo);
                    setCoresLista(cores);
                    setUpImg(imagemUrl);
                    setIsType(true);
                });
        }
    }, []);

    return (
        <Container lg={12} md={12} sm={11} xs={12}>
            <Row className="justify-content-between">
                <Col lg={4} md={12} sm={11} xs={12}>
                    <SelectComponent
                        label="Tipo do Produto"
                        name="tipoProduto"
                        value={getValues('tipoProduto')}
                        innerRef={register}
                        data={tipos}
                        onChange={(type) => handleOnChangeTipo(type)}
                    />
                </Col>
                <Col lg={4} md={12} sm={11} xs={12}>
                    <InputComponent
                        label="Código do Item"
                        innerRef={register}
                        placeholder="Ex. SU001"
                        isShadow
                        name="codigo"
                        id="codigo"
                        disabled={!isType}
                    />
                </Col>
                <Col lg={3} md={12} sm={11} xs={12}>
                    <InputComponent
                        label="Código de ERP"
                        innerRef={register}
                        placeholder="Ex. XYZ001"
                        isShadow
                        name="codigoErp"
                        id="codigoErp"
                        disabled={!isType}
                    />
                </Col>
                <Col lg={1} md={1} sm={1} xs={1} className="my-2">
                    <Label>Ativo</Label>
                    <Switch
                        className="inputCheck"
                        name="active"
                        offColor="warning"
                        offText={<i />}
                        onColor="success"
                        onText={<i />}
                        onChange={(e) => {
                            setActive(e.state.value);
                            setValue('ativo', active);
                        }}
                        value={active}
                        disabled={!isType}
                    />
                </Col>
            </Row>
            <Row className="justify-content-between">
                <Col lg={4} md={4} sm={12} xs={12} className="m-0">
                    <FileUploadComponent
                        label="Imagem"
                        src={upImg}
                        onSelectImage={(img) => handleImagem(img)}
                    />
                </Col>
                <Col lg={8} md={8} sm={12} xs={12} className="m-0 p-0">
                    <Col lg={12} md={12} sm={12} xs={12} className="m-0">
                        <TextAreaComponent
                            label="Descrição"
                            labelExtra="0 de 1024 caracteres"
                            placeholder="Ex. Marco superior 2 folhas"
                            name="descricao"
                            innerRef={register}
                            rows={2}
                            disabled={!isType}
                        />
                    </Col>
                    {watch().tipoProduto === '2' && (
                        <Row lg={12} md={12} sm={12} xs={12} className="mx-0">
                            <Col lg={6} md={6} sm={12} xs={12} className="m-0">
                                <InputComponent
                                    label="Qtd de itens na embalagem:"
                                    innerRef={register}
                                    isShadow
                                    name="quantidade"
                                    id="quantidade"
                                />
                                <FieldValidation error={errors?.quantidade} />
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12} className="m-0">
                                <SelectComponent
                                    label="Unidade do item"
                                    isShadow
                                    width="100%"
                                    name="tipoUnidade"
                                    value={getValues('tipoUnidade')}
                                    innerRef={register}
                                    data={unidades}
                                    onChange={(type) => handleOnChangeUn(type)}
                                />
                            </Col>
                        </Row>
                    )}
                    {watch().tipoProduto === '1' && (
                        <Row lg={12} md={12} sm={12} xs={12} className="mx-0">
                            <Col lg={6} md={6} sm={12} xs={12} className="m-0">
                                <InputComponent
                                    label="Peso"
                                    innerRef={register}
                                    isShadow
                                    name="peso"
                                    id="peso"
                                />
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12} className="m-0">
                                <InputComponent
                                    label="Tamanho Barra (mm)"
                                    innerRef={register}
                                    isShadow
                                    name="barra"
                                    id="barra"
                                />
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
            <Label>Cor do Item</Label>
            <Row
                style={{ border: '1px solid #dddddd', borderRadius: '10px' }}
                className="mx-1 mt-n2"
            >
                <Col lg={9}>
                    {coresLista.map((item, index) => (
                        <ListaCor
                            key={index}
                            codigo={item.codigo}
                            descricao={item.descricao}
                            onDelete={() => handleDeleteCor(index)}
                        />
                    ))}
                </Col>
                <Col lg={3}>
                    <Button
                        className="btn-cor boxButton form-control"
                        onClick={handleModalOpenObterCores}
                        disabled={!isType}
                    >
                        Adicionar Cor
                    </Button>
                </Col>
            </Row>
            <ModalFooter className="mt-3" style={{ border: 'none' }}>
                <div className="left-side">
                    <button
                        className="boxButton btn btn-link"
                        role="tablist"
                        onClick={handleCloseModal}
                    >
                        Fechar
                    </button>
                </div>
                <>
                    <div className="divider" />
                    <div className="right-side">
                        <button
                            className="boxButton btn btn-success btn-link"
                            role="tablist"
                            onClick={handleSave}
                            disabled={!isType}
                        >
                            Salvar
                        </button>
                    </div>
                </>
            </ModalFooter>
            <ModalProduct
                size="lg"
                title="Lista de Cores"
                open={modalObterCores}
                onClose={() => handleModalOpenObterCores(false)}
            >
                <ModalBody>
                    <Col lg={12} sm={12} xs={12}>
                        <form name="formCor">
                            <table className="table my-3 table-responsive">
                                <thead>
                                    <tr>
                                        <th>Código</th>
                                        <th>Descrição</th>
                                        <th>Selecione</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {opCores.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.codigo}</td>
                                            <td>{item.descricao}</td>
                                            <td>
                                                <Input
                                                    type="checkbox"
                                                    value={item.codCorProduto}
                                                    name={`${item.codCorProduto}.${item.codigo}`}
                                                    aria-checked
                                                    onChange={(e) =>
                                                        handleSelecionarCores(
                                                            e.target,
                                                            item
                                                        )
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </form>
                    </Col>
                    <Label>Adicionar nova cor na lista</Label>
                    <Row
                        style={{
                            border: '1px solid #dee2e6',
                            borderRadius: 10,
                        }}
                        className="m-1"
                    >
                        <Col>
                            <InputComponent
                                label="Código"
                                name="codCor"
                                isShadow
                                value={corCodigo}
                                onchange={(e) => setCorCodigo(e.target.value)}
                                placeholder="Código...."
                            />
                        </Col>
                        <Col>
                            <InputComponent
                                label="Descrição"
                                name="corDescricao"
                                isShadow
                                value={corDescricao}
                                onchange={(e) =>
                                    setCorDescricao(e.target.value)
                                }
                                placeholder="Descrição..."
                            />
                        </Col>
                        <Col style={{ alignSelf: 'center' }}>
                            <button
                                type="button"
                                onClick={handleNovaCor}
                                className="boxButton btn-success btn-link w-100"
                            >
                                Adicionar Cor
                            </button>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter className="justify-content-between">
                    <button
                        type="button"
                        onClick={() => handleModalOpenObterCores(false)}
                        className="btn btn-secondary boxButton col-4"
                    >
                        Voltar
                    </button>
                    <button
                        type="submit"
                        onClick={handleAddCorItem}
                        className="btn primaryEgShp boxButton col-4"
                    >
                        Salvar no Item
                    </button>
                </ModalFooter>
            </ModalProduct>
        </Container>
    );
}
