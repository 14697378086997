import React, { useEffect, useState } from 'react';
import Switch from 'react-bootstrap-switch';
import { Controller, useForm } from 'react-hook-form';
import {
    Button,
    Col,
    FormFeedback,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    Row,
} from 'reactstrap';
import * as Yup from 'yup';
import { useNotifyContext } from '~/hooks/useNotifyContext';
import webApi from '~/services/webapi';
import {errosMessage} from '../../../../../shared/messages/errorMessages';

const { ALTERAR_EMAIL } = errosMessage;

const schema = Yup.object({
    name: Yup.string().required('Campo obrigatório'),
    email: Yup.string()
        .required('Campo obrigatório')
        .email('Formato de e-mail inválido'),
});

const defaultValues = {
    name: '',
    email: '',
    cellPhone: '',
    ativo: false,
    contatoPadrao: false,
    linkCodigoRevenda: '',
    LinkRevenda: false,
    codUsuarioIndustriaRevenda: 0,
    codIndustria: 0,
    codUsuario: 0,
    codRevenda: 0,
};

export default function UserFormIndustry({
    isOpen,
    industryId,
    selectedUser,
    handleCloseModal,
    handleTableReload,
}) {
    const notificacao = useNotifyContext();
    const [loading, setLoading] = useState(false);
    const [userActive, setUserActive] = useState(false);
    const [defaultContact, setDefaultContact] = useState(false);
    const [ alterarEmail, setAlterarEmail ] = useState(false);
    const [ infoAlterarEmail, setInfoAlterarEmail] = useState(false);
    const {
        register,
        triggerValidation,
        getValues,
        setValue,
        errors,
        control,
        reset,
    } = useForm({
        mode: 'onChange',
        defaultValues,
        validationSchema: schema,
    });



    useEffect(() => {
        if (selectedUser) {
            (async () => {
                const userResult = await webApi.get(
                    `industrias/${industryId}/usuarios/${selectedUser}`
                );

                const {
                    name,
                    email,
                    cellPhone,
                    active,
                    defaultContact,
                    linkCodigoRevenda,
                    LinkRevenda,
                    codUsuarioIndustriaRevenda,
                    codIndustria,
                    codUsuario,
                    codRevenda,
                } = userResult;

                setValue('name', name);
                setValue('email', email);
                setValue('cellPhone', cellPhone);
                setValue('ativo', active);
                setValue('contatoPadrao', defaultContact);
                setValue('linkCodigoRevenda', linkCodigoRevenda);
                setValue('LinkRevenda', LinkRevenda);
                setValue(
                    'codUsuarioIndustriaRevenda',
                    codUsuarioIndustriaRevenda
                );
                setValue('codIndustria', codIndustria);
                setValue('codUsuario', codUsuario);
                setValue('codRevenda', codRevenda);

                setUserActive(active);
                setDefaultContact(defaultContact);
            })();
        }
    }, [isOpen]);

    const handleUserForm = async () => {
        const valid = await triggerValidation();
        if (valid) {
            const userData = {
                name: getValues('name'),
                email: getValues('email'),
                isAdmin: false,
                cellPhone: getValues('cellPhone'),
                active: userActive,
                defaultContact,
                TrocarEmail: alterarEmail,
            };

            try {
                if (selectedUser) {
                    await webApi.put(
                        `/Industrias/${industryId}/usuarios/${selectedUser}`,
                        userData
                    );
                } else {
                    await webApi.post(
                        `/Industrias/${industryId}/usuario`,
                        userData
                    );
                }
                handleCloseModal();
                handleTableReload();
            } catch (error) {
                notificacao.respostaHttp(error);
                const message = error.response.data.errors[0];
                if(message == ALTERAR_EMAIL) {
                    setInfoAlterarEmail(true);
                }
            }
        }
    };

    useEffect(() => {
        if(alterarEmail === true) handleUserForm();
    },[alterarEmail]);

    return (
        <Modal isOpen={isOpen} toggle={() => { setInfoAlterarEmail(false);handleCloseModal()}}>
            <div className="modal-header">
                <button
                    type="button"
                    className="close"
                    onClick={() => { setInfoAlterarEmail(false);handleCloseModal()}}
                >
                    <i className="mc-icon nc0simple-remove" />
                </button>
                <h4 className="title title-up">Usuário</h4>
            </div>

            <ModalBody>
                <form>
                    <Row>
                        <Col md="12" className="mt-3">
                            <Label>Nome:</Label>
                            <Input
                                type="text"
                                innerRef={register}
                                control={control}
                                className="form-control form-control-sm"
                                name="name"
                                placeholder=""
                                autoComplete="off"
                                invalid={errors?.name?.message}
                            />
                            <FormFeedback>{errors?.name?.message}</FormFeedback>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6" className="mt-3">
                            <Label>E-mail:</Label>
                            <Input
                                type="text"
                                innerRef={register}
                                control={control}
                                className="form-control form-control-sm"
                                name="email"
                                placeholder=""
                                autoComplete="off"
                                invalid={errors?.email?.message}
                            />
                            <FormFeedback>
                                {errors?.email?.message}
                            </FormFeedback>
                        </Col>
                        <Col md="6" className="mt-3">
                            <Label>Telefone:</Label>
                            <Input
                                type="text"
                                innerRef={register}
                                control={control}
                                className="form-control form-control-sm"
                                name="cellPhone"
                                placeholder=""
                                autoComplete="off"
                                invalid={errors?.celular?.message}
                            />
                            <FormFeedback>
                                {errors?.celular?.message}
                            </FormFeedback>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="6" className="mt-3">
                            <Label className="mr-2">
                                Contato Padrão
                                <Switch
                                    innerRef={register}
                                    className="form-control form-control-sm"
                                    value={defaultContact}
                                    control={control}
                                    name="contatoPadrao"
                                    offColor="danger"
                                    offText={
                                        <i className="nc-icon nc-simple-remove" />
                                    }
                                    onColor="success"
                                    onText={
                                        <i className="nc-icon nc-check-2" />
                                    }
                                    onChange={(e) => {
                                        setDefaultContact(e.state.value);
                                    }}
                                />
                            </Label>
                        </Col>
                        <Col md="6" className="mt-3">
                            <Label className="mr-2">
                                {'Status do Cliente '}
                                <Switch
                                    innerRef={register}
                                    className="form-control form-control-sm"
                                    value={userActive}
                                    name="ativo"
                                    offColor="warning"
                                    offText={
                                        <i className="nc-icon nc-simple-remove" />
                                    }
                                    onColor="success"
                                    onText={
                                        <i className="nc-icon nc-check-2" />
                                    }
                                    onChange={(e) => {
                                        setUserActive(e.state.value);
                                    }}
                                />
                            </Label>
                        </Col>
                    </Row>
                    {infoAlterarEmail &&
                        <div className='m-2 p-3' style={{background:"#FF7913", borderRadius:"10px", textAlign:"center"}}>
                            <p>Essa conta já está ativa, ao trocar o e-mail o login atual do usuário será descontinuado e um cadastro novo deverá ser feito. Deseja continuar?</p>
                            <Button onClick={() => {setAlterarEmail(true); setInfoAlterarEmail(false)}}>Sim</Button>
                        </div>
                    }
                </form>
            </ModalBody>

            <ModalFooter>
                <div className="left-side">
                    <Button
                        type="button"
                        className="btn btn-link"
                        onClick={() => {
                            setInfoAlterarEmail(false);
                            handleCloseModal()
                        }}
                    >
                        Cancelar
                    </Button>
                </div>
                <div className="divider" />

                <div className="right-side">
                    <Button
                        className="boxButton btn btn-success btn-link"
                        onClick={() => handleUserForm()}
                        disabled={loading}
                    >
                        {!!loading && (
                            <i
                                className="fa fa-refresh fa-spin fa-1x"
                                style={{ marginRight: '5px'}}
                            />
                        )}
                        Salvar
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
}
