import React, { useState, useEffect } from 'react';
import PageContent from '~/components/PageContent';
import { useOrcamentoContext } from 'hooks/useOrcamentoContext';
import { useParams } from 'react-router-dom';
import { Input, Button, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import { getSingleErrorMessage } from '~/utils';
import history from '~/services/history';
import {
    putPropostaRejeitada,
    putPedidoFechar,
} from '../../../../../services/modules/Orcamento/orcamento';
import {
    getValores,
    getObservacao,
} from '../../../../../services/modules/Orcamento/orcamentoTecnico';

export default function MyRequest() {
    const { codOrcamento, versao } = useParams();
    const [alertSend, setAlertSend] = useState(null);
    const [alertMessage, setAlertMessage] = useState(null);
    const { orcamentoStateContext } = useOrcamentoContext();
    const [valorTotal, setValorTotal] = useState('');
    const [observacaoVendedor, setObservacaoVendedor] = useState('');

    const handleShowMessage = (type, title, message, handleClickConfirm) => {
        setAlertMessage(
            <SweetAlert
                show
                type={type}
                title={title}
                style={{ display: 'block' }}
                confirmBtnBsStyle="success"
                onConfirm={() => {
                    if (handleClickConfirm) handleClickConfirm();
                    setAlertMessage(null);
                }}
            >
                {message}
            </SweetAlert>
        );
    };

    const handlePedidoFinalizado = () => {
        setAlertSend(
            <SweetAlert
                show
                info
                style={{ display: 'block' }}
                title="Deseja confirma o pedido?"
                confirmBtnBsStyle="success"
                cancelBtnText="Não"
                confirmBtnText="Sim"
                showCancel
                closeOnClickOutside={false}
                reverseButtons
                onConfirm={async () => {
                    try {
                        await putPedidoFechar(
                            orcamentoStateContext.codOrcamento,
                            observacaoVendedor
                        );
                        setAlertSend(null);
                        handleShowMessage(
                            'success',
                            'Sucesso',
                            'Pedido confirmado com sucesso!',
                            () => {
                                history.push(`/orcamentoVendedor`);
                            }
                        );
                    } catch (error) {
                        setAlertSend(null);
                        handleShowMessage(
                            'warning',
                            'Não foi possível realizar esta ação',
                            getSingleErrorMessage(error)
                        );
                    }
                }}
                onCancel={() => {
                    setAlertSend(null);
                }}
            />
        );
    };

    const handleRejeitarSolicitacao = () => {
        setAlertSend(
            <SweetAlert
                show
                danger
                style={{ display: 'block' }}
                title="Deseja rejeitar este orçamento?"
                confirmBtnBsStyle="success"
                cancelBtnText="Não"
                confirmBtnText="Sim"
                showCancel
                closeOnClickOutside={false}
                reverseButtons
                onConfirm={async () => {
                    try {
                        await putPropostaRejeitada(codOrcamento);
                        setAlertSend(null);
                        handleShowMessage(
                            'success',
                            'Sucesso',
                            'Orçamento rejeitado.',
                            () => {
                                history.push(`/orcamentoVendedor`);
                            }
                        );
                    } catch (error) {
                        setAlertSend(null);
                        handleShowMessage(
                            'warning',
                            'Não foi possível realizar esta ação',
                            getSingleErrorMessage(error)
                        );
                    }
                }}
                onCancel={() => {
                    setAlertSend(null);
                }}
            >
                Tem certeza que deseja rejeitar esta solicitação? Você não
                poderá reverter essa ação!
            </SweetAlert>
        );
    };

    useEffect(() => {
        (async () => {
            const result = await getValores(
                orcamentoStateContext.codOrcamentoTecnico,
                versao
            );
            setValorTotal(result.totalCustoVendedor);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const result = await getObservacao(
                orcamentoStateContext.codOrcamentoTecnico,
                versao
            );
            setObservacaoVendedor(result.orcamentoObservacaoPedido);
        })();
    }, []);

    return (
        <div>
            <PageContent titlePage="Orçamento detalhado">
                <span className="titulo-pagina">Resumo do Orçamento</span>
                <hr />
                <div className="row">
                    <div className="col-2 p-4">
                        <h6>Anexar Relatórios</h6>
                        <div className="mt-4">
                            <Input
                                className="form-check-input position-static m-0"
                                type="checkbox"
                                value="perfis"
                                aria-label="..."
                                disabled
                            />{' '}
                            Perfis
                        </div>
                        <div className="mt-1">
                            <Input
                                className="form-check-input position-static m-0"
                                type="checkbox"
                                value="componentes"
                                aria-label="..."
                                disabled
                            />{' '}
                            Componentes
                        </div>
                        <div className="mt-1">
                            <Input
                                className="form-check-input position-static m-0"
                                type="checkbox"
                                value="vidros"
                                aria-label="..."
                                disabled
                            />{' '}
                            Vidros
                        </div>
                        <div className="mt-1">
                            <Input
                                className="form-check-input position-static m-0"
                                type="checkbox"
                                value="vidros"
                                aria-label="..."
                                disabled
                            />{' '}
                            Superfícies
                        </div>
                    </div>

                    <div className="row" disabled>
                        <div className="col-sm-12 col-md-12 col-lg-12 ml-5 mt-4 mb-5">
                            <p style={{ color: '#57607A' }}>
                                Observação do pedido - Cliente
                            </p>
                            <textarea
                                type="text"
                                className="form-control"
                                rows="5"
                                style={{
                                    width: '644px',
                                }}
                                disabled
                                value={observacaoVendedor}
                                onChange={(e) =>
                                    setObservacaoVendedor(e.target.value)
                                }
                            />
                        </div>
                    </div>

                    <div className="row mb-5" disabled>
                        <div className="col-sm-12 col-md-12 col-lg-12 ml-5 mt-4">
                            <p
                                style={{
                                    color: '#4EAA79',
                                    fontWeight: '700',
                                }}
                            >
                                Custo Total
                            </p>
                            <p
                                style={{
                                    color: '#57607A',
                                    fontSize: '48px',
                                    fontWeight: '700',
                                    marginTop: '-30px',
                                }}
                            >
                                {valorTotal?.toLocaleString(
                                    'pt-br',
                                    {
                                        style: 'currency',
                                        currency: 'BRL',
                                    }
                                )}
                            </p>
                        </div>
                    </div>
                </div>
            </PageContent>
            <Col>
                <Col md="12">
                    <Button
                        className="btn btn-red mb-4"
                        onClick={() => handleRejeitarSolicitacao()}
                        disabled
                    >
                        <i className="far fa-trash-alt" /> Rejeitar solicitação
                    </Button>
                    <Button
                        className="btn btn-success mb-4 pull-right mr-4"
                        onClick={() => handlePedidoFinalizado()}
                        disabled={
                            !(
                                orcamentoStateContext.situacaoOrcamentoIndustria ===
                                12
                            )
                        }
                    >
                        <i className="far fa-share-square" /> Confirmar Pedido
                    </Button>
                </Col>
            </Col>
            {alertSend}
            {alertMessage}
        </div>
    );
}
