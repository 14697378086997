/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

const TableHeader = ({ headerGroups }) => {
    return (
        <thead style={{ width: 'auto' }}>
            {headerGroups.map((headerGroup) => (
                <tr
                    {...headerGroup.getHeaderGroupProps()}
                    className="d-none d-xs-none d-sm-table-row"
                >
                    {headerGroup.headers.map((column) => (
                        <th
                            rowSpan="1"
                            colSpan="1"
                            {...column.getHeaderProps(
                                column.getSortByToggleProps()
                            )}
                            style={column.style}
                            className={`th ${column.display}`}
                        >
                            {column.render('Header')}
                            <span>
                                {!column.disableSortBy ? (
                                    column.isSorted ? (
                                        column.isSortedDesc ? (
                                            <i className="icon-icon-down" />
                                        ) : (
                                            <i className="icon-icon-up" />
                                        )
                                    ) : (
                                        <i className="icon-icon-orderby" />
                                    )
                                ) : null}
                            </span>
                        </th>
                    ))}
                </tr>
            ))}
        </thead>
    );
};

export default TableHeader;
