import React, { useEffect, useState } from 'react';
import Switch from 'react-bootstrap-switch';
import { Col, Label, Row } from 'reactstrap';
import {
    getObterConfigs,
    ocultarOrcamentos,
} from 'services/modules/Orcamento/orcamentoOcultar';
import PageContent from '~/components/PageContent';

export default function ConfigurarOrcamento() {
    const [orcamentosConfiguracao, setOrcamentosConfiguracao] = useState([]);

    const handleChange = (ocultar, orcamentoConfiguracao) => {
        (async () => {
            await ocultarOrcamentos(
                orcamentoConfiguracao.codOrcamentoConfiguracao,
                ocultar
            );
            const orcamentosConfiguracoes = await getObterConfigs();
            setOrcamentosConfiguracao(orcamentosConfiguracoes);
        })();
    };

    useEffect(() => {
        (async () => {
            const orcamentosConfiguracoes = await getObterConfigs();
            setOrcamentosConfiguracao(orcamentosConfiguracoes);
        })();
    }, []);

    return (
        <>
            <Row className="ml-3">
                <h3>
                    Configurações de{' '}
                    <strong style={{ color: '#6b2c5f' }}>Orçamento</strong>
                </h3>
            </Row>
            <div className="m-0 col-12">
                <PageContent className="m-0 container">
                    {orcamentosConfiguracao.map((item, index) => (
                        <Col
                            md="12"
                            className="mt-3"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Label style={{ display: 'flex' }}>
                                <h6 className="mr-2">
                                    {item.nomeOrcamentoConfiguracao}
                                </h6>
                                <Switch
                                    name="esquadrias"
                                    offColor="warning"
                                    offText={
                                        <i className="nc-icon nc-simple-remove" />
                                    }
                                    onColor="success"
                                    onText={
                                        <i className="nc-icon nc-check-2" />
                                    }
                                    onChange={(e) => {
                                        handleChange(e.state.value, item);
                                    }}
                                    value={item.oculto}
                                />
                            </Label>
                        </Col>
                    ))}
                </PageContent>
            </div>
        </>
    );
}
