import { useState } from "react";
import readXlsxFile from 'read-excel-file'

export function useFileUploadXLSX() {
    const [file, setFile] = useState<File | undefined>();
    const [error, setError] = useState('');

    function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
        const currentFile = e.target.files?.[0];

        if (currentFile) {
            setFile(currentFile);
            if(error.length) {
                setError('')
            }
        } else {
            setFile(undefined);
        }
      }

      function handleReadFile() {
        if(!file) {
            setError('Por favor, selecione um arquivo')
            return [];
        };

        return readXlsxFile(file).then(rows => rows)
      }

      function clearFile() {
        setFile(undefined);
      }

    return {
        error,
        clearFile,
        handleReadFile,
        handleFileChange,
        selectedFile: file
    }
}
