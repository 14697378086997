/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';

const TableBodyRow = ({ row }) => {
    return (
        <tr {...row.getRowProps()}>
            {row.cells.map((cell) => {
                return (
                    <td
                        {...cell.getCellProps()}
                        className={`${cell.column.display}`}
                    >
                        {cell.render('Cell')}
                    </td>
                );
            })}
        </tr>
    );
};

const TableBodyLoading = ({ loading, rows }) => {
    const style = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };

    return (
        <tr>
            {loading && (
                <td colSpan="10000" className="text-center">
                    <div style={style}>
                        <i
                            className="fa fa-refresh fa-spin fa-2x  mt-3 mb-3"
                            style={{ marginRight: '10px' }}
                        />{' '}
                        Carregando...
                    </div>
                </td>
            )}

            {!loading && rows.length === 0 && (
                <td colSpan="10000" className="text-center">
                    <div className="mt-3 mb-3">Nenhum registro encontrado</div>
                </td>
            )}
        </tr>
    );
};

const TableBody = ({
    getTableBodyProps,
    includePagination,
    page,
    rows,
    prepareRow,
    loading,
}) => {
    return (
        <tbody {...getTableBodyProps()} style={{ width: 'auto' }}>
            {includePagination
                ? page.map((row) => {
                      prepareRow(row);
                      return <TableBodyRow row={row} key={row.index} />;
                  })
                : rows.map((row) => {
                      prepareRow(row);
                      return <TableBodyRow row={row} key={row.index} />;
                  })}
            <TableBodyLoading loading={loading} rows={rows} />
        </tbody>
    );
};

export default TableBody;
