import CabecalhoEgShop from "components/Cabecalho/CabecalhoEgShop";
import InputComponent from "components/Input";
import { useNotifyContext } from "hooks/useNotifyContext";
import SearchesGrid from "pages/Catalogo/Lista/components/searchesGrid";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Col, Label, Row } from "reactstrap";
import PageContent from "~/components/PageContent";
import webApi from "~/services/webapi";
import Modal from "../../components/Modal";
import ListaCores from "./listaCores";
import Select from "react-select";
import { itemOptions } from "./docs/data";
import GridData from "components/GridData";
import { getCoresById } from "services/modules/CorAvulsa";

const defaultValues = {
	cor: "",
	descricao: "",
	codigo: "",
	tipoProduto: "",
};

export default function CoresAvulso(item) {
	const notificacao = useNotifyContext();

	const [filtro, setFiltro] = useState("");
	const [newCorModal, setNewCorModal] = useState(false);
	const [tipos, setTipos] = useState([]);
	const [dataItem, setDataItem] = useState("");
	const [filtroSegmento, setFiltroSegmento] = useState(null);
	const [cores, setCores] = useState([]);

	function handleModal() {
		setNewCorModal((prevState) => !prevState);
	}

	const handleClickEdit = (id) => {
		setDataItem(id);
		setNewCorModal(true);
		getCoresAvulsa(id);
	};

	const { register, setValue, getValues, reset } = useForm({
		mode: "onChange",
		defaultValues,
	});

	function filtrarExtra(parametroFiltro) {
		if (parametroFiltro === "0") {
			setFiltroSegmento("");
		} else {
			setFiltroSegmento(parametroFiltro);
		}
	}

	const getProdutos = async () => {
		const response = await webApi.get("/api/Cor");
		const value = response.map((item) => ({
			id: item.id,
			cor: item.cor,
			descricao: item.descricao,
			tipoProduto: item.tipoProduto,
		}));
		setCores(value);
	};

	const handleCloseModal = () => {
		setDataItem("");
		setNewCorModal(false);
		getProdutos();
	};

	async function getTipos() {
		const response = await webApi.get("/Enums/tipo-produtos");
		const data = response.map((tipo) => ({
			label: tipo.key,
			name: tipo.value,
		}));
		setTipos(data);
	}

	const handleSave = async () => {
		const newCor = {
			id: item.data > 0 ? Number(item.data) : 0,
			cor: getValues("cor"),
			descricao: getValues("descricao"),
			tipoProduto: getValues("tipoProduto"),
		};
		if (item.data) {
			try {
				await webApi.put(`/api/Cor/${item.data}`, newCor);
				notificacao.sucesso("Item atualizado com sucesso");
			} catch (error) {
				notificacao.erro(error);
			}
		} else {
			try {
				await webApi.post("/api/Cor", newCor);
				notificacao.sucesso("Item adicionado com sucesso");
			} catch (error) {
				notificacao.erro(error);
			}
		}
		handleCloseModal();
	};

	async function handleDelete(id) {
		try {
			await webApi.delete(`/api/Cor/${id}`);
			notificacao.sucesso("Item excluido com sucesso");
			getProdutos();
		} catch (error) {
			notificacao.erro(error);
		}
	}

	async function getCoresAvulsa(id) {
		try {
			const result = await getCoresById(id);
			const { cor, descricao, tipoProduto } = result;
			setValue("cor", cor);
			setValue("descricao", descricao);
			setValue("tipoProduto", tipoProduto);
		} catch (error) {
			notificacao.erro(error);
		}
	}

	useEffect(() => {
		getTipos();
		getProdutos();
	}, [filtroSegmento, filtro]);

	useEffect(() => {
		item.id && getCoresAvulsa(item.id);
	}, [item.id]);

	return (
		<>
			<CabecalhoEgShop
				title="Cadastro de Cores"
				onRegisterNew={() => handleModal()}
				iconBtn={<i className="fas fa-paint-brush" />}
				descricaoBtn="Cadastrar Nova Cor"
			/>
			<div className="m-0 col-12">
				<PageContent className="m-0 mr-2 container">
					<SearchesGrid
						isSelect
						label="Filtrar"
						valueLabel="flex"
						data={tipos}
						valueSelect={filtroSegmento}
						onChangeSelect={filtrarExtra}
						isSearch
						value={filtro}
						onChange={(e) => setFiltro(e.target.value)}
						iconeColor="#6b2c5f"
					/>
					{cores.length === 0 ? (
						<div className="cardList my-3">
							<strong>Nenhum Registro</strong>
						</div>
					) : (
						<>
							<Row className="recordTotal">
								{" "}
								<p className="m-0">Total de Registro: {cores.length}</p>
							</Row>
							{cores.map((item, index) => (
								<div key={Number(index)}>
									<GridData
										dados={item}
										onEdit={handleClickEdit}
										onTrash={handleDelete}
										registro={cores.length}
										numBtn={2}
									>
										<ListaCores dados={item} tipo={tipos} />
									</GridData>
								</div>
							))}
						</>
					)}
				</PageContent>
			</div>
			<Modal
				open={newCorModal}
				size="md"
				title={dataItem === "" ? "Cadastrar Nova Cor" : "Editar Cor"}
				onClose={() => {
					handleModal();
				}}
				onSave={() => {
					handleSave();
				}}
				isButton
			>
				<Col lg={12} md={12} sm={11} xs={12}>
					<InputComponent
						label="Cor"
						innerRef={register}
						placeholder="Ex. Laranja"
						isShadow
						name="cor"
					/>
				</Col>
				<Col lg={12} md={12} sm={11} xs={12}>
					<InputComponent
						label="Descrição"
						innerRef={register}
						placeholder="Ex. Laranja0029"
						isShadow
						name="descricao"
					/>
				</Col>
				<Col lg={12} md={12} sm={11} xs={12} className="mb-4">
					<Label>Tipo do Produto</Label>
					<Select
						isMulti
						name="tipoProduto"
						options={itemOptions}
						className="basic-multi-select"
						classNamePrefix="select"
						valueSelect={getValues("tipoProduto")}
					/>
				</Col>
			</Modal>
		</>
	);
}
