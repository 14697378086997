import React, { useState, useMemo, useCallback } from 'react';

import { Button, Col, Row, Badge, Collapse } from 'reactstrap';

import SweetAlert from 'react-bootstrap-sweetalert';

import { DataTable } from '~/components/DataTable';

import PageContent from '~/components/PageContent';

import TableColumnCheckBox from '~/components/DataTable/components/TableColumnCheckBox';
import history from '~/services/history';

import webApi from '~/services/webapi';

export default function Notification() {
    const [selectedRows, setSelectedRows] = useState([]);

    const [reload, setReload] = useState(0);

    const [loading, setLoading] = useState(true);

    const [data, setData] = useState([]);

    const [pageCount, setPageCount] = useState(0);

    const [totalRecords, setTotalRecords] = useState(0);

    const [confirmDelete, setConfirmDelete] = useState(null);

    const [confirmUpdate, setConfirmUpdate] = useState(null);

    const getNotifications = useCallback(() => {
        setLoading(true);

        (async () => {
            const result = await webApi.post(
                'notificacao/obter-notificacoes',
                {}
            );
            const { records, page, total } = result;
            setData(records);
            setTotalRecords(total);
            setPageCount(page);
            setLoading(false);
        })();
    }, []);

    function RowCollapse({ row }) {
        const dispatch = useState();
        const setBudgetSelected = useState();
        const [isOpen, setIsOpen] = useState(false);
        const [isRead, setIsRead] = useState(row.isRead);
        async function handleSelect(id) {
            await webApi.post(`/notificacao/${id}/lida`);
            setIsOpen(!isOpen);
            setIsRead(true);
        }
        function handleClick({
            budgetRequestUUID,
            technicalBudgetUUID,
            industryBudgetRequestState,
            technicalBudgetVersion,
            technicalBudgetCode,
        }) {
            dispatch(
                setBudgetSelected({
                    uuid: budgetRequestUUID,
                    technicalBudgetUUID,
                    budgetRequestState: industryBudgetRequestState,
                    technicalBudgetVersion,
                    code: technicalBudgetCode,
                })
            );

            if (industryBudgetRequestState === 4) {
                history.push(`/orcamentos/${budgetRequestUUID}/client`);
            } else {
                history.push(
                    `/technical-budgets/${technicalBudgetUUID}/version/${technicalBudgetVersion}/values`
                );
            }
        }
        function handleClickUpdate(row) {
            const showMessage = () => {
                setConfirmUpdate(
                    <SweetAlert
                        show
                        info
                        style={{ display: 'block' }}
                        title="Aviso"
                        confirmBtnText="Ok"
                        reverseButtons
                        closeOnClickOutside={false}
                        onConfirm={async () => {
                            setConfirmUpdate(null);
                            setReload(!reload);
                        }}
                    >
                        Caso necessite de uma nova revisão no futuro, terá de
                        solicitar um novo envio para sua revenda.
                    </SweetAlert>
                );
            };
            setConfirmUpdate(
                <SweetAlert
                    show
                    warning
                    style={{ display: 'block' }}
                    title="Você tem certeza?"
                    closeOnClickOutside={false}
                    allowEscape={false}
                    onConfirm={null}
                    reverseButtons
                    customButtons={
                        <div>
                            <Button
                                size="lg"
                                color="success"
                                block
                                onClick={async () => {
                                    setConfirmUpdate(null);
                                    await webApi.post(
                                        `technical-budgets/${row.budgetRequestUUID}/update-revision`
                                    );
                                    setReload(!reload);
                                    setConfirmUpdate(
                                        <SweetAlert
                                            show
                                            success
                                            style={{ display: 'block' }}
                                            title="Aviso"
                                            confirmBtnText="Ok"
                                            reverseButtons
                                            closeOnClickOutside={false}
                                            onConfirm={async () => {
                                                setConfirmUpdate(null);
                                            }}
                                        >
                                            Orçamento Atualizado com sucesso
                                        </SweetAlert>
                                    );
                                }}
                            >
                                Atualizar Orçamento
                            </Button>

                            <Button
                                size="lg"
                                color="danger"
                                block
                                className="boxButton"
                                onClick={async () => {
                                    showMessage();
                                    await webApi.post(
                                        `technical-budgets/${row.budgetRequestUUID}/reject-revision`
                                    );
                                    setReload(!reload);
                                    showMessage(`Caso necessite de uma nova revisão no futuro, terá de
                                     solicitar um novo envio para sua revenda.`);
                                }}
                            >
                                Não Atualizar
                            </Button>

                            <Button
                                size="lg"
                                color="secondary"
                                block
                                className="boxButton"
                                onClick={async () => {
                                    setConfirmUpdate(null);
                                    showMessage(
                                        `Essa atualização de orçamento ficará pendente para que possa realizar ela mais tarde.`
                                    );
                                }}
                            >
                                Atualizar mais tarde
                            </Button>
                        </div>
                    }
                >
                    Tem certeza que deseja substituir o orçamento atual pelo
                    orçamento revisado pela revenda?{' '}
                    <p className="mt-2">
                        Ao realizar essa ação, qualquer edição realizada no
                        orçamento será perdida e não poderá ser desfeita.
                    </p>
                    <p className="mt-2">
                        Em caso de dúvidas entre em contato com sua revenda.
                    </p>
                </SweetAlert>
            );
        }

        return (
            <>
                <span className={`pr-4 ${!isRead ? ' font-weight-bold' : ''}`}>
                    {row.createAt}
                </span>
                <a
                    className={`collapsed  ${
                        !isRead ? ' font-weight-bold' : ''
                    }`}
                    onClick={() => handleSelect(row.id)}
                >
                    {row.title}
                    <i className="nc-icon nc-minimal-down ml-2" />
                </a>

                <Collapse isOpen={isOpen}>
                    <div className="mt-2">
                        <div className="pt-2 pb-2"> {row.content}</div>
                        <br />
                        {row.notificationType === 0 && (
                            <button
                                type="button"
                                className="boxButton btn btn-primary btn-sm"
                                onClick={() => handleClick(row)}
                            >
                                Acessar Orçamento{' '}
                                <i className="fas fa-external-link-alt" />
                            </button>
                        )}
                        {row.notificationType === 1 && (
                            <Button
                                disabled={!row.hasNewTechnicalBudgetVersion}
                                type="button"
                                className="boxButton btn btn-primary btn-sm"
                                onClick={() => handleClickUpdate(row)}
                            >
                                Atualizar Orçamento{' '}
                                <i className="fa fa-retweet " />
                            </Button>
                        )}
                    </div>
                </Collapse>
            </>
        );
    }

    const handleClickUpdate = () => {
        setConfirmUpdate(
            <SweetAlert
                show
                warning
                style={{ display: 'block' }}
                title="Você tem certeza?"
                confirmBtnBsStyle="danger"
                cancelBtnText="Não"
                confirmBtnText="Sim"
                closeOnClickOutside={false}
                showCancel
                reverseButtons
                onConfirm={async () => {
                    const items = selectedRows.map((item) => {
                        return { id: item.codNotificacao };
                    });
                    await webApi.post('Notificacao/lida', items);
                    setReload(!reload);
                    setConfirmUpdate(null);
                }}
                onCancel={() => {
                    setConfirmUpdate(null);
                }}
            >
                Tem certeza marcar as notificações selecionadas com lidas?
            </SweetAlert>
        );
    };

    const handleClickDelete = () => {
        setConfirmDelete(
            <SweetAlert
                show
                warning
                style={{ display: 'block' }}
                title="Você tem certeza?"
                confirmBtnBsStyle="danger"
                cancelBtnText="Não"
                confirmBtnText="Sim"
                closeOnClickOutside={false}
                showCancel
                reverseButtons
                onConfirm={async () => {
                    const notifications = selectedRows.map((item) => {
                        return item.codNotificacao;
                    });

                    await webApi.delete('notificacao/excluir', {
                        data: {
                            quantidade: notifications.length,
                            listaCodNotificacao: notifications,
                        },
                    });

                    setReload(!reload);
                    setConfirmDelete(null);
                }}
                onCancel={() => {
                    setConfirmDelete(null);
                }}
            >
                Tem certeza excluir as notificações selecionadas? Você não
                poderá reverter essa ação!
            </SweetAlert>
        );
    };

    const columns = useMemo(
        () => [
            {
                id: 'selection',
                disableSortBy: true,
                style: { width: '50px' },
                Header: ({ getToggleAllRowsSelectedProps }) => (
                    <TableColumnCheckBox
                        selectProps={getToggleAllRowsSelectedProps()}
                    />
                ),

                Cell: ({ row }) => (
                    <TableColumnCheckBox
                        selectProps={row.getToggleRowSelectedProps()}
                    />
                ),
            },

            {
                Header: '',
                accessor: 'title',
                disableSortBy: true,
                Cell: ({ row }) => (
                    <>
                        <RowCollapse row={row.original} />
                    </>
                ),
            },

            {
                Header: 'Titulo',
                accessor: 'titulo',
            },

            {
                Header: 'Mensagem',
                accessor: 'conteudo',
            },

            {
                Header: 'Situação',
                accessor: 'foiLida',
                Cell: ({ row }) =>
                    row.values.foiLida ? (
                        <Badge color="success">Lida</Badge>
                    ) : (
                        <Badge color="danger">Não Lida</Badge>
                    ),
            },
        ],

        []
    );

    return (
        <>
            <Row>
                <Col md="8">
                    <Button
                        disabled={selectedRows.length === 0}
                        type="button"
                        className="notfButton novoExcluir"
                        onClick={() => handleClickDelete()}
                    >
                        <i className="icon-icon-lixeira" />

                        <p>Excluir</p>
                    </Button>

                    <Button
                        disabled={selectedRows.length === 0}
                        type="button"
                        className="notfButton ml-4 novoExcluir"
                        color="primary"
                        onClick={() => handleClickUpdate()}
                    >
                        <i className="nc-icon nc-check-2" />

                        <p>Marcar como Lida</p>
                    </Button>
                </Col>
            </Row>

            <PageContent>
                <p
                    className="ml-1 mt-1 mb-1"
                    style={{
                        fontSize: '20px',
                        fontFamily: 'Inter, sans-serif',
                        color: '#57607A',
                        marginBottom: '-45px',
                    }}
                >
                    <strong style={{ color: '#41898C' }}>Notificações</strong>
                </p>

                <div className="row">
                    <div
                        className="col-sm-12 mr-1 ml-1"
                        style={{ marginLeft: '0px' }}
                    >
                        <DataTable
                            className=""
                            columns={columns}
                            loading={loading}
                            data={data}
                            fetchData={getNotifications}
                            pageCount={pageCount}
                            total={totalRecords}
                            reload={reload}
                            includePageSize={false}
                            includeTotalRecords={false}
                            setSelectedRows={setSelectedRows}
                            tableClass=""
                            includeFilter={false}
                        />
                    </div>
                </div>

                {confirmDelete}

                {confirmUpdate}
            </PageContent>
        </>
    );
}
