import React, { useState } from 'react';
import ImagemNaoDefinida from '~/assets/img/imagem-nao-definida.jpg';
import { Col, Row } from 'reactstrap';
import { useNotifyContext } from '~/hooks/useNotifyContext';
import SweetAlert from 'react-bootstrap-sweetalert';
import webApi from '~/services/webapi';
import styles from '../materiaisAvulsos.module.css';
import QuantidadeItem from '../../../components/InputQuantidade/QuantidadeItem';
import { situacaoCotacaoIndustriaConsts } from '../../../consts/situacaoOrcamentoIndustriaConsts';

function TipoProdutoComponent({ item }) {
    if (item?.tipo === 1 || item?.tipo === '1') {
        return (
            <>
                {/* <span className="mr-4">Peso: {item?.peso}kg/m </span> */}
                <span>Barra: {item?.barra}mm </span>
            </>
        );
    }

    if (item?.tipo === 2 || item?.tipo === '2') {
        return (
            <>
                <span>
                    Embalagem: {item?.quantidade} {item?.unidade}
                    (s)
                </span>
            </>
        );
    }
    return <span />;
}

export default function ItemProduto({ item, carregarPedido, pedido }) {
    const notificacao = useNotifyContext();
    const [showCancela, setShowCancela] = useState(false);

    function excluirItem() {
        setShowCancela(true);
    }

    return (
        <Row noGutters className={`boxAvulco ${styles.borda} mb-2`}>
            <Col lg={4} md={4} sm={4} xs={4}>
                <img
                    className={`${styles.img}`}
                    src={
                        item.imagemUrl
                            ? item.imagemUrl
                            : ImagemNaoDefinida
                    }
                />
            </Col>
            <Col lg={8} md={8} sm={8} xs={8} className="m-0 p-0">
                <Row
                    className="m-0 p-2"
                    style={{
                        background: '#f6f6f7',
                        width: '100%',
                        borderTopRightRadius: 10,
                    }}
                >
                    <Col lg={12} md={12} sm={12} xs={12} className="m-0 p-0">
                        <p className="my-1">
                            <strong>{item.codigo}</strong>
                        </p>
                        <p className="my-1">{item?.descricao}</p>
                        <TipoProdutoComponent item={item.item} />
                        <p className="my-1">{item.corDescricao}</p>
                        {item.valor > 0 && (
                            <div className="d-flex flex-column ml-3">
                                <Row>
                                    <span className="mr-2">Valor:</span>
                                    <span>
                                        {new Intl.NumberFormat('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL',
                                        }).format(item.valor)}
                                    </span>
                                </Row>
                            </div>
                        )}
                    </Col>
                </Row>
                <Row
                    className="m-0 p-2"
                    style={{
                        justifyContent: 'center',
                        alignContent: 'center',
                    }}
                >
                    <Col lg={6} md={6} sm={6} xs={6} className="m-0">
                        <QuantidadeItem
                            valor={item?.quantidadeItem}
                            pedido={pedido}
                        />
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={6}>
                        {(pedido?.status ===
                            situacaoCotacaoIndustriaConsts.cotacaoAberta) &&
                            (pedido?.codPedido === null || pedido?.codPedido === undefined) && (
                            <button
                                type="button"
                                className="btn btn-icon btn-icon-mini btn-neutral"
                                onClick={() => excluirItem()}
                            >
                                <i
                                    className={`icon-lixeira ${styles.iconeLixeira}`}
                                />
                            </button>
                        )}
                    </Col>
                </Row>
            </Col>

            <SweetAlert
                show={showCancela}
                onConfirm={async () => {
                    await webApi.delete(
                        `cotacao/${pedido.codCotacao}/item/${item.codItemCotacao}/excluir-item`
                    );
                    carregarPedido();
                    notificacao.sucesso('Item excluído com sucesso');
                    setShowCancela(false);
                }}
                title="Tem certeza que deseja excluir?"
                confirmBtnBsStyle="danger"
                cancelBtnText="Não"
                confirmBtnText="Sim"
                showCancel
                reverseButtons
                closeOnClickOutside={false}
                onCancel={() => setShowCancela(false)}
            >
                <p>Tem certeza que deseja excluir?</p>
            </SweetAlert>
        </Row>
    );
}
